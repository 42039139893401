import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const payoutReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_PAYOUT_COUNTRY):
      return { ...state, getPayoutCountryLoading: true, getPayoutCountryPayload: null, getPayoutCountryError: null };
    case SUCCESS(actionTypes.GET_PAYOUT_COUNTRY):
      return {
        ...state,
        getPayoutCountryPayload: actions.payload,
        getPayoutCountryError: null,
        getPayoutCountryLoading: false,
      };
    case FAILURE(actionTypes.GET_PAYOUT_COUNTRY):
      return {
        ...state,
        getPayoutCountryError: actions.payload,
        getPayoutCountryPayload: null,
        getPayoutCountryLoading: false,
      };
    case REQUEST(actionTypes.GET_IDENTITY_TYPES):
      return { ...state, getIdentityTypeLoading: true, getIdentityTypePayload: null, getIdentityTypeError: null };
    case SUCCESS(actionTypes.GET_IDENTITY_TYPES):
      return {
        ...state,
        getIdentityTypePayload: actions.payload,
        getIdentityTypeError: null,
        getIdentityTypeLoading: false,
      };
    case FAILURE(actionTypes.GET_IDENTITY_TYPES):
      return {
        ...state,
        getIdentityTypeError: actions.payload,
        getIdentityTypePayload: null,
        getIdentityTypeLoading: false,
      };
    case REQUEST(actionTypes.POST_PAYOUT_COUNTRY):
      return { ...state, postPayoutCountryLoading: true, postPayoutCountryPayload: null, postPayoutCountryError: null };
    case SUCCESS(actionTypes.POST_PAYOUT_COUNTRY):
      return {
        ...state,
        postPayoutCountryPayload: actions.payload,
        postPayoutCountryError: null,
        postPayoutCountryLoading: false,
      };
    case FAILURE(actionTypes.POST_PAYOUT_COUNTRY):
      return {
        ...state,
        postPayoutCountryError: actions.payload,
        postPayoutCountryPayload: null,
        postPayoutCountryLoading: false,
      };
    case REQUEST(actionTypes.GET_PAYOUT_MERCHANT):
      return { ...state, getPayoutMerchantLoading: true, getPayoutMerchantPayload: null, getPayoutMerchantError: null, mutateMerchantError: null, mutateMerchantPayload: null };
    case SUCCESS(actionTypes.GET_PAYOUT_MERCHANT):
      return {
        ...state,
        getPayoutMerchantPayload: actions.payload,
        getPayoutMerchantError: null,
        getPayoutMerchantLoading: false,
      };
    case FAILURE(actionTypes.GET_PAYOUT_MERCHANT):
      return {
        ...state,
        getPayoutMerchantError: actions.payload,
        getPayoutMerchantPayload: null,
        getPayoutMerchantLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_PAYOUT_REQUEST):
      return { ...state, togglePayoutRequestLoading: true, togglePayoutRequestPayload: null, togglePayoutRequestError: null };
    case SUCCESS(actionTypes.TOGGLE_PAYOUT_REQUEST):
      return {
        ...state,
        togglePayoutRequestPayload: actions.payload,
        togglePayoutRequestError: null,
        togglePayoutRequestLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_PAYOUT_REQUEST):
      return {
        ...state,
        togglePayoutRequestError: actions.payload,
        togglePayoutRequestPayload: null,
        togglePayoutRequestLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_ACTIVE_PAYOUT_REQUEST):
      return { ...state, toggleActivePayoutMerchantLoading: true, toggleActivePayoutMerchantPayload: null, toggleActivePayoutMerchantError: null };
    case SUCCESS(actionTypes.TOGGLE_ACTIVE_PAYOUT_REQUEST):
      return {
        ...state,
        toggleActivePayoutMerchantPayload: actions.payload,
        toggleActivePayoutMerchantError: null,
        toggleActivePayoutMerchantLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_ACTIVE_PAYOUT_REQUEST):
      return {
        ...state,
        toggleActivePayoutMerchantError: actions.payload,
        toggleActivePayoutMerchantPayload: null,
        toggleActivePayoutMerchantLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_COUNTRY_PAYOUT):
      return { ...state, toggleCountryLoading: true, toggleCountryPayload: null, toggleCountryError: null };
    case SUCCESS(actionTypes.TOGGLE_COUNTRY_PAYOUT):
      return {
        ...state,
        toggleCountryPayload: actions.payload,
        toggleCountryError: null,
        toggleCountryLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_COUNTRY_PAYOUT):
      return {
        ...state,
        toggleCountryError: actions.payload,
        toggleCountryPayload: null,
        toggleCountryLoading: false,
      };
    case REQUEST(actionTypes.GET_SWIFT_PAYOUT):
      return { ...state, getSwiftLoading: true, getSwiftPayload: null, getSwiftError: null };
    case SUCCESS(actionTypes.GET_SWIFT_PAYOUT):
      return {
        ...state,
        getSwiftPayload: actions.payload,
        getSwiftError: null,
        getSwiftLoading: false,
      };
    case FAILURE(actionTypes.GET_SWIFT_PAYOUT):
      return {
        ...state,
        getSwiftError: actions.payload,
        getSwiftPayload: null,
        getSwiftLoading: false,
      };
    case REQUEST(actionTypes.UPDATE_SWIFT_PAYOUT_STATUS):
      return { ...state, updateSwiftStatusLoading: true, updateSwiftStatusPayload: null, updateSwiftStatusError: null };
    case SUCCESS(actionTypes.UPDATE_SWIFT_PAYOUT_STATUS):
      return {
        ...state,
        updateSwiftStatusPayload: actions.payload,
        updateSwiftStatusError: null,
        updateSwiftStatusLoading: false,
      };
    case FAILURE(actionTypes.UPDATE_SWIFT_PAYOUT_STATUS):
      return {
        ...state,
        updateSwiftStatusError: actions.payload,
        updateSwiftStatusPayload: null,
        updateSwiftStatusLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_PAYOUT_GATEWAY):
      return { ...state, togglingGateway: true, toggleGatewayPayload: null, toggleGatewayError: null };
    case SUCCESS(actionTypes.TOGGLE_PAYOUT_GATEWAY):
      return {
        ...state,
        toggleGatewayPayload: actions.payload,
        toggleGatewayError: null,
        togglingGateway: false,
      };
    case FAILURE(actionTypes.TOGGLE_PAYOUT_GATEWAY):
      return {
        ...state,
        toggleGatewayError: actions.payload,
        toggleGatewayPayload: null,
        togglingGateway: false,
      };
    case REQUEST(actionTypes.POST_TRANSACTION_REQUERY):
      return { ...state, postTransactionRequestLoading: true, postTransactionRequestPayload: null, postTransactionRequestError: null };
    case SUCCESS(actionTypes.POST_TRANSACTION_REQUERY):
      return {
        ...state,
        postTransactionRequestPayload: actions.payload,
        postTransactionRequestError: null,
        postTransactionRequestLoading: false,
      };
    case FAILURE(actionTypes.POST_TRANSACTION_REQUERY):
      return {
        ...state,
        postTransactionRequestError: actions.payload,
        postTransactionRequestPayload: null,
        postTransactionRequestLoading: false,
      };
    case REQUEST(actionTypes.GET_TRANSACTION_DETAILS):
      return { ...state, getTransactionDetailsLoading: true, getTransactionDetailsPayload: null, getTransactionDetailsError: null };
    case SUCCESS(actionTypes.GET_TRANSACTION_DETAILS):
      return {
        ...state,
        getTransactionDetailsPayload: actions.payload,
        getTransactionDetailsError: null,
        getTransactionDetailsLoading: false,
      };
    case FAILURE(actionTypes.GET_TRANSACTION_DETAILS):
      return {
        ...state,
        getTransactionDetailsError: actions.payload,
        getTransactionDetailsPayload: null,
        getTransactionDetailsLoading: false,
      };
    case REQUEST(actionTypes.GET_GATEWAY):
      return {
        ...state,
        getGateWaySuccess: null,
        getGateWayError: null,
        getGateWayLoading: true,
      };
    case SUCCESS(actionTypes.GET_GATEWAY):
      return {
        ...state,
        getGateWayLoading: false,
        getGateWayPayload: actions.payload,
        getGateWayError: null,
      };
    case FAILURE(actionTypes.GET_GATEWAY):
      return {
        ...state,
        getGateWayLoading: false,
        getGateWayPayload: null,
        getGateWayError: actions.payload,
      };
    case REQUEST(actionTypes.FETCH_PAYOUT_MERCHANT_CONFIGS):
      return {
        ...state,
        merchantConfigsPayload: null,
        merchantConfigsError: null,
        fetchingMerchantConfigs: true,
      };
    case SUCCESS(actionTypes.FETCH_PAYOUT_MERCHANT_CONFIGS):
      return {
        ...state,
        fetchingMerchantConfigs: false,
        merchantConfigsPayload: actions.payload,
        merchantConfigsError: null,
      };
    case FAILURE(actionTypes.FETCH_PAYOUT_MERCHANT_CONFIGS):
      return {
        ...state,
        fetchingMerchantConfigs: false,
        merchantConfigsPayload: null,
        merchantConfigsError: actions.payload,
      };
    case REQUEST(actionTypes.MUTATE_PAYOUT_MERCHANT):
      return {
        ...state,
        mutateMerchantPayload: null,
        mutateMerchantError: null,
        mutatingMerchant: true,
      };
    case SUCCESS(actionTypes.MUTATE_PAYOUT_MERCHANT):
      return {
        ...state,
        mutatingMerchant: false,
        mutateMerchantPayload: actions.payload,
        mutateMerchantError: null,
      };
    case FAILURE(actionTypes.MUTATE_PAYOUT_MERCHANT):
      return {
        ...state,
        mutatingMerchant: false,
        mutateMerchantPayload: null,
        mutateMerchantError: actions.payload,
      };
    case REQUEST(actionTypes.FETCH_PAYOUT_COUNTRIES):
      return {
        ...state,
        payoutCountriesPayload: null,
        payoutCountriesError: null,
        fetchingPayoutCountries: true,
      };
    case SUCCESS(actionTypes.FETCH_PAYOUT_COUNTRIES):
      return {
        ...state,
        fetchingPayoutCountries: false,
        payoutCountriesPayload: actions.payload,
        payoutCountriesError: null,
      };
    case FAILURE(actionTypes.FETCH_PAYOUT_COUNTRIES):
      return {
        ...state,
        fetchingPayoutCountries: false,
        payoutCountriesPayload: null,
        payoutCountriesError: actions.payload,
      };
    case REQUEST(actionTypes.FETCH_WALLET_CURRENCIES):
      return {
        ...state,
        walletCurrencies: null,
        walletCurrenciesError: null,
        fetchingWalletCurrencies: true,
      };
    case SUCCESS(actionTypes.FETCH_WALLET_CURRENCIES):
      return {
        ...state,
        fetchingWalletCurrencies: false,
        walletCurrencies: actions.payload,
        walletCurrenciesError: null,
      };
    case FAILURE(actionTypes.FETCH_WALLET_CURRENCIES):
      return {
        ...state,
        fetchingWalletCurrencies: false,
        walletCurrencies: null,
        walletCurrenciesError: actions.payload,
      };
    case REQUEST(actionTypes.CREATE_PAYOUT_GATEWAY):
      return {
        ...state,
        createGatewayPayload: null,
        createGatewayError: null,
        creatingGateway: true,
      };
    case SUCCESS(actionTypes.CREATE_PAYOUT_GATEWAY):
      return {
        ...state,
        creatingGateway: false,
        createGatewayPayload: actions.payload,
        createGatewayError: null,
      };
    case FAILURE(actionTypes.CREATE_PAYOUT_GATEWAY):
      return {
        ...state,
        creatingGateway: false,
        createGatewayPayload: null,
        createGatewayError: actions.payload,
      };
    default:
      return { ...state };
  }
};

export default payoutReducer;
