import React from "react";
import Wrapper from "../Wrapper/Wrapper";
import * as CommonFunctions from "CommonFunctons";
import { appHelpers } from "appHelpers";
import { connect } from "react-redux";
import { saveUserData } from "logic/actions/actionTypes";
import { permissionstuff } from "permissionstuff";
import { ErrorPage } from "Pages/NotFound/ErrorPage";
import PreLoader from "components/Loader/PreLoader";
import Swal from "sweetalert2";
import IdleTimer from "react-idle-timer";
const LOCATIONSTORAGEKEY = "clientNavigationKey";
const PAYMENTGATEWAYSTORAGEKEY = "paymentGatewayNavigationKey";

class AuthenticatedLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { userobj: {} },
      token: "",
      sending: false,
      page: {},
      showSideBar: false,
      permissions: [],
      ServiceBase: null,
      ExportServiceBase: null,
      error: false,
      unAuthorized: null,
      expiresIn: parseInt(this.props.expiresIn),
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentWillMount() {
    // check if the user is authenticated. e.g if the token exist in localStorage or any where else
    // if token doesn't exist, reroute to login
  }
  async componentDidMount() {
    this.setState({ sending: true, error: false });
    let timeEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.UI--_"));
    if (timeEncrypt && timeEncrypt !== null) {
      console.log({timeEncrypt})
      let timeDecrypt = appHelpers.cryptographyService().decrypt(timeEncrypt);
      this.setState({ expiresIn: parseInt(timeDecrypt) });
    }
    let tkEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.UI_"));
    
    if (tkEncrypt && tkEncrypt !== null && tkEncrypt !== undefined) {
      let tkDecrypt = appHelpers.cryptographyService().decrypt(tkEncrypt);
      
      const Authtoken = `Bearer ${tkDecrypt}`;

      // let user = {};
      const { Constants } = this.props;
      let all = [];
    
      const clientNavigationKey = localStorage.getItem(LOCATIONSTORAGEKEY);
      const realKeyValue = parseInt(clientNavigationKey);
      if (realKeyValue) {
        if (this.props.location.pathname === "/home/clients" && clientNavigationKey !== -1) {
          localStorage.setItem(LOCATIONSTORAGEKEY, -1);
          this.props.history.push("/home/clients");
        }
      } else {
        localStorage.setItem(LOCATIONSTORAGEKEY, clientNavigationKey);
      }

      const paymentGatewayNavigationKey = localStorage.getItem(PAYMENTGATEWAYSTORAGEKEY);
      const realGatewayKeyValue = parseInt(paymentGatewayNavigationKey);
      if (realGatewayKeyValue) {
        if (this.props.location.pathname === "/home/payment-gateway" && paymentGatewayNavigationKey !== -1) {
          localStorage.setItem(PAYMENTGATEWAYSTORAGEKEY, -1);
          this.props.history.push("/home/payment-gateway");
        }
      } else {
        localStorage.setItem(PAYMENTGATEWAYSTORAGEKEY, paymentGatewayNavigationKey);
      }

      const permissions = permissionstuff.isAuthorized(all);

      await this.props
        .Service(Authtoken, this.props.history)
        .getInfo(Constants.USER, Constants.GET_USER_INFO)
        .then(res => {
          if (res !== undefined) {
            const { data } = res;
            if (data.requestSuccessful === true) {
              this.props.dispatch(saveUserData(data.responseData));
              //the token in LocalStorage was set on Login
              this.setState(
                {
                  ServiceBase: this.props.Service(Authtoken, this.props.history),
                  ExportServiceBase: this.props.ExportService(Authtoken, this.props.history),
                  validator: CommonFunctions.validator,
                  validatorAll: CommonFunctions.validatorAll,
                  permissions,
                  codes: all,
                },
                () => {
                  this.setState({ sending: false, error: false });
                }
              );
            } else {
              appHelpers.alertError("Something went wrong, login again.");
              this.props.history.push("/");
            }
          } else {
            this.setState({ sending: false, error: true });
          }
        })
        .catch(err => {
          if (err && err.response && err.response.status === 401) {
            this.setState({ unAuthorized: true });
          }
          this.setState({ error: true, sending: false });
        });
    } else {
      appHelpers.alertError("No Authorization Provided, please login again");
      this.props.history.push("/");
    }
  }

  logout = () => {
    this.setState({ sending: true }, () => {
      setTimeout(() => {
        localStorage.clear();
        this.props.history.push("/");
      }, 1000);
    });
  };

  handleOnIdle(event) {
    //alert("you're gonna be logged out")
    // let timerInterval;
    console.log("alidleTimer", this.idleTimer);

    Swal.fire({
      title: "Session Expired!",
      html: "Locking you out in <b></b> milliseconds.",
      timer: 5000,
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      confirmButtonText: "<span >Okay</span>",
      onBeforeOpen: () => {
        Swal.showLoading();
        setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      },
      //   onClose: () => {
      //     clearInterval(timerInterval)
      //   }
    }).then(result => {
      console.log("alidleTimer", this.idleTimer);

      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        localStorage.clear();
        this.props.history.push("/");
      }
      if (result.isConfirmed) {
        localStorage.clear();
        this.props.history.push("/");
      }
    });
    console.log("last active", this.idleTimer.getLastActiveTime());
  }

  handleOnAction(event) {
    // console.log('time remaining onAction', this.idleTimer.getRemainingTime())
  }

  handleOnActive(event) {
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  render() {
    const { expiresIn } = this.state;

    return (
      <div>
        {this.state.error && <ErrorPage {...this.props} unAuthorized={this.state.unAuthorized} />}
        {this.state.sending && (
          <div>
            <PreLoader />
          </div>
        )}

        {!this.state.sending && this.state.ServiceBase && this.state.ExportServiceBase && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            timeout={expiresIn * 1000} // convert the secconds value from the api to ms
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          >
            {" "}
            <Wrapper {...this.state} {...this.props} />
          </IdleTimer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userdata: state.authReducer.userdata,
    expiresIn: state.authReducer.expiresIn,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch, // ← Add this
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout);
