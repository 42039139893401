import React from 'react';
import SearchField from './SearchField';
import TuneIcon from '@material-ui/icons/Tune';
import CustomButton from "./CustomButton";

import './FilterMenu.scss';

export default function FilterMenu(props) {
    return (
        <div className="filter-menu-container">
            <div>
                {props.showSearch && <SearchField
                    label={props.searchLabel}
                    searchFieldContainerStyle={{ float: "right" }}
                    name={props.searchName}
                    style={props.searchFieldStyle}
                    value={props.searchValue}
                    searchValue={props.searchValue}
                    clearSearch={props.clearSearch}
                    onChange={(e) =>
                        props.handleSearchChange(e, props.searchName)
                    }
                />}
            </div>
            <div>
                {props.showFilter && <button
                    onClick={props.showFilterMenu}
                    className="btn filterButton"
                >
                    {props.filterLabel}&nbsp; &nbsp;{<TuneIcon />}
                </button>}
                {props.showCreateBtn && <CustomButton
                    onClick={() => props.openCreate()}
                    disabled={props.btnDisable}
                    show={true}
                    text={props.btnName}
                    className="create-btn"
                />}
            </div>
        </div>
    );
}
