import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

 
const complianceReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_CLIENT_DETAILS):
      return { ...state, getClientDetailsLoading: true, getClientDetailsPayload:null, getClientDetailsError:null };
    case SUCCESS(actionTypes.GET_CLIENT_DETAILS):
      return {
        ...state,
        getClientDetailsPayload: actions.payload,
        getClientDetailsError: null,
        getClientDetailsLoading: false,
      };
    case FAILURE(actionTypes.GET_CLIENT_DETAILS):
      return {
        ...state,
        getClientDetailsError: actions.payload,
        getClientDetailsPayload: null,
        getClientDetailsLoading: false,
      };
    case REQUEST(actionTypes.GET_PROFILE_DETAILS):
      return { ...state, getProfileDetailsLoading: true, getProfileDetailsPayload:null, getProfileDetailsError:null };
    case SUCCESS(actionTypes.GET_PROFILE_DETAILS):
      return {
        ...state,
        getProfileDetailsPayload: actions.payload,
        getProfileDetailsError: null,
        getProfileDetailsLoading: false,
      };
    case FAILURE(actionTypes.GET_PROFILE_DETAILS):
      return {
        ...state,
        getProfileDetailsError: actions.payload,
        getProfileDetailsPayload: null,
        getProfileDetailsLoading: false,
      };
    case REQUEST(actionTypes.GET_PROFILE_COMPLIANCE_DOC):
      return { ...state, getProfileComplianceDocLoading: true, getProfileComplianceDocPayload:null, getProfileComplianceDocError:null };
    case SUCCESS(actionTypes.GET_PROFILE_COMPLIANCE_DOC):
      return {
        ...state,
        getProfileComplianceDocPayload: actions.payload,
        getProfileComplianceDocError: null,
        getProfileComplianceDocLoading: false,
      };
    case FAILURE(actionTypes.GET_PROFILE_COMPLIANCE_DOC):
      return {
        ...state,
        getProfileComplianceDocError: actions.payload,
        getProfileComplianceDocPayload: null,
        getProfileComplianceDocLoading: false,
      };
    case REQUEST(actionTypes.GET_COMPLIANCE_MERCHANTS):
      return { ...state, getComplianceMerchantLoading: true, getComplianceMerchantPayload:null, getComplianceMerchantError:null };
    case SUCCESS(actionTypes.GET_COMPLIANCE_MERCHANTS):
      return {
        ...state,
        getComplianceMerchantPayload: actions.payload,
        getComplianceMerchantError: null,
        getComplianceMerchantLoading: false,
      };
    case FAILURE(actionTypes.GET_COMPLIANCE_MERCHANTS):
      return {
        ...state,
        getComplianceMerchantError: actions.payload,
        getComplianceMerchantPayload: null,
        getComplianceMerchantLoading: false,
      };
    case REQUEST(actionTypes.GET_COMPLIANCE_PROFILE):
      return { ...state, getComplianceProfileLoading: true, getComplianceProfilePayload:null, getComplianceProfileError:null };
    case SUCCESS(actionTypes.GET_COMPLIANCE_PROFILE):
      return {
        ...state,
        getComplianceProfilePayload: actions.payload,
        getComplianceProfileError: null,
        getComplianceProfileLoading: false,
      };
    case FAILURE(actionTypes.GET_COMPLIANCE_PROFILE):
      return {
        ...state,
        getComplianceProfileError: actions.payload,
        getComplianceProfilePayload: null,
        getComplianceProfileLoading: false,
      };
      case REQUEST(actionTypes.GET_COMPLIANCE_PROFILE_BY_ID):
        return { ...state, getComplianceProfileByIdLoading: true, getComplianceProfileByIdPayload:null, getComplianceProfileByIdError:null };
      case SUCCESS(actionTypes.GET_COMPLIANCE_PROFILE_BY_ID):
        return {
          ...state,
          getComplianceProfileByIdPayload: actions.payload,
          getComplianceProfileByIdError: null,
          getComplianceProfileByIdLoading: false,
        };
      case FAILURE(actionTypes.GET_COMPLIANCE_PROFILE_BY_ID):
        return {
          ...state,
          getComplianceProfileByIdError: actions.payload,
          getComplianceProfileByIdPayload: null,
          getComplianceProfileByIdLoading: false,
        };
        case REQUEST(actionTypes.GET_COMPLIANCE_DOC_BY_REFERENCE):
          return { ...state, getComplianceDocByReferenceLoading: true, getComplianceDocByReferencePayload:null, getComplianceDocByReferenceError:null };
        case SUCCESS(actionTypes.GET_COMPLIANCE_DOC_BY_REFERENCE):
          return {
            ...state,
            getComplianceDocByReferencePayload: actions.payload,
            getComplianceDocByReferenceError: null,
            getComplianceDocByReferenceLoading: false,
          };
        case FAILURE(actionTypes.GET_COMPLIANCE_DOC_BY_REFERENCE):
          return {
            ...state,
            getComplianceDocByReferenceError: actions.payload,
            getComplianceDocByReferencePayload: null,
            getComplianceDocByReferenceLoading: false,
          };
        case REQUEST(actionTypes.GET_COMPLIANCE_DOC):
          return { ...state, getComplianceDocLoading: true, getComplianceDocPayload:null, getComplianceDocError:null };
        case SUCCESS(actionTypes.GET_COMPLIANCE_DOC):
          return {
            ...state,
            getComplianceDocPayload: actions.payload,
            getComplianceDocError: null,
            getComplianceDocLoading: false,
          };
        case FAILURE(actionTypes.GET_COMPLIANCE_DOC):
          return {
            ...state,
            getComplianceDocError: actions.payload,
            getComplianceDocPayload: null,
            getComplianceDocLoading: false,
          };
        case REQUEST(actionTypes.COMPLIANCE_APPROVE):
          return { ...state, getComplianceApproveLoading: true, getComplianceApprovePayload:null, getComplianceApproveError:null };
        case SUCCESS(actionTypes.COMPLIANCE_APPROVE):
          return {
            ...state,
            getComplianceApprovePayload: actions.payload,
            getComplianceApproveError: null,
            getComplianceApproveLoading: false,
          };
        case FAILURE(actionTypes.COMPLIANCE_APPROVE):
          return {
            ...state,
            getComplianceApproveError: actions.payload,
            getComplianceApprovePayload: null,
            getComplianceApproveLoading: false,
          };
     default:
      return { ...state };
  }
};

export default complianceReducer;
