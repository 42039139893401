import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { clientsFilter } from 'Constants';
import FilterMenu from 'components/Input/FilterMenu';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from 'components/paginations/Paginations';
import { debounce } from 'lodash';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { createSmartCheckSubscriptionsPricingList, getSmartCheckSubscriptionsPlan, getSmartCheckSubscriptionsPlanPricingById, updateSmartCheckSubscriptionsPricingList } from "logic/actions/smartCheck";
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import ListTable from "../../../components/Table/ListTable";
import EditSubscriptionForm from "./editSubscriptionForm";

const initialFormValues = {
    billingItem: "",
    amount: "",
    ItemID: "",
    PlanId: "",
};

const Subscription = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    // const [status, setStatus] = useState(null);
    const [data, setData] = useState([]);
    const [createModal, setCreateModal] = useState({
        open: false,
        selectedId: '',
        payload: { ...initialFormValues },
        step: 1,
    }
    );
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();

    const { getSubscriptionsPayload, getSubscriptionsLoading, getSubscriptionsPricingLoading, getSubscriptionsPricingPayload, updateSubscriptionsPricingListPayload, updateSubscriptionsPricingListLoading, createSubscriptionsPricingListPayload, createSubscriptionsPricingListLoading } = useSelector(({ smartCheckReducer, }) => {
        return {
            getSubscriptionsError: smartCheckReducer?.getSubscriptionsPlanError,
            getSubscriptionsPayload: smartCheckReducer?.getSubscriptionsPlanPayload,
            getSubscriptionsLoading: smartCheckReducer?.getSubscriptionsPlanLoading,
            getSubscriptionsPricingLoading: smartCheckReducer?.getSubscriptionsPlanPricingLoading,
            getSubscriptionsPricingPayload: smartCheckReducer?.getSubscriptionsPlanPricingPayload,
            updateSubscriptionsPricingListPayload: smartCheckReducer?.updateSubscriptionsPricingListPayload,
            updateSubscriptionsPricingListLoading: smartCheckReducer?.updateSubscriptionsPricingListLoading,
            createSubscriptionsPricingListPayload: smartCheckReducer?.createSubscriptionsPricingListPayload,
            createSubscriptionsPricingListLoading: smartCheckReducer?.createSubscriptionsPricingListLoading,
        };
    });
    const getPaginationNumber = (page) => {
        setCurrentPage(page)
    }

    useEffect(() => {
        dispatch(getSmartCheckSubscriptionsPlan(currentPage, 10, searchValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getSubscriptionsPayload) {
            setData(getSubscriptionsPayload?.data.data);
        }
    }, [getSubscriptionsPayload]);

    const handleAutoCompleteCreateChange = (event, values, input) => { };

    const handlePlanSelection = (item) => {
        // Use the selected itemId as needed in the parent component
        setCreateModal((prev) => ({
            ...prev,
            selectedId: item.ItemID,
            payload: {
                ...prev.payload,
                billingItem: item.ItemName,
                amount: item.Price,
                ItemID: item.ItemID,
                PlanId: item.PlanID,
            },
            open: true,
        }));
    };

    const submit = () => {

        let errorStatus = false;
        if (createModal.step === 1 && createModal.selectedId) {
            setCreateModal((prev) => ({ ...prev, step: 2 }));
        } else {
            for (let data in createModal.payload) {
                if (createModal.payload.hasOwnProperty(data)) {
                    if (typeof createModal.payload[data] === 'boolean') {
                        continue; // Skip boolean values
                    } else if (!createModal.payload[data] || createModal.payload[data] === "") {
                        errorStatus = true;
                        setSubmitStatus(true);
                        break; // Exit the loop since there's an error
                    }
                }
            }
            if (!errorStatus) {
                dispatch(updateSmartCheckSubscriptionsPricingList(createModal.payload));
                setCreateModal((prev) => ({ ...prev, submitted: true }));
                setSubmitStatus(true);
            }
        }
    };

    useEffect(() => {
        if (!updateSubscriptionsPricingListLoading && updateSubscriptionsPricingListPayload?.data?.requestSuccessful && submitStatus) {
            setCreateModal((prev) => ({
                ...prev,
                open: false,
                step: 1
            }));
            setSubmitStatus(false);
            dispatch(getSmartCheckSubscriptionsPlan(currentPage, 10, searchValue));
            dispatch(successMessage("subscription updated successfully"))
        } else if (!updateSubscriptionsPricingListLoading && !updateSubscriptionsPricingListPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(updateSubscriptionsPricingListPayload?.data?.message || "Error occurred while updating this subscription"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSubscriptionsPricingListLoading]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setCreateModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
    };

    const debounceText = useCallback(debounce(async (srhTxt) => {
        // dispatch(getSmartCheckSubscriptionsPlan(1, 10, srhTxt));
    }, 500), [])

    const handleSearchChange = (event, input) => {
        const srhTxt = event.target.value;
        debounceText(srhTxt);
        setSearchValue(srhTxt);
    };

    const clearSearch = () => {
        debounceText("");
        setSearchValue("");
    };

    const showFilterMenu = () => { };

    const handleManageClick = (row) => {
        setCreateModal((prev) => ({ ...prev, open: true, payload: { ...prev.payload, ...row } }));
        dispatch(getSmartCheckSubscriptionsPlanPricingById(row?.PlanId));
    };

    const handleDefaultPlan = (row) => {
        if (!createSubscriptionsPricingListPayload?.data?.requestSuccessful && !createSubscriptionsPricingListLoading && createSubscriptionsPricingListPayload?.data?.message) {
            dispatch(errorMessage(createSubscriptionsPricingListPayload?.data?.message))
        } else if (createSubscriptionsPricingListPayload?.data?.requestSuccessful && !createSubscriptionsPricingListLoading) {
            dispatch(successMessage("Default plan added successfully"))
        }
        // console.log(row?.PlanId, row)
        dispatch(createSmartCheckSubscriptionsPricingList(row?.PlanId));

    };

    const itemsPerPage = 10;

    const format = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const slicedData = data.slice(startIndex, endIndex);

        return slicedData.filter((item) => item.Name.toLowerCase().includes(searchValue.toLowerCase())).map((item) => {
            const originalDate = item?.CreatedAt;
            const date = new Date(originalDate);
            const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

            return {
                name: item?.Name,
                id: item?.PlanId,
                code: item?.Code,
                desc: item?.Description,
                createdAt: formattedDate,
                Actions: (
                    <div className="d-flex align-items-center">
                        <Button
                            size="sm"
                            color="link"
                            className="text-primary font-weight-bold mr-4 p-0"
                            onClick={() => handleManageClick(item)}
                        >
                            Manage
                        </Button>
                        {
                            !item?.IsDefault ? (
                                <Button
                                    size="sm"
                                    color="link"
                                    className="text-primary font-weight-bold mr-4 p-0"
                                    onClick={() => handleDefaultPlan(item)}
                                >
                                    Make default
                                </Button>)
                                : null
                        }
                    </div>
                )
            };
        });
    };

    return (
        <>
            <div className="customScroll customScroll d-flex aling-items-center justify-space-between overflow-y-hidden">
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
                    showFilter={true}
                    showSearch={true}
                    showCreateBtn={false}
                    handleSearchChange={handleSearchChange}
                    searchValue={searchValue}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>
            <ListTable
                header={[
                    { name: "Plan Name" },
                    { name: "Description" },
                    { name: "Date" },
                    { name: "Action" },
                ]}
                isSearching={getSubscriptionsLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow key={index} style={{ cursor: "pointer" }}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">{row.desc}</TableCell>
                                <TableCell align="left">{row.createdAt}</TableCell>
                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {data?.length > 0 ? (
                <div className="paginate-content">
                    <div  >
                        Showing Page {currentPage} of{" "}
                        {Math.ceil(data?.length / itemsPerPage)}
                    </div>
                    <div>
                        <Pagination
                            totalPages={Math.ceil(data?.length / itemsPerPage)}
                            currentPage={currentPage}
                            changeCurrentPage={(p) => getPaginationNumber(p)}
                            previousBtn="Prev"
                            nextBtn="Next"
                        />
                    </div>
                </div>
            ) : null}

            {createModal.open && (
                <CreateModal
                    createForm={createModal.open}
                    handleCancelFromParent={createModal.step > 1}
                    onCancelClick={() => setCreateModal((prev) => ({ ...prev, step: 1 }))}
                    title={`${createModal.step === 1 ? `Manage Subscription` : `Edit Subscription`}`}
                    hideCreateForm={() =>
                        setCreateModal({ open: false, step: 1 })
                    }
                    onCreate={() => submit()}
                    creating={updateSubscriptionsPricingListLoading}
                    disableCreate={updateSubscriptionsPricingListLoading || createModal.payload?.ItemID === "" || createModal.payload?.ItemID === null || createModal.payload?.ItemID === undefined}
                    cancelText={createModal.step > 1 ? "Go Back" : "Close"}
                    createText={createModal.step > 1 ? "Finish" : "Edit"}
                    hidebtns={getSubscriptionsPricingPayload?.data.data.length === 0 || getSubscriptionsPricingLoading}
                    content={
                        <EditSubscriptionForm
                            step={createModal.step}
                            submitStatus={submitStatus}
                            onChange={handleFormChange}
                            payload={createModal.payload}
                            onPlanSelect={handlePlanSelection}
                            planList={getSubscriptionsPricingPayload?.data}
                            planIsLoading={getSubscriptionsPricingLoading}
                        />
                    }
                />
            )}
        </>
    )
}

export default Subscription;