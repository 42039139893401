import React from "react";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";

const AddCurrencyForm = ({ submitForm, formData, setFormData }) => {
  const onlyLetters = (e) => {
    const result = e.target.value.replace(/[^a-z]/gi, "");
    setFormData({ ...formData, currencyCode: result });
  };
  return (
    <Form onSubmit={(e) => submitForm(e, formData)}>
      <FormGroup>
        <Label for="currencyName"> Name</Label>
        <Input
          id="currencyName"
          name="currencyName"
          placeholder="Enter Currency name"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
          minLength={2}
          maxLength={20}
          type="text"
        />
        <FormFeedback>Oh noes! that name is already taken</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="currencyCode">Currency Code</Label>
        <Input
          id="currencyCode"
          name="currencyCode"
          placeholder="Enter Currency code"
          value={formData?.currencyCode || ""}
          onChange={(e) => onlyLetters(e)}
          required
          minLength={2}
          maxLength={3}
        />
      </FormGroup>
      <FormGroup>
        <Label for="numericCode">Numeric Code</Label>
        <Input
          id="numericCode"
          name="numericCode"
          required
          placeholder="Enter Numeric code"
          minLength={3}
          maxLength={10}
          onChange={(e) =>
            setFormData({ ...formData, numericCode: e.target.value })
          }
          type="number"
        />
      </FormGroup>
    </Form>
  );
};

export default AddCurrencyForm;
