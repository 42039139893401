import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Switch from "react-switch";

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import "../payout.scss";
import {
  createPayoutGateway,
  fetchPayoutCountries,
  getGateWay,
  togglePayoutGateway,
} from "logic/actions/payout";
import Pagination from "../../../components/paginations/Paginations";
import CreateModal from "components/Modal/CreateModal";
import { errorMessage, successMessage } from "logic/actions/notification";
import ToggleModal from "components/Modal/ToggleModal";
import { toast } from "react-toastify";
import Form from "./form";

const defaultValues = {
  name: "",
  code: "",
};

const Gateway = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [editor, setEditor] = useState({
    open: false,
    payload: { ...defaultValues },
    countries: [],
    submitted: false,
  });
  const [submitStatus, setSubmitStatus] = useState(false);
  const [toggle, setToggle] = useState({
    id: null,
    checked: false,
    open: false,
    toggled: false,
  });

  const {
    getGateWayPayload,
    getGateWayLoading,
    creatingGateway,
    toggleGatewayPayload,
    togglingGateway,
    createGatewayPayload,
    createGatewayError,
  } = useSelector(({ payoutReducer }) => {
    return {
      getGateWayPayload: payoutReducer?.getGateWayPayload,
      getGateWayLoading: payoutReducer?.getGateWayLoading,
      payoutCountriesPayload: payoutReducer?.payoutCountriesPayload,
      togglingGateway: payoutReducer?.togglingGateway,
      toggleGatewayPayload: payoutReducer?.toggleGatewayPayload,
      creatingGateway: payoutReducer?.creatingGateway,
      createGatewayPayload: payoutReducer?.createGatewayPayload,
      createGatewayError: payoutReducer?.createGatewayError,
    };
  });

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getGateWay(10, page, searchValue));
  };

  useEffect(() => {
    dispatch(getGateWay(10));
    dispatch(fetchPayoutCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getGateWayLoading && getGateWayPayload?.data?.requestSuccessful) {
      setData(getGateWayPayload?.data?.responseData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGateWayLoading]);

  useEffect(() => {
    if (!creatingGateway && editor.submitted) {
      setEditor((prev) => ({ ...prev, submitted: false }));

      if (createGatewayPayload?.data?.requestSuccessful) {
        dispatch(
          successMessage(
            createGatewayPayload?.data?.responseMessage ||
              createGatewayPayload?.data?.message ||
              "Record saved successfully"
          )
        );
        dispatch(getGateWay(10));
        setEditor((prev) => ({ ...prev, open: false, payload: {} }));
      } else {
        dispatch(
          errorMessage(
            createGatewayPayload?.data?.responseMessage ||
              createGatewayPayload?.data?.message ||
              "Something went wrong, unable to create gateway"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingGateway]);

  useEffect(() => {
    if (
      !creatingGateway &&
      Array.isArray(createGatewayError?.data?.errors) &&
      createGatewayError?.data?.errors?.length &&
      editor.submitted
    ) {
      const errors = createGatewayError?.data?.errors;
      for (let error of errors) {
        dispatch(errorMessage(error));
      }
      setEditor((prev) => ({ ...prev, submitted: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingGateway]);

  useEffect(() => {
    if (
      !togglingGateway &&
      toggleGatewayPayload?.data?.requestSuccessful &&
      toggle.toggled
    ) {
      toast.success(
        `Record ${toggle.checked ? "disabled" : "enabled"} successfully`,
        { position: "top-right" }
      );
      setToggle({ id: null, checked: false, open: false, toggled: false });
      dispatch(getGateWay(10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [togglingGateway]);

  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getGateWay(10, undefined, srhTxt));
    }, 500),
    []
  );

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const showFilterMenu = () => {};

  const handleAutoCompleteCreateChange = (event, values, input) => {};

  const openCreate = () => {
    setEditor((prev) => ({ ...prev, open: true }));
    setSubmitStatus(false);
  };
  const submit = () => {
    let errorStatus = false;
    setEditor((prev) => ({ ...prev, submitted: true }));
    const { payload } = editor;
    for (let data in payload) {
      if (payload[data] !== false && (!payload[data] || payload[data] === "")) {
        errorStatus = true;
        setSubmitStatus(true);
      }
    }
    if (!errorStatus) {
      dispatch(createPayoutGateway(payload));
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setEditor((prev) => ({
      ...prev,
      payload: { ...prev.payload, [name]: value },
    }));
  };

  const handleChange = (statusId, checked) => {
    setToggle({ id: statusId, checked, open: true });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
        className="customScroll"
      >
        <FilterMenu
          caption="Clients List"
          totalCount={10}
          searchLabel="Search.."
          filterOptions={clientsFilter}
          filterdefaultValueOptions={{
            label: "All",
            value: "all",
          }}
          handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
          showFilter={false}
          showSearch={true}
          showCreateBtn
          btnName="New Gateway&nbsp;&nbsp;+"
          openCreate={() => openCreate()}
          handleSearchChange={handleSearchChange}
          showFilterMenu={showFilterMenu}
          filterLabel="Filter by"
          searchName="keyword"
          searchValue={searchValue}
          clearSearch={clearSearch}
          entriesName="Entries"
          filterValue={"Search"}
          entriesOptions={{}}
          entriesdefaultValueOptions={{
            label: `Entries`,
            value: 20,
          }}
          entriesValue={{}}
          entriesLabel="Entries"
        />
      </div>

      <ListTable
        header={[
          { name: "Gateway Name" },
          { name: "Code" },
          { name: "Status" },
          { name: "Action" },
        ]}
        isSearching={getGateWayLoading}
        showError={false}
        style={{ opacity: 1 }}
        pageOfItems={data}
        content={
          <TableBody>
            {getGateWayPayload?.data?.responseData?.items?.map((row, index) => (
              <TableRow key={index} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.code}</TableCell>
                <TableCell align="left">
                  <div className="status-flag">
                    <span
                      className={`dot-status ${
                        row.isEnabled ? "dot-active" : "dot-inactive"
                      }`}
                    >
                      .
                    </span>
                    {row.isEnabled ? "Active" : "Inactive"}
                  </div>
                </TableCell>
                <TableCell align="left">
                  <Switch
                    height={18}
                    width={32}
                    onChange={() => handleChange(row?.id, row?.isEnabled)}
                    onColor="#175FFF"
                    checked={row?.isEnabled}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
      />
      <br />
      {data?.length > 0 ? (
        <div className="paginate-content px-4">
          <div>
            Showing Page {getGateWayPayload?.data?.responseData?.pageNumber} of{" "}
            {getGateWayPayload?.data?.responseData?.pages}
          </div>
          <div>
            <Pagination
              totalPages={getGateWayPayload?.data?.responseData?.pages}
              currentPage={currentPage}
              changeCurrentPage={(p) => getPaginationNumber(p)}
              previousBtn="Prev"
              nextBtn="Next"
            />
          </div>
        </div>
      ) : null}

      {editor.open && (
        <CreateModal
          handleCancelFromParent={editor.step > 1}
          onCancelClick={() => setEditor((prev) => ({ ...prev }))}
          title="New Gateway"
          createForm={editor.open}
          hideCreateForm={() =>
            setEditor({ open: false, payload: {}, countries: [] })
          }
          onCreate={() => submit()}
          creating={creatingGateway}
          disableCreate={creatingGateway}
          cancelText="Cancel"
          createText="Add"
          content={
            <Form
              payload={editor.payload}
              submitStatus={submitStatus}
              onChange={handleFormChange}
            />
          }
        />
      )}

      {toggle.open && (
        <ToggleModal
          title={toggle.checked ? "Disable item?" : "Enable Item?"}
          toggleForm={toggle.open}
          onToggle={() => {
            dispatch(togglePayoutGateway(toggle.id, toggle.status));
            setToggle((prev) => ({ ...prev, toggled: true }));
          }}
          toggling={togglingGateway}
          disableToggle={togglingGateway}
          hideToggleModal={() =>
            setToggle({ open: false, checked: false, id: null, toggled: false })
          }
          toggleText={toggle.checked ? "Disable" : "Enable"}
          textContent={
            toggle.checked
              ? "Are you sure you want to disable this item?"
              : "Are you sure you want to enable this item?"
          }
        />
      )}
    </>
  );
};

export default Gateway;
