import React, {useEffect, useState, useCallback} from 'react';
import { debounce} from 'lodash';
import { useDispatch, useSelector} from 'react-redux';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../Reconcilation.scss';
import { getReconcilationMerchant, toggleReconcilationRequest } from 'logic/actions/requests';
import ToggleModal from 'components/Modal/ToggleModal';
import Pagination from '../../../components/paginations/Paginations';

const Request = () => {
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleForm, setToggleForm] = useState(false);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

    const { getReconcilationLoading, getReconcilationPayload, toggleReconcilationLoading, toggleReconcilationPayload } = useSelector(({virtualAccountReducer}) => {
        return {
           getReconcilationError: virtualAccountReducer?.getReconcilationError,
            getReconcilationPayload: virtualAccountReducer?.getReconcilationPayload,
            getReconcilationLoading: virtualAccountReducer?.getReconcilationLoading,
            toggleReconcilationError: virtualAccountReducer?.toggleReconcilationError,
            toggleReconcilationPayload: virtualAccountReducer?.toggleReconcilationPayload,
            toggleReconcilationLoading: virtualAccountReducer?.toggleReconcilationLoading,
           };
      });

      const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getReconcilationMerchant(1, page, 10, searchValue))
      }
     
  useEffect(()=>{
      dispatch(getReconcilationMerchant(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    if(getReconcilationPayload && !getReconcilationLoading) {
       setData(getReconcilationPayload?.data?.responseData?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReconcilationLoading])
 
  useEffect(()=>{
    if(!getReconcilationLoading && getReconcilationPayload){
      setData(getReconcilationPayload?.data?.responseData?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReconcilationLoading]);

  useEffect(()=>{
    if(!toggleReconcilationLoading && toggleReconcilationPayload?.data?.requestSuccessful){
      let i = 0;
      for(let dt of data) {
        if(dt?.id === id) {
          data.splice(i, 1);
          setData([...data]);
          setToggleForm(false);
          return
        }
        i++
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleReconcilationLoading])
  
  const debounceText = useCallback(debounce(async(srhTxt) => {
    dispatch(getReconcilationMerchant(1, 1, 10, srhTxt)) 
  }, 500), [])
  
  
  const handleSearchChange = (event, input) => {
    debounceText(event.target.value)
    setSearchValue(event.target.value)
  };
  
  const clearSearch = () => {
    debounceText("")
    setSearchValue("")
  };
  

  const handleChange = (statusId, checked) => {
    setId(statusId);
    setStatus(checked);
    setToggleForm(true);
  }

  const showFilterMenu = () => {
  }; 

  
const handleAutoCompleteCreateChange = (event, values, input) => {
};

  const format = () => {
    return data?.map((item, index) => {
      return {
        id: item?.id,
        erpSystem: item?.erpSystem,
        merchantName: item?.merchantName,
        status: item?.approvalStatusDesc,
        Actions:
        <div>
          <span className="approve" onClick={()=>handleChange(item?.id, false)}>Approve</span>
          <span className="reject" onClick={()=>handleChange(item?.id, true)}>Reject</span>
        </div>
      };
    });
  }
 
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showCreateBtn={false}
            showSearch={true}
            handleSearchChange={handleSearchChange}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            searchValue={searchValue}
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Merchant Name" },
            { name: "Erp System" },
            { name: "Status" },
            { name: "Action" },
          ]}
          isSearching={getReconcilationLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={data}
          content={
            <TableBody>
              {format()?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell align="left">{row.merchantName}</TableCell>
                  <TableCell align="left">{row.erpSystem}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.Actions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page {getReconcilationPayload?.data?.responseData?.pageNumber}{" "}
              of {getReconcilationPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={getReconcilationPayload?.data?.responseData?.pages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {toggleForm && (
          <ToggleModal
            title={status ? "Reject?" : "Approve?"}
            toggleForm={toggleForm}
            onToggle={() => dispatch(toggleReconcilationRequest(id, status))}
            toggling={toggleReconcilationLoading}
            disableToggle={toggleReconcilationLoading}
            hideToggleModal={() => setToggleForm(false)}
            toggleText={status ? "Reject" : "Approve"}
            textContent={
              status
                ? "Are you sure you want to reject this item?"
                : "Are you sure you want to approve this item?"
            }
          />
        )}
      </>
    );
}

export default Request;