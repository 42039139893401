import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import Layout from "./components/hoc/Layout";
import * as serviceWorker from "./serviceWorker";
import configuration from "./configuration";
import * as Constants from "./Constants";
import { Service } from "./Services";
import { ExportService } from "./ExportService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import returnStoreAndPersistor from "./logic/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import Notification from "./components/Notifications/notifications";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./datepicker-override.scss";
import "./custommodalstyle.css";
import "./index.css";
import history from "./history";
const instance = Axios.create({
  baseURL: "",
  headers: {},
});
const { store } = returnStoreAndPersistor();
const { persistor } = returnStoreAndPersistor();

instance
  .get("/config.json")
  .then((res) => {
    if (window.env?.SERVICEBASEURI || (res.data && res?.data?.SERVICEBASEURI)) {
      const config = res.data;
      const serviceUrl =
        process.env.NODE_ENV !== "development"
          ? window.env?.SERVICEBASEURI || config?.SERVICEBASEURI
          : "https://afservicemanagertest.azurewebsites.net/api/v1/";
      sessionStorage.setItem("ApiBaseUrl", serviceUrl);

      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history} basename={configuration.basename}>
              <>
                <Notification />
                <Layout
                  Constants={Constants}
                  Service={Service.bind(null, serviceUrl, Axios)}
                  ExportService={ExportService.bind(null, serviceUrl, Axios)}
                />
                <ToastContainer className="custom-toast-container" limit={1} />
              </>
            </Router>
          </PersistGate>
        </Provider>,
        document.getElementById("root")
      );

      serviceWorker.unregister();
    } else {
      console.log("Error:", "base API URL is missing..");
    }
  })
  .catch((err) => {});
function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
  console.info = noop;
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
