import React, { useState, useEffect, useCallback } from 'react';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from 'components/Input/FilterMenu';
import { debounce } from 'lodash';
import { clientsFilter } from 'Constants';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from 'components/paginations/Paginations';
import { Button } from 'reactstrap';
import { getSmartCheckServices, newSmartCheckServices, updateSmartCheckServices } from 'logic/actions/smartCheck';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { useDispatch, useSelector } from 'react-redux';

const initialFormValues = {
    name: "",
    code: "",
    description: "",
};

const initialEditFormValues = {
    name: "",
    code: "",
    description: "",
    ItemId: "",
};

const Services = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [createModal, setCreateModal] = useState({
        open: false,
        payload: { ...initialFormValues },
        serviceId: "",
    });
    const [editorModal, setEditorModal] = useState({
        open: false,
        payload: {
            ...initialEditFormValues
        },
    });
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();

    const { getServicesLoading, getServicesPayload, addNewServicesLoading, addNewServicesPayload, updateServicesLoading, updateServicesPayload } = useSelector(({ smartCheckReducer, }) => {
        return {
            getServicesError: smartCheckReducer?.getServicesError,
            getServicesPayload: smartCheckReducer?.getServicesPayload,
            getServicesLoading: smartCheckReducer?.getServicesLoading,
            addNewServicesPayload: smartCheckReducer?.addNewServicesPayload,
            addNewServicesLoading: smartCheckReducer?.addNewServicesLoading,
            addNewServicesError: smartCheckReducer?.addNewServicesError,
            updateServicesPayload: smartCheckReducer?.updateServicesPayload,
            updateServicesLoading: smartCheckReducer?.updateServicesLoading,
            updateServicesError: smartCheckReducer?.updateServicesError,
        };
    });

    const updateService = () => {
        let errorStatus = false;
        for (let data in editorModal.payload) {
            if (!editorModal.payload[data] || editorModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }

        if (!errorStatus) {
            dispatch(updateSmartCheckServices(editorModal.payload));
            setSubmitStatus(true); // Add this line to reset submitStatus
        }
    };

    useEffect(() => {
        if (!updateServicesLoading && updateServicesPayload?.data?.requestSuccessful && submitStatus) {
            setEditorModal((prev) => ({
                ...prev,
                open: false,
            }));
            setSubmitStatus(false);
            dispatch(getSmartCheckServices());
            dispatch(successMessage("Billing updated successfully"))
        } else if (!updateServicesLoading && !updateServicesPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(updateServicesPayload?.data?.message || "Error occurred while updating this billing"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateServicesLoading]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setCreateModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
        setEditorModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
    };

    const getPaginationNumber = (page) => {
        setCurrentPage(page);
        dispatch(getSmartCheckServices(page, 10, searchValue))
    }

    const createNewService = () => {
        let errorStatus = false;
        for (let data in createModal.payload) {
            if (!createModal.payload[data] || createModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }
        if (!errorStatus) {
            dispatch(newSmartCheckServices(createModal.payload));
            setSubmitStatus(true);
        }
    }
    useEffect(() => {
        if (!addNewServicesLoading && addNewServicesPayload?.data?.requestSuccessful && submitStatus) {
            dispatch(getSmartCheckServices());
            setCreateModal((prev) => ({
                ...prev,
                payload: { ...initialFormValues },
            }));
            setCreateForm(false);
            setSubmitStatus(false);
            dispatch(successMessage("Billing created successfully"))
        } else if (!addNewServicesLoading && !addNewServicesPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(addNewServicesPayload?.data?.message || "Error occurred while creating this billing"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNewServicesLoading]);

    useEffect(() => {
        dispatch(getSmartCheckServices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getServicesPayload && !getServicesLoading) {
            setData(getServicesPayload?.data?.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getServicesLoading])

    const handleAutoCompleteCreateChange = (event, values, input) => { };

    const debounceText = useCallback(debounce(async (srhTxt) => {
        dispatch(getSmartCheckServices(1, 10, srhTxt))
    }, 500), [])

    const handleSearchChange = (event, input) => {
        debounceText(event.target.value);
        setSearchValue(event.target.value);
    };

    const clearSearch = () => {
        debounceText("");
        setSearchValue("");
    };

    const showFilterMenu = () => { };

    const handleManageClick = (row) => {
        setEditorModal((prev) => ({
            ...prev,
            open: true,
            payload: {
                name: row?.Name,
                code: row?.Code,
                description: row?.Description,
                ItemId: row?.ItemId,
            },
        }));
    };

    const itemsPerPage = 10;

    const format = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const formattedData = data.filter((item) =>
            item?.Name.toLowerCase().includes(searchValue.toLowerCase())
        )?.slice(startIndex, endIndex)?.map((item) => {
            return {
                name: item?.Name,
                id: item?.ItemId,
                code: item?.Code,
                desc: item?.Description,
                Actions: (
                    <div className=''>
                        <Button
                            size="sm"
                            color="link"
                            className="text-primary ml-2 font-weight-bold"
                            onClick={() => handleManageClick(item)}>
                            Edit
                        </Button>
                    </div>
                ),
            };
        });
        return formattedData;
    };
    return (
        <>
            <div className="customScroll customScroll d-flex aling-items-center justify-space-between overflow-y-hidden">
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
                    showFilter={false}
                    showSearch={true}
                    showCreateBtn={true}
                    btnName="New Service&nbsp;&nbsp;+"
                    openCreate={() => setCreateForm(true)}
                    handleSearchChange={handleSearchChange}
                    searchValue={searchValue}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>

            <ListTable
                header={[
                    { name: "Service Name" },
                    { name: "code" },
                    { name: "Description" },
                    { name: "" },
                ]}
                isSearching={getServicesLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow key={index} style={{ cursor: "pointer" }}>
                                <TableCell component="th" scope="row">
                                    <span className="capitalise">{row.name}</span>
                                </TableCell>
                                <TableCell align="left">{row.code}</TableCell>
                                <TableCell align="left">{row.desc}</TableCell>

                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {format()?.length > 0 ? (
                <div className="paginate-content">
                    <div  >
                        Showing Page {currentPage}{" "}
                        of {
                            Math.ceil(
                                data?.length / itemsPerPage
                            )
                        }
                    </div>
                    <div>
                        <Pagination
                            totalPages={
                                Math.ceil(
                                    data?.length / itemsPerPage
                                )
                            }
                            currentPage={currentPage}
                            changeCurrentPage={(p) => getPaginationNumber(p)}
                            previousBtn="Prev"
                            nextBtn="Next"
                        />
                    </div>
                </div>
            ) : null}

            {/* Create modal component */}
            {createForm && (
                <CreateModal
                    title="New Services"
                    createForm={createForm}
                    hideCreateForm={() => setCreateForm(false)}
                    onCreate={createNewService}
                    creating={addNewServicesLoading}
                    disableCreate={addNewServicesLoading}
                    cancelText="Cancel"
                    createText="Create"
                    content={
                        <form className="create-form">
                            <div>
                                <label id="name">Name</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Services Name'
                                    className="form-control"
                                    name="name"
                                />
                                {submitStatus && !createModal?.payload?.name && (
                                    <div className="error-desc">Name is required</div>
                                )}
                            </div>
                            <div>

                            </div>
                            <div>
                                <label id="code">Code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Services Code'
                                    className="form-control"
                                    name="code"
                                />
                                {submitStatus && !createModal?.payload.code && (
                                    <div className="error-desc">Code is required</div>
                                )}
                            </div>
                            <div>
                                <label htmlFor='narration' >Description</label>
                                <textarea
                                    rows={3}
                                    cols={4}
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Description'
                                    className='form-control'
                                    name="description"
                                />
                                {submitStatus && !createModal?.payload.description && (
                                    <div className="error-desc">Description is required</div>
                                )}
                            </div>
                        </form>
                    }
                />
            )}

            {/* Edit Modal */}
            {editorModal.open && (
                <CreateModal
                    onCancelClick={() => setEditorModal((prev) => ({ ...prev, open: false }))}
                    title={`Edit Services`}
                    createForm={editorModal.open}
                    hideCreateForm={() =>
                        setEditorModal({ open: false, payload: {}, })
                    }
                    onCreate={updateService}
                    creating={updateServicesLoading}
                    disableCreate={
                        updateServicesLoading
                    }
                    cancelText={"Cancel"}
                    createText={"Update"}
                    content={
                        <form className="create-form">
                            <div>
                                <label id="name">Name</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Services Name'
                                    className="form-control"
                                    name="name"
                                    defaultValue={editorModal?.payload.name}
                                />
                                {submitStatus && !editorModal?.payload?.name && (
                                    <div className="error-desc">Name is required</div>
                                )}
                            </div>
                            <div>

                            </div>
                            <div>
                                <label id="code">Code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Services Code'
                                    className="form-control"
                                    name="code"
                                    defaultValue={editorModal?.payload.code}
                                />
                                {submitStatus && !editorModal?.payload.code && (
                                    <div className="error-desc">Code is required</div>
                                )}
                            </div>
                            <div>
                                <label htmlFor='description' >Description</label>
                                <textarea
                                    rows={3}
                                    cols={4}
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Narration'
                                    className='form-control'
                                    name="description"
                                    defaultValue={editorModal?.payload.description}
                                />
                                {submitStatus && !editorModal?.payload.description && (
                                    <div className="error-desc">Description is required</div>
                                )}
                            </div>
                        </form>
                    }
                />
            )}
        </>
    )
}

export default Services;