import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from "lodash";
import { useDispatch, useSelector} from 'react-redux';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../FxServices.scss';
import { getCurrency } from 'logic/actions/requests';
import { getPastFx } from 'logic/actions/fxAction';
import Pagination from '../../../components/paginations/Paginations';

const PastRate = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const { gettingPastFxLoading, gettingPastFxPayload } = useSelector(({virtualAccountReducer, fxReducer}) => {
        return {
            gettingPastFxError: fxReducer?.gettingPastFxError,
            gettingPastFxPayload: fxReducer?.gettingPastFxPayload,
            gettingPastFxLoading: fxReducer?.gettingPastFxLoading,
           };
      });

    const getPaginationNumber = (page) => {
      setCurrentPage(page)
      dispatch(getPastFx(page, 10, searchValue))
    }
           
  useEffect(()=>{
      dispatch(getPastFx());
      dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(()=>{
    if(!gettingPastFxLoading && gettingPastFxPayload){
      setData(gettingPastFxPayload?.data?.responseData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettingPastFxLoading]);


  const handleAutoCompleteCreateChange = (event, values, input) => {
};

const debounceText = useCallback(debounce(async(srhTxt) => {
  dispatch(getPastFx(1, 10, srhTxt)) 
}, 500), [])


const handleSearchChange = (event, input) => {
  debounceText(event.target.value)
  setSearchValue(event.target.value)
};

const clearSearch = () => {
  debounceText("")
  setSearchValue("")
};


const showFilterMenu = () => {
}; 
   
const format = () => {
        return data?.map((item, index) => {
          return {
            from: item?.sourceCurrency,
            to: item?.destinationCurrency,
            rate: item?.rate,
            id: item?.id,
            status: item?.IsActive,
            date: item?.createdAt,
          };
        });
      }
     
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={true}
            showCreateBtn={false}
            btnName=""
            openCreate={() => {}}
            handleSearchChange={handleSearchChange}
            searchValue={searchValue}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "From" },
            { name: "To" },
            { name: "Rate" },
            { name: "Date" },
          ]}
          isSearching={gettingPastFxLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={gettingPastFxPayload?.data?.responseData?.items}
          content={
            <TableBody>
              {format()?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.from}
                  </TableCell>
                  <TableCell align="left">{row.to}</TableCell>
                  <TableCell align="left">{row.rate}</TableCell>
                  <TableCell align="left">
                    {new Date(row.date).toDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page{" "}
              {gettingPastFxPayload?.data?.responseData?.pageNumber} of{" "}
              {gettingPastFxPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={gettingPastFxPayload?.data?.responseData?.pages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}
      </>
    );
}

export default PastRate;
