import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Aux from "../../components/hoc/_Aux";
import './Compliance.scss';
import Merchants from "./merchants/merchants";

const Compliance = () => {
  const [tab, setTab] = useState("merchants")
  
    return (
      <Aux>
        <Row className="compliance-container">
          <Col>
              <div className="content-header">
                <div className="service-name">Compliance</div>
                <div className="service-items">
                  <div className={`item ${tab === 'merchants' && 'active'}`} onClick={()=>setTab('merchants')}>Merchants</div>
                </div>
                </div>
              <div className="tab-items">
                  {tab === "merchants" && <Merchants/>}
              </div>
          </Col>
        </Row>
    </Aux>
    );
  }

export default Compliance;
