import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import Select from 'react-select'
import '../../Collect.scss';
import CreateModal from 'components/Modal/CreateModal';
import GrowDot from 'icons/grow-dot';
import { Button } from 'reactstrap';
import { getSupportedCountry, getSupportedCurrency, manageMerchant } from 'logic/actions/collectAction';
import { CircularProgress } from '@material-ui/core';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { ArrowRight } from 'icons';

const payloadCountryData = {
    "currency": null,
    "pricingType": null,
    "pricingValue": null,
    "pricingCap": null,
    "revenueType": null,
    "revenueValue": null,
    "revenueCap": null,
    "countryCode": null
  }

const payloadChannelData = {
        "countryCode": null,
        "channelCode": null,
        "pricingPercentage": null,
        "pricingCap": null,
        "revenuePercentage": null,
        "currencyCode": null
  }

const Form = ({merchantId, manageForm, setManageForm}) => {
    const [createForm, setCreateForm] = useState(false);
    const [payload, setPayload] = useState({});
    const [submitStatus, setSubmitStatus] = useState(false);
    const [title, setTitle] = useState("");
    const dispatch = useDispatch();

    const { manageMerchantPayload, manageMerchantLoading, postMerchantLoading,getSupportedCurrencyPayload, getCountryPayload } = useSelector(({collectReducer}) => {
        return {
            manageMerchantError: collectReducer?.manageMerchantError,
            manageMerchantPayload: collectReducer?.manageMerchantPayload,
            manageMerchantLoading: collectReducer?.manageMerchantLoading,
            getCountryError: collectReducer?.getSupportedCountryError,
            getCountryPayload: collectReducer?.getSupportedCountryPayload,
            getCountryLoading: collectReducer?.getSupportedCountryLoading,
            getSupportedCurrencyError: collectReducer?.getSupportedCurrencyError,
            getSupportedCurrencyPayload: collectReducer?.getSupportedCurrencyPayload,
            getSupportedCurrencyLoading: collectReducer?.getSupportedCurrencyLoading,
         };
      });
    
    const openForm = (label) => {
        setTitle(label);
        if(label === "Country") {
            setPayload(payloadCountryData)
        }else{
            setPayload(payloadChannelData)
        }
        setCreateForm(true)
    }
    
    const submit = () => {
      let errorStatus = false;
        setSubmitStatus(true);
        console.log({payload})
        for(let data in payload) {
            if((payload[data] !== 0 && (!payload[data] || payload[data] === "")) && data !== "pricingFlat" && data !== "revenueFlat") {
                errorStatus = true;
            }
       }
       if(payload?.revenuePercentage && payload?.revenuePercentage > 100 ) {
           dispatch(errorMessage("Revenue percentage should not be greater than 100"))
           return
       }else if(payload?.pricingPercentage && payload?.pricingPercentage > 100 ) {
           dispatch(errorMessage("Price percentage should not be greater than 100"))
           return
       }   
        if(!errorStatus) {
            dispatch(manageMerchant(title,{...payload, merchantId}))
        }
    }

  useEffect(()=>{
      dispatch(getSupportedCountry());
      dispatch(getSupportedCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(()=>{
        if(!manageMerchantLoading && manageMerchantPayload?.data?.requestSuccessful && submitStatus){
          setCreateForm(false);
          setManageForm(false);
          setSubmitStatus(false);
          dispatch(successMessage(`${title==="Country"?"Country":"Channel"} configuration was successful`))
      }else if(!manageMerchantLoading && manageMerchantPayload?.data?.requestSuccessful === false && submitStatus){
        setSubmitStatus(false);
        dispatch(errorMessage(manageMerchantPayload?.data?.message || "Error occurred while performing this action"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageMerchantLoading]);

    return (
        <>

        {createForm && (
          <CreateModal
          title={`Manage ${title}`}
          createForm={createForm}
          hideCreateForm={() =>setCreateForm(false)}
          onCreate={()=>{}}
          creating={postMerchantLoading}
          disableCreate={postMerchantLoading}
          hidebtns={true}
          content={
            <form className="create-form manage-collect-merchant">
              <div>
                <label htmlFor="country">Country</label>
                <Select
                      value={payload?.countryPayload}
                      onChange={e => setPayload({...payload, countryCode: e.value?.toString(), countryPayload: e})}
                      options={getCountryPayload?.data?.map(val =>({ value: val?.countryCode, label: val?.countryName }))}
                  /> 
              </div>
              {submitStatus && !payload?.countryCode && <div className="error-desc">Country is required</div>}
              <div>
                <label htmlFor="country">Currency</label>
                <Select
                      value={payload?.currencyPayload}
                      onChange={e => setPayload({...payload, currency: e.value, currencyCode: e.value, currencyPayload: e})}
                      options={getSupportedCurrencyPayload?.data?.map(val =>({ value: val?.currencyCode, label: val?.currencyName }))}
                  /> 
               {submitStatus && !payload?.currency && <div className="error-desc">Currency is required</div>}
              </div>
              <div>
                <label htmlFor="country">{title === "Country"?"Pricing Type":"Channel"}</label>
                  <Select
                      value={payload?.pricingTypePayload}
                      onChange={e => setPayload({...payload, pricingType: e.value, channelCode: e.value, pricingTypePayload: e})}
                      options={title === "Country"?["Flat", "Percentage"].map(val=>({ value: val, label: val })):["Cards", "Transfer", "VisaQR", "NQR", "Bank", "USSD", "MobileMoney"].map(val=>({ value: val, label: val }))}
                  />
              </div>
               {submitStatus && !payload?.pricingType && <div className="error-desc">{title === "Country"?"Pricing Type":"Channel"} is required</div>}
             
              {title === "Country"?<><div>
                <label htmlFor="pricingValue">Pricing</label>
                <input type="number" value={payload?.pricingValue} onChange={(e)=>setPayload({...payload, pricingValue: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.pricingValue && <div className="error-desc">Pricing is required</div>}
              </>:<><div>
                <label htmlFor="pricingValue">Pricing Flat</label>
                <input type="number" value={payload?.pricingFlat} onChange={(e)=>setPayload({...payload, pricingFlat: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {/* {submitStatus && !payload?.pricingFlat && <div className="error-desc">Pricing Flat is required</div>} */}
              </>}

              {title !== "Country" && <>
               <div>
                <label htmlFor="pricingPercentage">Price Percentage</label>
                <input type="number" value={payload?.pricingPercentage} onChange={(e)=>setPayload({...payload, pricingPercentage: Number(e.target.value)})}  className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.pricingPercentage && <div className="error-desc">Price Percentage is required</div>}
               </>}

               <div>
                <label htmlFor="pricingValue">Pricing Cap</label>
                <input type="number" value={payload?.pricingCap} onChange={(e)=>setPayload({...payload, pricingCap: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.pricingCap && <div className="error-desc">Pricing Cap is required</div>}
             
               {title === "Country"&& <><div>
                <label htmlFor="country">Revenue Type</label>
                <Select
                      value={payload?.revenueTypePayload}
                      onChange={e => setPayload({...payload, revenueType: e.value, revenueTypePayload: e})}
                      options={[{value: "Flat", label: "Flat"}, {value: "Percentage", label: "Percentage"}]}
                  />  
              </div>
               {submitStatus && !payload?.revenueType && <div className="error-desc">Revenue Type is required</div>}
             </>}
              
             {title === "Country"?<>
                <div>
                <label htmlFor="revenueValue">Revenue</label>
                <input type="number" value={payload?.revenueValue} onChange={(e)=>setPayload({...payload, revenueValue: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.revenueValue && <div className="error-desc">Revenue is required</div>}
               </>:<>
                <div>
                <label htmlFor="revenueValue">Revenue Flat</label>
                <input type="number" value={payload?.revenueFlat} onChange={(e)=>setPayload({...payload, revenueFlat: e.target.value})} className="form-control" id="channelName"/>  
              </div>
               {/* {submitStatus && !payload?.revenueFlat && <div className="error-desc">Revenue Flat is required</div>} */}
               </>}
               {title === "Country"?<>
               <div>
                <label htmlFor="revenueValue">Revenue Cap</label>
                <input type="number" value={payload?.revenueCap} onChange={(e)=>setPayload({...payload, revenueCap: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.revenueCap && <div className="error-desc">Revenue Cap is required</div>}
               </>:<>
               <div>
                <label htmlFor="revenueValue">Revenue Percentage</label>
                <input type="number" value={payload?.revenuePercentage} onChange={(e)=>setPayload({...payload, revenuePercentage: Number(e.target.value)})}  className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.revenuePercentage && <div className="error-desc">Revenue Percentage is required</div>}
               </>}
               <div><Button onClick={()=>submit()} disabled={manageMerchantLoading} className="close-btn">Finish {manageMerchantLoading && (
              <CircularProgress
                size={20}
                color="white"
              />
            )}</Button></div>
               <div><Button onClick={()=>setManageForm(false)} className="goback-btn">Go Back</Button></div>
             
            </form>
          }
        />
        )}

        {manageForm && (
          <CreateModal
          title="Manage Merchant"
          createForm={manageForm}
          hideCreateForm={() =>setManageForm(false)}
          onCreate={()=>{}}
          creating={postMerchantLoading}
          disableCreate={postMerchantLoading}
          hidebtns={true}
          content={
            <div className="manage-collect-merchant">
                <div className="manage-merchant">
                    <span><span className="manage-icon"><GrowDot/></span> <span className="manage-title" onClick={()=>openForm("Country")}>Country Configuration</span></span>
                    <ArrowRight/>
                </div>
                <div className="manage-merchant">
                    <span><span className="manage-icon"><GrowDot/></span> <span className="manage-title" onClick={()=>openForm("Channel")}>Channel Configuration</span></span>
                    <ArrowRight/>
                </div>
                <div>
                    <Button onClick={()=>setManageForm(false)} className="close-btn">Close</Button>
                </div>
            </div>
          }
        />
        )}

        
      </>

    )
}

export default Form;