import React, { useState, useEffect } from 'react';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from 'components/Input/FilterMenu';
// import { debounce } from 'lodash';
import { clientsFilter } from 'Constants';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from 'components/paginations/Paginations';
import { Button } from 'reactstrap';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { useDispatch, useSelector } from 'react-redux';
import { getSmartCheckDocument, newSmartCheckDocument, updateSmartCheckDocument } from 'logic/actions/smartCheck';

const initialFormValues = {
    DocumentName: "",
    DocumentCode: "",
    DocumentDescription: "",
};

const initialEditFormValues = {
    Name: "",
    Code: "",
    Description: "",
    DocumentId: "",
};

const Index = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [createModal, setCreateModal] = useState({
        open: false,
        payload: { ...initialFormValues },
    });
    const [editorModal, setEditorModal] = useState({
        open: false,
        payload: {
            ...initialEditFormValues
        },
    });
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();

    const {
        getDocumentsPayload,
        getDocumentsLoading,
        updateDocumentsLoading,
        updateDocumentsPayload,
        addNewDocumentsLoading,
        addNewDocumentsPayload,
    } = useSelector(({ smartCheckReducer }) => {
        return {
            getDocumentsError: smartCheckReducer?.getDocumentsError,
            getDocumentsPayload: smartCheckReducer?.getDocumentsPayload,
            getDocumentsLoading: smartCheckReducer?.getDocumentsLoading,
            updateDocumentsLoading: smartCheckReducer?.updateDocumentsLoading,
            updateDocumentsError: smartCheckReducer?.updateDocumentsError,
            updateDocumentsPayload: smartCheckReducer?.updateDocumentsPayload,
            addNewDocumentsLoading: smartCheckReducer?.addNewDocumentsLoading,
            addNewDocumentsError: smartCheckReducer?.addNewDocumentsError,
            addNewDocumentsPayload: smartCheckReducer?.addNewDocumentsPayload,
        }
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setCreateModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
        setEditorModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
    };

    const getPaginationNumber = (page) => {
        setCurrentPage(page);
        // dispatch((page, 10, searchValue))
    }

    useEffect(() => {
        dispatch(getSmartCheckDocument(currentPage, 10, searchValue
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!getDocumentsLoading && getDocumentsPayload) {
            setData(getDocumentsPayload?.data?.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDocumentsLoading]);

    const handleAutoCompleteCreateChange = (event, values, input) => { };

    // const debounceText = useCallback(debounce(async (srhTxt) => {
    // }, 500), [])

    const handleSearchChange = (event, input) => {
        // debounceText(event.target.value);
        setSearchValue(event.target.value);
    };

    const clearSearch = () => {
        // debounceText("");
        setSearchValue("");
    };

    const showFilterMenu = () => { };

    const handleManageClick = (row) => {
        setEditorModal((prev) => ({
            ...prev,
            open: true,
            payload: {
                Name: row?.Name,
                Description: row?.Description,
                DocumentId: row?.DocumentId,
                Code: row?.Code,
            },
        }));
    };

    // const handleDelete = (item) => {
    //     console.log(item);
    // };

    const updateDocument = () => {
        let errorStatus = false;
        for (let data in editorModal.payload) {
            if (!editorModal.payload[data] || editorModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }

        if (!errorStatus) {
            dispatch(updateSmartCheckDocument(editorModal.payload));
            setSubmitStatus(true);
        }
    };

    useEffect(() => {
        if (!updateDocumentsLoading && updateDocumentsPayload?.data?.requestSuccessful && submitStatus) {
            setEditorModal((prev) => ({
                ...prev,
                open: false,
            }));
            setSubmitStatus(false);
            dispatch(getSmartCheckDocument());
            dispatch(successMessage("Document updated successfully"))
        } else if (!updateDocumentsLoading && !updateDocumentsPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(updateDocumentsPayload?.data?.message || "Error occurred while updating document"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDocumentsLoading]);

    const createDocument = () => {
        let errorStatus = false;
        for (let data in createModal.payload) {
            if (!createModal.payload[data] || createModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }
        if (!errorStatus) {
            dispatch(newSmartCheckDocument(createModal.payload));
            setSubmitStatus(true); // Add this line
        }
    }

    useEffect(() => {
        if (!addNewDocumentsLoading && addNewDocumentsPayload?.data?.requestSuccessful && submitStatus) {
            dispatch(getSmartCheckDocument());
            setCreateModal((prev) => ({
                ...prev,
                payload: { ...initialFormValues },
            }));
            setCreateForm(false);
            setSubmitStatus(false);
            dispatch(successMessage("Document created successfully"))
        } else if (!addNewDocumentsLoading && !addNewDocumentsPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(addNewDocumentsPayload?.data?.message || "Error occurred while creating document"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNewDocumentsLoading]);

    const itemsPerPage = 10;

    const format = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const formattedData = data.filter((item) =>
            item?.Name.toLowerCase().includes(searchValue.toLowerCase())
        )?.slice(startIndex, endIndex)?.map((item) => {
            return {
                name: item?.Name,
                description: item?.Description,
                id: item?.DocumentId,
                code: item?.Code,
                Actions: (
                    <div className='d-flex align-items-center'>
                        <Button
                            size="sm"
                            color="link"
                            className="text-primary ml-2 font-weight-bold"
                            onClick={() => handleManageClick(item)}>
                            Edit
                        </Button>
                        {/* <Button
                            size="sm"
                            color="link"
                            className="text-danger ml-2 font-weight-bold"
                            onClick={() => handleDelete(item)}>
                            Delete
                        </Button> */}

                    </div>
                ),
            };
        });
        return formattedData;
    };
    return (
        <>
            <div className="customScroll customScroll d-flex aling-items-center justify-space-between overflow-y-hidden">
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
                    showFilter={false}
                    showSearch={true}
                    showCreateBtn={true}
                    btnName="New Document&nbsp;&nbsp;+"
                    openCreate={() => setCreateForm(true)}
                    handleSearchChange={handleSearchChange}
                    searchValue={searchValue}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>

            <ListTable
                header={[
                    { name: "Document Name" },
                    { name: "Description" },
                    { name: "Code" },
                    { name: "" },
                ]}
                isSearching={getDocumentsLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow key={index} style={{ cursor: "pointer" }}>
                                <TableCell component="th" scope="row">
                                    <span className="capitalise">{row.name}</span>
                                </TableCell>
                                <TableCell align="left">{row.description}</TableCell>

                                <TableCell align="left">{row.code}</TableCell>

                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {format()?.length > 0 ? (
                <div className="paginate-content">
                    <div  >
                        Showing Page {currentPage}{" "}
                        of {
                            Math.ceil(
                                data?.length / itemsPerPage
                            )
                        }
                    </div>
                    <div>
                        <Pagination
                            totalPages={
                                Math.ceil(
                                    data?.length / itemsPerPage
                                )
                            }
                            currentPage={currentPage}
                            changeCurrentPage={(p) => getPaginationNumber(p)}
                            previousBtn="Prev"
                            nextBtn="Next"
                        />
                    </div>
                </div>
            ) : null}

            {/* Create modal component */}
            {createForm && (
                <CreateModal
                    title="New Document"
                    createForm={createForm}
                    hideCreateForm={() => setCreateForm(false)}
                    onCreate={createDocument}
                    creating={addNewDocumentsLoading}
                    disableCreate={addNewDocumentsLoading}
                    cancelText="Cancel"
                    createText="Create"
                    content={
                        <form className="create-form">
                            <div>
                                <label id="name">Name</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Document Name'
                                    className="form-control"
                                    name="DocumentName"
                                />
                                {submitStatus && !createModal?.payload?.DocumentName && (
                                    <div className="error-desc">Name is required</div>
                                )}
                            </div>
                            <div>

                            </div>
                            <div>
                                <label id="code">Code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Document Code'
                                    className="form-control"
                                    name="DocumentCode"
                                />
                                {submitStatus && !createModal?.payload.DocumentCode && (
                                    <div className="error-desc">Code is required</div>
                                )}
                            </div>
                            <div>
                                <label id="description">Description</label>
                                <textarea
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Document Description'
                                    className="form-control"
                                    name="DocumentDescription"
                                />
                                {submitStatus && !createModal?.payload.DocumentDescription && (
                                    <div className="error-desc">Description is required</div>
                                )}
                            </div>
                        </form>
                    }
                />
            )}

            {editorModal.open && (
                <CreateModal
                    onCancelClick={() => setEditorModal((prev) => ({ ...prev, open: false }))}
                    title={`Edit Document`}
                    createForm={editorModal.open}
                    hideCreateForm={() =>
                        setEditorModal({ open: false, payload: {}, })
                    }
                    onCreate={updateDocument}
                    creating={updateDocumentsLoading}
                    disableCreate={
                        updateDocumentsLoading
                    }
                    cancelText={"Cancel"}
                    createText={"Update"}
                    content={
                        <form className="create-form">
                            <div>
                                <label id="name">Name</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Document Name'
                                    className="form-control"
                                    name="Name"
                                    defaultValue={editorModal?.payload?.Name}
                                />
                                {submitStatus && !editorModal?.payload?.Name && (
                                    <div className="error-desc">Name is required</div>
                                )}
                            </div>
                            <div>

                            </div>
                            <div>
                                <label id="code">Code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Document Code'
                                    className="form-control"
                                    name="Code"
                                    defaultValue={editorModal?.payload?.Code}
                                />
                                {submitStatus && !editorModal?.payload.Code && (
                                    <div className="error-desc">Code is required</div>
                                )}
                            </div>

                            <div>
                                <label id="description">Description</label>
                                <textarea
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Document Description'
                                    className="form-control"
                                    name="Description"
                                    defaultValue={editorModal?.payload?.Description}
                                />
                                {submitStatus && !editorModal?.payload.Description && (
                                    <div className="error-desc">Description is required</div>
                                )}
                            </div>
                        </form>
                    }
                />
            )
            }
        </>
    )
}

export default Index;