import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function CustomButton({
  onClick,
  disabled,
  mainColor = true,
  style,
  className,
  loadingText,
  id,
  show,
  loading,
  text,
  btnColor,
  size,
}) {
  return (
    <>
      {show && (
        <button
          className={`btn btn-${btnColor ? btnColor : "success"} btn-${
            size ? size : "md"
          } shadow-2 d-flex align-items-center justify-content-center gap-2  ${
            mainColor ? mainColor : ""
          } ${className ? className : ""}`}
          onClick={onClick}
          disabled={disabled}
          style={{
            ...{ ...style, backgroundColor: "#175FFF" },
          }}
          id={id}>
          {loading && (
            <>
              <CircularProgress size={20} color="white" />
              {loadingText}
            </>
          )}{" "}
          {text}
        </button>
      )}
    </>
  );
}
