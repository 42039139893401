export const PAYOUT_CHART_TYPES = [
  {
    value: 1,
    label: "Flat",
  },
  {
    value: 2,
    label: "Percentage",
  },
];

export const PAYOUT_TYPES = [
  { label: "Bank", value: "Bank" },
  { label: "Mobile Money", value: "MobileMoney" },
  { label: "Swift", value: "Swift" },
];
