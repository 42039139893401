import actionTypes from "./actionTypes";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { Get, Post, Put } from "apiServices/apiHandler.service";
import { complianceBaseUrl } from "apiServices/config.service";

export const getFx = createAsyncAction(
  actionTypes.GET_FX,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/PlatformRate/GetAll?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      complianceBaseUrl
    );
  }
);

export const postFx = createAsyncAction(actionTypes.POST_FX, async (data) => {
  return await Post(`v1/PlatformRate`, data, complianceBaseUrl);
});

export const toggleFx = createAsyncAction(actionTypes.TOGGLE_FX, async (id) => {
  return await Put(
    `v1/PlatformRate/toggleisactive/${id}`,
    {},
    complianceBaseUrl
  );
});

export const getPastFx = createAsyncAction(
  actionTypes.GET_PAST_FX,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/PlatformRate/gethistoricalrates?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      complianceBaseUrl
    );
  }
);
