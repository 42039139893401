import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Aux from "../../../components/hoc/_Aux";
import "../Compliance.scss";
import DocHolder from "../../../assets/images/doc-holder.svg";
import {
  approve,
  getProfileComplianceDoc,
  getProfileDetails,
} from "logic/actions/compliance";
import LoadingOverlay from "react-loading-overlay";
import { errorMessage, successMessage } from "logic/actions/notification";
import { ArrowLeftIcon } from "icons/arrow-left";

const ProfileDetail = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [approving, setApproving] = useState(false);
  const {
    getProfileDetailsLoading,
    getProfileDetailsPayload,
    getProfileComplianceDocPayload,
    getComplianceApproveLoading,
    getComplianceApprovePayload,
  } = useSelector(({ complianceReducer }) => {
    return {
      getProfileDetailsError: complianceReducer?.getProfileDetailsError,
      getProfileDetailsPayload: complianceReducer?.getProfileDetailsPayload,
      getProfileDetailsLoading: complianceReducer?.getProfileDetailsLoading,
      getProfileComplianceDocError:
        complianceReducer?.getProfileComplianceDocError,
      getProfileComplianceDocPayload:
        complianceReducer?.getProfileComplianceDocPayload,
      getProfileComplianceDocLoading:
        complianceReducer?.getProfileComplianceDocLoading,
      getComplianceApproveLoading:
        complianceReducer?.getComplianceApproveLoading,
      getComplianceApprovePayload:
        complianceReducer?.getComplianceApprovePayload,
      getComplianceApproveError: complianceReducer?.getComplianceApproveError,
    };
  });

  const approveDocument = (id, clientId) => {
    setApproving(true);
    dispatch(
      approve({ type: "profile", typeQuery: "profileId", id, clientId })
    );
  };

  useEffect(() => {
    dispatch(getProfileDetails(params.peopleId, params.clientId));
    dispatch(getProfileComplianceDoc(params.peopleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getComplianceApprovePayload?.data?.requestSuccessful && approving) {
      setApproving(false);
      dispatch(successMessage("This compliance was successfully approved"));
      history.goBack();
    } else if (
      getComplianceApprovePayload?.data?.requestSuccessful === false &&
      approving
    ) {
      setApproving(false);
      dispatch(
        errorMessage(
          getComplianceApprovePayload?.data?.message ||
            "Error occurred while approving this compliance"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getComplianceApproveLoading]);

  console.log(params);

  return (
    <Aux>
      <Row className="compliance-container">
        <Col className="profile-detail-content">
          <div className="content-header">
            <div className="go-back" onClick={() => history.goBack()}>
              <ArrowLeftIcon /> Go Back
            </div>
            <div className="service-name">Profile Details</div>
            <div className="contents">
              <LoadingOverlay
                active={getProfileDetailsLoading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    minHeight: "100px",
                    opacity: 0.7,
                  }),
                }}
                spinner
                text="Loading Records..."
              >
                <Row>
                  <Col md="9">
                    <Row className="people-profile">
                      <Col md="6">
                        <div className="name">First Name</div>
                        <div className="desc">
                          {
                            getProfileDetailsPayload?.data?.responseData
                              ?.firstName
                          }
                        </div>
                      </Col>
                      {getProfileComplianceDocPayload?.data?.responseData?.map(
                        (val, i) => (
                          <Col md="6">
                            <div className="name">File</div>
                            <div className="desc-docs">
                              <span>
                                <img
                                  src={DocHolder}
                                  className="doc-img"
                                  alt="docs"
                                />
                                {/* <span>passport.png</span> */}
                              </span>
                              <span
                                className="view-file"
                                onClick={() => window.open(val, "_blank")}
                              >
                                View File
                              </span>
                            </div>
                          </Col>
                        )
                      )}
                      <Col md="6">
                        <div className="name">Last Name</div>
                        <div className="desc">
                          {
                            getProfileDetailsPayload?.data?.responseData
                              ?.lastName
                          }
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="name">Phone Number</div>
                        <div className="desc">
                          {
                            getProfileDetailsPayload?.data?.responseData
                              ?.phoneNumber
                          }
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="name">Country</div>
                        <div className="desc">
                          {
                            getProfileDetailsPayload?.data?.responseData
                              ?.country
                          }
                        </div>
                      </Col>
                    </Row>
                    <div className="submit-action">
                      <button
                        className="cancel"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </button>
                      <button
                        className="submit"
                        disabled={approving || params.status === "1"}
                        onClick={() =>
                          approveDocument(params.peopleId, params.clientId)
                        }
                      >
                        {approving && (
                          <CircularProgress
                            size={20}
                            style={{ marginBottom: "-4px" }}
                            color="white"
                          />
                        )}
                        {params.status === "1" ? "Approved" : "Approve"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>
          </div>
        </Col>
      </Row>
    </Aux>
  );
};

export default ProfileDetail;
