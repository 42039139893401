import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const cardsReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_ALL_CARDS_MERCHANTS):
      return { ...state, fetchingMerchants: true, merchantsPayload:null, merchantsError:null, merchantConfigPayload:null, merchantConfigError:null, upsertMerchantPayload:null, upsertMerchantError:null, toggleMerchantPayload:null, toggleMerchantError:null };
    case SUCCESS(actionTypes.GET_ALL_CARDS_MERCHANTS):
      return {
        ...state,
        merchantsPayload: actions.payload,
        merchantsError: null,
        fetchingMerchants: false,
      };
    case FAILURE(actionTypes.GET_ALL_CARDS_MERCHANTS):
      return {
        ...state,
        merchantsError: actions.payload,
        merchantsPayload: null,
        fetchingMerchants: false,
      };

    case REQUEST(actionTypes.GET_ALL_CARDS_REQUESTS):
      return { ...state, fetchingRequests: true, requestsPayload:null, requestsError:null, toggleRequestPayload: null, toggleRequestError: null };
    case SUCCESS(actionTypes.GET_ALL_CARDS_REQUESTS):
      return {
        ...state,
        requestsPayload: actions.payload,
        requestsError: null,
        fetchingRequests: false,
      };
    case FAILURE(actionTypes.GET_ALL_CARDS_REQUESTS):
      return {
        ...state,
        requestsError: actions.payload,
        requestsPayload: null,
        fetchingRequests: false,
      };

    case REQUEST(actionTypes.TOGGLE_CARDS_REQUEST):
      return { ...state, togglingRequest: true, toggleRequestPayload:null, toggleRequestError:null };
    case SUCCESS(actionTypes.TOGGLE_CARDS_REQUEST):
      return {
        ...state,
        toggleRequestPayload: actions.payload,
        toggleRequestError: null,
        togglingRequest: false,
      };
    case FAILURE(actionTypes.TOGGLE_CARDS_REQUEST):
      return {
        ...state,
        toggleRequestError: actions.payload,
        toggleRequestPayload: null,
        togglingMerchant: false,
      };

    case REQUEST(actionTypes.TOGGLE_CARDS_MERCHANT):
      return { ...state, togglingMerchant: true, toggleMerchantPayload:null, toggleMerchantError:null };
    case SUCCESS(actionTypes.TOGGLE_CARDS_MERCHANT):
      return {
        ...state,
        toggleMerchantPayload: actions.payload,
        toggleMerchantError: null,
        togglingMerchant: false,
      };
    case FAILURE(actionTypes.TOGGLE_CARDS_MERCHANT):
      return {
        ...state,
        toggleMerchantError: actions.payload,
        toggleMerchantPayload: null,
        togglingMerchant: false,
      };

    case REQUEST(actionTypes.FETCH_CARDS_CURRENCIES):
      return { ...state, fetchingCurrencies: true, currenciesPayload:null, currenciesError:null, upsertCurrencyPayload: null, upsertCurrencyError: null };
    case SUCCESS(actionTypes.FETCH_CARDS_CURRENCIES):
      return {
        ...state,
        currenciesPayload: actions.payload,
        currenciesError: null,
        fetchingCurrencies: false,
      };
    case FAILURE(actionTypes.FETCH_CARDS_CURRENCIES):
      return {
        ...state,
        currenciesError: actions.payload,
        currenciesPayload: null,
        fetchingCurrencies: false,
      };

    case REQUEST(actionTypes.FETCH_CARDS_MERCHANT_CONFIG):
      return { ...state, fetchingMerchantConfig: true, merchantConfigPayload:null, merchantConfigError:null };
    case SUCCESS(actionTypes.FETCH_CARDS_MERCHANT_CONFIG):
      return {
        ...state,
        merchantConfigPayload: actions.payload,
        merchantConfigError: null,
        fetchingMerchantConfig: false,
      };
    case FAILURE(actionTypes.FETCH_CARDS_MERCHANT_CONFIG):
      return {
        ...state,
        merchantConfigError: actions.payload,
        merchantConfigPayload: null,
        fetchingMerchantConfig: false,
      };

    case REQUEST(actionTypes.UPSERT_CARDS_CURRENCY):
      return { ...state, upsertingCurrency: true, upsertCurrencyPayload:null, upsertCurrencyError:null };
    case SUCCESS(actionTypes.UPSERT_CARDS_CURRENCY):
      return {
        ...state,
        upsertCurrencyPayload: actions.payload,
        upsertCurrencyError: null,
        upsertingCurrency: false,
      };
    case FAILURE(actionTypes.UPSERT_CARDS_CURRENCY):
      return {
        ...state,
        upsertCurrencyError: actions.payload,
        upsertCurrencyPayload: null,
        upsertingCurrency: false,
      };

    case REQUEST(actionTypes.UPSERT_CARDS_MERCHANT):
      return { ...state, upsertingMerchant: true, upsertMerchantPayload:null, upsertMerchantError:null };
    case SUCCESS(actionTypes.UPSERT_CARDS_MERCHANT):
      return {
        ...state,
        upsertMerchantPayload: actions.payload,
        upsertMerchantError: null,
        upsertingMerchant: false,
      };
    case FAILURE(actionTypes.UPSERT_CARDS_MERCHANT):
      return {
        ...state,
        upsertMerchantError: actions.payload,
        upsertMerchantPayload: null,
        upsertingMerchant: false,
      };

     default:
      return { ...state };
  }
};

export default cardsReducer;
