import { getClientDetail } from "logic/actions/compliance";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Aux from "../../../components/hoc/_Aux";
import Business from "../business/business";
import '../Compliance.scss';
import Licenses from "../licenses/licenses";
import People from "../people/people";

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1)
  const params = useParams();
  const history = useHistory();
  
  const { getClientDetailsPayload } = useSelector (({complianceReducer}) => {
    return {
       getClientDetailsError: complianceReducer?.getClientDetailsError,
        getClientDetailsPayload: complianceReducer?.getClientDetailsPayload,
        getClientDetailsLoading: complianceReducer?.getClientDetailsLoading,
      };
  });

  useEffect(() => {
    dispatch(getClientDetail(params.id))
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
    return (
      <Aux>
        <Row className="compliance-container">
          <Col className="profile-detail-content">
              <div className="content-header">
                <div className="go-back" onClick={()=>history.goBack()}>Go Back</div>
                <div className="service-name">{getClientDetailsPayload?.data?.responseData?.name}</div>
                <div className="row">
                <div className="col tab-content">
                    <div className={`${active === 1?'active-tab':'inactive-tab'}`} onClick={()=>setActive(1)}>
                        People’s Profile
                    </div>
                    <div className={`${active === 2?'active-tab':'inactive-tab'}`} onClick={()=>setActive(2)}>
                        Business Profile
                    </div>
                    <div className={`${active === 3?'active-tab':'inactive-tab'}`} onClick={()=>setActive(3)}>
                        Licenses
                    </div>
                </div>
                </div>
                <div className="row contents">
                    <div  className="col">
                      {active === 1 && <People />}
                      {active === 2 && <Business/>}
                      {active === 3 && <Licenses/>}

                    </div>
                </div>
            </div>
          </Col>
        </Row>
    </Aux>
    );
  }

export default ProfileInfo;
