import React, { Component } from "react";
import "./PreLoader.css";

class PreLoader extends Component {
	// constructor(props){
	// 	super(props);
	// }

	render() {

		return (
			<div className="preloader2" style={{ marginTop: `${window.innerHeight / 2.2}px` }}>
				<div className="loader">
					<div className="loader__figure"></div>
					<p className="loader__label">Service Manager Admin</p>
				</div>
			</div>
		)
	}
}

export default PreLoader;
