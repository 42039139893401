import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const kycReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_KYC_ONBOARDED_COUNTRIES):
      return { ...state, fetchingOnboardedCountries: true, onboardedCountriesPayload:null, onboardedCountriesError:null };
    case SUCCESS(actionTypes.GET_KYC_ONBOARDED_COUNTRIES):
      return {
        ...state,
        onboardedCountriesPayload: actions.payload,
        onboardedCountriesError: null,
        fetchingOnboardedCountries: false,
      };
    case FAILURE(actionTypes.GET_KYC_ONBOARDED_COUNTRIES):
      return {
        ...state,
        onboardedCountriesError: actions.payload,
        onboardedCountriesPayload: null,
        fetchingOnboardedCountries: false,
      };
    case REQUEST(actionTypes.FETCH_KYC_MERCHANT_CONFIGS):
      return { ...state, fetchingMerchantConfigs: true, merchantConfigsPayload:null, merchantConfigsError:null };
    case SUCCESS(actionTypes.FETCH_KYC_MERCHANT_CONFIGS):
      return {
        ...state,
        merchantConfigsPayload: actions.payload,
        merchantConfigsError: null,
        fetchingMerchantConfigs: false,
      };
    case FAILURE(actionTypes.FETCH_KYC_MERCHANT_CONFIGS):
      return {
        ...state,
        merchantConfigsError: actions.payload,
        merchantConfigsPayload: null,
        fetchingMerchantConfigs: false,
      };
    case REQUEST(actionTypes.MUTATE_KYC_MERCHANT):
      return { ...state, mutatingMerchant: true, mutateMerchantPayload:null, mutateMerchantError:null };
    case SUCCESS(actionTypes.MUTATE_KYC_MERCHANT):
      return {
        ...state,
        mutateMerchantPayload: actions.payload,
        mutateMerchantError: null,
        mutatingMerchant: false,
      };
    case FAILURE(actionTypes.MUTATE_KYC_MERCHANT):
      return {
        ...state,
        mutateMerchantError: actions.payload,
        mutateMerchantPayload: null,
        mutatingMerchant: false,
      };
    case REQUEST(actionTypes.FETCH_KYC_MERCHANT_ENTITIES):
      return { ...state, fetchingCountryEntities: true, countryEntitiesPayload:null, countryEntitiesError:null };
    case SUCCESS(actionTypes.FETCH_KYC_MERCHANT_ENTITIES):
      return {
        ...state,
        countryEntitiesPayload: actions.payload,
        countryEntitiesError: null,
        fetchingCountryEntities: false,
      };
    case FAILURE(actionTypes.FETCH_KYC_MERCHANT_ENTITIES):
      return {
        ...state,
        countryEntitiesError: actions.payload,
        countryEntitiesPayload: null,
        fetchingCountryEntities: false,
      };
    case REQUEST(actionTypes.GET_KYC_ENTITIES):
      return { ...state, getKycEntitiesLoading: true, getKycEntitiesPayload:null, getKycEntitiesError:null };
    case SUCCESS(actionTypes.GET_KYC_ENTITIES):
      return {
        ...state,
        getKycEntitiesPayload: actions.payload,
        getKycEntitiesError: null,
        getKycEntitiesLoading: false,
      };
    case FAILURE(actionTypes.GET_KYC_ENTITIES):
      return {
        ...state,
        getKycEntitiesError: actions.payload,
        getKycEntitiesPayload: null,
        getKycEntitiesLoading: false,
      };
    case REQUEST(actionTypes.GET_IDENTITY_TYPES):
      return { ...state, getIdentityTypeLoading: true, getIdentityTypePayload:null, getIdentityTypeError:null };
    case SUCCESS(actionTypes.GET_IDENTITY_TYPES):
      return {
        ...state,
        getIdentityTypePayload: actions.payload,
        getIdentityTypeError: null,
        getIdentityTypeLoading: false,
      };
    case FAILURE(actionTypes.GET_IDENTITY_TYPES):
      return {
        ...state,
        getIdentityTypeError: actions.payload,
        getIdentityTypePayload: null,
        getIdentityTypeLoading: false,
      };
    case REQUEST(actionTypes.POST_KYC_ENTITIES):
      return { ...state, postKycEntitiesLoading: true, postKycEntitiesPayload:null, postKycEntitiesError:null };
    case SUCCESS(actionTypes.POST_KYC_ENTITIES):
      return {
        ...state,
        postKycEntitiesPayload: actions.payload,
        postKycEntitiesError: null,
        postKycEntitiesLoading: false,
      };
    case FAILURE(actionTypes.POST_KYC_ENTITIES):
      return {
        ...state,
        postKycEntitiesError: actions.payload,
        postKycEntitiesPayload: null,
        postKycEntitiesLoading: false,
      };
    case REQUEST(actionTypes.GET_KYC_MERCHANT):
      return { ...state, getKycMerchantLoading: true, getKycMerchantPayload:null, getKycMerchantError:null, mutateMerchantPayload:null, mutateMerchantError:null  };
    case SUCCESS(actionTypes.GET_KYC_MERCHANT):
      return {
        ...state,
        getKycMerchantPayload: actions.payload,
        getKycMerchantError: null,
        getKycMerchantLoading: false,
      };
    case FAILURE(actionTypes.GET_KYC_MERCHANT):
      return {
        ...state,
        getKycMerchantError: actions.payload,
        getKycMerchantPayload: null,
        getKycMerchantLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_KYC_REQUEST):
      return { ...state, toggleKycRequestLoading: true, toggleKycRequestPayload:null, toggleKycRequestError:null };
    case SUCCESS(actionTypes.TOGGLE_KYC_REQUEST):
      return {
        ...state,
        toggleKycRequestPayload: actions.payload,
        toggleKycRequestError: null,
        toggleKycRequestLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_KYC_REQUEST):
      return {
        ...state,
        toggleKycRequestError: actions.payload,
        toggleKycRequestPayload: null,
        toggleKycRequestLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_ACTIVE_KYC_REQUEST):
      return { ...state, toggleActiveKycMerchantLoading: true, toggleActiveKycMerchantPayload:null, toggleActiveKycMerchantError:null };
    case SUCCESS(actionTypes.TOGGLE_ACTIVE_KYC_REQUEST):
      return {
        ...state,
        toggleActiveKycMerchantPayload: actions.payload,
        toggleActiveKycMerchantError: null,
        toggleActiveKycMerchantLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_ACTIVE_KYC_REQUEST):
      return {
        ...state,
        toggleActiveKycMerchantError: actions.payload,
        toggleActiveKycMerchantPayload: null,
        toggleActiveKycMerchantLoading: false,
      };
    case REQUEST(actionTypes.KYC_TRANSACTION_STATS):
      return { ...state, kycTransactionStatsLoading: true, kycTransactionStatsPayload:null, kycTransactionStatsError:null };
    case SUCCESS(actionTypes.KYC_TRANSACTION_STATS):
      return {
        ...state,
        kycTransactionStatsPayload: actions.payload,
        kycTransactionStatsError: null,
        kycTransactionStatsLoading: false,
      };
    case FAILURE(actionTypes.KYC_TRANSACTION_STATS):
      return {
        ...state,
        kycTransactionStatsError: actions.payload,
        kycTransactionStatsPayload: null,
        kycTransactionStatsLoading: false,
      };
     default:
      return { ...state };
  }
};

export default kycReducer;
