import React, { useState, useCallback } from "react";

import { debounce } from "lodash";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "components/Input/FilterMenu"
import { fetchAllUnSettledTransactions } from "logic/actions/requests";
import getSymbolFromCurrency from "currency-symbol-map";
import { Button } from "reactstrap";
import { clientsFilter } from "Constants";
import ListTable from "components/Table/ListTable";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/paginations/Paginations";
import { Grid, Paper } from "@material-ui/core";
import PageTitle from "components/PageTitle/PageTitle";
import { useHistory } from "react-router";

import '../settlements.scss';
import { useQueryParams } from "utils/hooks";
import { useEffect } from "react";
import { NumericFormat } from "react-number-format";

const GroupedUnsettledTransactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  const startDate = query.get("startDate")
  const endDate = query.get("endDate")
  const currency = query.get("currency")

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const {
    fetchingUnsettledTransactions,
    unsettledTransactions,
  } = useSelector(({ virtualAccountReducer }) => {
    return {
      getTransactionError: virtualAccountReducer?.getTransactionError,
      fetchingUnsettledTransactions: virtualAccountReducer?.fetchingUnsettledTransactions,
      unsettledTransactions: virtualAccountReducer?.unsettledTransactions,
    };
  });

  useEffect(() => {
    if (startDate && endDate && currency) {
      dispatch(fetchAllUnSettledTransactions(currency, startDate, endDate))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currency])

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const showFilterMenu = () => { };

  const handleAutoCompleteCreateChange = (event, values, input) => { };

  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(fetchAllUnSettledTransactions(currency, startDate, endDate, 1, 10, srhTxt))
    }, 500),
    []
  );

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(fetchAllUnSettledTransactions(currency, startDate, endDate, page, 10, searchValue))
  };

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  const handleViewClick = (data) => {
    history.push(`/services/virtual-accounts/transactions?merchantId=${data.merchantId}&name=${data.merchantName}`, {
      startDate, endDate, currency
    })
  }

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <PageTitle title="Transactions yet to be settled" linkText="Settlements" onGoBack={() => history.push("/services/virtual-accounts")} />
      </Grid>

      <Grid item>
        <Paper elevation={0} className="pt-1 table-wrap">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflowY: "hidden",
            }}
            className="customScroll m-3">
            <FilterMenu
              caption="Clients List"
              totalCount={10}
              searchLabel="Search.."
              filterOptions={clientsFilter}
              filterdefaultValueOptions={{
                label: "All",
                value: "all",
              }}
              handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
              showFilter={false}
              showSearch={true}
              showCreateBtn={false}
              btnName=""
              handleSearchChange={handleSearchChange}
              showFilterMenu={showFilterMenu}
              filterLabel="Filter by"
              searchName="keyword"
              searchValue={searchValue}
              clearSearch={clearSearch}
              entriesName="Entries"
              filterValue={"Search"}
              entriesOptions={{}}
              entriesdefaultValueOptions={{
                label: `Entries`,
                value: 20,
              }}
              entriesValue={{}}
              entriesLabel="Entries"
            />
          </div>

          <ListTable
            header={[
              { name: "Merchant Name" },
              { name: "Transactions to Settle" },
              { name: "Amount to Settle" },
              { name: "Action" },
            ]}
            isSearching={fetchingUnsettledTransactions}
            showError={false}
            style={{ opacity: 1 }}
            pageOfItems={unsettledTransactions?.data?.responseData?.items}
            content={
              <TableBody>
                {unsettledTransactions?.data?.responseData?.items?.map((row, index) => (
                  <TableRow key={index} style={{ cursor: "pointer" }}>
                    <TableCell component="th" scope="row">
                      {row?.merchantName || "-"}
                    </TableCell>
                    <TableCell align="left">{row?.transactionCount || "0"}</TableCell>
                    <TableCell align="left"><NumericFormat value={row?.amountToSettle} prefix={getSymbolFromCurrency(
                      row?.currency
                    )} displayType='text' thousandSeparator renderText={(value) => <span>{value}</span>} /></TableCell>
                    <TableCell align="left"><Button size="sm" color="link" className="text-primary ml-2 font-weight-bold" onClick={() => handleViewClick(row)}>View Transactions</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
          />
          <br />
          {unsettledTransactions?.data?.responseData?.items?.length > 0 ? (
            <div className="d-flex align-items-center justify-content-between px-3 pb-2">
              <div>
                Showing Page {unsettledTransactions?.data?.responseData?.pageNumber}{" "}
                of {unsettledTransactions?.data?.responseData?.pages}
              </div>
              <div>
                <Pagination
                  totalPages={unsettledTransactions?.data?.responseData?.pages}
                  currentPage={currentPage}
                  changeCurrentPage={(p) => getPaginationNumber(p)}
                  previousBtn="Prev"
                  nextBtn="Next"
                />
              </div>
            </div>
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default GroupedUnsettledTransactions