import React, { useState } from "react";
// import {useDispatch} from 'react-redux';
import { Row } from "react-bootstrap";
import Aux from "../../components/hoc/_Aux";
import PageLayout from "components/hoc/PageLayout";
import './payout.scss';
import Entities from "./country/country";
import Request from "./request/request";
import Merchants from "./merchants/merchants";
import Gateway from "./gateway/gateway";
import Lookup from "./lookup/lookup";
import Swift from "./swift/swift";

const Payout = () => {
  const [tab, setTab] = useState("request")
    return (
      <Aux>
        <Row className="payout-container">
          <PageLayout
            history={{}}
            topSection={
              <div>
                <div className="service-name">Payout</div>
                <div className="service-items">
                <div className={`item ${tab === 'request' && 'active'}`} onClick={()=>setTab('request')}>Request</div>
                <div className={`item ${tab === 'merchants' && 'active'}`} onClick={()=>setTab('merchants')}>Merchants</div>
                  <div className={`item ${tab === 'country' && 'active'}`} onClick={()=>setTab('country')}>Country</div>
                  <div className={`item ${tab === 'gateway' && 'active'}`} onClick={()=>setTab('gateway')}>Gateway</div>
                  <div className={`item ${tab === 'lookup' && 'active'}`} onClick={()=>setTab('lookup')}>Lookup</div>
                  <div className={`item ${tab === 'swift' && 'active'}`} onClick={()=>setTab('swift')}>Swift</div>
                </div>
              </div>
            }
            hideCard={tab === "lookup"}
            bodySection={
                <div>
                    <div className={tab !== "lookup"?"tab-items":""}>
                        {tab === "request" && <Request/>}
                        {tab === "merchants" && <Merchants/>}
                        {tab === "country" && <Entities/>}
                        {tab === "gateway" && <Gateway />}
                        {tab === "lookup" && <Lookup />}
                        {tab === "swift" && <Swift />}
                    </div>
              </div>
            }
          />
        </Row>
    </Aux>
    );
  }

export default Payout;
