export default {
  items: [
    {
      id: "compliance",
      title: "Compliance",
      type: "item",
      url: "/compliance",
      classes: "nav-item",
      icon: "fa fa-user-circle",
      code: "CanViewClient",
    },
    {
      id: "Currency",
      title: "Currency",
      type: "item",
      url: "/currency",
      classes: "nav-item",
      icon: "fa fa-usd",
      code: "CanViewClient",
    },
    {
      id: "payment-services",
      title: "FX Services",
      type: "item",
      url: "/services/fx",
      icon: "fa fa-exchange",
      code: "CanViewGateway",
    },
    {
      id: "merchants",
      title: "Merchants",
      type: "item",
      url: "/merchants",
      classes: "nav-item",
      icon: "fa fa-users",
      code: "CanViewClient",
    },
    {
      id: "transfer",
      title: "Transfers",
      type: "item",
      url: "/transfers",
      classes: "nav-item",
      icon: "fa fa-users",
      code: "CanViewClient",
    },
    {
      id: "payment-gateways",
      title: "Services",
      type: "group",
      icon: "icon-ui",
      children: [
        {
          id: "payment-gateway",
          title: "Services",
          type: "collapse",
          icon: "fa fa-building",
          children: [
            {
              id: "virtual-account-services",
              title: "Virtual Account",
              type: "item",
              url: "/services/virtual-accounts",
              code: "CanViewGateway",
            },
            {
              id: "smart-check",
              title: "SmartCheck",
              type: "item",
              url: "/services/smart-check",
              code: "CanViewGateway",
            },
            {
              id: "kyc-services",
              title: "KYC",
              type: "item",
              url: "/services/kyc",
              code: "CanViewGateway",
            },
            {
              id: "payout-services",
              title: "Payout",
              type: "item",
              url: "/services/payout",
              code: "CanViewGateway",
            },
            {
              id: "cards-services",
              title: "Cards",
              type: "item",
              url: "/services/cards",
              code: "CanViewGateway",
            },
            {
              id: "payment-collect",
              title: "Collect",
              type: "item",
              url: "/services/collect",
              code: "CanViewGateway",
            },
            {
              id: "payment-reconcilation",
              title: "Reconcilation",
              type: "item",
              url: "/services/reconcilation",
              code: "CanViewGateway",
            },
          ],
        },
      ],
    },
    {
      id: "settings",
      title: "Settings",
      type: "group",
      icon: "icon-ui",
      children: [
        {
          id: "setting",
          title: "Settings",
          type: "collapse",
          icon: "fa fa-cog",
          children: [
            {
              id: "feature-settings",
              title: "Feature Flags",
              type: "item",
              url: "/settings/setup-features",
              code: "CanViewGateway",
            },
          ],
        },
      ],
    },
    {
      id: "user-management",
      title: "User Management",
      type: "item",
      url: "/user-management",
      classes: "nav-item",
      icon: "fa fa-server",
      code: "CanViewClient",
    },
  ],
};
