import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import '../Compliance.scss';
import { useSelector, useDispatch } from 'react-redux';
import { approve, getComplianceDoc, getComplianceDocByReference } from 'logic/actions/compliance';
import DocsIcon from '../../../assets/images/upload.svg';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { CircularProgress } from "@material-ui/core";
import NoRecord from 'Pages/Error/NoRecord';
import LoadingOverlay from 'react-loading-overlay';

// import { getPeopleProfile } from '../../../redux/actions/compliance/compliance';

const Licenses = ({active}) => {
   const dispatch = useDispatch();
   const params = useParams();
   const [getDocumentStatus, setGetDocumentStatus] = useState(false)
   const [approving, setApproving] = useState(false)
   
   const { getComplianceDocPayload, getComplianceDocLoading, getComplianceDocByReferencePayload,getComplianceDocByReferenceLoading, getComplianceApproveLoading, getComplianceApprovePayload  } =
    useSelector(({ complianceReducer }) => {
      return {
        getComplianceDocLoading: complianceReducer?.getComplianceDocLoading,
        getComplianceDocPayload: complianceReducer?.getComplianceDocPayload,
        getComplianceDocError: complianceReducer?.getComplianceDocError,
        getComplianceApproveLoading: complianceReducer?.getComplianceApproveLoading,
        getComplianceApprovePayload: complianceReducer?.getComplianceApprovePayload,
        getComplianceApproveError: complianceReducer?.getComplianceApproveError,
        getComplianceDocByReferenceLoading: complianceReducer?.getComplianceDocByReferenceLoading,
        getComplianceDocByReferencePayload: complianceReducer?.getComplianceDocByReferencePayload,
        getComplianceDocByReferenceError: complianceReducer?.getComplianceDocByReferenceError,
      };
    });

    const approveDocument = (id, clientId) => {
       setApproving(true);
       dispatch(approve({type: "Licence",typeQuery: "licenseId", id,clientId}))
    }

    const getDocument = (referenceNo) => {
       setGetDocumentStatus(true);
       dispatch(getComplianceDocByReference(referenceNo))
    }

    useEffect(()=>{
       if(getComplianceDocByReferencePayload?.data?.requestSuccessful && getDocumentStatus) {
         setGetDocumentStatus(false)
         window.open(getComplianceDocByReferencePayload?.data?.responseData?.fileString, "_blank")
       }else if(getComplianceDocByReferencePayload?.data?.requestSuccessful ===false && getDocumentStatus) {
         setGetDocumentStatus(false)
          dispatch(errorMessage("Error occurred while retrieving a document"))
       }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getComplianceDocByReferenceLoading]);

    useEffect(()=>{
      if(getComplianceApprovePayload?.data?.requestSuccessful && approving) {
        setApproving(false)
        dispatch(successMessage("This compliance was successfully approved"));
      }else if(getComplianceApprovePayload?.data?.requestSuccessful ===false && approving) {
         setApproving(false)
         dispatch(errorMessage(getComplianceApprovePayload?.data?.message ||"Error occurred while approving this compliance"))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [getComplianceApproveLoading]);

    useEffect(()=>{
       dispatch(getComplianceDoc(params?.id, 2))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <LoadingOverlay
      active={getComplianceDocLoading}
      styles={{
        overlay: (base) => ({
          ...base,
          minHeight:'100px',
          opacity: 0.7,
        }),
      }}
      spinner
      text="Loading Records..."
    >
            <div className="row">
                {getComplianceDocPayload?.data?.responseData?.items?.length === 0 && <div className="col-12 center"><NoRecord /></div>}
                {getComplianceDocPayload?.data?.responseData?.items?.map(val =>(<div key={val.id} className="col-xs-12 col-md-3 col-sm-6">
                   <div className="profile-box">
                      <div className="profile-info">
                        <div className="profile-img"><img src={DocsIcon} alt="docs icon"/></div>
                        <div className="name">{val?.documentName}</div>
                        </div>
                        <div className="view-accept-action">
                           <button className="view" onClick={()=>getDocument(val?.reference)}>View</button>
                           {val?.approvalStatus === 0 && <button className="accept" disabled={approving} onClick={()=>approveDocument(val.resourceId, val.clientId)}>
                              {approving && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} Accept</button>}
                              {val?.approvalStatus === 1 && <span className="accept">Approved</span>}
                        </div>
                   </div>   
                </div>))}
                
            </div>
            </LoadingOverlay>
 )
}

export default Licenses;