import React from "react";
import $ from "jquery";
import Payout from "Pages/payout/payout";
import Compliance from "Pages/Compliance/Compliance";
import ProfileInfo from "Pages/Compliance/profileInfo/profileInfo";
import ProfileDetail from "Pages/Compliance/profileDetail/profileDetail";
import FxServices from "Pages/FxServices/FxServices";
import FxDetails from "Pages/FxServices/fxDetail/fxDetail";
import Currency from "Pages/Currency/Currency";
import Collect from "Pages/Collect/Collect";
import GroupedUnsettledTransactions from "Pages/VirtualAccounts/settlement/groupedUnsettledTransactions/groupedUnsettledTransactions";
import MerchantTransactions from "Pages/VirtualAccounts/settlement/merchantTransactions/merchantTransactions";
import RecentTransaction from "Pages/VirtualAccounts/query/RecentTransactions/recentTransaction";
import ReQuery from "Pages/VirtualAccounts/query/TransactionReQuery/transactionReQuery";
import SmartCheck from "Pages/SmartCheck/SmartCheck";
import Transfers from "Pages/transfers/Transfers";
import Reconcilation from "Pages/Reconcilation/Reconcilation";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const VirtualAccounts = React.lazy(() =>
  import("./Pages/VirtualAccounts/VirtualAccounts")
);
const Kyc = React.lazy(() => import("./Pages/kyc/Kyc"));
const Cards = React.lazy(() => import("./Pages/cards/Cards"));
const FeatureSetup = React.lazy(() =>
  import("./Pages/Settings/featureSetup/FeatureSetup")
);
const UserManagement = React.lazy(() =>
  import("./Pages/UserManagement/UserManagement")
);
const Merchants = React.lazy(() => import("./Pages/Merchants/Merchants"));

const routes = [
  {
    path: "/compliance/profile-detail/:clientId/:peopleId/:status",
    exact: true,
    name: "Default",
    component: ProfileDetail,
  },
  {
    path: "/compliance/profile/:id",
    exact: true,
    name: "Default",
    component: ProfileInfo,
  },
  { path: "/compliance", name: "Default", component: Compliance },
  {
    path: "/services/virtual-accounts",
    exact: true,
    name: "Default",
    component: VirtualAccounts,
  },
  {
    path: "/services/virtual-accounts/grouped-transactions",
    exact: true,
    name: "Default",
    component: GroupedUnsettledTransactions,
  },
  {
    path: "/services/virtual-accounts/transactions",
    exact: true,
    name: "Default",
    component: MerchantTransactions,
  },
  {
    path: "/services/virtual-accounts/recent-transaction",
    exact: true,
    name: "Default",
    component: RecentTransaction,
  },
  {
    path: "/services/virtual-accounts/re-query",
    exact: true,
    name: "Default",
    component: ReQuery,
  },
  { path: "/services/fx", exact: true, name: "Default", component: FxServices },
  { path: "/transfers", exact: true, name: "Default", component: Transfers },
  { path: "/services/reconcilation", exact: true, name: "Default", component: Reconcilation },
  {
    path: "/merchants",
    exact: true,
    name: "Default",
    component: Merchants,
  },
  {
    path: "/services/smart-check",
    exact: true,
    name: "Default",
    component: SmartCheck,
  },
  {
    path: "/services/fx/:id",
    exact: true,
    name: "Default",
    component: FxDetails,
  },
  { path: "/services/kyc", exact: true, name: "Default", component: Kyc },
  { path: "/services/payout", exact: true, name: "Default", component: Payout },
  { path: "/services/cards", exact: true, name: "Default", component: Cards },
  {
    path: "/services/collect",
    exact: true,
    name: "Default",
    component: Collect,
  },
  { path: "/currency", exact: true, name: "Default", component: Currency },
  {
    path: "/settings/setup-features",
    exact: true,
    name: "Default",
    component: FeatureSetup,
  },
  {
    path: "/user-management",
    exact: true,
    name: "Default",
    component: UserManagement,
  },
];

export default routes;
