import React, {useEffect, useState, useCallback} from 'react';
import Switch from "react-switch";
import {debounce} from "lodash";
import { useDispatch, useSelector} from 'react-redux';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../FxServices.scss';
import { getCurrency } from 'logic/actions/requests';
import { getFx, toggleFx, postFx } from 'logic/actions/fxAction';
import ToggleModal from 'components/Modal/ToggleModal';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from '../../../components/paginations/Paginations';
import Form from './form';
import { errorMessage, successMessage } from 'logic/actions/notification';

const payloadData = {
  "destinationCurrencyCode": null,
  "sourceCurrencyCode": null,
  "margin": null,
  "marginCurrencyCode": null,
  "rate": null,
  "isActive": true
}
const TodayRate = () => {
    const [id, setId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [status, setStatus] = useState(null);
    const [toggleForm, setToggleForm] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const [payload, setPayload] = useState(payloadData);
    const dispatch = useDispatch();

    const { gettingFxLoading, gettingFxPayload, toggleFxLoading, toggleFxPayload, postFxLoading, postFxPayload, getCurrencyPayload } = useSelector(({virtualAccountReducer, fxReducer}) => {
        return {
            gettingFxError: fxReducer?.gettingFxError,
            gettingFxPayload: fxReducer?.gettingFxPayload,
            gettingFxLoading: fxReducer?.gettingFxLoading,
            toggleFxError: fxReducer?.toggleFxError,
            toggleFxPayload: fxReducer?.toggleFxPayload,
            toggleFxLoading: fxReducer?.toggleFxLoading,
            postFxError: fxReducer?.postFxError,
            postFxPayload: fxReducer?.postFxPayload,
            postFxLoading: fxReducer?.postFxLoading,
            getCurrencyError: virtualAccountReducer?.getCurrencyError,
            getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
            getCurrencyLoading: virtualAccountReducer?.getCurrencyLoading,
         };
      });

    const getPaginationNumber = (page) => {
      setCurrentPage(page)
      dispatch(getFx(page, 10, searchValue))
    }
           
  useEffect(()=>{
      dispatch(getFx());
      dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!gettingFxLoading && gettingFxPayload){
      setData(gettingFxPayload?.data?.responseData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettingFxLoading]);

  useEffect(()=>{
    if(!postFxLoading && postFxPayload?.data?.requestSuccessful && submitStatus){
      dispatch(getFx());
      setPayload(payloadData);
      setCreateForm(false);
      setSubmitStatus(false);
      dispatch(successMessage("Rate created successfully"))
    }else if(!postFxLoading && !postFxPayload?.data?.requestSuccessful && submitStatus){
      setSubmitStatus(false);
      dispatch(errorMessage(postFxPayload?.data?.message||"Error occurred while creating this rate"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postFxLoading]);


  useEffect(()=>{
    if(!toggleFxLoading && toggleFxPayload?.data?.requestSuccessful){
      let i = 0;
      for(let dt of data) {
        if(dt?.id === id) {
           data[i]['isActive'] = !dt?.isActive; 
            setData([...data]);
            setToggleForm(false)
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFxLoading])
  
  const handleChange = (item) => {
    setId(item?.id);
    setStatus(item?.isActive);
    setToggleForm(true);
  }
  const handleAutoCompleteCreateChange = (event, values, input) => {
};

const submit = () => {
  let errorStatus = false;
  setSubmitStatus(true);    
  for(let data in payload) {
    if(!payload[data] || payload[data] === "" || (data === "rate" && payload[data] < 0) || (payload.sourceCurrencyCode === payload.destinationCurrencyCode)) {
        errorStatus = true
    }
  }
  if(!errorStatus) {
    dispatch(postFx({...payload, margin: Number(payload?.margin)}))
  }
}

const debounceText = useCallback(debounce(async(srhTxt) => {
  dispatch(getFx(1, 10, srhTxt)) 
}, 500), [])


const handleSearchChange = (event, input) => {
  debounceText(event.target.value)
  setSearchValue(event.target.value)
};

const clearSearch = () => {
  debounceText("")
  setSearchValue("")
};

const showFilterMenu = () => {
}; 
   
const format = () => {
        return data?.map((item, index) => {
          return {
            from: item?.sourceCurrencyCode,
            to: item?.destinationCurrencyCode,
            rate: item?.rate,
            id: item?.id,
            status: item?.isActive,
            date: item?.createdAt,
            Actions:
            <div>
              <Switch onChange={()=>handleChange(item)} onColor="#175FFF" checked={item?.isActive} uncheckedIcon={false} checkedIcon={false}/>
            </div>
          };
        });
      }
     
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={true}
            showCreateBtn={true}
            btnName="+&nbsp;&nbsp;New Rate"
            openCreate={() => setCreateForm(true)}
            handleSearchChange={handleSearchChange}
            searchValue={searchValue}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "From" },
            { name: "To" },
            { name: "Rate" },
            { name: "Status" },
            { name: "Action" },
          ]}
          isSearching={gettingFxLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={gettingFxPayload?.data?.responseData?.items}
          content={
            <TableBody>
              {format()?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.from}
                  </TableCell>
                  <TableCell align="left">{row.to}</TableCell>
                  <TableCell align="left">{row.rate}</TableCell>
                  <TableCell align="left">
                    <div className="status-flag">
                      <span
                        className={`dot-status ${
                          row?.status ? "dot-active" : "dot-inactive"
                        }`}>
                        .
                      </span>
                      {row.status ? "Active" : "Inactive"}
                    </div>
                  </TableCell>
                  <TableCell align="left">{row.Actions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page {gettingFxPayload?.data?.responseData?.pageNumber} of{" "}
              {gettingFxPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={gettingFxPayload?.data?.responseData?.pages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {createForm && (
          <CreateModal
            title="Enter New Rate"
            createForm={createForm}
            hideCreateForm={() => setCreateForm(false)}
            onCreate={() => submit()}
            creating={postFxLoading}
            disableCreate={postFxLoading}
            cancelText="Cancel"
            createText="Create"
            content={
              <Form
                getCurrencyPayload={getCurrencyPayload}
                setPayload={setPayload}
                payload={payload}
                submitStatus={submitStatus}
              />
            }
          />
        )}

        {toggleForm && (
          <ToggleModal
            title={status ? "Disable item?" : "Enable Item?"}
            toggleForm={toggleForm}
            onToggle={() => dispatch(toggleFx(id))}
            toggling={toggleFxLoading}
            disableToggle={toggleFxLoading}
            hideToggleModal={() => setToggleForm(false)}
            toggleText={status ? "Disable" : "Enable"}
            textContent={
              status
                ? "Are you sure you want to disable this item?"
                : "Are you sure you want to enable this item?"
            }
          />
        )}
      </>
    );
}

export default TodayRate;