import React, { useEffect } from 'react';
import "./transactionReQuery.scss";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionReQuery
} from "logic/actions/requests";
import { errorMessage } from "logic/actions/notification";

const TransactionReQuery = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  const country = query.get("country");
  const providerBankId = query.get("providerBank");
  const sessionId = query.get("sessionId");
  const accountNumber = query.get("accountNumber");

  const { transactionReQueryPayload, transactionReQueryError, transactionReQueryFetching } = useSelector(({ virtualAccountReducer }) => {
    return {
      transactionReQueryPayload: virtualAccountReducer?.getTransactionQueryByIdPayload,
      transactionReQueryError: virtualAccountReducer?.getTransactionQueryByIdError,
      transactionReQueryFetching: virtualAccountReducer?.getTransactionQueryByIdLoading,
    };
  });

  useEffect(() => {
    dispatch(getTransactionReQuery({ country, providerBankId, sessionId, accountNumber }));
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (transactionReQueryError) {
      dispatch(errorMessage(transactionReQueryError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionReQueryError])

  const responseData = transactionReQueryPayload?.data?.responseData;

  const itemsArray = Array.isArray(responseData) ? responseData : [responseData];


  console.log("responseDataArray", itemsArray);

  return (
    <div>
      <Row className="compliance-container">
        <Col className="profile-detail-content">
          <div className="content-header">
            <div className="go-back flex" onClick={() => history.goBack()}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <span className="ml-2">
                Go Back
              </span>
            </div>
            <div className="service-name">Result History</div>
          </div>
          <div className="tab-items">
            <div className="d-flex justify-content-between hide-overflow customScroll p-3">
              <div className="code-wrapper rounded">
                {transactionReQueryFetching ? (
                  <div>Loading...</div>
                ) : (
                  transactionReQueryPayload?.data?.responseData ? (
                    itemsArray.map((item, index) => {
                      return (
                        <div className='text-white' key={index}>

                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">Session ID:</span>
                            </div>
                            <div>
                              <span>{item?.sessionId}</span>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">Beneficiary Account Name:</span>
                            </div>
                            <div>
                              <span>{item?.beneficiaryAccountName}</span>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">Beneficiary Account Number:</span>
                              <div>
                                <span>{item?.beneficiaryAccountNumber}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">Transaction Narration:</span>
                            </div>
                            <div>
                              <span>{item?.narration}</span>
                            </div>
                          </div>

                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">Transaction Amount:</span>
                            </div>
                            <div>
                              <span>{item?.amount}</span>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">From :</span>
                            </div>
                            <div>
                              <span>{item?.originatorAccountName}</span>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-2">
                              <span className="font-weight-bold">Payment Reference:</span>
                            </div>
                            <div>
                              <span>{item?.paymentReference}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No record of sessionid in logs</div>
                  )
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TransactionReQuery