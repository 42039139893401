import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import "../Compliance.scss";
import { useSelector, useDispatch } from "react-redux";
import { getComplianceProfile } from "logic/actions/compliance";
import NoRecord from "Pages/Error/NoRecord";
import LoadingOverlay from "react-loading-overlay";
// import { getPeopleProfile } from '../../../redux/actions/compliance/compliance';

const People = ({ active }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const { getComplianceProfileLoading, getComplianceProfilePayload } =
    useSelector(({ complianceReducer }) => {
      return {
        getComplianceProfileError: complianceReducer?.getComplianceProfileError,
        getComplianceProfilePayload:
          complianceReducer?.getComplianceProfilePayload,
        getComplianceProfileLoading:
          complianceReducer?.getComplianceProfileLoading,
      };
    });

  useEffect(() => {
    dispatch(getComplianceProfile(params.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBadge = (status) => {
    if (status === 0) return <div className="review">Review</div>;
    if (status === 1) return <div className="approve">Approved</div>;
    if (status === 2) return <div className="pending">Pending</div>;
    return null;
  };

  return (
    <LoadingOverlay
      active={getComplianceProfileLoading}
      styles={{
        overlay: (base) => ({
          ...base,
          minHeight: "100px",
          opacity: 0.7,
        }),
      }}
      spinner
      text="Loading Records..."
    >
      <div className="row">
        {getComplianceProfilePayload?.data?.responseData?.items?.length ===
          0 && (
          <div className="col-12 center">
            <NoRecord />
          </div>
        )}
        {getComplianceProfilePayload?.data?.responseData?.items?.map((val) => (
          <div key={val.id} className="col-xs-12 col-md-3 col-sm-6">
            <div className="profile-box">
              {/* <span className="edit-item"><img src={EditIcon} alt="edit icon"/></span> */}
              <div className="profile-img">
                {val.firstName[0].toUpperCase()}
                {val.lastName[0].toUpperCase()}
              </div>
              <div className="name">{val.firstName}</div>
              <div className="email">{val.email}</div>
              {renderBadge(val.status)}
              <div>
                <button
                  className="view-profile"
                  onClick={() =>
                    history.push(
                      `/compliance/profile-detail/${params.id}/${val.id}/${val.status}`
                    )
                  }
                >
                  View Profile
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </LoadingOverlay>
  );
};

export default People;
