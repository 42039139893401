import React, { useState, useEffect, useCallback } from 'react';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from 'components/Input/FilterMenu';
import { debounce } from 'lodash';
import { clientsFilter } from 'Constants';
// import Switch from "react-switch";
import CreateModal from 'components/Modal/CreateModal';
import Pagination from 'components/paginations/Paginations';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSmartCheckSubscriptionsPlan, createSmartCheckSubscriptionsPlan, updateSmartCheckSubscriptionsPlan } from 'logic/actions/smartCheck';
import AddNewSubscriptionForm from './addNewSubscriptionForm';
import { errorMessage, successMessage } from 'logic/actions/notification';
import EditSubscriptionForm from './editSubscriptionForm';


const initialAddFormValues = {
    name: "",
    code: "",
    description: "",
    amount: "",
    duration: "",
};

const initialEditFormValues = {
    name: "",
    code: "",
    description: "",
    amount: "",
    duration: "",
    PlanId: ""
};

const SubscriptionNamePlan = () => {
    const [currentPage, setCurrentPage] = useState(1);
    // const [submitStatus, setSubmitStatus] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [data, setData] = useState([]);
    const [createModal, setCreateModal] = useState({
        open: false,
        payload: { ...initialAddFormValues },
    });
    const [editorModal, setEditorModal] = useState({
        open: false,
        payload: {
            ...initialEditFormValues
        },
    });
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [submitStatus, setSubmitStatus] = useState(false);
    const dispatch = useDispatch();

    const { getSubscriptionsPlanLoading, getSubscriptionsPlanPayload, createSubscriptionsPlanLoading, createSubscriptionsPlanPayload, updateSubscriptionsPlanLoading, updateSubscriptionsPlanPayload } = useSelector(({ smartCheckReducer, }) => {
        return {
            getSubscriptionsPlanError: smartCheckReducer?.getSubscriptionsPlanError,
            getSubscriptionsPlanPayload: smartCheckReducer?.getSubscriptionsPlanPayload,
            getSubscriptionsPlanLoading: smartCheckReducer?.getSubscriptionsPlanLoading,
            createSubscriptionsPlanLoading: smartCheckReducer?.createSubscriptionsPlanLoading,
            createSubscriptionsPlanPayload: smartCheckReducer?.createSubscriptionsPlanPayload,
            createSubscriptionsPlanError: smartCheckReducer?.createSubscriptionsPlanError,
            updateSubscriptionsPlanLoading: smartCheckReducer?.updateSubscriptionsPlanLoading,
            updateSubscriptionsPlanPayload: smartCheckReducer?.updateSubscriptionsPlanPayload,
        };
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setCreateModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
        setEditorModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));

    };
    const getPaginationNumber = (page) => {
        setCurrentPage(page)
    }

    useEffect(() => {
        dispatch(getSmartCheckSubscriptionsPlan(currentPage, 10, searchValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getSubscriptionsPlanPayload) {
            setData(getSubscriptionsPlanPayload?.data.data);
        }
        setLoading(getSubscriptionsPlanLoading);
    }, [getSubscriptionsPlanLoading, getSubscriptionsPlanPayload]);

    const handleAutoCompleteCreateChange = (event, values, input) => { };

    const addNewSubscription = () => {
        let errorStatus = false;
        for (let data in createModal.payload) {
            if (!createModal.payload[data] || createModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }
        if (!errorStatus) {
            dispatch(createSmartCheckSubscriptionsPlan(createModal.payload));
            setSubmitStatus(true); // Add this line
        }
    };

    useEffect(() => {
        if (!createSubscriptionsPlanLoading && createSubscriptionsPlanPayload?.data?.requestSuccessful && submitStatus) {
            dispatch(getSmartCheckSubscriptionsPlan());
            setCreateModal((prev) => ({
                ...prev,
                payload: { ...initialAddFormValues },
            }));
            setCreateForm(false);
            setSubmitStatus(false);
            dispatch(successMessage("Subscription Plan created successfully"))
        } else if (!createSubscriptionsPlanLoading && !createSubscriptionsPlanPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(createSubscriptionsPlanPayload?.data?.Message || "Error occurred while creating this subscription"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSubscriptionsPlanLoading]);


    const updateSubscriptionPlan = () => {
        let errorStatus = false;
        for (let data in editorModal.payload) {
            if (!editorModal.payload[data] || editorModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }

        if (!errorStatus) {
            dispatch(updateSmartCheckSubscriptionsPlan(editorModal.payload));
            console.log(editorModal?.payload)
            setSubmitStatus(true); // Add this line to reset submitStatus
        }
    };

    useEffect(() => {
        if (!updateSubscriptionsPlanLoading && updateSubscriptionsPlanPayload?.data?.requestSuccessful && submitStatus) {
            setEditorModal((prev) => ({
                ...prev,
                open: false,
            }));
            setSubmitStatus(false);
            dispatch(getSmartCheckSubscriptionsPlan());
            dispatch(successMessage("Subscription Plan updated successfully"))
        } else if (!updateSubscriptionsPlanLoading && !updateSubscriptionsPlanPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(updateSubscriptionsPlanPayload?.data?.Message || "Error occurred while updating this plan"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSubscriptionsPlanLoading]);


    const debounceText = useCallback(
        debounce(async (srhTxt) => { }, 500),
        []
    );

    const handleSearchChange = (event, input) => {
        debounceText(event.target.value);
        setSearchValue(event.target.value);
    };

    const clearSearch = () => {
        debounceText("");
        setSearchValue("");
    };

    const showFilterMenu = () => { };
    const handleManageClick = (row) => {
        setEditorModal((prev) => ({
            ...prev,
            payload: {
                name: row.Name || '',
                code: row.Code || '',
                // Amount: row.Amount || '',
                description: row.Description || '',
                // Duration: row.Duration || '',
                PlanId: row.PlanId
            },
            open: true
        }));
    };
    const itemsPerPage = 10;
    const format = () => {
        const startIndex = (currentPage - 1) * 10;
        const lastIndex = startIndex + itemsPerPage;
        const paginatedData = data?.slice(startIndex, lastIndex);

        return paginatedData.filter((item) => item.Name.toLowerCase().includes(searchValue.toLowerCase()))?.map((item) => {
            return {
                name: item?.Name,
                Id: item?.PlanId,
                code: item?.Code,
                desc: item?.Description,
                createdAt: item?.CreatedAt,
                Actions: (
                    <div className='d-flex align-items-center justify-content-center'>

                        <Button
                            size="sm"
                            color="link"
                            className="text-primary ml-2 font-weight-bold"
                            onClick={() => handleManageClick(item)}>
                            Edit
                        </Button>
                    </div>
                ),
            };
        });
    };
    return (
        <>
            <div className="customScroll customScroll d-flex aling-items-center justify-space-between overflow-y-hidden">
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
                    showFilter={false}
                    showSearch={true}
                    showCreateBtn={true}
                    btnName="Add New &nbsp;&nbsp;+"
                    openCreate={() => setCreateForm(true)}
                    handleSearchChange={handleSearchChange}
                    searchValue={searchValue}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>

            <ListTable
                header={[
                    { name: "Subscription Name" },
                    { name: "code" },
                    { name: "Description" },
                    { name: "" },
                ]}
                isSearching={loading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow key={index} style={{ cursor: "pointer" }}>
                                <TableCell component="th" scope="row">
                                    <span className="capitalise">{row.name}</span>
                                </TableCell>
                                <TableCell align="left">{row.code}</TableCell>
                                <TableCell align="left">{row.desc}</TableCell>
                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {format()?.length > 0 ? (
                <div className="paginate-content">
                    <div  >
                        Showing Page {currentPage}{" "}
                        of {Math.ceil(data?.length / itemsPerPage)}
                    </div>
                    <div>
                        <Pagination
                            totalPages={Math.ceil(data?.length / itemsPerPage)}
                            currentPage={currentPage}
                            changeCurrentPage={(p) => getPaginationNumber(p)}
                            previousBtn="Prev"
                            nextBtn="Next"
                        />
                    </div>
                </div>
            ) : null}

            {createForm && (
                <CreateModal
                    onCancelClick={() => setCreateForm(false)}
                    title={`Add New Subscription`}
                    createForm={createForm}
                    hideCreateForm={() =>
                        setCreateForm(false)
                    }
                    onCreate={() => addNewSubscription()}
                    creating={createSubscriptionsPlanLoading}
                    disableCreate={createSubscriptionsPlanLoading}
                    cancelText={"Cancel"}
                    createText={"Create"}
                    content={
                        <AddNewSubscriptionForm
                            payload={createModal.payload}
                            onChange={(e) => { handleFormChange(e) }}
                            submitStatus={submitStatus}
                        />
                    }
                />
            )}

            {/* Edit Modal */}
            {editorModal.open && (
                <CreateModal
                    onCancelClick={() => setEditorModal((prev) => ({ ...prev, open: false, payload: {} }))}
                    title={`Edit Subscription`}
                    createForm={editorModal.open}
                    hideCreateForm={() =>
                        setEditorModal({ open: false, payload: {}, })
                    }
                    onCreate={() => updateSubscriptionPlan()}
                    creating={updateSubscriptionsPlanLoading}
                    disableCreate={updateSubscriptionsPlanLoading}
                    cancelText={"Cancel"}
                    createText={"Update"}
                    content={
                        <EditSubscriptionForm
                            payload={editorModal?.payload}
                            onChange={(e) => { handleFormChange(e) }}
                            submitStatus={submitStatus}
                        />
                    }
                />
            )}
        </>
    )
}

export default SubscriptionNamePlan;