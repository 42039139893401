import React, {useEffect, useState, useCallback} from 'react';
import Switch from "react-switch";
import {debounce} from "lodash";
import { useDispatch, useSelector} from 'react-redux';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../Transfers.scss';
import { getCountry, getCurrency } from 'logic/actions/requests';
import { approveTransfer, getTransfers, postTransfers } from 'logic/actions/transferAction';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from '../../../components/paginations/Paginations';
import Form from './form';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { ArrowLeftIcon } from 'icons/arrow-left';
import { ArrowBack } from '@material-ui/icons';
import { Drawer } from '@material-ui/core';
import CloseIcon from 'icons/close-icon';
import { Button } from 'react-bootstrap';
import { SuccessIcon } from 'icons/success-icon';

const payloadData ={
  country: "",
  sourceBankName: "",
  sourceAccount: "",
  currency: "",
  destinationBankName: "",
   destinationAccount: "",
   totalAmount: "",
   narration: ""

}

const Request = () => {
   const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [step, setStep] = useState(1);
    const [view, setView] = useState(null);
    const [createForm, setCreateForm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const [payload, setPayload] = useState(payloadData);
     const [successModal, setSuccessModal] = useState(false);

    const dispatch = useDispatch();

    const { accountLookupLoading, gettingBanksLoading, gettingBanksPayload, accountLookupPayload, gettingTransfersLoading, gettingTransfersPayload, getCountryPayload, postTransfersLoading, postTransfersPayload, getCurrencyPayload } = useSelector(({transfersReducer, virtualAccountReducer}) => {
        return {
            accountLookupPayload: transfersReducer?.accountLookupPayload,
            accountLookupLoading: transfersReducer?.accountLookupLoading,
            gettingBanksPayload: transfersReducer?.gettingBanksPayload,
            gettingBanksLoading: transfersReducer?.gettingBanksLoading,
            gettingTransfersError: transfersReducer?.gettingTransfersError,
            gettingTransfersPayload: transfersReducer?.gettingTransfersPayload,
            gettingTransfersLoading: transfersReducer?.gettingTransfersLoading,
            postTransfersError: transfersReducer?.postTransfersError,
            postTransfersPayload: transfersReducer?.postTransfersPayload,
            postTransfersLoading: transfersReducer?.postTransfersLoading,
            approveTransferError: transfersReducer?.approveTransferError,
            approveTransferPayload: transfersReducer?.approveTransferPayload,
            approveTransferLoading: transfersReducer?.approveTransferLoading,
            getCountryPayload: virtualAccountReducer?.getCountryPayload,
            getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
           };
      });

      console.log({postTransfersPayload})
    const getPaginationNumber = (page) => {
      setCurrentPage(page)
      dispatch(getTransfers(page, 10, searchValue))
    }
           
  useEffect(()=>{
      dispatch(getTransfers());
      dispatch(getCurrency());
      dispatch(getCountry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!gettingTransfersLoading && gettingTransfersPayload){
      setData(gettingTransfersPayload?.data?.responseData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettingTransfersLoading]);

  useEffect(()=>{
    if(!postTransfersLoading && postTransfersPayload?.data?.requestSuccessful && submitStatus){
      dispatch(getTransfers());
      setPayload(payloadData);
      setCreateForm(false);
      setSubmitStatus(false);
      setSuccessModal(true)
    }else if(!postTransfersLoading && !postTransfersPayload?.data?.requestSuccessful && submitStatus){
      setSubmitStatus(false);
      dispatch(errorMessage(postTransfersPayload?.data?.message||"Error occurred while making this transfer"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTransfersLoading]);


  const handleAutoCompleteCreateChange = (event, values, input) => {
};

const modalTitle = <span>{step===2&& <span className='cursor' onClick={()=>setStep(1)}><ArrowBack/></span>} New Transfer {(step+"/2")}</span>

const submit = () => {
  if(step === 1) {
    let errorStatus = false;
    setSubmitStatus(true);    
    console.log({payload})
    for(let data in payload) {
      if((data === "country" || data === "sourceBankName" || data === "sourceAccount" || data === "currency") && (!payload[data] || payload[data] === "") ) {
          errorStatus = true
      }
    }
    if(!errorStatus) {
      setStep(2);
      setSubmitStatus(false);   
    }
    
  }else{
    let errorStatus = false;
    setSubmitStatus(true);    
    for(let data in payload) {
      if(!payload[data] || payload[data] === "") {
          errorStatus = true
      }
    }
    if(!errorStatus) {
      dispatch(postTransfers({...payload, destinationAccountName: accountLookupPayload?.data?.responseData}))
    }
  }
}

const debounceText = useCallback(debounce(async(srhTxt) => {
  dispatch(getTransfers(1, 10, srhTxt)) 
}, 500), [])


const handleSearchChange = (event, input) => {
  debounceText(event.target.value)
  setSearchValue(event.target.value)
};

const clearSearch = () => {
  debounceText("")
  setSearchValue("")
};

const showFilterMenu = () => {
}; 
   
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={true}
            showCreateBtn={true}
            btnName="New Transfer&nbsp;&nbsp;+"
            openCreate={() => setCreateForm(true)}
            handleSearchChange={handleSearchChange}
            searchValue={searchValue}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Source Bank" },
            { name: "Destination Bank" },
            { name: "Des. Account Number" },
            { name: "Amount" },
            { name: "Approval By" },
            { name: "Action" },
          ]}
          isSearching={gettingTransfersLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={gettingTransfersPayload?.data?.responseData?.items}
          content={
            <TableBody>
              {gettingTransfersPayload?.data?.responseData?.items?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.sourceBankName||"--"}
                  </TableCell>
                  <TableCell align="left">{row.destinationBankName||"--"}</TableCell>
                  <TableCell align="left">{row.destinationAccount === "INVALID ACCOUNT"?"--":row.destinationAccount||"--"}</TableCell>
                  <TableCell align="left">{row.totalAmount||"--"}</TableCell>
                  <TableCell align="left">{row.approvedBy||"--"}</TableCell>
                 <TableCell align="left">
                    <div className="action" onClick={()=>setView(row)}>
                      View
                    </div>
                  </TableCell>
                  <TableCell align="left">{row.Actions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page {gettingTransfersPayload?.data?.responseData?.pageNumber} of{" "}
              {gettingTransfersPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={gettingTransfersPayload?.data?.responseData?.pages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {createForm && (
          <CreateModal
            title={modalTitle}
            createForm={createForm}
            hideCreateForm={() => setCreateForm(false)}
            onCreate={() => submit()}
            creating={postTransfersLoading}
            disableCreate={postTransfersLoading}
            cancelText="Cancel"
            createText={step === 1? "Next":"Transfer"}
            content={
              <Form
                getCurrencyPayload={getCurrencyPayload}
                setPayload={setPayload}
                payload={payload}
                getCountryPayload={getCountryPayload}
                submitStatus={submitStatus}
                gettingBanksPayload={gettingBanksPayload}
                accountLookupPayload={accountLookupPayload}
                step={step}
                gettingBanksLoading={gettingBanksLoading}
                accountLookupLoading={accountLookupLoading}
              />
            }
          />
        )}

<Drawer
        anchor="right"
        open={!!view}
        onClose={() => setView(null)}
      >
        <div className="swift-detail-form">
        <div className="detail-title">Transfer Details <span className="cursor" onClick={() => setView(null)}><CloseIcon/></span></div>
          <div className="p-3">
          <div className="display-item w-100 mt-4 pb-1">
            <div className="title">Source Bank</div>
            <div className="desc mb-1">{view?.sourceBankName}</div>
          </div>
        
       
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Source Account Number</div>
            <div className="desc mb-1">{view?.sourceAccount}</div>
          </div>

         
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Currency</div>
            <div className="desc mb-1">{view?.currency}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Destination Bank</div>
            <div className="desc mb-1">{view?.destinationBankName}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Destination Account Name </div>
            <div className="desc mb-1">{view?.destinationAccountName}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Destination Account Number</div>
            <div className="desc mb-1">{view?.destinationAccount}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Account Name</div>
            <div className="desc mb-1">{view?.destinationAccountName}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Currency</div>
            <div className="desc mb-1">{view?.currency}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Country</div>
            <div className="desc mb-1">{view?.country}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Amount</div>
            <div className="desc mb-1">{view?.totalAmount}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Approval by</div>
            <div className="desc mb-1">{view?.transactionStatus}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Processing Gateway</div>
            <div className="desc mb-1">{view?.processingGateway}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Narration</div>
            <div className="desc mb-1">{view?.narration}</div>
          </div>
          </div>
          {/* <div className="update-btn">
            <div><Button className="primary-btn" onClick={()=>dispatch(approveTransfer(view.id, "approve"))}>Approve</Button></div>
            <div><Button variant="outline-danger" onClick={()=>dispatch(approveTransfer(view.id, "reject"))}>Reject</Button></div>
            </div> */}
         </div>
      </Drawer>

      {successModal && (
        <CreateModal
          title="Success"
          fullWidth
          createForm={successModal}
          hideCreateForm={() => setSuccessModal(false)}
          onCreate={() => submit()}
          cancelText="Cancel"
          createText="Update"
          maxWidth="md"
          hidebtns={true}
          className="swift-modal-container"
          content={
            <div className="view-transfer-success-content">
                 <SuccessIcon/>
                 <div className="title">Transfer request successful</div>
                 <div className="desc">Your request has been sent for approval.</div>
                 <div className='btn'>
                   <Button className="close-btn" onClick={()=>setSuccessModal(false)}>
                      Close
                   </Button>
                  </div>
            </div>
          }
        />
      )}

      </>
    );
}

export default Request;