import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import _debounce from 'lodash/debounce';
import moment from "moment"
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../Collect.scss';
import { getChargeBack, postChargeBack } from 'logic/actions/collectAction';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from '../../../components/paginations/Paginations';
import Form from './form';
import { errorMessage, successMessage } from 'logic/actions/notification';

const payloadData = {
  "transactionReference": null,
  "amount": null,
  "reason": null,
  "chargeType": 0,
  "pending": "Pending",
}
const ChargeBack = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [data, setData] = useState([]);
    const [payload, setPayload] = useState(payloadData);
    const [search] = useState('');
    const dispatch = useDispatch();

    const { getChargeBackLoading, getChargeBackPayload, postChargeBackLoading, postChargeBackPayload, postChargeBackError } = useSelector(({collectReducer}) => {
        return {
            getChargeBackError: collectReducer?.getChargeBackError,
            getChargeBackPayload: collectReducer?.getChargeBackPayload,
            getChargeBackLoading: collectReducer?.getChargeBackLoading,
            postChargeBackError: collectReducer?.postChargeBackError,
            postChargeBackPayload: collectReducer?.postChargeBackPayload,
            postChargeBackLoading: collectReducer?.postChargeBackLoading,
         };
      });

    const getPaginationNumber = (page) => {
      setCurrentPage(page)
      dispatch(getChargeBack(page))
    }
           
  useEffect(()=>{
      dispatch(getChargeBack());
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!getChargeBackLoading && getChargeBackPayload){
      setData(getChargeBackPayload?.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChargeBackLoading]);

  useEffect(()=>{
    if(!postChargeBackLoading &&  postChargeBackPayload?.data?.requestSuccessful && submitStatus){
      setPayload(payloadData);
      setCreateForm(false);
      setSubmitStatus(false);
      dispatch(successMessage("Charge-Back was created successfully"))
    }else if(!postChargeBackLoading &&  postChargeBackError && submitStatus) {
      setSubmitStatus(false)
      dispatch(errorMessage(postChargeBackError?.data?.message || "Error occurred while creating charge back"))
    }else if(!postChargeBackLoading &&  !postChargeBackPayload?.data?.requestSuccessful && submitStatus) {
      setSubmitStatus(false)
      dispatch(errorMessage(postChargeBackPayload?.data?.message || "Error occurred while creating charge back"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },  [postChargeBackLoading]);


const submit = () => {
  let errorStatus = false;
  setSubmitStatus(true);
  for(let data in payload) {
    if(!payload[data] || payload[data] === "") {
        errorStatus = true;
        setSubmitStatus(false)
     }
  }
  if(!errorStatus) {
    dispatch(postChargeBack(payload))
  }
}

const handleSearchChange = (event, input) => {
  event.persist();
};

const clearSearch = () => {
};

const formateDate = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedDate = dd + '/' + mm + '/' + yyyy;
  return formattedDate
}
const showFilterMenu = () => {
}; 
   
const format = () => {
        return data?.map((item, index) => {
          return {
            merchantName: item?.merchant?.merchantName,
            reference: item?.transactionReference,
            Id: item?.id,
            amount: item?.amount,
            type: item?.chargeType,
            approvalStatus: item?.approvalStatus,
            status: item?.status,
            createdAt: item?.createdAt,
          };
        });
      }
     
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={{}}
            showFilter={false}
            showSearch={false}
            showCreateBtn={true}
            btnName="New Chargeback&nbsp;&nbsp;+"
            openCreate={() => setCreateForm(true)}
            handleSearchChange={_debounce(handleSearchChange, 1000)}
            searchValue={search}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Merchant Name" },
            { name: "Amount" },
            { name: "Transaction Reference" },
            { name: "Chargeback Type" },
            { name: "Approval Status" },
            { name: "Status" },
            { name: "Date Created" },
          ]}
          isSearching={getChargeBackLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={getChargeBackPayload?.data?.data}
          content={
            <TableBody>
              {format()?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.merchantName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.amount}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.reference}
                  </TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.approvalStatus}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">
                    {formateDate(row.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page {getChargeBackPayload?.data?.pageNumber} of{" "}
              {getChargeBackPayload?.data?.totalPages}
            </div>
            <div>
              <Pagination
                totalPages={getChargeBackPayload?.data?.totalPages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {createForm && (
          <CreateModal
            title="Add ChargeBack"
            createForm={createForm}
            hideCreateForm={() => setCreateForm(false)}
            onCreate={() => submit()}
            creating={postChargeBackLoading}
            disableCreate={postChargeBackLoading}
            cancelText="Cancel"
            createText="Create ChargeBack"
            content={
              <Form
                setPayload={setPayload}
                payload={payload}
                submitStatus={submitStatus}
              />
            }
          />
        )}
      </>
    );
}

export default ChargeBack;