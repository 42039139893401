import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F4F5F6",
    color: "black",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function Header(props) {
  const { header } = props

  return (
    <TableHead>
      <TableRow>
        {header.map((row, index) => (
          <StyledTableCell key={index}>{row.name}</StyledTableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}