import { toast } from "react-toastify";
import { RoleEnum } from "./Constants";
import Swal from "sweetalert2";
import SimpleCryptp from "simple-crypto-js";
import TableExport from "tableexport";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Chip } from "@material-ui/core";
import React from "react";
import CustomToolTip from "components/Input/CustomToolTip";
export const appHelpers = {
  trimEvery: (t) => {
    return t.replace(/\s/g, "");
  },
  readFile: (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  },
  // from stackoverflow
  dataURLtoFile: (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  },
  genClientRef: (length = 16) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  madParse(x) {
    //   used to parse '1500.00' from string to float
    return x === x * 1 ? x * 1 : x;
  },
  numberWithCommas: (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  validateLogin: (context) => {
    if (context.state.email === "") {
      toast.error("Email is Required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    } else if (context.state.password === "") {
      toast.error("Password is Required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    } else {
      return true;
    }
  },

  formatData: (data) => {
    return data.map((item) => ({
      FirstName: item.firstName,
      Email: item.email,
      LastName: item.lastName,
      Status: item.status,
      RoleName: RoleEnum.filter((role) => item.roleId === role.value)[0].label,
    }));
  },
  changeTypeColor: (type) => {
    if (type === "Delete") {
      return "danger";
    } else if (type === "Update") {
      return "info";
    } else if (type === "Create") {
      return "success";
    }
  },

  validateResetPassword: (context) => {
    if (context.state.Password === "") {
      toast.error("Password is Required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    } else if (context.state.ConfirmPassword === "") {
      toast.error("Confirm Password is Required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    } else if (context.state.ConfirmPassword !== context.state.Password) {
      toast.error("Passwords don't match", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    } else {
      return true;
    }
  },

  showBackground: (array, index) => {
    // debugger
    if (Array.isArray(array)) {
      return array.find((value) => {
        return value.index === index;
      });
    }
    // debugger
  },

  showActionPopup: (yesAction, noAction) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn sweetyesbutton",
        cancelButton: "btn sweetcancelbutton",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you would like to cancel this form",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire("Your form has been saved.", "success");
          if (yesAction) {
            yesAction();
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Your form has been cancelled",
            "error"
          );
          if (noAction) {
            noAction();
          }
        }
      });
  },

  alertError: (message, duration) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
    });
  },
  alertSuccess: (message, duration) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
    });
  },
  alertWarning: (message, duration) => {
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
    });
  },
  filterByValue: (array, index) => {
    return array.filter((o) => Object.keys(o).some((k) => o[k] === index));
  },
  createRandomString: () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  },

  truncateEmail: (email) => {
    if (email) {
      if (email.length > 20) {
        return email.substring(0, 20) + "...";
      }
      return email;
    } else {
      return "nil.nil@gmail.com";
    }
  },

  showDeactivate: (handleCloseActions) => {
    handleCloseActions();
    Swal.fire({
      title: "Are you sure you want to deactivate user?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#51b64b",
      cancelButtonColor: "#f78222",
      showLoaderOnConfirm: true,
      confirmButtonText: "Yes, deactivate user!",
      preConfirm: () => {
        return fetch("https://jsonplaceholder.typicode.com/posts/1", {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deactivated!", "This User has been Deactivated", "success");
      }
    });
  },

  showActivate: (handleCloseActions) => {
    handleCloseActions();
    Swal.fire({
      title: "Are you sure you want to activate user?",
      showCancelButton: true,
      confirmButtonColor: "#51b64b",
      cancelButtonColor: "#f78222",
      showLoaderOnConfirm: true,
      confirmButtonText: "Yes, activate user!",
      preConfirm: () => {
        return fetch("https://jsonplaceholder.typicode.com/posts/1", {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire("Activated!", "This User has been Activated", "success");
      }
    });
  },

  showDelete: (handleCloseActions) => {
    handleCloseActions();
    Swal.fire({
      title: "Are you sure you want to delete user?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#51b64b",
      cancelButtonColor: "#f78222",
      showLoaderOnConfirm: true,
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return fetch("https://jsonplaceholder.typicode.com/posts/1", {
          method: "DELETE",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "This User has been Deleted", "success");
      }
    });
  },

  data2pdf: (rowData, headers, documentTitle) => {
    // Construct the dataset for Pdf
    let data = rowData;
    // Construct the headers for the Pdf
    // header format :
    // [
    //   {
    //     alias: "Email ",
    //     name: "Email",
    //     flex: 1,
    //   },
    //   {
    //     alias: "LastName ",
    //     name: "LastName",
    //     flex: 1,
    //   },
    //   {
    //     alias: "FirstName ",
    //     name: "FirstName",
    //     flex: 1,
    //   },
    //   {
    //     alias: "RoleName",
    //     name: "RoleName",
    //     flex: 1,
    //   },
    // ]
    // Generate the Pdf
    window.objectExporter({
      type: "pdf",
      exportable: data,
      headers: headers,
      fileName: "sample_pdf",
      documentTitle: documentTitle,
      headerStyle:
        "font-weight: bold; padding: 5px; border: 1px solid #dddddd;",
      cellStyle: "border: 1px solid lightgray; margin-bottom: -1px;",
    });
  },

  data2csv: (data, headers, mainHeader, formatData) => {
    // Construct the dataset for CSV

    // const DATA = "";
    const FILEEXTENSION = ".csv";
    const MIMETYPE = "text/csv";
    const headerString = headers.map((item) => `"${item}"`).join(",");
    let instance = new TableExport(document.createElement("table"), {});
    const dataArr = formatData.call(this, data).map((item, index) => {
      return [++index, ...Object.values(item)];
    });
    const dataArr2 = dataArr.map((item) =>
      item.map((item) => `"${item}"`).join(",")
    );
    instance.export2file(
      [mainHeader, headerString, ...dataArr2].join("\r\n"),
      MIMETYPE,
      mainHeader,
      FILEEXTENSION
    );
  },
  data2excel: (data, headers, mainHeader, formatData) => {
    //  this code is not stable,excel doesn't work, we need to update it
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArr = formatData.call(this, data).map((item, index) => {
      return {
        SN: ++index,
        ...item,
      };
    });
    const ws = XLSX.utils.json_to_sheet(dataArr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data2 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data2, mainHeader + fileExtension);
  },
  scrambleText: (text) => {
    if (text) {
      if (text.length > 3) {
        let leftPart = `${text.charAt(0)}${text.charAt(1)}${text.charAt(2)}`;
        let rightPart = `${text[text.length - 2]}${text[text.length - 1]}`;
        return `${leftPart}**************${rightPart}`;
      } else {
        return `${text.charAt(0)}***${text.charAt(2)}`;
      }
    }
  },
  getStatusColor: (tnxData, getRealData, props) => {
    let datasets = {
      label: "First dataset",
      data: getRealData(tnxData).arr,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
      pointRadius: 15,
      pointHoverRadius: 15,
      pointHoverBorderColor: "black",
      fill: false,
    };

    for (let i in tnxData) {
      let status = tnxData[i].status;
      if (status === 1) {
        return {
          ...datasets,
          pointBackgroundColor: "#ffeb3b",
          pointHoverBackgroundColor: "#ffeb3b",
        };
      } else if (status === 2) {
        return {
          ...datasets,
          pointBackgroundColor: "#4caf50",
          pointHoverBackgroundColor: "#4caf50",
        };
      } else if (status === 3) {
        return {
          ...datasets,
          pointBackgroundColor: "#f44336",
          pointHoverBackgroundColor: "#f44336",
        };
      } else {
        return "white";
      }
    }
  },

  // returnLineDatasets(tnxData) {
  //   let elems = [];
  //   for (let i in tnxData) {
  //   }
  // },
  returnAllRolesNams(roles) {
    let names = [];
    for (let i in roles) {
      names.push(roles[i].name);
    }
    return names.toString();
  },
  renderMultipleRoles(r) {
    let domElems = [];

    // we show just first three and show the rest on hover
    if (r.length > 4) {
      let firstThreeAgain = r.slice(0, 3);
      for (let j in firstThreeAgain) {
        domElems.push(
          <Chip size="small" key={j} label={firstThreeAgain[j].name} />
        );
      }

      domElems.push(
        <CustomToolTip
          tooltipvalue={appHelpers.returnAllRolesNams(r)}
          show
          content={
            <div style={{ fontWeight: "bold" }}>view +{r.length - 3}</div>
          }
        />
      );
    } else {
      let firstThree = r.slice(0, 3);
      for (let j in firstThree) {
        domElems.push(<Chip size="small" key={j} label={firstThree[j].name} />);
      }
    }
    return domElems;
  },
  formatdocs(data) {
    return data.map((item, index) => {
      return {
        FirstName: item.FirstName,
        LastName: item.LastName,
        EmailAddress: item.Email,
        RoleName: item.RoleName,
      };
    });
  },
  formatCountries(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.code2,
      };
    });
  },
  formAvailableCoutries(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  },
  formatTransactionCoutries(data) {
    return data.map((item, index) => {
      return {
        label: `${item.name} - ${item.isO3}`,
        value: item.isO3,
      };
    });
  },
  formatTransactionCurrency(data) {
    return data.map((item, index) => {
      return {
        label: `${item.name} - ${item.currencyCode}`,
        value: item.currencyCode,
      };
    });
  },
  formatState(data) {
    return data.map((item, index) => {
      return {
        label: item.name,
        value: item.code,
      };
    });
  },
  formatResponseEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.responseCode}-${item.serviceName}`,
        value: item.id,
      };
    });
  },
  formatCredentialKeys(data) {
    return data.map((item, index) => {
      return {
        gatewayCredentialKeyId: item.id,
        key: item.key,
        value: item.value || "",
      };
    });
  },
  formatCustomEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.configurationTag}`,
        value: item.id,
        clientId: item.clientId,
      };
    });
  },

  formatGatewayAccountEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.accountName}-${item.gatewayAccountNumber}`,
        value: item.id,
      };
    });
  },

  formatTransactionCConfiguration(data) {
    return data.map((item, index) => {
      return {
        label: `${item.configurationTag}`,
        value: item.configurationTag,
      };
    });
  },

  formatTransactionCServices(data) {
    return data.map((item, index) => {
      return {
        label: `${item.serviceName}`,
        value: item.serviceCode,
      };
    });
  },

  formatTransactionClientServices(data) {
    return data.map((item, index) => {
      return {
        label: `${item.serviceName}`,
        value: item.serviceId,
      };
    });
  },

  formatEnum(data) {
    if (data) {
      return data.map((item, index) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
  },
  formatChargeType(data) {
    let arr = [];
    if (data) {
      // return data.map((item, index) => {
      for (const [key, value] of Object.entries(data)) {
        arr.push({
          label: key,
          value: value,
        });
      }
    }
    return arr;
  },
  formatObjResponse(data) {
    let arr = [];
    if (data) {
      // return data.map((item, index) => {
      for (const [key, value] of Object.entries(data)) {
        arr.push({
          label: key,
          value: value,
        });
      }
    }
    return arr;
  },
  formatCustomDataEnum(data, keyName) {
    if (data) {
      return data.map((item, index) => {
        return {
          label: item[keyName],
          value: item.id,
        };
      });
    }
  },
  formatUsers(data) {
    return data.map((item, index) => {
      return {
        label: `${item.firstName}-${item.lastName}`,
        value: item.id,
      };
    });
  },
  formatCurrencyPairEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.sendingCurrencyCode}-${item.receivingCurrencyCode}`,
        value: item.id,
      };
    });
  },
  formatCountryEnum(data) {
    return data.map((item, index) => {
      return {
        label: `${item.name} - ${item.isO3}`,
        value: item.id,
      };
    });
  },
  resetPagination(context, value) {
    context.setState((prevState) => ({
      [value]: {
        ...prevState[value],
        PageSize: 10,
      },
    }));
  },

  cryptographyService: () => {
    const simpleCrypto = new SimpleCryptp("T()o(){o){R}");
    return simpleCrypto;
  },
  isValidUrl: (string) => {
    // try {
    //   new URL(string);
    // } catch (_) {
    //   return false;
    // }

    // return true;
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(string);
  },

  doSearch: (context, value, input, searchName) => {
    if (value.length > 2) {
      context.setState((prevState) => ({
        [searchName]: {
          ...prevState[searchName],
          [input]: value,
        },
      }));
    }

    if (value.length === 0) {
      context.setState((prevState) => ({
        [searchName]: {
          ...prevState[searchName],
          [input]: value,
        },
      }));
    }
  },

  mainFilter: (_data, codes) => {
    let res;
    _data = Object.values(_data).filter(function (obj) {
      return Object.keys(obj).some(function (key) {
        if (obj.children) {
          //console.log("obj[key", obj[key])
          res = appHelpers.mainFilter(obj[key], codes);

          return res.pop();
        } else {
          // return obj[key].toLowerCase().includes(_allsearch);
          // return obj[key].toLowerCase() === _allsearch;
          return codes.includes(obj.code);
        }

        // the code always breaks here
      });
    });

    return _data;
  },
};
