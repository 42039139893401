import React from "react";

const Form = ({ onChange, payload, submitStatus }) => {
  return (
    <form className="create-form">
      <div>
        <label htmlFor="name">Name</label>
        <input
          value={payload?.name}
          onChange={onChange}
          className="form-control"
          id="name"
          name="name"
        />
      </div>
      {submitStatus && !payload?.name && (
        <div className="error-desc">Name is required</div>
      )}

      <div>
        <label htmlFor="code">Gateway Code</label>
        <input
          value={payload?.code}
          onChange={onChange}
          className="form-control"
          id="code"
          name="code"
        />
      </div>
      {submitStatus && !payload?.code && (
        <div className="error-desc">Code is required</div>
      )}
    </form>
  );
};

export default Form;
