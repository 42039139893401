import { Grid } from "@material-ui/core";
import React from "react";
import "../payout.scss";

const Form = ({
  setPayload,
  payload
}) => {
  return (
    <form className="payout-country-form w-100">
          <div className="mb-4">
            <label htmlFor="Status" className="mb-1">
            Transaction Status
            </label>
            <div>
            <select className="form-control" value={payload?.status} id="Status" onChange={(e)=>setPayload({...payload, status: e.target.value})}>
              <option></option>
              <option>Processing</option>
              <option>Success</option>
              <option>Pending</option>
              <option>Failed</option>
            </select>
            </div>
            </div>
    </form>
  );
};

export default Form;
