import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const featureFlagReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_FEATURE_FLAGS):
        return {
            ...state,
            getFeatureFlagsLoading: true,
            getFeatureFlagsPayload: null,
            getFeatureFlagsError: null,
        };
    case SUCCESS(actionTypes.GET_FEATURE_FLAGS):
        return {
            ...state,
            getFeatureFlagsPayload: actions.payload,
            getFeatureFlagsError: null,
            getFeatureFlagsLoading: false,
        };
    case FAILURE(actionTypes.GET_FEATURE_FLAGS):
        return {
            ...state,
            getFeatureFlagsError: actions.payload,
            getFeatureFlagsPayload: null,
            getFeatureFlagsLoading: false,
        };
    case REQUEST(actionTypes.TOGGLE_FEATURE_FLAG):
      return {
        ...state,
        toggleFeatureFlagLoading: true,
        toggleFeatureFlagPayload: null,
        toggleFeatureFlagError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_FEATURE_FLAG):
      return {
        ...state,
        toggleFeatureFlagPayload: actions.payload,
        toggleFeatureFlagError: null,
        toggleFeatureFlagLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_FEATURE_FLAG):
      return {
        ...state,
        toggleFeatureFlagError: actions.payload,
        toggleFeatureFlagPayload: null,
        toggleFeatureFlagLoading: false,
      };
    case REQUEST(actionTypes.CREATE_FEATURE_FLAG):
      return {
        ...state,
        createFeatureFlagLoading: true,
        createFeatureFlagPayload: null,
        createFeatureFlagError: null,
      };
    case SUCCESS(actionTypes.CREATE_FEATURE_FLAG):
      return {
        ...state,
        createFeatureFlagPayload: actions.payload,
        createFeatureFlagError: null,
        createFeatureFlagLoading: false,
      };
    case FAILURE(actionTypes.CREATE_FEATURE_FLAG):
      return {
        ...state,
        createFeatureFlagError: actions.payload,
        createFeatureFlagPayload: null,
        createFeatureFlagLoading: false,
      };
    default:
      return state;
  }
};

export default featureFlagReducer;
