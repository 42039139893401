import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Aux from "../../components/hoc/_Aux";
import Request from "./request/request";
import Merchants from "./merchants/merchants";
import "./Reconcilation.scss";

const Reconcilation = () => {
  const [tab, setTab] = useState("merchants");
  return (
    <Aux>
      <Row className="virtual-account-container">
        <Col>
          <div className="content-header">
            <div className="service-name">Reconcilation</div>
            <div className="service-items">
              <div
                className={`item ${tab === "merchants" && "active"}`}
                onClick={() => setTab("merchants")}>
                Merchants
              </div>
              <div
                className={`item ${tab === "request" && "active"}`}
                onClick={() => setTab("request")}>
                Request
              </div>
                </div>
          </div>
          <div className="tab-items">
            {tab === "merchants" && <Merchants />}
            {tab === "request" && <Request />}
          </div>
        </Col>
      </Row>
    </Aux>
  );
};

export default Reconcilation;
