import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from 'lodash'
import { useDispatch, useSelector} from 'react-redux';

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../payout.scss';
import { getPayoutMerchant, togglePayoutRequest } from 'logic/actions/payout';
import ToggleModal from 'components/Modal/ToggleModal';
import Pagination from '../../../components/paginations/Paginations';
import { successMessage } from 'logic/actions/notification';

const Request = () => {
    const [id, setId] = useState(null);
    const [status, setStatus] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [toggleForm, setToggleForm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const { getPayoutMerchantLoading, getPayoutMerchantPayload, togglePayoutRequestLoading, togglePayoutRequestPayload } = useSelector(({payoutReducer}) => {
        return {
           getPayoutMerchantError: payoutReducer?.getPayoutMerchantError,
            getPayoutMerchantPayload: payoutReducer?.getPayoutMerchantPayload,
            getPayoutMerchantLoading: payoutReducer?.getPayoutMerchantLoading,
            togglePayoutRequestError: payoutReducer?.togglePayoutRequestError,
            togglePayoutRequestPayload: payoutReducer?.togglePayoutRequestPayload,
            togglePayoutRequestLoading: payoutReducer?.togglePayoutRequestLoading,
           };
      });
    
      const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getPayoutMerchant(0, 10, page, searchValue))
      }

  useEffect(()=>{
      dispatch(getPayoutMerchant(0));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  useEffect(()=>{
    if(!getPayoutMerchantLoading && getPayoutMerchantPayload?.data?.requestSuccessful){
      setData(getPayoutMerchantPayload?.data?.responseData?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayoutMerchantLoading]);

  useEffect(()=>{
    if(!togglePayoutRequestLoading && togglePayoutRequestPayload?.data?.requestSuccessful){
      dispatch(successMessage(togglePayoutRequestPayload?.data?.message))
      let i = 0;
      for(let dt of data) {
        if(dt?.id === id) {
          data.splice(i, 1);
          setData([...data]);
          setToggleForm(false);
          return
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [togglePayoutRequestLoading])
  
  const handleChange = (statusId, checked) => {
    setId(statusId);
    setStatus(checked);
    setToggleForm(true);
  }

  
const debounceText = useCallback(debounce(async(srhTxt) => {
  dispatch(getPayoutMerchant(0, 10, 1, srhTxt)) 
}, 500), [])


const handleSearchChange = (event, input) => {
  debounceText(event.target.value)
  setSearchValue(event.target.value)
};

const clearSearch = () => {
  debounceText("")
  setSearchValue("")
};



  const showFilterMenu = () => {
  }; 
  
  const handleAutoCompleteCreateChange = (event, values, input) => {
  };

  const format = () => {
    return data?.map((item, index) => {
      return {
        name: item?.merchantName,
        Id: item?.id,
        wallet: item?.mifosWalletId,
        country: item?.country,
        currency: item?.currency,
        isActive: item?.isActive,
        createdAt:  new Date(item?.createdAt).toLocaleString(),
        Actions:
        <div>
          <span className="approve" onClick={()=>handleChange(item?.id, false)}>Approve</span>
          <span className="reject" onClick={()=>handleChange(item?.id, true)}>Reject</span>
        </div>
      };
    });
  }
 
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={true}
            showCreateBtn={false}
            btnName=""
            openCreate={() => {}}
            handleSearchChange={handleSearchChange}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            searchValue={searchValue}
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Name" },
            { name: "Wallet" },
            { name: "Country" },
            { name: "Currency" },
            { name: "Status" },
            { name: "Action" },
          ]}
          isSearching={getPayoutMerchantLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={data}
          content={
            <TableBody>
              {format()?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.wallet}</TableCell>
                  <TableCell align="left">{row.country}</TableCell>
                  <TableCell align="left">{row.currency}</TableCell>
                  <TableCell align="left">
                    <div className="status-flag">
                      <span
                        className={`dot-status ${
                          row.isActive ? "dot-active" : "dot-inactive"
                        }`}>
                        .
                      </span>
                      {row.isActive ? "Active" : "Inactive"}
                    </div>
                  </TableCell>
                  <TableCell align="left">{row.Actions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page{" "}
              {getPayoutMerchantPayload?.data?.responseData?.pageNumber} of{" "}
              {getPayoutMerchantPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={getPayoutMerchantPayload?.data?.responseData?.pages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {toggleForm && (
          <ToggleModal
            title={status ? "Disable item?" : "Enable Item?"}
            toggleForm={toggleForm}
            onToggle={() => dispatch(togglePayoutRequest(id, status))}
            toggling={togglePayoutRequestLoading}
            disableToggle={togglePayoutRequestLoading}
            hideToggleModal={() => setToggleForm(false)}
            toggleText={status ? "Disable" : "Enable"}
            textContent={
              status
                ? "Are you sure you want to reject this item?"
                : "Are you sure you want to approve this item?"
            }
          />
        )}
      </>
    );
}

export default Request;