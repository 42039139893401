import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Aux from "../../components/hoc/_Aux";
import './FxServices.scss';
import TodayRate from "./todayRate/todayRate";
import PastRate from "./pastRate/pastRate";

const FxServices = () => {
  const [tab, setTab] = useState("today")
  
  
    return (
      <Aux>
        <Row className="virtual-account-container">
          <Col>
              <div className="content-header">
                <div className="service-name">FX</div>
                <div className="service-items">
                  <div className={`item ${tab === 'today' && 'active'}`} onClick={()=>setTab('today')}>Today's Rate</div>
                  <div className={`item ${tab === 'past' && 'active'}`} onClick={()=>setTab('past')}>Past's Rate</div>
                </div>

              </div>
              <div className="tab-items">
                  {tab === "today" && <TodayRate/>}
                  {tab === "past" && <PastRate/>}
              </div>
          </Col>
        </Row>
    </Aux>
    );
  }

export default FxServices;
