import actionTypes from "./actionTypes";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { Get, Put } from "apiServices/apiHandler.service";
import { complianceBaseUrl } from "apiServices/config.service";

export const approve = createAsyncAction(
  actionTypes.COMPLIANCE_APPROVE,
  async ({ type, typeQuery, id, clientId, status }) => {
    return await Put(
      `v1/AdminCompliance/${type}?${typeQuery}=${id}&ClientId=${clientId}`,
      {
        approvalStatus: 1,
        comment: "string",
      },
      complianceBaseUrl
    );
  }
);
export const getProfileComplianceDoc = createAsyncAction(
  actionTypes.GET_PROFILE_COMPLIANCE_DOC,
  async (peopleId) => {
    return await Get(
      `v1/AdminCompliance/profileDocuments?peopleId=${peopleId}`,
      complianceBaseUrl
    );
  }
);
export const getProfileDetails = createAsyncAction(
  actionTypes.GET_PROFILE_DETAILS,
  async (peopleId, clientId) => {
    return await Get(
      `v1/AdminCompliance/profile?peopleId=${peopleId}&ClientId=${clientId}`,
      complianceBaseUrl
    );
  }
);
export const getComplianceMerchant = createAsyncAction(
  actionTypes.GET_COMPLIANCE_MERCHANTS,
  async (pageSize = 10, pageNumber = 1, keyword = "") => {
    return await Get(
      `v1/Clients/GetAll?pageSize=${pageSize}&pageNumber=${pageNumber}&&keyword=${keyword}`,
      complianceBaseUrl
    );
  }
);
export const getComplianceProfile = createAsyncAction(
  actionTypes.GET_COMPLIANCE_PROFILE,
  async (id, pageSize = 100000, pageNumber = 1) => {
    return await Get(
      `v1/AdminCompliance/profiles?ClientId=${id}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
      complianceBaseUrl
    );
  }
);
export const getComplianceDoc = createAsyncAction(
  actionTypes.GET_COMPLIANCE_DOC,
  async (id, type, pageSize = 10000, pageNumber = 1) => {
    return await Get(
      `v1/AdminCompliance/documents?ClientId=${id}&resourceType=${type}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
      complianceBaseUrl
    );
  }
);
export const getComplianceDocByReference = createAsyncAction(
  actionTypes.GET_COMPLIANCE_DOC_BY_REFERENCE,
  async (referenceNo) => {
    return await Get(
      `v1/AdminCompliance/Document?referenceId=${referenceNo}`,
      complianceBaseUrl
    );
  }
);
export const getClientDetail = createAsyncAction(
  actionTypes.GET_CLIENT_DETAILS,
  async (id) => {
    return await Get(`v1/Admin/clients/${id}`, complianceBaseUrl);
  }
);
