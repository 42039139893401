import React, { useState, useCallback, useEffect } from "react";

import { debounce } from "lodash";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "components/Input/FilterMenu";
import CurrencyFormat from "react-currency-format";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  fetchAllBatchTransactions,
  fetchAllMerchantTransactions,
  getTransaction,
  postCompleteSettlement,
} from "logic/actions/requests";
import { clientsFilter } from "Constants";
import ListTable from "components/Table/ListTable";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/paginations/Paginations";
import { Grid, Paper } from "@material-ui/core";
import PageTitle from "components/PageTitle/PageTitle";
import { useHistory } from "react-router";

import "../settlements.scss";
import { useQueryParams } from "utils/hooks";
import { errorMessage, successMessage } from "logic/actions/notification";
import CustomButton from "components/Input/CustomButton";

const MerchantTransactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  const merchantId = query.get("merchantId");
  const merchantName = query.get("name");
  const batchId = query.get("batch");
  const currency = query.get("currency");
  const locationState = history?.location?.state;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  const {
    fetchingMerchantTransactions,
    merchantTransactions,
    fetchingBatchTransactions,
    batchTransactions,
    completeSettlementPayload,
    completeSettlementError,
    completingSettlement,
  } = useSelector(({ virtualAccountReducer }) => {
    return {
      getTransactionError: virtualAccountReducer?.getTransactionError,
      fetchingMerchantTransactions:
        virtualAccountReducer?.fetchingMerchantTransactions,
      merchantTransactions: virtualAccountReducer?.merchantTransactions,
      fetchingBatchTransactions:
        virtualAccountReducer?.fetchingBatchTransactions,
      batchTransactions: virtualAccountReducer?.batchTransactions,
      completeSettlementPayload:
        virtualAccountReducer?.completeSettlementPayload,
      completeSettlementError: virtualAccountReducer?.completeSettlementError,
      completingSettlement: virtualAccountReducer?.completingSettlement,
    };
  });

  useEffect(() => {
    if (batchId) {
      dispatch(
        fetchAllBatchTransactions({
          batchId,
          pageNumber: currentPage,
          currency,
        })
      );
    }
    if (merchantId && locationState) {
      const { startDate, endDate, currency } = locationState;
      dispatch(
        fetchAllMerchantTransactions({
          merchantId,
          pageNumber: currentPage,
          startDate,
          endDate,
          currency,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId, batchId, currency, locationState, currentPage]);

  useEffect(() => {
    if (!completingSettlement && submitStatus) {
      setSubmitStatus(false);
      if (completeSettlementPayload?.data?.requestSuccessful) {
        if (merchantId && locationState) {
          const { startDate, endDate, currency } = locationState;
          dispatch(
            fetchAllMerchantTransactions({
              merchantId,
              pageNumber: currentPage,
              startDate,
              endDate,
              currency,
            })
          );
        }
        dispatch(
          successMessage(
            completeSettlementPayload?.data?.message ||
              "Settlement completed successfully"
          )
        );
      } else if (
        !completeSettlementPayload?.data?.requestSuccessful &&
        submitStatus
      ) {
        dispatch(
          errorMessage(
            completeSettlementPayload?.data?.message ||
              "Error occurred while creating charge back"
          )
        );
      } else if (completeSettlementError) {
        dispatch(
          errorMessage(
            completeSettlementPayload?.data?.message ||
              "Error occurred while creating charge back"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeSettlementPayload]);

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const onCompleteTransactionClick = () => {
    if (merchantId && locationState) {
      setSubmitStatus(true);
      const amount =
        merchantTransactions?.data?.responseData?.items[0]?.totalAmount;
      const { startDate, endDate, currency } = locationState;
      dispatch(
        postCompleteSettlement({
          startDate,
          endDate,
          currency,
          merchantId,
          amount,
        })
      );
    }
  };

  const showFilterMenu = () => {};

  const handleAutoCompleteCreateChange = (event, values, input) => {};

  const debounceText = useCallback(
    debounce(async (keyword) => {
      if (batchId && currency) {
        dispatch(fetchAllBatchTransactions({ batchId, currency, keyword }));
      }
      if (merchantId && locationState) {
        const { startDate, endDate, currency } = locationState;
        dispatch(
          fetchAllMerchantTransactions({
            merchantId,
            pageNumber: currentPage,
            startDate,
            endDate,
            currency,
            keyword,
          })
        );
      }
    }, 500),
    []
  );

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getTransaction(page, 10, searchValue));
  };

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <PageTitle
          title={merchantName}
          linkText={batchId ? "Go Back" : "Settlement / Details"}
          onGoBack={() =>
            batchId
              ? history.push("/services/virtual-accounts")
              : history.push(
                  locationState
                    ? `/services/virtual-accounts/grouped-transactions?startDate=${locationState?.startDate}&endDate=${locationState?.endDate}&currency=${locationState?.currency}`
                    : "/services/virtual-accounts"
                )
          }
        />
      </Grid>

      {batchId ? (
        <Grid item>
          <Paper elevation={0} className="pt-1 table-wrap">
            <div className="customScroll content-div d-flex justify-content-between m-3">
              <FilterMenu
                caption="Clients List"
                totalCount={10}
                searchLabel="Search.."
                filterOptions={clientsFilter}
                filterdefaultValueOptions={{
                  label: "All",
                  value: "all",
                }}
                handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
                showFilter={false}
                showSearch
                showCreateBtn={false}
                btnName=""
                handleSearchChange={handleSearchChange}
                showFilterMenu={showFilterMenu}
                filterLabel="Filter by"
                searchName="keyword"
                searchValue={searchValue}
                clearSearch={clearSearch}
                entriesName="Entries"
                filterValue={"Search"}
                entriesOptions={{}}
                entriesdefaultValueOptions={{
                  label: `Entries`,
                  value: 20,
                }}
                entriesValue={{}}
                entriesLabel="Entries"
              />
            </div>

            <ListTable
              header={[
                { name: "Acc. Number" },
                { name: "Bank Name" },
                { name: "Currency" },
                { name: "Date" },
              ]}
              isSearching={fetchingBatchTransactions}
              showError={false}
              style={{ opacity: 1 }}
              pageOfItems={batchTransactions?.data?.responseData?.items}
              content={
                <TableBody>
                  {batchTransactions?.data?.responseData?.items?.map(
                    (row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row?.accountNumber || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.bankName || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.currency || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.transactionDate.split(" ")[0] || "-"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              }
            />
            <br />
            {batchTransactions?.data?.responseData?.items?.length > 0 ? (
              <div className="d-flex align-items-center justify-content-between px-3 pb-3">
                <div>
                  Showing Page{" "}
                  {batchTransactions?.data?.responseData?.pageNumber} of{" "}
                  {batchTransactions?.data?.responseData?.pages}
                </div>
                <div>
                  <Pagination
                    totalPages={batchTransactions?.data?.responseData?.pages}
                    currentPage={currentPage}
                    changeCurrentPage={(p) => getPaginationNumber(p)}
                    previousBtn="Prev"
                    nextBtn="Next"
                  />
                </div>
              </div>
            ) : null}
          </Paper>
        </Grid>
      ) : (
        <>
          <Grid item>
            <Paper elevation={0} className="pt-1 table-wrap">
              <div className="customScroll content-div d-flex justify-content-between m-3">
                <div>
                  <p className="amt-label">Total amount to be settled</p>
                  <CurrencyFormat
                    value={
                      merchantTransactions?.data?.responseData?.items[0]
                        ?.amountToSettle
                    }
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={
                      merchantTransactions?.data?.responseData?.items[0]
                        ?.currency
                        ? getSymbolFromCurrency(
                            merchantTransactions?.data?.responseData?.items[0]
                              ?.currency
                          )
                        : ""
                    }
                    renderText={(value) => (
                      <h3 className="amt-value">{value}</h3>
                    )}
                  />
                </div>
                <div>
                  <CustomButton
                    onClick={() => onCompleteTransactionClick()}
                    disabled={completingSettlement}
                    show={true}
                    loading={completingSettlement}
                    loadingText="Processing..."
                    text="Complete Settlement"
                    className="create-btn"
                  />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item>
            <Paper elevation={0} className="pt-1 table-wrap">
              <div className="customScroll content-div d-flex justify-content-between m-3">
                <FilterMenu
                  caption="Clients List"
                  totalCount={10}
                  searchLabel="Search.."
                  filterOptions={clientsFilter}
                  filterdefaultValueOptions={{
                    label: "All",
                    value: "all",
                  }}
                  handleAutoCompleteCreateChange={
                    handleAutoCompleteCreateChange
                  }
                  showFilter
                  showSearch
                  handleSearchChange={handleSearchChange}
                  showFilterMenu={showFilterMenu}
                  filterLabel="Filter by"
                  searchName="keyword"
                  searchValue={searchValue}
                  clearSearch={clearSearch}
                  entriesName="Entries"
                  filterValue={"Search"}
                  entriesOptions={{}}
                  entriesdefaultValueOptions={{
                    label: `Entries`,
                    value: 20,
                  }}
                  entriesValue={{}}
                  entriesLabel="Entries"
                />
              </div>

              <ListTable
                header={[
                  { name: "Acc. Number" },
                  { name: "Bank Name" },
                  { name: "Transaction Amount" },
                  // { name: "Amount to be Settled" },
                  { name: "Currency" },
                  { name: "Reference" },
                  { name: "Date" },
                ]}
                isSearching={fetchingMerchantTransactions}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={merchantTransactions?.data?.responseData?.items}
                content={
                  <TableBody>
                    {merchantTransactions?.data?.responseData?.items?.map(
                      (row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row?.accountNumber || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.bankName || "-"}
                          </TableCell>
                          <TableCell align="left">
                            <CurrencyFormat
                              value={row?.transactionAmount}
                              displayType="text"
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              prefix={getSymbolFromCurrency(
                                row?.currency || ""
                              )}
                            />
                          </TableCell>
                          {/* <TableCell align="left">
                            <CurrencyFormat
                              value={row?.amountToSettle}
                              displayType="text"
                              decimalScale={2}
                              fixedDecimalScale
                              thousandSeparator={true}
                              prefix={getSymbolFromCurrency(
                                row?.currency || ""
                              )}
                            />
                          </TableCell> */}
                          <TableCell align="left">
                            {row?.currency || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.transactionReference || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row?.transactionDate.split(" ")[0] || "-"}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                }
              />
              <br />
              {merchantTransactions?.data?.responseData?.items?.length > 0 ? (
                <div className="d-flex align-items-center justify-content-between px-3 pb-3">
                  <div>
                    Showing Page{" "}
                    {merchantTransactions?.data?.responseData?.pageNumber} of{" "}
                    {merchantTransactions?.data?.responseData?.pages}
                  </div>
                  <div>
                    <Pagination
                      totalPages={
                        merchantTransactions?.data?.responseData?.pages
                      }
                      currentPage={currentPage}
                      changeCurrentPage={(p) => getPaginationNumber(p)}
                      previousBtn="Prev"
                      nextBtn="Next"
                    />
                  </div>
                </div>
              ) : null}
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MerchantTransactions;
