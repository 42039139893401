import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {};

const userManagementReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_USERS):
      return {
        ...state,
        gettingUsersLoading: true,
        gettingUsersPayload: null,
        gettingUsersError: null,
      };
    case SUCCESS(actionTypes.GET_USERS):
      return {
        ...state,
        gettingUsersPayload: actions.payload,
        gettingUsersError: null,
        gettingUsersLoading: false,
      };
    case FAILURE(actionTypes.GET_USERS):
      return {
        ...state,
        gettingUsersError: actions.payload,
        gettingUsersPayload: null,
        gettingUsersLoading: false,
      };
    case REQUEST(actionTypes.ADD_USER):
      return {
        ...state,
        addingUserLoading: true,
        addingUserPayload: null,
        addingUserError: null,
      };
    case SUCCESS(actionTypes.ADD_USER):
      return {
        ...state,
        addingUserPayload: actions.payload,
        addingUserError: null,
        addingUserLoading: false,
      };
    case FAILURE(actionTypes.ADD_USER):
      return {
        ...state,
        addingUserError: actions.payload,
        addingUserPayload: null,
        addingUserLoading: false,
      };
    case REQUEST(actionTypes.UPDATE_USER):
      return {
        ...state,
        updatingUserLoading: true,
        updatingUserPayload: null,
        updatingUserError: null,
      };
    case SUCCESS(actionTypes.UPDATE_USER):
      return {
        ...state,
        updatingUserPayload: actions.payload,
        updatingUserError: null,
        updatingUserLoading: false,
      };
    case FAILURE(actionTypes.UPDATE_USER):
      return {
        ...state,
        updatingUserError: actions.payload,
        updatingUserPayload: null,
        updatingUserLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_USER):
      return {
        ...state,
        togglingUserLoading: true,
        togglingUserPayload: null,
        togglingUserError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_USER):
      return {
        ...state,
        togglingUserPayload: actions.payload,
        togglingUserError: null,
        togglingUserLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_USER):
      return {
        ...state,
        togglingUserError: actions.payload,
        togglingUserPayload: null,
        togglingUserLoading: false,
      };
    case REQUEST(actionTypes.GET_ROLES):
      return {
        ...state,
        gettingRolesLoading: true,
        gettingRolesPayload: null,
        gettingRolesError: null,
      };
    case SUCCESS(actionTypes.GET_ROLES):
      return {
        ...state,
        gettingRolesPayload: actions.payload,
        gettingRolesError: null,
        gettingRolesLoading: false,
      };
    case FAILURE(actionTypes.GET_ROLES):
      return {
        ...state,
        gettingRolesError: actions.payload,
        gettingRolesPayload: null,
        gettingRolesLoading: false,
      };
    case REQUEST(actionTypes.CREATE_USER):
      return {
        ...state,
        creatingUserLoading: true,
        creatingUserPayload: null,
        creatingUserError: null,
      };
    case SUCCESS(actionTypes.CREATE_USER):
      return {
        ...state,
        creatingUserPayload: actions.payload,
        creatingUserError: null,
        creatingUserLoading: false,
      };
    case FAILURE(actionTypes.CREATE_USER):
      return {
        ...state,
        creatingUserError: actions.payload,
        creatingUserPayload: null,
        creatingUserLoading: false,
      };
    default:
      return state;
  }
};

export default userManagementReducer;
