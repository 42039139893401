import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

 
const fxReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_PAST_FX):
      return { ...state, gettingPastFxLoading: true, gettingPastFxPayload:null, gettingPastFxError:null };
    case SUCCESS(actionTypes.GET_PAST_FX):
      return {
        ...state,
        gettingPastFxPayload: actions.payload,
        gettingPastFxError: null,
        gettingPastFxLoading: false,
      };
    case FAILURE(actionTypes.GET_PAST_FX):
      return {
        ...state,
        gettingPastFxError: actions.payload,
        gettingPastFxPayload: null,
        gettingPastFxLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_FX):
      return { ...state, toggleFxLoading: true, toggleFxPayload:null, toggleFxError:null };
    case SUCCESS(actionTypes.TOGGLE_FX):
      return {
        ...state,
        toggleFxPayload: actions.payload,
        toggleFxError: null,
        toggleFxLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_FX):
      return {
        ...state,
        toggleFxError: actions.payload,
        toggleFxPayload: null,
        toggleFxLoading: false,
      };
    case REQUEST(actionTypes.POST_FX):
      return { ...state, postFxLoading: true, postFxPayload:null, postFxError:null };
    case SUCCESS(actionTypes.POST_FX):
      return {
        ...state,
        postFxPayload: actions.payload,
        postFxError: null,
        postFxLoading: false,
      };
    case FAILURE(actionTypes.POST_FX):
      return {
        ...state,
        postFxError: actions.payload,
        postFxPayload: null,
        postFxLoading: false,
      };
      case REQUEST(actionTypes.GET_FX):
        return { ...state, gettingFxLoading: true, gettingFxPayload:null, gettingFxError:null };
      case SUCCESS(actionTypes.GET_FX):
        return {
          ...state,
          gettingFxPayload: actions.payload,
          gettingFxError: null,
          gettingFxLoading: false,
        };
      case FAILURE(actionTypes.GET_FX):
        return {
          ...state,
          gettingFxError: actions.payload,
          gettingFxPayload: null,
          gettingFxLoading: false,
        };
    default:
      return { ...state };
  }
};

export default fxReducer;
