import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { NumericFormat } from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map'
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter, generalErrorMessage } from "Constants";
import "../payout.scss";
import CreateModal from "components/Modal/CreateModal";
import Pagination from "../../../components/paginations/Paginations";
import { errorMessage, successMessage } from "logic/actions/notification";
import {
  getSwiftPayout,
  updateSwiftPayoutStatus,
} from "logic/actions/payout";
import Form from "./form";
import { Drawer } from "@material-ui/core";
import { Button } from "react-bootstrap";
import CloseIcon from "icons/close-icon";

const Swift = () => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [payload, setPayload] = useState();
  const [editor, setEditor] = React.useState({
    open: false,
    isModify: false,
    submitted: false,
  });
  const [viewer, setViewer] = React.useState({ open: false, data: null });
  const dispatch = useDispatch();

  const {
    getSwiftLoading,
    getSwiftPayload,
    getSwiftError,

    updateSwiftStatusLoading,
    updateSwiftStatusPayload,
    updateSwiftStatusError,
     } = useSelector(({ payoutReducer }) => {
    return {
      getSwiftError: payoutReducer?.getSwiftError,
      getSwiftPayload: payoutReducer?.getSwiftPayload,
      getSwiftLoading: payoutReducer?.getSwiftLoading,
      updateSwiftStatusError: payoutReducer?.updateSwiftStatusError,
      updateSwiftStatusPayload: payoutReducer?.updateSwiftStatusPayload,
      updateSwiftStatusLoading: payoutReducer?.updateSwiftStatusLoading,
      };
  });

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getSwiftPayout(10, page, searchValue));
  };

  useEffect(() => {
    dispatch(getSwiftPayout(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !updateSwiftStatusLoading &&
      updateSwiftStatusPayload?.data?.requestSuccessful && submitStatus
    ) {
      setSubmitStatus(false)
      setEditor({ open: true, isModify: false });
      dispatch(getSwiftPayout(10, currentPage, searchValue));
      updateSwiftStatusPayload?.data?.message && dispatch(
        successMessage(
          updateSwiftStatusPayload?.data?.message
        )
      );
    } else if (
      !updateSwiftStatusLoading &&
      !updateSwiftStatusPayload?.data?.requestSuccessful && submitStatus
    ) {
      setSubmitStatus(false)
      dispatch(
        errorMessage(
          updateSwiftStatusPayload?.data?.message || generalErrorMessage
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSwiftStatusLoading]);
 
 
  const handleActionItemClick = (data, actionType) => {
    switch (actionType) {
      case "edit":
        setSubmitStatus(false);
        setEditor({ open: true, isModify: true });
        setViewer({ data, open: false });
        setPayload({
          status: data.transactionStatus,
          id: data.transactionId,
        });
        break;
      case "view":
        setViewer({ data, open: true });
        break;

      default:
        break;
    }
  };

  const submit = () => {
    const {id, status} = payload;
    setSubmitStatus(true)
    dispatch(updateSwiftPayoutStatus(id, status));
  }

  const debounceText = useCallback(
    debounce(async (srhTxt, page) => {
      dispatch(getSwiftPayout(10, page, srhTxt));
    }, 500),
    []
  );

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value, currentPage);
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    debounceText("", currentPage);
    setSearchValue("");
  };

  const showFilterMenu = () => {};

  return (
    <div className="swift-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
        className="customScroll"
      >
        <FilterMenu
          caption="Clients List"
          totalCount={10}
          searchLabel="Search.."
          filterOptions={clientsFilter}
          filterdefaultValueOptions={{
            label: "All",
            value: "all",
          }}
          handleAutoCompleteCreateChange={()=>{}}
          showFilter={false}
          showSearch={true}
          showCreateBtn={false}
          btnName="New Country&nbsp;&nbsp;+"
          openCreate={() => {}}
          handleSearchChange={handleSearchChange}
          showFilterMenu={showFilterMenu}
          filterLabel="Filter by"
          searchName="keyword"
          searchValue={searchValue}
          clearSearch={clearSearch}
          entriesName="Entries"
          filterValue={"Search"}
          entriesOptions={{}}
          entriesdefaultValueOptions={{
            label: `Entries`,
            value: 20,
          }}
          entriesValue={{}}
          entriesLabel="Entries"
        />
      </div>

      <ListTable
        header={[
          { name: "Swift Code" },
          { name: "Destination Country" },
          { name: "Amount" },
          { name: "Bank" },
          { name: "Acc. Number" },
          { name: "Status" },
          { name: "Action" },
        ]}
        isSearching={getSwiftLoading}
        showError={false}
        style={{ opacity: 1 }}
        pageOfItems={getSwiftPayload?.data?.items}
        content={
          <TableBody>
            {getSwiftPayload?.data?.items?.map(
              (row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.metaData?.SwiftCode}
                  </TableCell>
                
                  <TableCell align="left">{row?.metaData?.countryName}</TableCell>
                  <TableCell align="left"><NumericFormat displayType="text" value={row.amount} allowLeadingZeros thousandSeparator="," prefix={getSymbolFromCurrency(row.currency)}/>
                  </TableCell>
                  <TableCell align="left">{row.beneficiaryBankName}</TableCell>
                  <TableCell align="left">{row.beneficiaryAccount}</TableCell>
                  <TableCell align="left">{row.transactionStatus}</TableCell>
                  <TableCell align="left">
                    <div className="payout-action">
                      <span onClick={()=>handleActionItemClick(row, "view")} className="view-more">View More</span>
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        }
      />
      <br />
      {getSwiftPayload?.data?.items?.length > 0 ? (
        <div className="paginate-content px-3">
          <div>
            Showing Page{" "}
            {getSwiftPayload?.data?.responseData?.pageNumber} of{" "}
            {getSwiftPayload?.data?.responseData?.pages}
          </div>
          <div>
            <Pagination
              totalPages={getSwiftPayload?.data?.pages}
              currentPage={currentPage}
              changeCurrentPage={(p) => getPaginationNumber(p)}
              previousBtn="Prev"
              nextBtn="Next"
            />
          </div>
        </div>
      ) : null}

      {editor.open && (
        <CreateModal
          title="Update Transaction Status"
          fullWidth
          createForm={editor.open}
          hideCreateForm={() => setEditor({ open: false, isModify: false })}
          onCreate={() => submit()}
          creating={updateSwiftStatusLoading}
          disableCreate={updateSwiftStatusLoading || !payload?.status}
          cancelText="Cancel"
          createText="Update"
          maxWidth="md"
          className="swift-modal-container"
          content={
            <Form
              setPayload={setPayload}
              payload={payload}
              submitStatus={submitStatus}
            />
          }
        />
      )}

      <Drawer
        anchor="right"
        open={viewer.open}
        onClose={() => setViewer({ open: false, data: null })}
      >
        <div className="swift-detail-form">
        <div className="detail-title">Transaction Details <span className="cursor" onClick={() => setViewer({ open: false, data: null })}><CloseIcon/></span></div>
          <div className="p-3">
          <div className="display-item w-100 mt-4 pb-1">
            <div className="title">Swift Code</div>
            <div className="desc mb-1">{viewer.data?.metaData?.SwiftCode}</div>
          </div>
         
          <div className="display-item w-100 mt-4 pb-1">
            <div className="title">Destination Country</div>
            <div className="desc mb-1">{viewer.data?.metaData?.countryName}</div>
          </div>
         
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Amount</div>
            <div className="desc mb-1"><NumericFormat displayType="text" value={viewer.data?.amount} allowLeadingZeros thousandSeparator="," prefix={getSymbolFromCurrency(viewer.data?.currency)}/></div>
          </div>

          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Bank</div>
            <div className="desc mb-1">{viewer.data?.beneficiaryBankName}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Acc. Number</div>
            <div className="desc mb-1">{viewer.data?.beneficiaryAccount}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Acc. Name</div>
            <div className="desc mb-1">{viewer.data?.beneficiaryName}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Status</div>
            <div className="desc mb-1">{viewer.data?.transactionStatus}</div>
          </div>
          </div>
          <div className="update-btn"><Button className="primary-btn" onClick={()=>handleActionItemClick(viewer.data,"edit")}>Update Transaction Status</Button>
            </div>
         </div>
      </Drawer>

     
    </div>
  );
};

export default Swift;
