import React from "react";

const GrowDot = () => (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_14229_16426)">
<path d="M7.99992 14.6673C4.31792 14.6673 1.33325 11.6827 1.33325 8.00065C1.33325 4.31865 4.31792 1.33398 7.99992 1.33398C11.6819 1.33398 14.6666 4.31865 14.6666 8.00065C14.6666 11.6827 11.6819 14.6673 7.99992 14.6673ZM6.47325 13.112C5.81553 11.7169 5.4345 10.2075 5.35125 8.66732H2.70792C2.83764 9.69325 3.26253 10.6594 3.93096 11.4485C4.5994 12.2375 5.48259 12.8154 6.47325 13.112ZM6.68658 8.66732C6.78725 10.2933 7.25192 11.8207 7.99992 13.1687C8.76813 11.785 9.21688 10.247 9.31325 8.66732H6.68658ZM13.2919 8.66732H10.6486C10.5653 10.2075 10.1843 11.7169 9.52658 13.112C10.5172 12.8154 11.4004 12.2375 12.0689 11.4485C12.7373 10.6594 13.1622 9.69325 13.2919 8.66732ZM2.70792 7.33398H5.35125C5.4345 5.79384 5.81553 4.28444 6.47325 2.88932C5.48259 3.1859 4.5994 3.76382 3.93096 4.55285C3.26253 5.34188 2.83764 6.30805 2.70792 7.33398ZM6.68725 7.33398H9.31258C9.21642 5.75438 8.7679 4.21634 7.99992 2.83265C7.23171 4.21628 6.78296 5.75433 6.68658 7.33398H6.68725ZM9.52658 2.88932C10.1843 4.28444 10.5653 5.79384 10.6486 7.33398H13.2919C13.1622 6.30805 12.7373 5.34188 12.0689 4.55285C11.4004 3.76382 10.5172 3.1859 9.52658 2.88932Z" fill="#175FFF"/>
</g>
<defs>
<clipPath id="clip0_14229_16426">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
)

export default GrowDot