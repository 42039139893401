import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import "./input.css";
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "35ch",
  },
  iconButton: {
    "&:focus": {
      outline: "none"
    },
    marginRight: "0",
    left: "7px"

  }
}));

export default function SearchField(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={props.searchFieldContainerStyle}>
      <TextField
        label={props.label}
        // id="search-field-guy"
        // className={clsx(classes.margin, classes.textField)}
        onChange={(e) =>
          props.onChange(e, props.name)
        }
        style={{
          ...{ width: "45ch", ...props.style }
        }}
        value={props.searchValue}
        className="search-field-guy"
        margin="dense"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" style={{ marginRight: "0" }}>
              {props.value.length > 0 ?
                <IconButton onClick={props.clearSearch} style={{ outline: "none" }} classes={classes.iconButton}>
                  <CloseIcon />
                </IconButton>
                :
                <SearchIcon />

              }
            </InputAdornment>
            // <InputAdornment position="start"style={{marginRight:"0"}}>
            //   <SearchIcon/>
            // </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </div>
  );
}
