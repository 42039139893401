import React from "react";
import noRecord from "../../assets/images/no_record_found.svg";

const NoRecord = () => {
  return (
    <div
      style={{ marginTop: "120px", position: "relative" }}
      className="empty-data"
    >
      <div>
        <img src={noRecord} alt="no record" style={{ width: "100px" }} />
      </div>
      <div style={{ color: "#aba4a4" }}>
        <small>No Records found</small>
      </div>
    </div>
  );
};

export default NoRecord;
