import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles({
  button: {
    textTransform: "none",
    "&:focus": {
      outline: "none",
    },
  },
});

export default function CreateModal(props) {
  const handleClose = () => {
    props.hideCreateForm();

  };

  const handleCancel = () => {
    if (props.handleCancelFromParent) {
      props.onCancelClick();
    } else {
      props.hideCreateForm();
    }

    // this is no more needed
    //appHelpers.showActionPopup(null,null)
  };

  let closeImg = {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
  };

  return (
    <div >
      <Dialog
        open={props.createForm}
        onClose={handleClose}
        fullWidth={props.fullWidth}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={props.maxWidth || "md"}
        className={props.className}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <div>
            {props.title}
            <img
              alt="dialog-close"
              onClick={handleClose}
              src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
              style={closeImg}
            />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText component={"div"}>
            {props.content}
          </DialogContentText>
        </DialogContent>
        {!props.hidebtns && (
          <DialogActions>
            {!props.hideCancelBtn && (
              <Button
                autoFocus
                onClick={handleCancel}
                className="cancel-btn text-capitalize"
              >
                {props.cancelText}
              </Button>
            )}
            <button
              disabled={props.disableCreate}
              onClick={props.onCreate}
              className={`btn submit-btn ${props.fullBtnWidth ? "w-100" : ""}`}
              id="create-button"
            >
              {props.creating && (
                <CircularProgress
                  size={20}
                  style={{ marginBottom: "-4px" }}
                  color="white"
                />
              )}{" "}
              {props.createText}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
