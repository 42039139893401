import actionTypes from "../actions/actionTypes";
import { FAILURE, REQUEST, SUCCESS } from "./action-type.util";

const initialState = {
  allusers: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        allusers: action.payload.allusers,
      };

    case REQUEST(actionTypes.FETCH_USER_ROLES):
      return { ...state, fetchingUserRoles: true, userRolesPayload: null, userRolesError: null };
    case SUCCESS(actionTypes.FETCH_USER_ROLES):
      return {
        ...state,
        userRolesPayload: action.payload,
        userRolesError: null,
        fetchingUserRoles: false,
      };
    case FAILURE(actionTypes.FETCH_USER_ROLES):
      return {
        ...state,
        userRolesError: action.payload,
        userRolesPayload: null,
        fetchingUserRoles: false,
      };

    case REQUEST(actionTypes.MUTATE_USER_ROLE):
      return { ...state, mutatingUserRole: true, mutateUserRoleSuccess: null, mutateUserRoleError: null };
    case SUCCESS(actionTypes.MUTATE_USER_ROLE):
      return {
        ...state,
        mutateUserRoleSuccess: action.payload,
        mutateUserRoleError: null,
        mutatingUserRole: false,
      };
    case FAILURE(actionTypes.MUTATE_USER_ROLE):
      return {
        ...state,
        mutateUserRoleError: action.payload,
        mutateUserRoleSuccess: null,
        mutatingUserRole: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
