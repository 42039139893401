import { accountLookup, getBanks } from "logic/actions/transferAction";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

import { useDispatch } from "react-redux";
import Select, { components } from "react-select";

const Form = ({ setPayload, payload, submitStatus,getCountryPayload, getCurrencyPayload, gettingBanksPayload, accountLookupPayload, step, gettingBanksLoading, accountLookupLoading }) => {
  const dispatch = useDispatch();
 const banks = gettingBanksPayload?.data?.responseData?.map(
  (val) => ({ value: val?.bankCode, label: val?.bankName })
)

const Input = ({ ...rest }) =><components.Input {...rest} autoComplete={'nope'} />
  
  return (
    <form className="create-form">
      {step ===1 ?<div>
        <div>
                <label htmlFor="country">Country</label>
                <Select
                   id="country"
                   name="country"
                      value={payload?.countryPayload}
                      components={{ Input }}
                      onChange={e => {
                        dispatch(getBanks(e.value?.toString()))
                        setPayload({...payload, country: e.value?.toString(), countryPayload: e})
                      }}
                      options={getCountryPayload?.data?.responseData?.items?.map(val =>({ value: val?.isO3, label: val?.name }))}
                  /> 
              </div>
                 {submitStatus && !payload?.country && <div className="error-desc">Country is required</div>}
             
      {banks?.length > 0 &&<div>
        <label htmlFor="sourceBank">Source Bank</label>
        <Select
          value={payload?.sourceBankNamePayload}
          components={{ Input }}
          onChange={(e) =>
            setPayload({
              ...payload,
              sourceBankName: e.label,
              sourceBankNamePayload: e
            })
          }
          options={banks}
        />
      </div>}
      {gettingBanksLoading &&<div className="field-msg"><Spinner size="sm" color="primary" /> Loading banks </div>}
      {!gettingBanksLoading && !banks?.length && payload?.countryPayload && <div className="error-desc">No banks configured for the selected country</div>}
      {submitStatus && !payload?.sourceBankName && (
        <div className="error-desc">Source Bank is required</div>
      )}
      <div>
        <label htmlFor="rate">Source Account Number</label>
        <input
          value={payload.sourceAccount}
          components={{ Input }}
          onChange={(e) => setPayload({ ...payload, sourceAccount: e.target.value })}
          type="number"
          className="form-control"
          id="sourceAccount"
        />
        
      </div>
      {!payload?.sourceAccount && submitStatus && (
        <div className="error-desc">Source Account Number is required</div>
      )}
      {payload?.sourceAccount && submitStatus && payload?.sourceAccount < 0 && (
        <div className="error-desc">Valid Source Account Number is required</div>
      )}

      <div className="select-dropdown-mb">
        <label htmlFor="from">Currency</label>
        <Select
          value={payload?.currencyPayload}
          components={{ Input }}
          onChange={(e) =>
            setPayload({
              ...payload,
              currency: e.value,
              currencyPayload: e,
            })
          }
          
          options={getCurrencyPayload?.data?.responseData?.items?.map(
            (val) => ({ value: val?.currencyCode, label: val?.name })
          )}
        />
      </div>
      {submitStatus && !payload?.currency && (
        <div className="error-desc">Currency is required</div>
      )}
      </div>:<div>
      
      <div>
        <label htmlFor="destinationAccount">Destination Account Number</label>
        <input
          value={payload.destinationAccount}
          onChange={(e) => {
            payload?.institutionCode && e.target.value?.toString()?.length > 7 && dispatch(accountLookup(e.target.value, payload?.institutionCode, payload?.country))
            setPayload({ ...payload, destinationAccount: e.target.value })}
          }
          type="number"
          className="form-control"
          id="destinationAccount"
        />
        
      </div>
      {!payload?.destinationAccount && submitStatus && (
        <div className="error-desc">Destination Account Number is required</div>
      )}
      {payload?.destinationAccount && submitStatus && payload?.destinationAccount < 0 && (
        <div className="error-desc">Valid Destination Account Number is required</div>
      )}

<div>
        <label htmlFor="destinationBankName">Destination Bank</label>
        <Select
         id="destinationBankName"
         name="destinationBankName"
         inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
         value={payload?.destinationPayload}
          onChange={(e) =>{
            payload.destinationAccount && dispatch(accountLookup(payload.destinationAccount, e.value, payload?.country));
            setPayload({
              ...payload,
              institutionCode: e.value,
              destinationBankName: e.label,
              destinationPayload: e,

            })
          }
          }
          options={banks}
        />
      </div>
      {submitStatus && !payload?.destinationBankName && (
        <div className="error-desc">Destination Bank is required</div>
      )}


{accountLookupPayload?.data?.responseData && <div>
        <label htmlFor="rate">Account Name</label>
        <input
          value={accountLookupPayload?.data?.responseData}
          onChange={(e) => setPayload({ ...payload, destinationAccountName: e.target.value })}
          type="text"
          className="form-control"
          id="destinationAccountName"
        />
        
      </div>}
      {accountLookupLoading &&<div className="field-msg"><Spinner size="sm" color="primary" /> Loading account name </div>}
      {!accountLookupLoading && !accountLookupPayload?.data?.responseData && payload?.destinationPayload && <div className="error-desc">Unable to get the account name, kindly verify your account number and bank</div>}
     
      {!accountLookupPayload?.data?.responseData && submitStatus && (
        <div className="error-desc">Account Name is required</div>
      )}

<div>
        <label htmlFor="rate">Amount</label>
        <input
          value={payload.totalAmount}
          onChange={(e) => setPayload({ ...payload, totalAmount: e.target.value })}
          type="number"
          className="form-control"
          id="totalAmount"
        />
      </div>
      {!payload?.totalAmount && submitStatus && (
        <div className="error-desc">Amount is required</div>
      )}
<div>
        <label htmlFor="narration">Narration</label>
        <textarea
          value={payload.narration}
          onChange={(e) => setPayload({ ...payload, narration: e.target.value })}
          className="form-control"
          id="narration"
        />
      </div>
      {!payload?.narration && submitStatus && (
        <div className="error-desc">Narration is required</div>
      )}
      </div>}
    </form>
  );
};

export default Form;
