import React from 'react';
import { Col, Card } from 'react-bootstrap';
import "./layout.css"
export default function PageLayout(props) {

    return (
        <>
            <Col sm={12}>
                {props.topSection}
                {!props.hideCard?<Card style={{ height: "auto", maxHeight: "max-content" }}>
                    <Card.Body  >
                        {props.bodySection}
                    </Card.Body>
                </Card>:props.bodySection}

            </Col>
        </>

    );
}