import actionTypes from "./actionTypes";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { Get, Post, Put } from "apiServices/apiHandler.service";
import {
  collectBaseUrl,
  complianceBaseUrl,
  payoutBaseUrl,
} from "apiServices/config.service";

export const togglePayoutRequest = createAsyncAction(
  actionTypes.TOGGLE_PAYOUT_REQUEST,
  async (id, status) => {
    return await Put(
      `v1/Admin/${status ? "reject" : "approve"}`,
      { id },
      payoutBaseUrl
    );
  }
);
export const toggleActivePayoutMerchant = createAsyncAction(
  actionTypes.TOGGLE_ACTIVE_PAYOUT_REQUEST,
  async (id) => {
    return await Put(`v1/Admin/togglemerchant`, { id }, payoutBaseUrl);
  }
);
export const toggleCountryPayout = createAsyncAction(
  actionTypes.TOGGLE_COUNTRY_PAYOUT,
  async (id) => {
    return await Put(`v1/Admin/country/${id}`, {}, payoutBaseUrl);
  }
);
export const getSwiftPayout = createAsyncAction(
  actionTypes.GET_SWIFT_PAYOUT,
  async ( page=10, size=1, searchValue="") => {
    return await Get(`v1/admin/transactions?PageNumber=${size}&PageSize=${page}&Keyword=${searchValue}`, payoutBaseUrl);
  }
);
export const updateSwiftPayoutStatus = createAsyncAction(
  actionTypes.UPDATE_SWIFT_PAYOUT_STATUS,
  async (id, status) => {
    return await Put(`v1/admin/updatetransactions?transactionId=${id}&transactionStatus=${status}`,{}, payoutBaseUrl);
  }
);
export const getGateWay = createAsyncAction(
  actionTypes.GET_GATEWAY,
  async (size = 1000, page = 1, keyword = "") => {
    return await Get(
      `v1/Gateways/GetAll?pageSize=${size}&pageNumber=${page}&keyword=${keyword}`,
      complianceBaseUrl
    );
  }
);
export const getPayoutMerchant = createAsyncAction(
  actionTypes.GET_PAYOUT_MERCHANT,
  async (status, pageSize = 10, pageNumber = 1, keyword = "") => {
    return await Get(
      `v1/Admin/getmerchants?Status=${status}&pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
      payoutBaseUrl
    );
  }
);
export const getPayoutCountry = createAsyncAction(
  actionTypes.GET_PAYOUT_COUNTRY,
  async (pageSize = 10, pageNumber = 1, keyword = "") => {
    return await Get(
      `v1/Admin/country?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&IsAscending=false`,
      payoutBaseUrl
    );
  }
);
export const getIdentityType = createAsyncAction(
  actionTypes.GET_IDENTITY_TYPES,
  async () => {
    return await Get(`v1/Admin/IdentityTypes`, payoutBaseUrl);
  }
);
export const postPayoutCountry = createAsyncAction(
  actionTypes.POST_PAYOUT_COUNTRY,
  async (data) => {
    if (data.id) return await Put(`v1/Admin/country`, data, payoutBaseUrl);
    return await Post(`v1/Admin/country`, data, payoutBaseUrl);
  }
);

export const fetchPayoutMerchantConfigs = createAsyncAction(
  actionTypes.FETCH_PAYOUT_MERCHANT_CONFIGS,
  async ({ merchantName, countryCode }) => {
    return await Get(
      `v1/admin/merchantcountryconfigs?PageNumber=1&PageSize=300&MerchantName=${merchantName}&CountryCode=${countryCode}&IsActive=true`,
      payoutBaseUrl
    );
  }
);

export const fetchPayoutMerchantConfig = createAsyncAction(
  actionTypes.FETCH_PAYOUT_MERCHANT_CONFIGS,
  async ({ merchantId, countryCode }) => {
    return await Get(
      `v1/admin/merchantcountryconfigs/country?merchantId=${merchantId}&countryCode=${countryCode}`,
      payoutBaseUrl
    );
  }
);

export const fetchPayoutCountries = createAsyncAction(
  actionTypes.FETCH_PAYOUT_COUNTRIES,
  async () => {
    return await Get(
      `v1/admin/country/getlist?PageNumber=1&PageSize=300`,
      payoutBaseUrl
    );
  }
);

export const fetchWalletCurrencies = createAsyncAction(
  actionTypes.FETCH_WALLET_CURRENCIES,
  async () => {
    return await Get(
      `Currency/wallet?pageSize=1000&pageNumber=1&isAscending=false`
    );
  }
);

export const mutatePayoutMerchant = createAsyncAction(
  actionTypes.MUTATE_PAYOUT_MERCHANT,
  async ({ payload, id }) => {
    const endpoint = "v1/admin/merchantcountryconfig";
    if (!id) {
      return await Post(endpoint, payload, payoutBaseUrl);
    } else {
      return await Put(`${endpoint}?Id=${id}`, payload, payoutBaseUrl);
    }
  }
);

export const createPayoutGateway = createAsyncAction(
  actionTypes.CREATE_PAYOUT_GATEWAY,
  async (payload) => {
    return await Post(`v1/Gateways`, payload, complianceBaseUrl);
  }
);

export const togglePayoutGateway = createAsyncAction(
  actionTypes.TOGGLE_PAYOUT_GATEWAY,
  async (id) => {
    return await Put(
      `v1/Gateways/ToggleIsEnabled/${id}`,
      {},
      complianceBaseUrl
    );
  }
);

export const transactionrequery = createAsyncAction(
  actionTypes.POST_TRANSACTION_REQUERY,
  async (data) => {
    return await Post(`v1/admin/transactionrequery`, data, payoutBaseUrl);
  }
);
export const transactiondetails = createAsyncAction(
  actionTypes.GET_TRANSACTION_DETAILS,
  async (reference, collect) => {
    if (collect) {
      return await Post(
        `v1/admin/transaction/requery`,
        reference,
        collectBaseUrl
      );
    } else {
      return await Get(
        `v1/admin/transactiondetails?transactionReference=${reference}`,
        payoutBaseUrl
      );
    }
  }
);
