import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { logger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { appSettings } from "./reducers/appSettings";
import navigation from "./reducers/navigation";
import authReducer from "./reducers/authReducer";
import usersReducer from "./reducers/usersReducer";
import virtualAccountReducer from "./reducers/virtualAccount";
import notificationReducer from "./reducers/notification";
import kycReducer from "./reducers/kycReducer";
import payoutReducer from "./reducers/payoutReducer";
import complianceReducer from "./reducers/complianceReducer";
import transfersReducer from "./reducers/transfersReducer";
import fxReducer from "./reducers/fxReducer";
import featureFlagReducer from "./reducers/featureFlags";
import userManagementReducer from "./reducers/userManagement";
import merchantsReducer from "./reducers/merchants";
import cardsReducer from "./reducers/cardsReducer";
import collectReducer from "./reducers/collectReducer";
import smartCheckReducer from "./reducers/smartCheck";
import localForage from "localforage";

export const rootReducer = combineReducers({
  appSettings,
  navigation,
  usersReducer,
  authReducer,
  virtualAccountReducer,
  notificationReducer,
  kycReducer,
  payoutReducer,
  fxReducer,
  transfersReducer,
  cardsReducer,
  collectReducer,
  complianceReducer,
  featureFlagReducer,
  userManagementReducer,
  merchantsReducer,
  smartCheckReducer,
});

const persistConfig = {
  key: "root",
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const middleWare = [thunk];

if (process.env.NODE_ENV === "development") {
  middleWare.push(logger);
}

const store = createStore(
  persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleWare, promise),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
const persistor = persistStore(store);

export default () => {
  return { store, persistor };
};
