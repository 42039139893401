import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Row, Col } from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../FxServices.scss';
import { getCountry, getCurrency, getMerchant, toggleMerchant } from 'logic/actions/requests';
import ToggleModal from 'components/Modal/ToggleModal';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from '../../../components/paginations/Paginations';
import Aux from 'components/hoc/_Aux';
import { ArrowLeftIcon } from 'icons/arrow-left';

const FxDetails = () => {
    const history = useHistory()
    const [id] = useState(null);
    const [status] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [toggleForm, setToggleForm] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const { getMerchantLoading, getMerchantPayload, toggleMerchantLoading, toggleMerchantPayload, postMerchantLoading, getCountryPayload, getCurrencyPayload } = useSelector(({virtualAccountReducer}) => {
        return {
           getMerchantError: virtualAccountReducer?.getMerchantError,
            getMerchantPayload: virtualAccountReducer?.getMerchantPayload,
            getMerchantLoading: virtualAccountReducer?.getMerchantLoading,
            toggleMerchantError: virtualAccountReducer?.toggleMerchantError,
            toggleMerchantPayload: virtualAccountReducer?.toggleMerchantPayload,
            toggleMerchantLoading: virtualAccountReducer?.toggleMerchantLoading,
            getCountryError: virtualAccountReducer?.getCountryError,
            getCountryPayload: virtualAccountReducer?.getCountryPayload,
            getCountryLoading: virtualAccountReducer?.getCountryLoading,
            getCurrencyError: virtualAccountReducer?.getCurrencyError,
            getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
            getCurrencyLoading: virtualAccountReducer?.getCurrencyLoading,
         };
      });
    
      const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getMerchant(page))
      }

  useEffect(()=>{
      dispatch(getMerchant());
      dispatch(getCountry());
      dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!getMerchantLoading && getMerchantPayload){
      setData(getMerchantPayload?.data?.responseData?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMerchantLoading]);

  useEffect(()=>{
    if(!toggleMerchantLoading && toggleMerchantPayload){
      let i = 0;
      for(let dt of data) {
        if(dt?.id === id) {
           data[i]['isActive'] = !dt?.isActive; 
            setData([...data]);
            setToggleForm(false)
            return
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMerchantLoading])
  

const handleSearchChange = (event, input) => {
};

const clearSearch = () => {
};


const showFilterMenu = () => {
}; 
  
 
  const handleAutoCompleteCreateChange = (event, values, input) => {
  };

 
  
  
  const format = () => {
    return data?.map((item, index) => {
      return {
        from: item?.merchantName,
        to: item?.merchantName,
        rate: item?.merchantName,
        date: item?.isActive?'Active':'Inactive',
      };
    });
  }
 
    return (
      <Aux>
        <Row className="virtual-account-container">
          <Col>
            <div className="content-header">
              <div className="go-back" onClick={() => history.goBack()}>
                <ArrowLeftIcon /> &nbsp; Go Back
              </div>
              <div className="service-name">FX Details</div>
            </div>
            <div className="tab-items">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  overflowY: "hidden",
                }}
                className="customScroll">
                <FilterMenu
                  caption="Clients List"
                  totalCount={10}
                  searchLabel="Search.."
                  filterOptions={clientsFilter}
                  filterdefaultValueOptions={{
                    label: "All",
                    value: "all",
                  }}
                  handleAutoCompleteCreateChange={
                    handleAutoCompleteCreateChange
                  }
                  showFilter={false}
                  showSearch={true}
                  showCreateBtn={false}
                  btnName=""
                  openCreate={() => setCreateForm(true)}
                  handleSearchChange={handleSearchChange}
                  showFilterMenu={showFilterMenu}
                  filterLabel="Filter by"
                  searchName="keyword"
                  searchValue={""}
                  clearSearch={clearSearch}
                  entriesName="Entries"
                  filterValue={"Search"}
                  entriesOptions={{}}
                  entriesdefaultValueOptions={{
                    label: `Entries`,
                    value: 20,
                  }}
                  entriesValue={{}}
                  entriesLabel="Entries"
                />
              </div>

              <ListTable
                header={[
                  { name: "From" },
                  { name: "To" },
                  { name: "Rate" },
                  { name: "Date" },
                ]}
                isSearching={getMerchantLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={data}
                content={
                  <TableBody>
                    {format()?.map((row, index) => (
                      <TableRow key={index} style={{ cursor: "pointer" }}>
                        <TableCell component="th" scope="row">
                          {row.from}
                        </TableCell>
                        <TableCell align="left">{row.to}</TableCell>
                        <TableCell align="left">{row.rate}</TableCell>
                        <TableCell align="left">{row.date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                }
              />
              <br />
              {data?.length > 0 ? (
                <div className="paginate-content">
                  <div>
                    Showing Page{" "}
                    {getMerchantPayload?.data?.responseData?.pageNumber} of{" "}
                    {getMerchantPayload?.data?.responseData?.pages}
                  </div>
                  <div>
                    <Pagination
                      totalPages={getMerchantPayload?.data?.responseData?.pages}
                      currentPage={currentPage}
                      changeCurrentPage={(p) => getPaginationNumber(p)}
                      previousBtn="Prev"
                      nextBtn="Next"
                    />
                  </div>
                </div>
              ) : null}
            </div>

            {createForm && (
              <CreateModal
                title="Add Bank"
                createForm={createForm}
                hideCreateForm={() => setCreateForm(false)}
                onCreate={() => {}}
                creating={postMerchantLoading}
                disableCreate={postMerchantLoading}
                cancelText="Cancel"
                createText="Create Bank"
                content={
                  <form className="create-form">
                    <div>
                      <label id="bank-name">Bank Name</label>
                      <input
                        onChange={() => {}}
                        className="form-control"
                        for="bank-name"
                      />
                    </div>
                    <div>
                      <label id="country">Country</label>
                      <select
                        onChange={() => {}}
                        className="form-control"
                        for="country">
                        <option></option>
                        {getCountryPayload?.data?.responseData?.items?.map(
                          (val) => (
                            <option key={val?.id}>{val?.name}</option>
                          )
                        )}
                      </select>
                    </div>
                    <div>
                      <label id="currency">Currency</label>
                      <select
                        onChange={() => {}}
                        className="form-control"
                        for="currency">
                        <option></option>
                        {getCurrencyPayload?.data?.responseData?.items?.map(
                          (val) => (
                            <option key={val?.id}>{val?.name}</option>
                          )
                        )}
                      </select>
                    </div>
                  </form>
                }
              />
            )}

            {toggleForm && (
              <ToggleModal
                title={status ? "Disable item?" : "Enable Item?"}
                toggleForm={toggleForm}
                onToggle={() => dispatch(toggleMerchant(id))}
                toggling={toggleMerchantLoading}
                disableToggle={toggleMerchantLoading}
                hideToggleModal={() => setToggleForm(false)}
                toggleText={status ? "Disable" : "Enable"}
                textContent={
                  status
                    ? "Are you sure you want to disable this item?"
                    : "Are you sure you want to enable this item?"
                }
              />
            )}
          </Col>
        </Row>
      </Aux>
    );
}

export default FxDetails;