import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { MoreHoriz } from "@material-ui/icons"
import React from 'react'

const TableActionMenu = ({ data, menuItems, onItemClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton id={data.id} aria-controls={Boolean(anchorEl) ? `country-action-${data.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? 'true' : undefined} onClick={handleMenuClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id={`country-action-${data.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': `country-action-${data.id}`,
        }}
      >
        {
          menuItems?.map(item => <MenuItem key={item.key} onClick={() => {
            onItemClick(data, item.key)
            setAnchorEl(null)
          }}>{item.name}</MenuItem>)
        }
      </Menu>
    </div>
  )
}

export default TableActionMenu