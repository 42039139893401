import { appHelpers } from "appHelpers";

export const Service = (baseUrl, Axios, token, history) => {
  const instance = Axios.create({
    baseURL: baseUrl,
    headers: { Authorization: token },
    // you can add other headers here which has been passed from AuthorizedLayout as arguments
  });

  instance.interceptors.response.use(response=>response,
    error=>{
      let status = error.response.status
      // debugger
      const url = error.response.config.url
      const module = url  && url.split("/")[0]

      if(status === 403){
        appHelpers.alertError(`Requested Resource '${module && module}' is Forbidden `)
       }
      else if (status === 401){
        appHelpers.alertError("Session expired. Login again")
        localStorage.clear()
        history.push("/login")
      }
    }
    );

  const getApiUrl = (controller, action) => {
    return `${controller}/${action}/`;
  };

  const getRestUrl = (controller, action) => {
    return `${controller}/${action}`;
  };

  const getApiv1Url = (controller) => {
    return `${controller}`;
  };

  const getAllItems = (controller, action, data) => {
    return instance.post(getApiUrl(controller, action), data);
  };

  const getListItems = (controller, action, pageSize = 10, pageNumber = 1, searchData) => {

    let url = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;


    if (searchData && searchData.keyword !== null && searchData.keyword !== "" && searchData.keyword !== undefined) {
      url += `&keyword=${searchData.keyword}`
    }
    if (searchData && searchData.status !== null && searchData.status !== "" && searchData.status !== undefined) {
      url += `&status=${searchData.status}`
    }
    if (searchData && searchData.clientId !== null && searchData.clientId !== "" && searchData.clientId !== undefined) {
      url += `&clientId=${searchData.clientId}`
    }

    if (searchData && searchData.gatewayId !== null && searchData.gatewayId !== "" && searchData.gatewayId !== undefined) {
      url += `&gatewayId=${searchData.gatewayId}`
    }
    if (searchData && searchData.microserviceId !== null && searchData.microserviceId !== "" && searchData.microserviceId !== undefined) {
      url += `&microserviceId=${searchData.microserviceId}`
    }
    if (searchData && searchData.useAML !== null && searchData.useAML !== "" && searchData.useAML !== undefined) {
      url += `&UseAML=${searchData.useAML}`
    }
    if (searchData && searchData.isAdmin !== null && searchData.isAdmin !== "" && searchData.isAdmin !== undefined) {
      url += `&isAdmin=${searchData.isAdmin}`
    }
    if (searchData && searchData.gatewayAccountId !== null && searchData.gatewayAccountId !== "" && searchData.gatewayAccountId !== undefined) {
      url += `&gatewayAccountId=${searchData.gatewayAccountId}`
    }
    if (searchData && searchData.currencyPairId !== null && searchData.currencyPairId !== "" && searchData.currencyPairId !== undefined) {
      url += `&currencyPairId=${searchData.currencyPairId}`
    }
    if (searchData && searchData.serviceId !== null && searchData.serviceId !== "" && searchData.serviceId !== undefined) {
      url += `&serviceId=${searchData.serviceId}`
    }
    if (searchData && searchData.responseCodeId !== null && searchData.responseCodeId !== "" && searchData.responseCodeId !== undefined) {
      url += `&responseCodeId=${searchData.responseCodeId}`
    }
    if (searchData && searchData.serviceGroupId !== null && searchData.serviceGroupId !== "" && searchData.serviceGroupId !== undefined) {
      url += `&serviceGroupId=${searchData.serviceGroupId}`
    }
    if (searchData && searchData.CanUseDestinationBank !== null && searchData.CanUseDestinationBank !== "" && searchData.CanUseDestinationBank !== undefined) {
      url += `&CanUseBankDestination=${searchData.CanUseDestinationBank}`
    }
    if (searchData && searchData.roleId !== null && searchData.roleId !== "" && searchData.roleId !== undefined) {
      url += `&roleId=${searchData.roleId}`
    }
    if (searchData && searchData.countryId !== null && searchData.countryId !== "" && searchData.countryId !== undefined) {
      url += `&countryId=${searchData.countryId}`
    }
    if (searchData && searchData.bankId !== null && searchData.bankId !== "" && searchData.bankId !== undefined) {
      url += `&bankId=${searchData.bankId}`
    }
    return instance.get(
      `${getRestUrl(controller, action)}${url}`
    );
  };
  const getListItemsById = (controller, action, pageSize = 10, pageNumber = 1, searchData) => {
    let url;
    if(searchData.Id){
    url = `${searchData.Id && `/${searchData.Id}`}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    }
    if(searchData.clientId){
      url = `${searchData.clientId && `/${searchData.clientId}`}?pageSize=${pageSize}&pageNumber=${pageNumber}`;
 
    }
    if (searchData && searchData.keyword !== null && searchData.keyword !== "" && searchData.keyword !== undefined) {
      url += `&keyword=${searchData.keyword}`
    }
    if (searchData && searchData.status !== null && searchData.status !== "" && searchData.status !== undefined) {
      url += `&status=${searchData.status}`
    }
    if (searchData && searchData.gatewayAccountId !== null && searchData.gatewayAccountId !== "" && searchData.gatewayAccountId !== undefined) {
      url += `&gatewayAccountId=${searchData.gatewayAccountId}`
    }
    if (searchData && searchData.currencyPairId !== null && searchData.currencyPairId !== "" && searchData.currencyPairId !== undefined) {
      url += `&currencyPairId=${searchData.currencyPairId}`
    }
    if (searchData && searchData.gatewayId !== null && searchData.gatewayId !== "" && searchData.gatewayId !== undefined) {
      url += `&gatewayId=${searchData.gatewayId}`
    }
    if (searchData && searchData.configurationId !== null && searchData.configurationId !== "" && searchData.configurationId !== undefined) {
      url += `&configurationId=${searchData.configurationId}`
    }
    if (searchData && searchData.useDestinationBank !== null && searchData.useDestinationBank !== "" && searchData.useDestinationBank !== undefined) {
      url += `&useBankDestination=${searchData.useDestinationBank}`
    }
    if (searchData && searchData.serviceId !== null && searchData.serviceId !== "" && searchData.serviceId !== undefined) {
      url += `&serviceId=${searchData.serviceId}`
    }
    if (searchData && searchData.startDate !== null && searchData.startDate !== "" && searchData.startDate !== undefined) {
      url += `&startDate=${searchData.startDate}`
    }
    if (searchData && searchData.roleId !== null && searchData.roleId !== "" && searchData.roleId !== undefined) {
      url += `&roleId=${searchData.roleId}`
    }
    if (searchData && searchData.endDate !== null && searchData.endDate !== "" && searchData.endDate !== undefined) {
      url += `&endDate=${searchData.endDate}`
    }
    if ( searchData.isAscending !== null && searchData.isAscending !== "" && searchData.isAscending !== undefined) {
      url += `&isAscending=${searchData.isAscending}`
    }
    return instance.get(`${getRestUrl(controller, action)}${url}`);
  };


  const uploadImage = (controller, action, id,data) => {
    return instance.put(`${getRestUrl(controller, action)}/${id}`, data);
  }
  const getTransactionLogByRef = (controller, action, pageSize = 10, pageNumber = 1, searchData) => {
    let url = `TransactionReference=${searchData.TransactionReference}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

    if (searchData && searchData.keyword !== null && searchData.keyword !== "" && searchData.keyword !== undefined) {
      url += `&keyword=${searchData.keyword}`
    }
    if (searchData && searchData.ClientId !== null && searchData.ClientId !== "" && searchData.ClientId !== undefined) {
      url += `&ClientId=${searchData.ClientId}`
    }

    if (searchData && searchData.roleId !== null && searchData.roleId !== "" && searchData.roleId !== undefined) {
      url += `&roleId=${searchData.roleId}`
    }
    if (searchData && searchData.endDate !== null && searchData.endDate !== "" && searchData.endDate !== undefined) {
      url += `&endDate=${searchData.endDate}`
    }
    return instance.get(`${getRestUrl(controller, action)}?${url}`);
  };
  const getReduxItems = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };
  const getReduxItemsFilter = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    searchData
  ) => {
    let url = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    if (searchData && searchData.isAdmin !== null && searchData.isAdmin !== "" && searchData.isAdmin !== undefined) {
      url += `&isAdmin=${searchData.isAdmin}`
    }
    if (searchData && searchData.countryId !== null && searchData.countryId !== "" && searchData.countryId !== undefined) {
      url += `&countryId=${searchData.countryId}`
    }
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}${url}`
    );
  };

  const getReduxItemsById = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    id
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };

  const getAllCredentialKeys = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    id
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };

  const getReduxItemsByTwoIds = (
    controller,
    action,
    pageSize = 1000,
    pageNumber = 1,
    idOne,
    idTwo
  ) => {
    return instance.get(
      `${getRestUrl(
        controller,
        action
      )}/${idOne}/${idTwo}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  };
  const getItems = (controller, action, searchData) => {
    let url = `?`;

    if (searchData && searchData.VGNTransactionReference !== null && searchData.VGNTransactionReference !== "" && searchData.VGNTransactionReference !== undefined) {
      url += `&VGNTransactionReference=${searchData.VGNTransactionReference}`
    }
    if (searchData && searchData.clientTransactionId !== null && searchData.clientTransactionId !== "" && searchData.clientTransactionId !== undefined) {
      url += `&clientTransactionId=${searchData.clientTransactionId}`
    }
    return instance.get(`${getRestUrl(controller,action)}${url}`);
  };

  const getInfo = (controller, action) => {
    return instance.get(getApiUrl(controller, action));
  };

  const createItem = (data, controller, action) => {
    return instance.post(getApiUrl(controller, action), data);
  };

  const toggleEnabled = (controller, action, id) => {
    return instance.put(`${getRestUrl(controller, action)}/${id}`)
  }
  const toggleClientEnabled = (controller, action,clientid, id) => {
    return instance.put(`${getRestUrl(controller, action)}/${clientid}/${id}`)
  }

  const toggleIsActive = (controller, action, id) => {
    return instance.post(`${getRestUrl(controller, action)}/${id}`)
  }


  const getDataUsingId = (controller, action, id) => {
    return instance.get(`${getRestUrl(controller, action)}/${id}`);
  };
  const getDataUsingV1Id = (controller, id) => {
    return instance.get(`${getApiv1Url(controller)}/${id}`);
  };

  const getDataById = (id, controller, action) => {
    return instance.post(`${getApiUrl(controller, action)}${id}`);
  };
  // const editItem = (controller,data,id) => {
  // 	return instance.put(`${getApiv1Url(controller)}/${id}`,data);
  // }
  const editItem = (controller, data) => {
    return instance.put(`${getApiv1Url(controller)}`, data);
  };

  const deleteItem = (controller, id) => {
    return instance.delete(`${getApiv1Url(controller)}/${id}`);
  };

  const userLogin = (data, controller, action) => {
    return instance.post(getRestUrl(controller, action), data);
  };


  const getUserById = (controller, id) => {
    return instance.get(`${getApiv1Url(controller)}/${id}`);
  };

  //https://service-manager-mock.herokuapp.com/api/userlist?FirstName=Michael
  const getUserByFirstname = (controller, action, name) => {
    return instance.get(`${getRestUrl(controller, action)}?FirstName=${name}`);
  };

  const createItemV1 = (data, controller) => {
    return instance.post(getApiv1Url(controller), data);
  };

  // all other api service functions can be created here which is then accessible to other routes and components in the Authorized Layout

  return {
    getDataUsingV1Id,
    getUserByFirstname,
    getInfo,
    getReduxItemsByTwoIds,
    getTransactionLogByRef,
    getDataUsingId,
    getDataById,
    toggleIsActive,
    deleteItem,
    getAllCredentialKeys,
    getReduxItemsFilter,
    getReduxItemsById,
    getListItemsById,
    toggleClientEnabled,
    getListItems,
    getAllItems,
    getReduxItems,
    uploadImage,
    createItem,
    userLogin,
    editItem,
    getItems,
    getUserById,
    createItemV1,
    toggleEnabled,
  };
};
