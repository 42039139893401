import React from 'react';
import "./merchants.scss";

const PRICING_TYPE = [
  {
    label: "Percentage",
    value: 'percentage'
  },
  {
    label: "Flat",
    value: 'flat'
  }
]

const ManageMerchantForm = ({payload, onChange, submitStatus, currencies, step, loadingConfigs }) => {

if(step === 1) return (
  <form className="create-form form-height">
      <div className="mb-3">
      <label htmlFor="currency">Currency</label>
        <select
          className="form-control"
          value={payload.currency}
          onChange={(e) => onChange(e)}
          placeholder="Select Currency"
          label="Currency"
          name='currency'
          id='currency'
        >
          <option value=""></option>
          {currencies?.map(currency => <option key={currency?.currencyCode} value={currency?.currencyCode}>{currency?.name}</option>)}
        </select>
      </div>
    </form>
  )
    if(step === 2) return( loadingConfigs ? <div>Loading...</div> : 
        <form className="create-form">

          <div className="mb-3">
            <label htmlFor="pricingType">Type</label>
              <select
                className="form-control"
                value={payload.pricingType}
                onChange={(e) => onChange(e)}
                placeholder="Select Type"
                label="Type"
                name='pricingType'
                id='pricingType'
              >
                <option value=""></option>
                {PRICING_TYPE?.map(type => <option key={type?.value} value={type?.value}>{type?.label}</option>)}
              </select>
            </div>
    
          {payload.pricingType === 'percentage' && <>
          <div className="mb-3">
            <label htmlFor="pricingPercentage">Pricing Percentage</label>
            <input type="number" name="pricingPercentage" value={payload.pricingPercentage} onChange={onChange} className="form-control" id="pricingPercentage" placeholder="Pricing Percentage"/>
            {submitStatus && !payload?.pricingPercentage && <div className="error-desc">Pricing Percentage is required</div>}
          </div>
          
          <div className="mb-3">
             <label htmlFor="pricingCap">Pricing Cap</label>
              <input type="number" name="pricingCap" value={payload.pricingCap} onChange={onChange} className="form-control" id="pricingCap" placeholder="Pricing Cap"/>
              {submitStatus && !payload?.pricingCap && <div className="error-desc">Pricing Cap is required</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="revenuePercentage">Revenue Percentage</label>
            <input type="number" name="revenuePercentage" value={payload.revenuePercentage} onChange={onChange} className="form-control" id="revenuePercentage" placeholder="Revenue Percentage"/>
            {submitStatus && !payload?.revenuePercentage && <div className="error-desc">Revenue Percentage is required</div>}
          </div>
          </> 
          }
          {payload.pricingType === 'flat' && <>
          <div className="mb-3">
             <label htmlFor="pricingFlat">Pricing Flat</label>
              <input type="number" name="pricingFlat" value={payload.pricingFlat} onChange={onChange} className="form-control" id="Pricing Flat" placeholder="pricingFlat"/>
              {submitStatus && !payload?.pricingFlat && <div className="error-desc">Pricing Flat is required</div>}
          </div>
          
          <div className="mb-3">
            <label htmlFor="revenueFlat">Revenue Flat</label>
            <input type="number" name="revenueFlat" value={payload.revenueFlat} onChange={onChange} className="form-control" id="revenueFlat" placeholder="Revenue Flat"/>
            {submitStatus && !payload?.revenueFlat && <div className="error-desc">Revenue Flat is required</div>}
          </div>
          </> 
          }
             <div className="mb-3">
            <label htmlFor="pricingType">Charge Configuration</label>
              <select
                className="form-control"
                value={payload.chargeConfigurationType}
                onChange={(e) => onChange(e)}
                placeholder="Select Charge Configuration Type"
                label="Type"
                name='chargeConfigurationType'
                id='ChargeConfiguration'
              >
                <option value=""></option>
                {["Payer", "Merchant"]?.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
              {submitStatus && !payload?.chargeConfigurationType && <div className="error-desc">Charge Configuration Type is required</div>}
            </div>
    
      </form>
    )

    return null
} 

export default ManageMerchantForm;