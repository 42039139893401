import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./recentTransaction.scss";
import { useQueryParams } from "utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getRecentTransactions } from "logic/actions/requests";
import { errorMessage } from "logic/actions/notification";


const RecentTransaction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  const country = query.get("country");
  const providerBankId = query.get("providerBank");
  const accountNumber = query.get("accountNumber");

  const { recentTransactionsPayload, recentTransactionsError, recentTransactionsFetching } = useSelector(({ virtualAccountReducer }) => {
    return {
      recentTransactionsPayload: virtualAccountReducer?.getRecentTransactionPayload,
      recentTransactionsError: virtualAccountReducer?.getRecentTransactionError,
      recentTransactionsFetching: virtualAccountReducer?.getRecentTransactionLoading,
    };
  });

  useEffect(() => {
    dispatch(getRecentTransactions({ country, accountNumber, providerBankId }));
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (recentTransactionsError) {
      dispatch(errorMessage(recentTransactionsError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentTransactionsError])

  return (
    <div>
      <Row className="compliance-container">
        <Col className="profile-detail-content">
          <div className="content-header mb-3">
            <div className="go-back flex" onClick={() => history.goBack()}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <span className="ml-2">
                Go Back
              </span>
            </div>
            <div className="service-name">Result History</div>
          </div>
          <div className="tab-items">
            <div className="d-flex justify-content-between hide-overflow customScroll p-3">
              {recentTransactionsFetching ? (
                <div>Loading...</div>
              ) : (
                recentTransactionsPayload?.data?.responseData.length > 0 ? (
                  recentTransactionsPayload?.data?.responseData?.items.map((item, index) => {
                    return (
                      <div key={index}>
                        json: {JSON.stringify(item)}
                      </div>
                    );
                  })
                ) : (
                  <div>No record of {accountNumber} in logs</div>
                )
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RecentTransaction;