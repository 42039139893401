import React, {useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import Select from 'react-select'
import '../Collect.scss';
import { errorMessage, successMessage } from 'logic/actions/notification';

const Form = ({payload, setPayload, submitStatus, setSubmitStatus, setCreateForm, editStatus}) => {
    const dispatch = useDispatch();

    const { manageMerchantPayload, manageMerchantError, manageMerchantLoading,getSupportedCurrencyPayload, getCountryPayload } = useSelector(({collectReducer}) => {
        return {
            manageMerchantError: collectReducer?.manageMerchantError,
            manageMerchantPayload: collectReducer?.manageMerchantPayload,
            manageMerchantLoading: collectReducer?.manageMerchantLoading,
            getCountryError: collectReducer?.getSupportedCountryError,
            getCountryPayload: collectReducer?.getSupportedCountryPayload,
            getCountryLoading: collectReducer?.getSupportedCountryLoading,
            getSupportedCurrencyError: collectReducer?.getSupportedCurrencyError,
            getSupportedCurrencyPayload: collectReducer?.getSupportedCurrencyPayload,
            getSupportedCurrencyLoading: collectReducer?.getSupportedCurrencyLoading,
         };
      });
    
  useEffect(()=>{
        if(!manageMerchantLoading && manageMerchantPayload?.data?.requestSuccessful && submitStatus){
          setCreateForm(false);
          setSubmitStatus(false);
          dispatch(successMessage(editStatus?`Country update was successful`:`Country configuration was successful`))
      }else if(!manageMerchantLoading && manageMerchantPayload?.data?.requestSuccessful === false && submitStatus){
        setSubmitStatus(false);
        dispatch(errorMessage(manageMerchantPayload?.data?.message || "Error occurred while performing this action"))
    }else if(!manageMerchantLoading && manageMerchantError?.data?.message && submitStatus){
      setSubmitStatus(false);
      dispatch(errorMessage(manageMerchantError?.data?.message || "Error occurred while performing this action"))
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageMerchantLoading]);
 
    return (
            <form className="create-form manage-collect-merchant">
              <div>
                <label htmlFor="country">Country</label>
                <Select
                      value={payload?.countryPayload}
                      onChange={e => setPayload({...payload, countryCode: e.value?.toString(), countryPayload: e})}
                      options={getCountryPayload?.data?.map(val =>({ value: val?.countryName, label: val?.countryCode }))}
                  /> 
              </div>
              {submitStatus && !payload?.countryCode && <div className="error-desc">Country is required</div>}
              <div>
                <label htmlFor="country">Currency</label>
                <Select
                      value={payload?.currencyPayload}
                      onChange={e => setPayload({...payload, currency: e.value, currencyCode: e.value, currencyPayload: e})}
                      options={getSupportedCurrencyPayload?.data?.map(val =>({ value: val?.currencyCode, label: val?.currencyName }))}
                  /> 
               {submitStatus && !payload?.currencyPayload && <div className="error-desc">Currency is required</div>}
              </div>
             
              <div>
                <label htmlFor="pricingValue">Minimum Settlement Amount</label>
                <input type="number" value={payload?.minimumSettlementAmount} onChange={(e)=>setPayload({...payload, minimumSettlementAmount: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.minimumSettlementAmount && <div className="error-desc">Minimum Settlement Amount is required</div>}
              
               <div>
                <label htmlFor="pricingValue">Pricing Cap</label>
                <input type="number" value={payload?.pricingCap} onChange={(e)=>setPayload({...payload, pricingCap: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.pricingCap && <div className="error-desc">Pricing Cap is required</div>}
             

              <div>
                <label htmlFor="pricingValue">Pricing Flat</label>
                <input type="number" value={payload?.pricingFlat} onChange={(e)=>setPayload({...payload, pricingFlat: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {/* {submitStatus && !payload?.pricingFlat && <div className="error-desc">Pricing Flat is required</div>} */}
              

               <div>
                <label htmlFor="pricingPercentage">Price Percentage</label>
                <input type="number" value={payload?.pricingPercentage} onChange={(e)=>setPayload({...payload, pricingPercentage: Number(e.target.value)})}  className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.pricingPercentage && <div className="error-desc">Price Percentage is required</div>}
              
              <div>
                <label htmlFor="revenueValue">Revenue Flat</label>
                <input type="number" value={payload?.revenueFlat} onChange={(e)=>setPayload({...payload, revenueFlat: e.target.value})} className="form-control" id="channelName"/>  
              </div>
               {/* {submitStatus && !payload?.revenueFlat && <div className="error-desc">Revenue Flat is required</div>} */}
              
              
               <div>
                <label htmlFor="revenueValue">Revenue Percentage</label>
                <input type="number" value={payload?.revenuePercentage} onChange={(e)=>setPayload({...payload, revenuePercentage: Number(e.target.value)})}  className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.revenuePercentage && <div className="error-desc">Revenue Percentage is required</div>}
              
             
            </form>
    )
}

export default Form;