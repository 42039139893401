import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from 'lodash';
import { useDispatch, useSelector} from 'react-redux';

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../Collect.scss';
import { getCountry, getCurrency } from 'logic/actions/requests';
import { toggleMerchant, getMerchant } from 'logic/actions/collectAction';
import ToggleModal from 'components/Modal/ToggleModal';
import Pagination from '../../../components/paginations/Paginations';
import ManageForm from './manage/form';
import ViewModal from 'components/Modal/ViewModal';

const Merchants = () => {
    const [id, setId] = useState(null);
    const [status, setStatus] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [toggleForm, setToggleForm] = useState(false);
    const [merchantId, setMerchantId] = useState(null);
    const [, setCreateForm] = useState(false);
    const [manageForm, setManageForm] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const [viewDetails, setViewDetails] = useState({
      open: false,
      payload: {},
      countries: [],
      step: 1,
    });
    const dispatch = useDispatch();

    const { getMerchantLoading, getMerchantPayload, toggleMerchantLoading, toggleMerchantPayload } = useSelector(({collectReducer, virtualAccountReducer}) => {
        return {
           getMerchantError: collectReducer?.getMerchantError,
            getMerchantPayload: collectReducer?.getMerchantPayload,
            getMerchantLoading: collectReducer?.getMerchantLoading,
            toggleMerchantError: collectReducer?.toggleMerchantError,
            toggleMerchantPayload: collectReducer?.toggleMerchantPayload,
            toggleMerchantLoading: collectReducer?.toggleMerchantLoading,
            getCountryError: virtualAccountReducer?.getCountryError,
            getCountryPayload: virtualAccountReducer?.getCountryPayload,
            getCountryLoading: virtualAccountReducer?.getCountryLoading,
            getCurrencyError: virtualAccountReducer?.getCurrencyError,
            getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
            getCurrencyLoading: virtualAccountReducer?.getCurrencyLoading,
         };
      });
    
      const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getMerchant(true, page, 10, searchValue))
      }
 
  useEffect(()=>{
      dispatch(getMerchant(true, 0));
      dispatch(getCountry());
      dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!getMerchantLoading && !getMerchantPayload?.data?.hasError){
      setData(getMerchantPayload?.data?.responseData?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMerchantLoading]);

  useEffect(()=>{
    if(!toggleMerchantLoading && toggleMerchantPayload){
      let i = 0;
      for(let dt of data) {
        if(dt?.id === id) {
          data.splice(i, 1);
          setData([...data]);
          setToggleForm(false);
          return
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMerchantLoading])
  
  const handleChange = (statusId, checked) => {
    setId(statusId);
    setStatus(checked);
    setToggleForm(true);
  }

  const handleViewClick = (row) => {
    setViewDetails((prev) => ({
      ...prev,
      payload: {
        ...row,
      },
      open: true,
      merchantName: row.merchantName,
    }));
  };


  const debounceText = useCallback(debounce(async(srhTxt) => {
    dispatch(getMerchant(true, 1, 10, srhTxt)) 
  }, 500), [])
  
  
  const handleSearchChange = (event, input) => {
    debounceText(event.target.value)
    setSearchValue(event.target.value)
  };
  
  const clearSearch = () => {
    debounceText("")
    setSearchValue("")
  };
  
  
const showFilterMenu = () => {
}; 
  
 
  const handleAutoCompleteCreateChange = (event, values, input) => {
  };

 
  
  
  const format = () => {
    return data?.map((item, index) => {
      return {
        name: item?.merchantName,
        Id: item?.id,
        created: item?.createdAt,
        status: item?.isActive,
        Actions:
        <div>
          <span 
              className="approve"
              onClick={() => handleViewClick(item)}
            >
              View
            </span>
          <span className="approve" onClick={()=>{setMerchantId(item?.id);setManageForm(true)}}>Manage</span>
          <span className="reject" onClick={()=>handleChange(item?.id, true)}>Suspend</span>
        </div>
      };
    });
  }
 
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={true}
            showCreateBtn={false}
            btnName=""
            openCreate={() => setCreateForm(true)}
            handleSearchChange={handleSearchChange}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            searchValue={searchValue}
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Merchant Name" },
            { name: "Status" },
            { name: "Creation date" },
            { name: "Action" },
          ]}
          isSearching={getMerchantLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={data}
          content={
            <TableBody>
              {format()?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    <div className="status-flag">
                      <span
                        className={`dot-status ${
                          row?.status ? "dot-active" : "dot-inactive"
                        }`}>
                        .
                      </span>
                      {row.status ? "Active" : "Inactive"}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {new Date(row.created).toDateString()}
                  </TableCell>
                  <TableCell align="left">{row.Actions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page {getMerchantPayload?.data?.responseData?.pageNumber}{" "}
              of {getMerchantPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={getMerchantPayload?.data?.responseData?.pages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {manageForm && (
          <ManageForm
            manageForm={manageForm}
            setManageForm={setManageForm}
            merchantId={merchantId}
          />
        )}
<ViewModal
        open={viewDetails.open}
        content={viewDetails.payload}
        hideViewForm={() =>
          setViewDetails({...viewDetails, open: false})
        }
        title="Merchant Details"
      />
        {toggleForm && (
          <ToggleModal
            title={status ? "Disable item?" : "Enable Item?"}
            toggleForm={toggleForm}
            onToggle={() => dispatch(toggleMerchant(id))}
            toggling={toggleMerchantLoading}
            disableToggle={toggleMerchantLoading}
            hideToggleModal={() => setToggleForm(false)}
            toggleText={status ? "Disable" : "Enable"}
            textContent={
              status
                ? "Are you sure you want to reject this item?"
                : "Are you sure you want to approve this item?"
            }
          />
        )}
      </>
    );
}

export default Merchants;