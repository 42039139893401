import { Button, Grid, Typography } from "@material-ui/core"
import { NavigateBefore } from "@material-ui/icons"
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
 btn: {
  textTransform: 'capitalize',
  fontWeight: 600,
  fontSize: ".875rem",
  fontFamily: 'Inter',
 },
 title: {
  fontFamily: 'Inter',
fontWeight: 700,
fontSize: "1.5rem",
lineHeight: "16px",
color: "#0D0F11",
letterSpacing: "-0.05px"
 }
}));

const PageTitle = ({linkText, onGoBack, title}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
      <Button variant="text" color="primary" startIcon={<NavigateBefore />} onClick={onGoBack} className={classes.btn}>{linkText}</Button>
      </Grid>
      <Grid item>
        <Typography variant="h4" className={classes.title}>{title}</Typography>
      </Grid>
    </Grid>
  )
}

export default PageTitle