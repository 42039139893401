import React from "react";
import { Spinner } from "reactstrap";
import "./modalLoading.scss";

export default function ModalLoading() {
  return (
    <div className="d-flex align-items-center justify-content-center modal-loading">
      <div className="d-flex flex-column align-items-center justify-content-center w-full">
        <Spinner size="sm" color="primary" />
        <p className="mt-2">Loading...</p>
      </div>
    </div>
  );
}
