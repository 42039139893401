import React from 'react'

const EditSubscriptionForm = ({ submitStatus, payload, onChange }) => {
    return (
        <form className="create-form">
            <div>
                <label id="name">Name</label>
                <input
                    onChange={onChange}
                    placeholder='Enter Subscription Name'
                    className="form-control"
                    name="name"
                    defaultValue={payload?.name}
                />
                {submitStatus && !payload?.name && (
                    <div className="error-desc">Name is required</div>
                )}
            </div>
            <div>

            </div>
            <div>
                <label id="code">Code</label>
                <input
                    onChange={onChange}
                    placeholder='Enter Subscription Code'
                    className="form-control"
                    name="code"
                    defaultValue={payload?.code}
                />
                {submitStatus && !payload?.code && (
                    <div className="error-desc">Code is required</div>
                )}
            </div>
            {/* <div>
                <label htmlFor='amount' >Amount</label>
                <input
                    onChange={onChange}
                    placeholder='Enter Amount here...'
                    className='form-control'
                    name="amount"
                    type='number'
                    defaultValue={payload?.amount}
                />
                {submitStatus && !payload?.amount && (
                    <div className="error-desc">Amount is required</div>
                )}
            </div>

            <div>
                <label htmlFor="duration">Duration</label>
                <select
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    name="duration"
                    placeholder='Select Duration'
                    id='duration'
                    label="Duration"
                >
                    <option value={""}></option>

                </select>
                {submitStatus && !payload?.duration && (
                    <div className="error-desc">Duration is required</div>
                )}
            </div> */}
            <div>
                <label htmlFor='description' >Description</label>
                <textarea
                    rows={3}
                    cols={4}
                    onChange={onChange}
                    placeholder='Enter Description here...'
                    className='form-control'
                    name="description"
                    defaultValue={payload.description}
                />
                {
                    submitStatus && !payload?.description && (
                        <div className="error-desc">Description is required</div>
                    )
                }
            </div>
        </form>

    )
}

export default EditSubscriptionForm;