import actionTypes from "./actionTypes";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { Get, Post } from "apiServices/apiHandler.service";
import { payoutBaseUrl } from "apiServices/config.service";

export const getTransfers = createAsyncAction(
  actionTypes.GET_TRANSFERS,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/admin/transfer/getall?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      payoutBaseUrl
    );
  }
);
export const approveTransfer = createAsyncAction(
  actionTypes.APPROVE_TRANSFER,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/admin/transfer/getall?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      payoutBaseUrl
    );
  }
);
export const getBanks = createAsyncAction(
  actionTypes.GET_BANKS,
  async (code) => {
    return await Get(
      `v1/client/institutions/${code}?channel=Bank`,
      payoutBaseUrl
    );
  }
);
export const accountLookup = createAsyncAction(
  actionTypes.ACCOUNT_LOOKUP,
  async (AccountNumber, institutionCode, countryCode) => {
    return await Get(
      `v1/client/resolve-bank-account?AccountNumber=${AccountNumber}&institutionCode=${institutionCode}&countryCode=${countryCode}`,
      payoutBaseUrl
    );
  }
);

export const postTransfers = createAsyncAction(actionTypes.POST_TRANSFERS, async (data) => {
  return await Post(`v1/admin/transfer`, data, payoutBaseUrl);
});

