import React, { useState } from "react";

const ManageMerchantForm = ({
  onChange,
  submitStatus,
  step,
  planIsLoading,
  planList,
  onPlanSelect,
  payload,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null); // Track the selected plan

  const handlePlanClick = (item) => {
    setSelectedPlan(item); // Update the selected plan
    onPlanSelect(item);
  };

  const isPlanSelected = (item) => {
    return selectedPlan && selectedPlan.ItemName === item.ItemName;
  };

  if (step === 1)
    return (
      <div className="create-form">
        {planIsLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border p-4 spinner-border-lg" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : planList?.data.length > 0 ? (
          planList?.data.map((item, index) => (
            <div
              key={index}
              className={`subscription-container w-100 my-2 ${
                isPlanSelected(item) ? "opacity" : ""
              }`}
              onClick={() => handlePlanClick(item)}>
              <p className="header">{item.ItemName}</p>
              <p className="desc">{`$${item.Price}`}</p>
            </div>
          ))
        ) : (
          <div className="p-4 d-flex justify-content-center gap-3 flex-column align-items-center">
            <span> No plan available</span>
            <button className="btn text-white btn-primary btn-sm">
              Create a plan
            </button>
          </div>
        )}
      </div>
    );
  if (step === 2)
    return (
      <form className="create-form m-0">
        <div>
          <label id="billing-item">Billing Item</label>
          <input
            onChange={(e) => onChange(e)}
            placeholder="Aml"
            className="form-control"
            name="billingItem"
            type="text"
            defaultValue={payload?.billingItem}
          />
          {submitStatus && !payload?.billingItem && (
            <div className="error-desc">Billing item is required</div>
          )}
        </div>
        <div></div>
        <div>
          <label id="amount">Amount</label>
          <input
            placeholder="amount"
            className="form-control"
            name="amount"
            type="number"
            onChange={(e) => onChange(e)}
            defaultValue={payload?.amount}
          />
          {submitStatus && !payload?.amount && (
            <div className="error-desc">Amount is required</div>
          )}
        </div>
      </form>
    );

  return null;
};

export default ManageMerchantForm;
