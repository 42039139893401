

export const generalErrorMessage = "Error occured while processing this request";
// constants for api calls;
export const USER = "user";
export const GETLOGINUSER = "GETLOGINUSER";
export const USER_CREATE = "User";
export const GET_USER = "User";
export const PERMISSIONS = "Permissions";
export const CREATE = "Create";
export const CREATE_1 = "create";
export const CREATE_USER = "createuser";
export const CREATE_CLIENT = "createclient";
export const ACCOUNT = "Account";
export const LOGIN = "Login";
export const AUTH = "auth";
export const API = "api";
export const USER_LIST = "userlist";
export const COUNT = "count";
export const COUNTRY = "Country";
export const GET_USER_BY_EMAIL = "GetUserByEmail";
export const GET_ALL = "GetAll";
export const GET = "Get";
export const GET_ALL_USERS_BY_CLIENT_ID = "GetAllUsersByClientId";
export const SERVICE_GROUPS = "ServiceGroups";
export const GATEWAYS = "Gateways";
export const SERVICES = "Services";
export const CLIENTS = "Clients";
export const ROLES = "Roles";
export const MICRO_SERVICES = "Microservices";
export const SETUP = "Setup";
export const CLIENT_SERVICES = "ClientServices";
export const CLIENT_GATEWAY_MICROSERVICES = "ClientGatewayMicroservices";
export const GET_ALL_BY_CLIENT_ID = "GetAllByClientId";
export const GET_ALL_TRANSACTIONS_BY_CLIENT_ID = "GetAllTransactionsByClientId";
export const TRANSACTION = "Transaction";
export const CONFIGURATION = "Configuration";
export const ACTIVATE = "activate";
export const GET_ALL_CONFIGURATIONS_BY_CLIENT_ID =
  "GetAllConfigurationsByClientId";
export const GATEWAY_ACCOUNT = "GatewayAccount";
export const GATEWAY_CREDENTIAL_KEY = "GatewayCredentialKey";
export const GET_BY_GATEWAY_ID = "GetByGatewayId";
export const GET_CLIENT_CREDENTIALS = "GetClientCredentials";
export const GENERATE_NEW_CLIENT_CREDENTIALS = "GenerateNewClientCredentials";
export const CLIENT_GATEWAY_MICROSERVICE = "ClientGatewayMicroservices";
export const CURRENCY_PAIR = "CurrencyPair";
export const RESPONSE_CODE = "ResponseCode";
export const CLIENT_USER = "ClientUser";
export const BANK_DESTINATION = "BankDestination"; //BankDestination
export const GATEWAY_RESPONSE_CODE_MAP = "GatewayResponseCodeMap";
export const CURRENCY = "Currency";
export const GATEWAY_ACCOUNT_CURRENCY_PAIR = "GatewayAccountCurrencyPair";
export const TOGGLE_IS_ENABLED = "ToggleIsEnabled";
export const VERIFY_USER = "VerifyUser";
export const FORGOT_PASSWORD = "ForgotPassword";
export const RESET_PASSWORD = "ResetPassword";
export const GET_USER_INFO = "GetUserInfo";
export const SETUP_ACCOUNT = "SetupAccount";
export const CHANGE_PASSWORD = "ChangePassword";
export const ResendConfirmationToken = "ResendConfirmationToken";
export const DEFAULT_GATEWAY_ACCOUNT = "DefaultGatewayAccount";
export const TransactionMonitor = "TransactionMonitor";
export const GetTransactionLogDetails = "GetTransactionLogDetails";
export const TERMINAL = "Terminal";
export const UploadLogo = "UploadLogo";
export const GET_ALL_BY_CLIENT_ID_NEW = "getbyclientid";
export const BANK = "Bank";
export const AUDIT_TRAIL = "AuditTrail";
export const HOLDING_ACCOUNT = "HoldingAccount";
export const KYC_SERVICE_LEVEL = "KYCServiceLevel";
export const PLATFORM_CHARGE = "PlatformCharge";
export const GET_CHARGE_TYPE = "getchargetype";
export const GATEWAY_CHARGE = "GatewayCharge";
export const STATE = "State";
export const GET_KYC_LEVELS = "getkyclevels";
export const SETTLEMENT_PROFILE = "SettlementProfile";
export const GATEWAY_CHARGE_BEARER = "GatewayChargeBearer";
export const COLLECTION = "Collection";
export const GET_ALLCOLLECTIONS_BY_CLIENT_ID = "gettransactionsbyclientId";
export const GET_COLLECTION_COLLECTIONREFERENCE =
  "GetCollectionByCollectionReference";
export const GLCONFIGURATION = "GLConfiguration";
// constants for context
export const Modules = {
  GATEWAY_ACCOUNT: "GATEWAY_ACCOUNT",
  PAYMENT_GATEWAY: "PaymentGateway",
  CONFIGURATION: "CONFIGURATION",
  RESPONSE_CODE: "RESPONSE_CODE",
  CURRENCY_PAIR: "CURRENCY_PAIR",
  MICRO_SERVICE: "Microservice",
  COUNTRY: "Country",
  CURRENCY: "Currency",
  TERMINAL: "Terminal",
  CLIENT_GATEWAY_MICROSERVICE: "CLIENT_GATEWAY_MICROSERVICE",
  PAYMENT_SERVICE_GROUP: "PaymentServiceGroup",
  PAYMENT_SERVICE: "PaymentService",
  ROLE: "Role",
  USER: "User",
  CLIENT: "CLIENT",
  BANK: "Bank",
  CLIENT_TRANSACTIONS: "CLIENT_TRANSACTIONS",
  CLIENT_SERVICES: "CLIENT_SERVICES",
  GATEWAY_CREDENTIAL_KEY: "GATEWAY_CREDENTIAL_KEY",
  CLIENT_USER: "CLIENT_USER",
  BANK_DESTINATION: "BANK_DESTINATION",
  GATEWAY_RESPONSE_CODE_MAP: "GATEWAY_RESPONSE_CODE_MAP",
  GATEWAY_ACCOUNT_CURRENCY_PAIR: "GATEWAY_ACCOUNT_CURRENCY_PAIR",
  HOLDING_ACCOUNT: "HOLDING_ACCOUNT",
  KYC_SERVICE_LEVEL: "KYC_SERVICE_LEVEL",
  PLATFORM_CHARGE: "PLATFORM_CHARGE",
  GATEWAY_CHARGE: "GATEWAY_CHARGE",
  SETTLEMENT_PROFILE: "SETTLEMENT_PROFILE",
  GLCONFIGURATION: "GLCONFIGURATION",
};

export const microServiceCodes = ["ACCAGG", "DISAGG"];
export const LABELS = {
  SYNC_TEXT_PENDING: "Syncing Page",
  SYNC_TEXT_SUCCESS: "Page Updated",
};

export const Colors = {
  main: "#261B64",
};

const DEMO = {
  BLANK_LINK: "#!",
};
// This tabs have to follow this sequential order,
export const clientTabRoutes = [
  "/home/clients/info",
  "/home/clients/users",
  "/home/clients/transactions",
  "/home/clients/terminal",
  "/home/clients/bankdestination",
  "/home/clients/service",
  "/home/clients/gatewayaccount",
  "/home/clients/configurationprofile",
  "/home/clients/gatewaycorridor",
  "/home/clients/collections",
  "/home/clients/clientcredentials",
];

export const paymentGatewayTabRoutes = [
  "/home/payment-gateway/info",
  "/home/payment-gateway/gateway-credential-key",
];

export const TAB_INDEX = {
  CLIENTS: -1,
  CLIENT_INFO: 0,
  CLIENT_USERS: 1,
  CLIENT_TRANSACTIONS: 2,
  CLIENT_TERMINAL: 3,
  CLIENT_DESTINATIONBANK: 4,
  CLIENT_CLIENTSERVICE: 5,
  CLIENT_GATEWAY_ACCOUNT: 6,
  CLIENT_CONFIGURATION: 7,
  GATEWAY_ACCOUNT_CURRENCY_PAIR: 8,
};

export const GATEWAY_TAB_INDEX = {
  GATEWAYS: -1,
  GATEWAY_INFO: 0,
  GATEWAY_CREDENTIAL_KEY: 1,
};

export const entries = [
  {
    label: "10 Entries",
    value: 10,
  },
  {
    label: "20 Entries",
    value: 20,
  },
  {
    label: "50 Entries",
    value: 50,
  },
  {
    label: "100 Entries",
    value: 100,
  },
];

export const blankForm = {
  name: "",
  code: "",
  description: "",
  isEnabled: true,
};

export const SETTLEMENT_PREFERENCE_OPTIONS = [
  {label: "Bank", value: 3},
  {label: "Collection Wallet", value: 2},
  {label: "Coporate Wallet", value: 1}
]
// export const SETTLEMENT_OPTIONS = [
//   {label: "Bank", value: "BANK"},
//   {label: "Collection Wallet", value: "COLLECTIONWALLET"},
//   {label: "Coporate Wallet", value: "CORPORATEWALLET"}
// ]

export const PAYOUT_TYPE = [{ value: 1, label: "Bank" }, { value: 2, label: "Mobile Money" }]

export const priorityEnum = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];
export const clientsFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const paymentGatewayFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
  { label: "Status", value: "status" },
];
export const paymentServiceFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];

export const paymentGroupFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const currencyPairFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const countryFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const stateFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const currencyFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const responseCodeFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const microserviceFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const userListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const roleListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const transactionListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const clientServicesListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const clientuserListFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const auditTrailFilter = [
  { label: "All", value: "all" },
  { label: "Name", value: "name" },
  { label: "Code", value: "code" },
];
export const transactionsFilter = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];
export const RoleEnum = [
  { label: "Admin", value: 0 },
  { label: "Settlement", value: 1 },
  { label: "Operations", value: 2 },
  { label: "Customer Support", value: 3 },
  { label: "Developer", value: 4 },
];
export const paymentServicesDummyEnum = [
  { label: "Local Disbursement", value: 0 },
  { label: "Disbursement Service", value: 1 },
  { label: "Collections Service", value: 2 },
];
export const microServicesDummyEnum = [
  { label: "Local Disbursement", value: 0 },
  { label: "Disbursement Microservice", value: 1 },
  { label: "Collections Microservice", value: 2 },
];
export const ClientFilterEnum = [
  { label: "Id", value: "id" },
  { label: "Name", value: "code" },
  { label: "Code", value: "name" },
];

export const serviceGroupsDummy = [
  {
    name: "Group1",
    value: "1",
  },
  {
    name: "Group2",
    value: "2",
  },
  {
    name: "Group3",
    value: "3",
  },
  {
    name: "Group4",
    value: "4",
  },
];

export const usersDummy = [
  {
    Email: "precious.uchendu@venturegardengroup.com",
    Status: 1,
    FirstName: "Precious",
    LastName: "Uchendu",
    RoleName: "Client",
  },
  {
    Email: "samuel.awnorin@venturegardengroup.com",
    Status: 0,
    FirstName: "Samuel",
    LastName: "Awonorin",
    RoleName: "Admin",
  },
  {
    Email: "florence.adikwu@venturegardengroup.com",
    Status: 2,
    FirstName: "Florence",
    LastName: "Adikwu",
    RoleName: "Customer",
  },
  {
    Email: "michael.fabiyi@venturegardengroup.com",
    Status: 0,
    FirstName: "Michael",
    LastName: "Fabiyi",
    RoleName: "Admin",
  },
  {
    Email: "teslimi.yusuf@venturegardengroup.com",
    Status: 1,
    FirstName: "Tesilimi",
    LastName: "Yusuf",
    RoleName: "Client",
  },
  {
    Email: "akinwale.agbaje@venturegardengroup.com",
    Status: 1,
    FirstName: "Akinwale",
    LastName: "Agbaje",
    RoleName: "Client",
  },
  {
    Email: "emeka.ugochukwu@venturegardengroup.com",
    Status: 1,
    FirstName: "Emeka",
    LastName: "Ugochukwu",
    RoleName: "Client",
  },
  {
    Email: "ayoola.kelani@venturegardengroup.com",
    Status: 1,
    FirstName: "Ayoola",
    LastName: "Kelani",
    RoleName: "Client",
  },
  {
    Email: "olutoye.owojaye@venturegardengroup.com",
    Status: 1,
    FirstName: "Olutoye",
    LastName: "Owojaye",
    RoleName: "Client",
  },
  {
    Email: "rachael.olujuyigbe@venturegardengroup.com",
    Status: 1,
    FirstName: "Rachael",
    LastName: "Olujuyigbe",
    RoleName: "Client",
  },
  {
    Email: "bunmi.akinyemiju@venturegardengroup.com",
    Status: 1,
    FirstName: "Bunmi",
    LastName: "Akinyemiju",
    RoleName: "Client",
  },
];

export const rolesDummy = [
  {
    roleName: "VGG Admin",
    roleDescription: "niweo",
    rolePermissions: ["CR-USER"],
  },
  {
    roleName: "Settlment Officer",
    roleDescription: "pwpmo",
    rolePermissions: ["DL-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "ncwnpp",
    rolePermissions: ["CR-USER", "UP-USER"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "cweonoine",
    rolePermissions: ["CR-USER", "UP-USER"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "npwempo",
    rolePermissions: ["UP-USER", "DL-ROLE", "UP-ADMIN"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "mpmepo",
    rolePermissions: ["UP-USER", "DL-ROLE", "UP-ADMIN"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "ncpoep",
    rolePermissions: ["UP-USER", "DL-ROLE", "UP-ADMIN"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "pmmeiompoem",
    rolePermissions: ["RD-USER", "UP-USER", "DL-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "np  nenpoe  pemo",
    rolePermissions: ["CR-USER", "UP-USER"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "pnweiomepom",
    rolePermissions: ["DL-ROLE", "UP-ROLE", "CR-ROLE", "RD-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "owneoinowei",
    rolePermissions: ["UP-ADMIN", "CR-ADMIN"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "npmpome",
    rolePermissions: ["CR-ROLE", "DL-ROLE", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
  {
    roleName: "VGG Admin",
    roleDescription: "noiome",
    rolePermissions: ["CR-USER", "DL-USER", "UP-ROLE"],
  },
];

export const transactionsListDummy = [
  {
    transactionId: "OINR",
    transactionDate: "NCOIENROI",
    customerName: "OINIONFR",
    paymentService: "CIORM",
    amount: "WOEOI",
    status: "MIOMOIR",
  },
  {
    transactionId: "OWINOI",
    transactionDate: "IWEI",
    customerName: "WOEINIO",
    paymentService: "NWEIOIN",
    amount: "QOIIE",
    status: "COIEO",
  },
  {
    transactionId: "OIODI",
    transactionDate: "QOIWIO",
    customerName: "AOIWOI",
    paymentService: "WOII3",
    amount: "IFOI",
    status: "DOIEI",
  },
];

export const clientPaymentServiceDummy = [
  {
    clientPaymentServiceName: "Local Disbursement",
    clientPaymentServiceCode: "09873655",
    clientPaymentServiceGroup: "hhh",
    clientPaymentServiceStatus: "active",
  },
  {
    clientPaymentServiceName: "International Disbursement",
    clientPaymentServiceCode: "08374656",
    clientPaymentServiceGroup: "hhh",
    clientPaymentServiceStatus: "active",
  },
  {
    clientPaymentServiceName: "Collections Service",
    clientPaymentServiceCode: "09873655",
    clientPaymentServiceGroup: "hhh",
    clientPaymentServiceStatus: "inactive",
  },
];
export const clientUsersDummy = [
  {
    clientUserEmail: "precious.uchendu@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Precious Uchendu",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "samuel.awonorin@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Samuel Awonorin",
    clientUserRole: "Admin",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "florence.adikwu@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Florence Adikwu",
    clientUserRole: "Customer",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "michael.fabiyi@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Michael Fabiyi",
    clientUserRole: "Admin",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "teslimi.yusuf@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Tesilimi Yusuf",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "akinwale.agbaje@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Akinwale Agbaje",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "emeka.ugochukwu@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Emeka Ugochkwu",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "ayoola.kelani@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Ayoola Kelani",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "olutoye.owojaye@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Olutoye Owojaye",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "rachael.olujuyigbe@venturegardengroup.com",
    clientUserStatus: "inactive",
    clientUserFullName: "Rachael Olujuyigbe",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
  {
    clientUserEmail: "bunmi.akinyemiju@venturegardengroup.com",
    clientUserStatus: "active",
    clientUserFullName: "Bunmi Akinyemiju",
    clientUserRole: "Client",
    clientUserDateCreated: "12-01-2020",
  },
];

export const clientsDummy = [
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
  {
    clientId: "cowneoi",
    clientName: "John doe",
    clientEmail: "john.doe@gmail.com",
    dateOnboarded: "ceoneoioeioj",
    clientStatus: "active",
  },
];

export const REQUEST_STATUS_ENUM = {
  APPROVED: "APPROVED",
  SUSPENDED: "SUSPENDED",
  PENDING: "PENDING"
}
export default DEMO;
