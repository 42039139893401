import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter, generalErrorMessage } from "Constants";
import "../Collect.scss";
import CreateModal from "components/Modal/CreateModal";
import Pagination from "../../../components/paginations/Paginations";
import Form from "./form";
import { errorMessage, successMessage } from "logic/actions/notification";
import {
  getChannel,
  getSupportedCountry,
  getSupportedCurrency,
  postChannel,
  putChannel,
  toggleChannel,
} from "logic/actions/collectAction";
import ToggleModal from "components/Modal/ToggleModal";

const payloadData = {
  channelName: null,
  country: null,
  pricingPercentage: null,
  pricingCap: null,
  revenuePercentage: null,
  currency: null,
  channel: null,
  queueName: null,
};

const channelType = [
  "Cards",
  "Transfer",
  "VisaQR",
  "NQR",
  "Bank",
  "USSD",
  "MobileMoney",
];
const Channel = () => {
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [toggleForm, setToggleForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [payload, setPayload] = useState(payloadData);
  const dispatch = useDispatch();

  const {
    postChannelError,
    putChannelLoading,
    putChannelPayload,
    toggleChannelLoading,
    toggleChannelPayload,
    getChannelLoading,
    getChannelPayload,
    getChannelError,
    postChannelLoading,
    postChannelPayload,
    getSupportedCurrencyPayload,
    getSupportedCountryPayload,
  } = useSelector(({ collectReducer, virtualAccountReducer }) => {
    return {
      getChannelError: collectReducer?.getChannelError,
      getChannelPayload: collectReducer?.getChannelPayload,
      getChannelLoading: collectReducer?.getChannelLoading,
      postChannelError: collectReducer?.postChannelError,
      postChannelPayload: collectReducer?.postChannelPayload,
      postChannelLoading: collectReducer?.postChannelLoading,
      getSupportedCurrencyPayload: collectReducer?.getSupportedCurrencyPayload,
      getSupportedCountryPayload: collectReducer?.getSupportedCountryPayload,

      toggleChannelError: collectReducer?.toggleChannelError,
      toggleChannelPayload: collectReducer?.toggleChannelPayload,
      toggleChannelLoading: collectReducer?.toggleChannelLoading,

      putChannelError: collectReducer?.putChannelError,
      putChannelPayload: collectReducer?.putChannelPayload,
      putChannelLoading: collectReducer?.putChannelLoading,
    };
  });

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getChannel(page, 10, searchValue));
  };

  useEffect(() => {
    if (
      !toggleChannelLoading &&
      toggleChannelPayload?.data?.requestSuccessful
    ) {
      let i = 0;
      for (let dt of data) {
        if (dt?.id === id) {
          data[i]["isActive"] = !status;
          setData([...data]);
          setToggleForm(false);
          return;
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleChannelLoading]);

  useEffect(() => {
    if (!putChannelLoading && putChannelPayload?.data?.requestSuccessful) {
      let i = 0;
      for (let dt of data) {
        if (dt?.id === id) {
          data[i] = putChannelPayload?.data?.responseData;
          setData([...data]);
          setToggleForm(false);
          setCreateForm(false);
          dispatch(successMessage("Channel update was successful"));
          return;
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putChannelLoading]);

  useEffect(() => {
    dispatch(getChannel());
    dispatch(getSupportedCountry());
    dispatch(getSupportedCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !getChannelLoading &&
      getChannelPayload &&
      getChannelPayload?.data?.data
    ) {
      setData(getChannelPayload?.data?.data);
    } else if (
      !getChannelLoading &&
      (getChannelPayload?.data?.hasError || getChannelError)
    ) {
      dispatch(
        errorMessage(getChannelError?.data?.message || generalErrorMessage)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChannelLoading]);

  useEffect(() => {
    if (
      !postChannelLoading &&
      postChannelPayload?.data?.requestSuccessful &&
      submitStatus
    ) {
      setData([postChannelPayload?.data?.responseData, ...data]);
      dispatch(successMessage("Channel creation was successful"));
      setPayload(payloadData);
      setCreateForm(false);
      setSubmitStatus(false);
    } else if (
      !postChannelLoading &&
      !postChannelPayload?.data?.requestSuccessful &&
      submitStatus
    ) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          postChannelPayload?.data?.message ||
            "Error occurred while processing this request"
        )
      );
    } else if (!postChannelLoading && postChannelError && submitStatus) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          postChannelError?.data?.message ||
            "Error occurred while processing this request"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postChannelLoading]);

  const handleAutoCompleteCreateChange = (event, values, input) => {};

  const onEdit = (id, item) => {
    setId(id);
    const country = getSupportedCountryPayload?.data
      ?.filter((val) => val?.countryCode === item?.country)
      ?.map((val) => ({ value: val?.countryCode, label: val?.countryName }));
    const currency = getSupportedCurrencyPayload?.data
      ?.filter((val) => val?.currencyCode === item?.currency)
      ?.map((val) => ({ value: val?.currencyCode, label: val?.currencyName }));

    setId(id);
    setPayload({
      ...item,
      currency: currency?.[0]?.value,
      country: country?.[0]?.value,
      channel: item.channel,
      currencyPayload: currency?.[0],
      countryPayload: country?.[0],
      channelPayload: { value: item.channel, label: item.channel },
    });

    setEditStatus(true);
    setCreateForm(true);
  };

  const submit = () => {
    let errorStatus = false;
    setSubmitStatus(true);
    console.log({payload})
    for (let data in payload) {
      if (
        (payload[data] !== 0 && (!payload[data] || payload[data] === "")) &&
        data !== "pricingFlat" &&
        data !== "revenueFlat" &&
        data !== "updatedAt" &&
        data !== "deletedBy" &&
        data !== "isActive" &&
        data !== "lastUpdatedBy"
      ) {
        errorStatus = true;
      }
    }
    if (!errorStatus && editStatus) {
      dispatch(putChannel(id, payload));
    } else if (!errorStatus && !editStatus) {
      dispatch(postChannel(payload));
    }
  };

  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getChannel(1, 10, srhTxt));
    }, 500),
    []
  );

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const showFilterMenu = () => {};

  const openCreate = () => {
    setCreateForm(true);
    setEditStatus(false);
    setPayload(payloadData);
  };

  const handleChange = (statusId, flag) => {
    setId(statusId);
    setStatus(flag);
    setToggleForm(true);
  };

  const format = () => {
    return data?.map((item, index) => {
      return {
        name: item?.channelName,
        channel: item?.channel,
        id: item?.id,
        currency: item?.currency,
        country: item?.country,
        isActive: item?.isActive,
        Edit: (
          <div>
            <span className="approve" onClick={() => onEdit(item?.id, item)}>
              Edit
            </span>
          </div>
        ),
        Actions: (
          <div>
            {item?.isActive ? (
              <span
                className="reject"
                onClick={() => handleChange(item?.id, true)}>
                Suspend
              </span>
            ) : (
              <span
                className="approve"
                onClick={() => handleChange(item?.id, false)}>
                Approve
              </span>
            )}
          </div>
        ),
      };
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
        className="customScroll">
        <FilterMenu
          caption="Clients List"
          totalCount={10}
          searchLabel="Search.."
          filterOptions={clientsFilter}
          filterdefaultValueOptions={{
            label: "All",
            value: "all",
          }}
          handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
          showFilter={false}
          showSearch={true}
          showCreateBtn={true}
          btnName="New Channel&nbsp;&nbsp;+"
          openCreate={() => openCreate()}
          handleSearchChange={handleSearchChange}
          showFilterMenu={showFilterMenu}
          filterLabel="Filter by"
          searchName="keyword"
          searchValue={searchValue}
          clearSearch={clearSearch}
          entriesName="Entries"
          filterValue={"Search"}
          entriesOptions={{}}
          entriesdefaultValueOptions={{
            label: `Entries`,
            value: 20,
          }}
          entriesValue={{}}
          entriesLabel="Entries"
        />
      </div>

      <ListTable
        header={[
          { name: "Channel Name" },
          { name: "Channel" },
          { name: "Country" },
          { name: "Currency" },
          { name: "Status" },
          { name: "Action" },
          { name: "" },
        ]}
        isSearching={getChannelLoading}
        showError={false}
        style={{ opacity: 1 }}
        pageOfItems={data}
        content={
          <TableBody>
            {format()?.map((row, index) => (
              <TableRow key={index} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.channel}
                </TableCell>
                <TableCell align="left">{row.country}</TableCell>
                <TableCell align="left">{row.currency}</TableCell>
                <TableCell align="left">
                  <div className="status-flag">
                    <span
                      className={`dot-status ${
                        row.isActive ? "dot-active" : "dot-inactive"
                      }`}>
                      .
                    </span>
                    {row.isActive ? "Active" : "Inactive"}
                  </div>
                </TableCell>

                <TableCell align="left">{row.Actions}</TableCell>

                <TableCell align="left">{row.Edit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
      />
      <br />
      {data?.length > 0 ? (
        <div className="paginate-content">
          <div>
            Showing Page {getChannelPayload?.data?.pageNumber} of{" "}
            {getChannelPayload?.data?.totalPages}
          </div>
          <div>
            <Pagination
              totalPages={getChannelPayload?.data?.totalPages}
              currentPage={currentPage}
              changeCurrentPage={(p) => getPaginationNumber(p)}
              previousBtn="Prev"
              nextBtn="Next"
            />
          </div>
        </div>
      ) : null}

      {createForm && (
        <CreateModal
          title={editStatus ? "Edit Channel" : "Add Channel"}
          createForm={createForm}
          hideCreateForm={() => setCreateForm(false)}
          onCreate={() => submit()}
          maxWidth="md"
          creating={postChannelLoading || putChannelLoading}
          disableCreate={postChannelLoading || putChannelLoading}
          cancelText="Cancel"
          createText={editStatus ? "Update" : "Add"}
          content={
            <Form
              getCurrencyPayload={getSupportedCurrencyPayload}
              channelType={channelType}
              getCountryPayload={getSupportedCountryPayload}
              setPayload={setPayload}
              payload={payload}
              submitStatus={submitStatus}
              edit={editStatus}
            />
          }
        />
      )}

      {toggleForm && (
        <ToggleModal
          title={status ? "Disable item?" : "Enable Item?"}
          toggleForm={toggleForm}
          onToggle={() => dispatch(toggleChannel(id, status))}
          toggling={toggleChannelLoading}
          disableToggle={toggleChannelLoading}
          hideToggleModal={() => setToggleForm(false)}
          toggleText={status ? "Disable" : "Enable"}
          textContent={
            status
              ? "Are you sure you want to reject this item?"
              : "Are you sure you want to approve this item?"
          }
        />
      )}
    </div>
  );
};

export default Channel;
