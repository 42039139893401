import React from 'react';

const Form = ({setPayload, payload, submitStatus }) => {
    return(
        <form className="create-form">
        <div>
          <label htmlFor="transactionReference">Transaction Reference</label>
          <input onChange={(e)=>setPayload({...payload, transactionReference: e.target.value})} placeholder="Transaction Reference" className="form-control" id="transactionReference"/>
        </div>
        {submitStatus && !payload?.transactionReference && <div className="error-desc">Transaction Reference is required</div>}
        
        <div>
          <label htmlFor="Amount">Amount</label>
          <input type="number" onChange={(e)=>setPayload({...payload, amount: e.target.value})} placeholder="Amount" className="form-control" id="Amount"/>
        </div>
        {submitStatus && !payload?.amount && <div className="error-desc">Amount is required</div>}
        
        
        <div>
          <label htmlFor="chargeType">Chargeback Type</label>
          <select onChange={(e)=>setPayload({...payload, chargeType: e.target.value})} placeholder="Chargeback Type" className="form-control" id="chargeType">
            <option></option>
            <option>Flat</option>
            <option>Percentage</option>
          </select>
        </div>
        {submitStatus && !payload?.chargeType && <div className="error-desc">Chargeback Type is required</div>}

        <div>
          <label htmlFor="reason">Reason for Chargeback</label>
          <textarea onChange={(e)=>setPayload({...payload, reason: e.target.value})} placeholder="Reason for Chargeback" className="form-control" id="reason">
          </textarea>
        </div>
        {submitStatus && !payload?.reason && <div className="error-desc">Reason for Chargeback is required</div>}
      </form>
    
    )
}

export default Form;