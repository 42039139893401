import React from "react";

export const SuccessIcon = () =>(
<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="50" cy="50" r="50" fill="#E8EFFD"/>
<g clip-path="url(#clip0_2683_7854)">
<path d="M49.9999 66.6673C40.7949 66.6673 33.3333 59.2057 33.3333 50.0007C33.3333 40.7957 40.7949 33.334 49.9999 33.334C59.2049 33.334 66.6666 40.7957 66.6666 50.0007C66.6666 59.2057 59.2049 66.6673 49.9999 66.6673ZM49.9999 63.334C53.5361 63.334 56.9275 61.9292 59.428 59.4287C61.9285 56.9283 63.3333 53.5369 63.3333 50.0007C63.3333 46.4644 61.9285 43.073 59.428 40.5726C56.9275 38.0721 53.5361 36.6673 49.9999 36.6673C46.4637 36.6673 43.0723 38.0721 40.5718 40.5726C38.0713 43.073 36.6666 46.4644 36.6666 50.0007C36.6666 53.5369 38.0713 56.9283 40.5718 59.4287C43.0723 61.9292 46.4637 63.334 49.9999 63.334ZM48.3333 41.6673H51.6666V45.0007H48.3333V41.6673ZM48.3333 48.334H51.6666V58.334H48.3333V48.334Z" fill="#5B8EF0"/>
</g>
<defs>
<clipPath id="clip0_2683_7854">
<rect width="40" height="40" fill="white" transform="translate(30 30)"/>
</clipPath>
</defs>
</svg>
)
