import React from "react";

const CloseIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5534_6338)">
<path d="M8.364 6.95L13.314 2L14.728 3.414L9.778 8.364L14.728 13.314L13.314 14.728L8.364 9.778L3.414 14.728L2 13.314L6.95 8.364L2 3.414L3.414 2L8.364 6.95Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_5534_6338">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

)

export default CloseIcon;