import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import CustomButton from "components/Input/CustomButton";
import "./Modal.scss";
import CloseIcon from "icons/close-icon";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ width: '400px' }} />
    </Draggable>
  );
}

export default function ViewModal(props) {
  const [data, setData] = useState([])
  
  const handleClose = () => {
    props.hideViewForm();
  };

  const camelCaseToTitle = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  useEffect(()=>{
    let items = [];
     for(let dt in props?.content){
      if(dt === "merchantID"){
        items.push({key: "Merchant ID", value: props?.content[dt].toString()})
      }else if(dt !=="id" && dt !=="banks" && props?.content[dt] !== null){
        items.push({key: camelCaseToTitle(dt), value: props?.content[dt].toString()})
      }
     }
     setData(items)
  },[props?.content])

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="view-container"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title" className="title">
          {props.title}
          <span className="close" onClick={handleClose}>
            <CloseIcon/>
          </span>
          
        </DialogTitle>
        <DialogContent dividers>
         {data.map(val => (<div key={val.key}>
          <div className="key">{val?.key}</div>
          <div className="value">{val?.value}</div>
         </div>))}
        </DialogContent>
       
      </Dialog>
    </div>
  );
}
