import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { debounce } from "lodash";
import ToggleModal from "components/Modal/ToggleModal";

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter, generalErrorMessage } from "Constants";
import "../payout.scss";
import { getCountry, getCurrency } from "logic/actions/requests";
import CreateModal from "components/Modal/CreateModal";
import Pagination from "../../../components/paginations/Paginations";
import { errorMessage } from "logic/actions/notification";
import {
  getPayoutCountry,
  postPayoutCountry,
  getGateWay,
  toggleCountryPayout,
} from "logic/actions/payout";
import Form from "./form";
import { Drawer } from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import TableActionMenu from "./tableActionMenu";
import { toast } from "react-toastify";

const payloadData = {
  // "chargeType": "Flat",
  // "localChargeType": "Flat",

  destinationCurrency: "", // Standard Currency
  countryCode: "",
  countryName: "",
  gatewayCode: "",
  chargeCurrency: "", // Local Currency
  payoutType: "", // Payment Channel
  localCharge: null, // local charge fee
  localPercent: null, // local charge percent
  localCap: null, // Local Cap
  chargeValue: null, // Standard Flat Fee
  chargePercent: null, // Standard charge percent
  chargeCap: null, // Standard cap
  transactionLimitAmount: null, // Standard cap
};
const Country = () => {
  const [id, setId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [toggleForm, setToggleForm] = useState({ open: false, toggled: false });
  const [status, setStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [data, setData] = useState([]);
  const [payload, setPayload] = useState(payloadData);
  const [editor, setEditor] = React.useState({
    open: false,
    isModify: false,
    submitted: false,
  });
  const [viewer, setViewer] = React.useState({ open: false, data: null });
  const dispatch = useDispatch();

  const {
    toggleCountryLoading,
    toggleCountryPayload,
    getPayoutCountryLoading,
    getPayoutCountryPayload,
    getPayoutCountryError,
    getIdentityTypeLoading,
    getIdentityTypePayload,
    getIdentityTypeError,
    postPayoutCountryLoading,
    postPayoutCountryPayload,
    getCountryPayload,
    getCurrencyPayload,
    getGateWayPayload,
  } = useSelector(({ payoutReducer, virtualAccountReducer }) => {
    return {
      toggleCountryError: payoutReducer?.toggleCountryError,
      toggleCountryPayload: payoutReducer?.toggleCountryPayload,
      toggleCountryLoading: payoutReducer?.toggleCountryLoading,
      getPayoutCountryError: payoutReducer?.getPayoutCountryError,
      getPayoutCountryPayload: payoutReducer?.getPayoutCountryPayload,
      getPayoutCountryLoading: payoutReducer?.getPayoutCountryLoading,
      getIdentityTypeError: payoutReducer?.getIdentityTypeError,
      getIdentityTypePayload: payoutReducer?.getIdentityTypePayload,
      getIdentityTypeLoading: payoutReducer?.getIdentityTypeLoading,
      postPayoutCountryError: payoutReducer?.postPayoutCountryError,
      postPayoutCountryPayload: payoutReducer?.postPayoutCountryPayload,
      postPayoutCountryLoading: payoutReducer?.postPayoutCountryLoading,
      getCountryError: virtualAccountReducer?.getCountryError,
      getCountryPayload: virtualAccountReducer?.getCountryPayload,
      getCountryLoading: virtualAccountReducer?.getCountryLoading,
      getCurrencyError: virtualAccountReducer?.getCurrencyError,
      getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
      getCurrencyLoading: virtualAccountReducer?.getCurrencyLoading,
      getGateWayPayload: payoutReducer?.getGateWayPayload,
    };
  });

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getPayoutCountry(10, page, searchValue));
  };

  useEffect(() => {
    dispatch(getPayoutCountry(10));
    // dispatch(getIdentityType());
    dispatch(getCountry());
    dispatch(getCurrency());
    dispatch(getGateWay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !getPayoutCountryLoading &&
      getPayoutCountryPayload?.data?.requestSuccessful
    ) {
      setData(getPayoutCountryPayload?.data?.responseData?.items);
    } else if (
      !getPayoutCountryLoading &&
      getPayoutCountryPayload?.data?.requestSuccessful
    ) {
      dispatch(
        errorMessage(
          getPayoutCountryError?.data?.message || generalErrorMessage
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayoutCountryLoading]);

  useEffect(() => {
    if (
      !getIdentityTypeLoading &&
      (getIdentityTypePayload?.data?.hasError || getIdentityTypeError)
    ) {
      dispatch(
        errorMessage(getIdentityTypeError?.data?.message || generalErrorMessage)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdentityTypeLoading]);

  useEffect(() => {
    if (
      !postPayoutCountryLoading &&
      postPayoutCountryPayload?.data?.requestSuccessful &&
      editor.submitted
    ) {
      dispatch(getPayoutCountry(10, 1, searchValue));
      setCurrentPage(1);
      setPayload(payloadData);
      toast.success(
        `Country ${editor.isModify ? "updated" : "created"} successfully`,
        { position: "top-right" }
      );
      setEditor({ open: false, isModify: false, submitted: false });
    } else if (
      !postPayoutCountryLoading &&
      !postPayoutCountryPayload?.data?.requestSuccessful
    ) {
      dispatch(errorMessage(postPayoutCountryPayload?.data?.message));
      setEditor((prev) => ({ ...prev, submitted: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postPayoutCountryLoading]);

  useEffect(() => {
    if (
      !toggleCountryLoading &&
      toggleCountryPayload?.data?.requestSuccessful &&
      toggleForm.toggled
    ) {
      let i = 0;
      for (let dt of data) {
        if (dt?.id === id) {
          toast.success(
            `Record ${dt?.isActive ? "deactivated" : "activated"} successfully`,
            { position: "top-right" }
          );
          data[i]["isActive"] = !dt?.isActive;
          setData([...data]);
          setToggleForm({ open: false, toggled: false });
          return;
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCountryLoading]);

  const handleActionItemClick = (data, actionType) => {
    switch (actionType) {
      case "edit":
        setSubmitStatus(false);
        setEditor({ open: true, isModify: true });
        setPayload({
          gatewayCode: data.gatewayCode,
          countryCode: data.countryCode,
          countryName: data.countryName,
          destinationCurrency: data.destinationCurrency,
          payoutType: data.payoutType,
          chargeValue: data.chargeValue ?? null,
          chargeCurrency: data.chargeCurrency,
          localCharge: data.localCharge ?? null,
          localPercent: data.localPercent ?? null, // local charge percent
          localCap: data.localCap ?? null, // Local Cap
          chargePercent: data.chargePercent ?? null, // Standard charge percent
          chargeCap: data.chargeCap ?? null, // Standard cap
          id: data.id,
        });
        break;
      case "view":
        setViewer({ data, open: true });
        break;

      default:
        break;
    }
  };

  const handleAutoCompleteCreateChange = (event, values, input) => {};
  const submit = () => {
    setEditor((prev) => ({ ...prev, submitted: true }));
    let errorStatus = false;
    for (let data in payload) {
      if (payload[data] !== false && (!payload[data] || payload[data] === "")) {
        errorStatus = true;
        setSubmitStatus(true);
      }
    }
    if (!errorStatus) {
      const {
        gatewayCode,
        countryCode,
        countryName,
        payoutType,
        chargeValue,
        chargeCurrency, // Standard Currency
        localCharge,
        destinationCurrency, // Local Charge Currency
        localPercent, // local charge percent
        localCap, // Local Cap
        chargePercent, // Standard charge percent
        chargeCap, // Standard cap
        transactionLimitAmount,
        id,
      } = payload;
      dispatch(
        postPayoutCountry({
          gatewayCode,
          countryCode,
          countryName,
          destinationCurrency,
          transactionLimitAmount,
          payoutType,
          chargeValue,
          chargeCurrency,
          localCharge,
          localPercent, // local charge percent
          localCap, // Local Cap
          chargePercent, // Standard charge percent
          chargeCap, // Standard cap
          id,
        })
      );
    }
  };

  const handleChange = (statusId, checked) => {
    setId(statusId);
    setStatus(checked);
    setToggleForm({ open: true, toggled: false });
  };

  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getPayoutCountry(10, 1, srhTxt));
      setCurrentPage(1);
    }, 500),
    []
  );

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const showFilterMenu = () => {};

  const openCreate = () => {
    setEditor({ open: true, isModify: false });
    setPayload(payloadData);
    setSubmitStatus(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
        className="customScroll"
      >
        <FilterMenu
          caption="Clients List"
          totalCount={10}
          searchLabel="Search.."
          filterOptions={clientsFilter}
          filterdefaultValueOptions={{
            label: "All",
            value: "all",
          }}
          handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
          showFilter={false}
          showSearch={true}
          showCreateBtn={true}
          btnName="New Country&nbsp;&nbsp;+"
          openCreate={() => openCreate()}
          handleSearchChange={handleSearchChange}
          showFilterMenu={showFilterMenu}
          filterLabel="Filter by"
          searchName="keyword"
          searchValue={searchValue}
          clearSearch={clearSearch}
          entriesName="Entries"
          filterValue={"Search"}
          entriesOptions={{}}
          entriesdefaultValueOptions={{
            label: `Entries`,
            value: 20,
          }}
          entriesValue={{}}
          entriesLabel="Entries"
        />
      </div>

      <ListTable
        header={[
          { name: "Country" },
          { name: "Gateway" },
          { name: "Charge Currency" },
          { name: "Payment Channel" },
          { name: "Charge Value" },
          { name: "Local Charge" },
          { name: "Status" },
          { name: "Action" },
        ]}
        isSearching={getPayoutCountryLoading}
        showError={false}
        style={{ opacity: 1 }}
        pageOfItems={getPayoutCountryPayload?.data?.responseData?.items}
        content={
          <TableBody>
            {getPayoutCountryPayload?.data?.responseData?.items?.map(
              (row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.countryName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.gatewayCode}
                  </TableCell>
                  <TableCell align="left">{row.chargeCurrency}</TableCell>
                  <TableCell align="left">{row.payoutType}</TableCell>
                  <TableCell align="left">{row.chargeValue}</TableCell>
                  <TableCell align="left">{row.localCharge}</TableCell>
                  <TableCell align="left">
                    <div className="status-flag">
                      <span
                        className={`dot-status ${
                          row.isActive ? "dot-active" : "dot-inactive"
                        }`}
                      >
                        .
                      </span>
                      {row.isActive ? "Active" : "Inactive"}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="payout-action">
                      <Switch
                        height={18}
                        width={32}
                        onChange={() => handleChange(row?.id, row?.isActive)}
                        onColor="#175FFF"
                        checked={row?.isActive}
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      <TableActionMenu
                        data={row}
                        onItemClick={handleActionItemClick}
                        menuItems={[
                          { name: "View", key: "view" },
                          { name: "Edit", key: "edit" },
                        ]}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        }
      />
      <br />
      {data?.length > 0 ? (
        <div className="paginate-content px-3">
          <div>
            Showing Page{" "}
            {getPayoutCountryPayload?.data?.responseData?.pageNumber} of{" "}
            {getPayoutCountryPayload?.data?.responseData?.pages}
          </div>
          <div>
            <Pagination
              totalPages={getPayoutCountryPayload?.data?.responseData?.pages}
              currentPage={currentPage}
              changeCurrentPage={(p) => getPaginationNumber(p)}
              previousBtn="Prev"
              nextBtn="Next"
            />
          </div>
        </div>
      ) : null}

      {editor.open && (
        <CreateModal
          title="New Country"
          fullWidth
          createForm={editor.open}
          hideCreateForm={() => setEditor({ open: false, isModify: false })}
          onCreate={() => submit()}
          creating={postPayoutCountryLoading}
          disableCreate={postPayoutCountryLoading}
          cancelText="Cancel"
          createText="Add"
          content={
            <Form
              currencies={getCurrencyPayload?.data?.responseData?.items?.map(
                (val) => ({ value: val?.currencyCode, label: val?.name })
              )}
              countries={getCountryPayload?.data?.responseData?.items?.map(
                (val) => ({ value: val?.isO3, label: val?.name })
              )}
              setPayload={setPayload}
              payload={payload}
              submitStatus={submitStatus}
              gateways={getGateWayPayload?.data?.responseData?.items?.map(
                (val) => ({ value: val?.code, label: val?.name })
              )}
            />
          }
        />
      )}

      {toggleForm.open && (
        <ToggleModal
          title={status ? "Disable item?" : "Enable Item?"}
          toggleForm={toggleForm.open}
          onToggle={() => {
            setToggleForm((prev) => ({ ...prev, toggled: true }));
            dispatch(toggleCountryPayout(id, status));
          }}
          toggling={toggleCountryLoading}
          disableToggle={toggleCountryLoading}
          hideToggleModal={() => setToggleForm({ open: false, toggled: false })}
          toggleText={status ? "Disable" : "Enable"}
          textContent={
            status
              ? "Are you sure you want to deactivate this item?"
              : "Are you sure you want to activate this item?"
          }
        />
      )}
      <Drawer
        anchor="right"
        open={viewer.open}
        onClose={() => setViewer({ open: false, data: null })}
      >
        <div className="p-3 detail-form">
          <div className="display-item w-100 mt-4 pb-1">
            <div className="title">Country</div>
            <div className="desc mb-1">{viewer.data?.countryName}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Gateway Name</div>
            <div className="desc mb-1">{viewer.data?.gatewayCode}</div>
          </div>

          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Currency</div>
            <div className="desc mb-1">{viewer.data?.destinationCurrency}</div>
          </div>

          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Payment Channel</div>
            <div className="desc mb-1">{viewer.data?.payoutType}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Local Charge Amount</div>
            <div className="desc mb-1">
               {viewer.data?.localCharge}({viewer.data?.destinationCurrency})
            </div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Standard Charge Currency</div>
            <div className="desc mb-1">{viewer.data?.chargeCurrency}</div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
            <div className="title">Standard Charge Amount</div>
            <div className="desc mb-1">
              {viewer.data?.chargeValue}({viewer.data?.destinationCurrency})
            </div>
          </div>
          <div className="display-item w-100 mt-3 pb-3">
              Transaction Limit Amount
            <div className="title"></div>
            <div className="desc mb-1">
            {viewer.data?.transactionLimitAmount}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Country;
