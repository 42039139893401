import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./lookup.scss";
import { transactiondetails, transactionrequery } from "logic/actions/payout";
import { errorMessage, successMessage } from "logic/actions/notification";
import { Spinner } from "reactstrap";
import NoRecord from "Pages/Error/NoRecord";
import { formateDate } from "utils/utils";

const Lookup = () => {
  const dispatch = useDispatch();
  const [reference, setReference] = useState("");
  const [result, setResult] = useState(null);
  const [action, setAction] = useState("");
  const [requestStatusDetail, setRequestStatusDetail] = useState(false);
  const [requestStatusQuery, setRequestStatusQuery] = useState(false);

  const {
    getTransactionDetailsLoading,
    getTransactionDetailsPayload,
    getTransactionDetailsError,
    postTransactionRequestLoading,
    postTransactionRequestPayload,
    postTransactionRequestError,
  } = useSelector(({ payoutReducer }) => {
    return {
      postTransactionRequestError: payoutReducer?.postTransactionRequestError,
      postTransactionRequestPayload:
        payoutReducer?.postTransactionRequestPayload,
      postTransactionRequestLoading:
        payoutReducer?.postTransactionRequestLoading,
      getTransactionDetailsError: payoutReducer?.getTransactionDetailsError,
      getTransactionDetailsPayload: payoutReducer?.getTransactionDetailsPayload,
      getTransactionDetailsLoading: payoutReducer?.getTransactionDetailsLoading,
    };
  });

  const handleReferenceChange = (event) => {
    setReference(event.target.value);
  };

  const onSearch = () => {
    setRequestStatusDetail(true);
    dispatch(transactiondetails(reference));
  };

  const requery = (type) => {
    setAction(type);
    setRequestStatusQuery(true);
    dispatch(
      transactionrequery({
        transactionReference: reference,
        action: type,
      })
    );
  };

  console.log({ddfffgg: result?.transactionDetails})
  const camelCaseToWord = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  useEffect(() => {
    if (requestStatusDetail) {
      if (
        !getTransactionDetailsLoading &&
        getTransactionDetailsPayload?.data?.requestSuccessful
      ) {
        setResult(getTransactionDetailsPayload?.data?.responseData);
      } else if (
        !getTransactionDetailsLoading &&
        (getTransactionDetailsError ||
          !getTransactionDetailsPayload?.data?.requestSuccessful)
      ) {
        dispatch(
          errorMessage(
            getTransactionDetailsError?.data?.message ||
              getTransactionDetailsPayload?.data?.message ||
              "Error occurred while making query"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTransactionDetailsLoading]);

  useEffect(() => {
    if (requestStatusQuery) {
      if (
        !postTransactionRequestLoading &&
        postTransactionRequestPayload?.data?.requestSuccessful
      ) {
        dispatch(successMessage(`${action} was successfull`));
      } else if (
        !postTransactionRequestLoading &&
        (postTransactionRequestError ||
          !postTransactionRequestPayload?.data?.requestSuccessful)
      ) {
        dispatch(
          errorMessage(
            postTransactionRequestError?.data?.message ||
              postTransactionRequestPayload?.data?.message ||
              "Error occurred while making action"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTransactionRequestLoading]);

  return (
    <>
      <div className="row lookup-container">
        <div className="col-4 m-0 p-0">
          <div className="account-lookup">Account Lookup</div>
          <div className="form-content">
            <label>Transaction Reference</label>
            <div>
              <input
                className="form-control"
                onChange={handleReferenceChange}
                value={reference}
              />
            </div>
            <div>
              <button
                className="search-btn"
                onClick={onSearch}
                disabled={reference === ""}>
                {getTransactionDetailsLoading ? (
                  <Spinner size="sm" />
                ) : (
                  "Search"
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="col-8 content">
          {result && (
            <>
              <div className="search-result">Search Results</div>
              {result?.transactionDetails?.map((val) => (
                <div className="result">
                  <div className="details">Transaction Details</div>
                  <div className="item">
                    Beneficiary name: <span>{val?.beneficiaryName}</span>
                  </div>
                  <div className="item">
                    Beneficiary bank: <span>{val?.beneficiaryBankName}</span>
                  </div>
                  <div className="item">
                    Account Number: <span>{val?.beneficiaryAccount}</span>
                  </div>
                  <div className="item">
                    Amount: <span>{val?.amount}</span>
                  </div>
                  <div className="item">
                    Charge Amount: <span>{val?.chargeAmount}</span>
                  </div>
                  <div className="item">
                    Total Amount: <span>{result?.totalAmount}</span>
                  </div>
                  <div className="item">
                    Transaction Date: <span>{formateDate(val?.createdAt)}, {val?.createdAt?.split("T")[1].slice(0,8)}</span>
                  </div>
                </div>
              ))}
              <div className="result">
                <div className="details">Transaction Processing Details</div>
                <div className="item">
                  Queue status: <span>{result?.processingStatus}</span>
                </div>
                <div className="item">
                  Gateway status: <span>{result?.transactionStatus}</span>
                </div>
                <div className="item">
                  Account debit status:{" "}
                  <span>
                    {result?.gatewayDebitReference ? "Successful" : "Failed"}
                  </span>
                </div>
              </div>
              <div className="action-btn">
                {result?.transactionStatus?.indexOf("Failed") > -1 && (
                  <button
                    className="reverse-btn"
                    onClick={() => requery("REVERSE")}
                    disabled={reference === ""}>
                    {postTransactionRequestLoading && action === "REVERSE" ? (
                      <Spinner size="sm" />
                    ) : (
                      "Reverse"
                    )}
                  </button>
                )}
                {result?.transactionStatus?.indexOf("Failed") < 0 &&
                  result?.transactionStatus?.indexOf("Success") < 0 && (
                    <button
                      className="retry-btn"
                      onClick={() => requery("RETRY")}
                      disabled={reference === ""}>
                      {postTransactionRequestLoading && action === "RETRY" ? (
                        <Spinner size="sm" />
                      ) : (
                        "Retry"
                      )}
                    </button>
                  )}
              </div>
            </>
          )}
          {getTransactionDetailsPayload?.data?.responseData === null && (
            <div className="no-record">
              <NoRecord />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Lookup;
