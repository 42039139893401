import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { debounce } from "lodash";
import { useHistory } from 'react-router-dom'
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import '../Compliance.scss';
import { getComplianceMerchant } from 'logic/actions/compliance';
import Pagination from '../../../components/paginations/Paginations';

const Merchants = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();

    const { getComplianceMerchantLoading, getComplianceMerchantPayload } = useSelector(({complianceReducer}) => {
        return {
           getComplianceMerchantError: complianceReducer?.getComplianceMerchantError,
            getComplianceMerchantPayload: complianceReducer?.getComplianceMerchantPayload,
            getComplianceMerchantLoading: complianceReducer?.getComplianceMerchantLoading,
          };
      });

      const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getComplianceMerchant(10, page, searchValue))
      }
    
    useEffect(()=>{
      dispatch(getComplianceMerchant(10, 1, searchValue));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!getComplianceMerchantLoading && getComplianceMerchantPayload){
      setData(getComplianceMerchantPayload?.data?.responseData?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getComplianceMerchantLoading]);

  
  const debounceText = useCallback(debounce(async(srhTxt) => {
    dispatch(getComplianceMerchant(10, 1, srhTxt)) 
  }, 500), [])
  
  
const handleSearchChange = (event, input) => {
  debounceText(event.target.value)
  setSearchValue(event.target.value)
};

const clearSearch = () => {
  debounceText("")
  setSearchValue("")
};


const showFilterMenu = () => {
}; 
  
const viewProfile = (id) =>{ 
  history.push(`/compliance/profile/${id}`);
}
 
  const handleAutoCompleteCreateChange = (event, values, input) => {
  };
 
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={true}
            showCreateBtn={false}
            btnName=""
            openCreate={() => {}}
            handleSearchChange={handleSearchChange}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            searchValue={searchValue}
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Name" },
            { name: "Currency Code" },
            { name: "Country Code" },
            { name: "Stauts" },
            { name: "Action" },
          ]}
          isSearching={getComplianceMerchantLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={data}
          content={
            <TableBody>
              {data?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.currencyCode}</TableCell>
                  <TableCell align="left">{row.countryCode}</TableCell>
                  <TableCell align="left">
                    {row.isEnabled ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell align="left">
                    <div
                      className="view-profile"
                      onClick={() => viewProfile(row.id)}>
                      View Profile
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page{" "}
              {getComplianceMerchantPayload?.data?.responseData?.pageNumber} of{" "}
              {getComplianceMerchantPayload?.data?.responseData?.pages}
            </div>
            <div>
              <Pagination
                totalPages={
                  getComplianceMerchantPayload?.data?.responseData?.pages
                }
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}
      </>
    );
}

export default Merchants;