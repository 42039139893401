import React from "react";
import "./merchant.scss";

const durationOptions = [
  { value: "yearly", label: "Yearly" },
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
];

const ManageMerchantForm = ({
  onChange,
  submitStatus,
  step,
  subscription,
  payload,
  subscriptionPayload,
  subscriptionLoading,
}) => {
  if (step === 1) {
    if (subscriptionLoading) {
      return (
        <div className="d-flex justify-content-center create-form">
          <div className="spinner-border p-4 spinner-border-lg" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else if (subscriptionPayload) {
      return (
        <div className="create-form">
          <div className="subscription-container">
            <p className="header">Current Plan</p>
            <p className="desc">{subscription}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="create-form">
          <div className="subscription-container">
            <p className="header">No Subscription Plan</p>
            <p className="desc">Create a new subscription plan.</p>
          </div>
        </div>
      );
    }
  }

  if (step === 2) {
    if (!subscriptionPayload) {
      return (
        <form className="create-form m-0">
          <div>
            <label htmlFor="amount">Custom Amount</label>
            <input
              type="number"
              name="amount"
              onChange={(e) => onChange(e)}
              className="form-control"
              id="amount"
              placeholder="custom amount"
            />
            {submitStatus && !payload?.amount && (
              <div className="error-desc">Custom Amount is required</div>
            )}
          </div>
          <div>
            <label htmlFor="duration">Custom Duration</label>
            <select
              className="form-control"
              onChange={(e) => onChange(e)}
              placeholder="Select duration"
              label="duration"
              name="duration"
              id="duration">
                <option selected disabled value="">select an option</option>
              {durationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {submitStatus && !payload?.duration && (
              <div className="error-desc">Custom Duration is required</div>
            )}
          </div>
          <div>
            <label htmlFor="name">Subscription start date</label>
            <input
              type="date"
              name="startDate"
              onChange={(e) => onChange(e)}
              className="form-control"
              id="startDate"
              placeholder="subscription start date"
            />
            {submitStatus && !payload?.startDate && (
              <div className="error-desc">
                Subscription start date is required
              </div>
            )}
          </div>

          <div>
            <label htmlFor="name">Subscription end date</label>
            <input
              type="date"
              name="endDate"
              onChange={(e) => onChange(e)}
              className="form-control"
              id="endDate"
              placeholder="subscription end date"
            />
            {submitStatus && !payload?.endDate && (
              <div className="error-desc">
                Subscription end date is required
              </div>
            )}
          </div>
        </form>
      );
    }

    return (
      <form className="create-form m-0">
        <div>
          <label htmlFor="amount">Custom Amount</label>
          <input
            type="number"
            name="amount"
            defaultValue={11}
            onChange={(e) => onChange(e)}
            className="form-control"
            id="amount"
            placeholder="custom amount"
          />
          {submitStatus && !payload?.amount && (
            <div className="error-desc">Custom Amount is required</div>
          )}
        </div>
        <div>
          <label htmlFor="duration">Custom Duration</label>
          <select
            className="form-control"
            defaultValue={"monthly"}
            onChange={(e) => onChange(e)}
            placeholder="Select duration"
            label="duration"
            name="duration"
            id="duration">
            {durationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {submitStatus && !payload?.duration && (
            <div className="error-desc">Custom Duration is required</div>
          )}
        </div>
      </form>
    );
  }

  return null;
};

export default ManageMerchantForm;
