import React from "react";
import { NavLink, Link } from "react-router-dom";

import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { resetPasswordErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { CircularProgress } from "@material-ui/core";
import Logo from "../../../assets/images/baseone-dark-bg.svg";


class SetupAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      err: resetPasswordErrors,
      data: {
        rpassword: "",
        rconfirmPassword: "",
      },
      hidden: true,
      hide: true,
      isError: false,
      isSuccess: false,
      token: "",
      id: "",
      loading: false,
      disableCreate: false,
      errorText: "",
      successText: ""
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.secondToggleShow = this.secondToggleShow.bind(this);
  }

  componentWillMount() {
    if (this.props.location) {
      if (!this.props.location.state) {
        this.props.history.push("/")
      }
    }
  }
  componentDidMount() {
    // const { location } = this.props

  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  secondToggleShow() {
    this.setState({ hide: !this.state.hide });
  }

  reset = () => {
    //
    const { Constants } = this.props
    this.setState({ disableCreate: true, isSuccess: false, isError: false, errorText: "", loading: true })
    this.props.validatorAll(
      [
        { name: "rpassword", value: this.state.data.rpassword, label: "Password" },
        {
          name: "rconfirmPassword",
          value: this.state.data.rconfirmPassword,
          rpassword: this.state.data.rpassword,
          label: "Confirm Password"
        },
      ],
      "SetupAccount",
      this
    );
    if (this.state.err.all.size > 0) {
      this.setState({ creating: false, disableCreate: false, loading: false, errorText: "" });
      return;
    }

    const payload = {
      "email": this.props.location.state.email,
      "password": this.state.data.rpassword,
      "confirmPassword": this.state.data.rconfirmPassword
    }

    const SetupAccountService = this.props.Service(null, null);

    SetupAccountService.createItem(payload, Constants.ACCOUNT, Constants.SETUP_ACCOUNT)
      .then((res) => {
        const { data } = res;
        if (data.requestSuccessful === false) {
          //  something crazy happened
          this.setState({ disableCreate: false, isSuccess: false, isError: true, loading: false, errorText: data.message })
          return;
        } else {

          this.setState({ disableCreate: false, isError: false, isSuccess: true, loading: false, successText: "Account created Successfully" })
          setTimeout(() => {
            this.props.history.push("/")
          }, 1500);
          return;
        }

      })
      .catch((err) => {
        if (err) {
          if (err.response === undefined) {
            this.setState({ disableCreate: false, loading: false, isSuccess: false, isError: true, errorText: "Check your network and please try again" })

          }
          if (err.response) {
            this.setState({ disableCreate: false, loading: false, isSuccess: false, isError: true, })

          }
        }
      })
  };
  handleCreateFormInputChange = (input, label) => ({ target: { value } }) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [input]: value,
        },
      }),
      () => {
        this.props.validator(
          { name: input, value: value, label: label, rpassword: this.state.data.rpassword, rconfirmPassword: this.state.data.rconfirmPassword },
          "SetupAccount",
          this
        );
      }
    );
  };

  render() {
    const { err, data, successText } = this.state;
    return (
      <Aux>
        <Breadcrumb />
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="card">
              <div className="card-body text-center">
                <Link to={"/"}>
                  <img src={Logo} alt="Logo" style={{ width: "190px", height: "80px" }} />
                </Link>
                <h4 className="mb-5">
                  <b>Setup Account</b>
                </h4>
                {/* <h6
                  className="text-muted mt-3"
                  style={{ marginBottom: "2rem" }}
                >
                  Enter a new passwrd
                </h6> */}

                <CustomInput
                  value={this.props.location && this.props.location.state.email}
                  id="eemail"
                  labelText="Email"
                  placeholder="test"
                  type={"text"}
                  disabled={true}
                />
                <div
                  className="input-group mb-3"
                  style={{ position: "relative" }}
                >

                  <CustomInput
                    errorText={err.rpassword}
                    showError={err.rpassword.length > 0}
                    value={data.rpassword}
                    onChange={this.handleCreateFormInputChange("rpassword", "Password")}
                    id="reset-password"
                    labelText="Password"
                    placeholder="password"
                    type={this.state.hidden ? "password" : "text"}
                    endIcon={
                      <p
                        onClick={this.toggleShow}
                        text={this.state.hidden ? "show" : "hide"}
                        style={{ color: "#5E4BCE" }}
                      >{this.state.hidden ? "show" : "hide"}</p>}
                  />
                </div>

                <CustomInput
                  errorText={err.rconfirmPassword}
                  showError={err.rconfirmPassword.length > 0}
                  value={data.rconfirmPassword}
                  onChange={this.handleCreateFormInputChange(
                    "rconfirmPassword", "Confirm Password"
                  )}
                  id="login-password"
                  labelText="Confirm Password"
                  placeholder="enter a password again"
                  type={this.state.hide ? "password" : "text"}
                  endIcon={
                    <p
                      onClick={this.secondToggleShow}
                      text={this.state.hide ? "show" : "hide"}
                      style={{ color: "#5E4BCE" }}
                    >{this.state.hide ? "show" : "hide"}</p>}
                />
                <p className="mb-2 text-muted"></p>
                {this.state.isError && <h6 className="text-danger" style={{ marginBottom: "2rem" }}>{this.state.errorText}</h6>}
                {this.state.isSuccess && <h6 className="text-success" style={{ marginBottom: "2rem" }}>{successText}</h6>}

                <p className="mb-2 text-muted" style={{ textAlign: "end" }}>
                  {" "}
                  <NavLink to="/" style={{ color: "#5E4BCE" }}>
                    Already a user ? Login
                  </NavLink>
                </p>
                <button
                  className="btn btn-primary shadow-2 mb-4 mainColor"
                  style={{ width: "100%" }}
                  disabled={this.state.disableCreate}
                  onClick={this.reset}
                >
                  {this.state.loading && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} {" "}

                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default SetupAccount;
