import React from 'react';
import Select from 'react-select';
import "./form.scss";
import "../Collect.scss";

const Form = ({setPayload, payload, submitStatus, getCountryPayload, getCurrencyPayload, edit, channelType }) => {
    return(
      <form className="create-form manage-collect-merchant">
        <div className="row">
          <div className="col-6">
          <div>
                <label htmlFor="pricingValue">Channel Name</label>
                <input type="text" value={payload?.channelName} onChange={(e)=>setPayload({...payload, channelName: e.target.value})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.channelName && <div className="error-desc">Channel Name is required</div>}
              <div>
                <label htmlFor="country">Country</label>
                <Select
                      value={payload?.countryPayload}
                      onChange={e => setPayload({...payload, country: e.value?.toString(), countryPayload: e})}
                      options={getCountryPayload?.data?.map(val =>({ value: val?.countryCode, label: val?.countryName }))}
                  /> 
              </div>
                 {submitStatus && !payload?.country && <div className="error-desc">Country is required</div>}
              <div>
                <label htmlFor="country">Channel</label>
                  <Select
                      value={payload?.channelPayload}
                      onChange={e => setPayload({...payload, channel: e.value, channelPayload: e})}
                      options={channelType.map(val=>({ value: val, label: val }))}
                  />
              </div>
               {submitStatus && !payload?.channel && <div className="error-desc">Channel is required</div>}
               <div>
        <label htmlFor="country">Currency</label>
        <Select
              value={payload?.currencyPayload}
              onChange={e => setPayload({...payload, currency: e.value, currencyPayload: e})}
              options={getCurrencyPayload?.data?.map(val =>({ value: val?.currencyCode, label: val?.currencyName }))}
          /> 
       {submitStatus && !payload?.currency && <div className="error-desc">Currency is required</div>}
      </div>
      <div>
                <label htmlFor="pricingValue">Minimum Settlement Amount</label>
                <input type="number" value={payload?.minimumSettlementAmount} onChange={(e)=>setPayload({...payload, minimumSettlementAmount: Number(e.target.value)})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.minimumSettlementAmount && <div className="error-desc">Minimum Settlement Amount is required</div>}
            
      <div>
     
        <label htmlFor="pricingValue">Pricing Cap</label>
        <input type="number" value={payload?.pricingCap} onChange={(e)=>setPayload({...payload, pricingCap: Number(e.target.value)})} className="form-control" id="channelName"/>  
      </div>
       {submitStatus && !payload?.pricingCap && <div className="error-desc">Pricing Cap is required</div>}
     
          </div>
          <div className="col-6">
          
          <div>
                <label htmlFor="pricingValue">Queue Name</label>
                <input type="text" value={payload?.queueName} onChange={(e)=>setPayload({...payload, queueName: e.target.value})} className="form-control" id="channelName"/>  
              </div>
               {submitStatus && !payload?.queueName && <div className="error-desc">Queue Name is required</div>}
          <div>
        <label htmlFor="pricingValue">Pricing Flat</label>
        <input type="number" value={payload?.pricingFlat} onChange={(e)=>setPayload({...payload, pricingFlat: Number(e.target.value)})} className="form-control" id="channelName"/>  
      </div>
       {/* {submitStatus && !payload?.pricingFlat && <div className="error-desc">Pricing Flat is required</div>} */}
      

       <div>
        <label htmlFor="pricingPercentage">Price Percentage2</label>
        <input type="number" value={payload?.pricingPercentage} onChange={(e)=>setPayload({...payload, pricingPercentage: Number(e.target.value)})}  className="form-control" id="channelName"/>  
      </div>
       {submitStatus && !payload?.pricingPercentage && <div className="error-desc">Price Percentage is required</div>}
      
      <div>
        <label htmlFor="revenueValue">Revenue Flat</label>
        <input type="number" value={payload?.revenueFlat} onChange={(e)=>setPayload({...payload, revenueFlat: e.target.value})} className="form-control" id="channelName"/>  
      </div>
       {/* {submitStatus && !payload?.revenueFlat && <div className="error-desc">Revenue Flat is required</div>} */}
      
      
       <div>
        <label htmlFor="revenueValue">Revenue Percentage</label>
        <input type="number" value={payload?.revenuePercentage} onChange={(e)=>setPayload({...payload, revenuePercentage: Number(e.target.value)})}  className="form-control" id="channelName"/>  
      </div>
       {submitStatus && !payload?.revenuePercentage && <div className="error-desc">Revenue Percentage is required</div>}
      
          </div>
        </div>   
    </form>

    )
}

export default Form;