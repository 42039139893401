import React from "react";
import failure from "../../assets/images/failed_to_load.svg";

const Error = () => {
  return (
    <div>
      <div className="error-block">
        <img
          classname="image-content"
          src={failure}
          alt="failed"
          style={{ width: "100px" }}
        />
        <p classname="image-content">Failure in fetching data</p>
      </div>
    </div>
  );
};

export default Error;
