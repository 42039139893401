import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./lookup.scss";
import { transactiondetails, transactionrequery } from "logic/actions/payout";
import { errorMessage, successMessage } from "logic/actions/notification";
import { Spinner } from "reactstrap";
import NoRecord from "Pages/Error/NoRecord";

const Lookup = () => {
  const dispatch = useDispatch();
  const [reference, setReference] = useState("");
  const [result, setResult] = useState(null);
  const [action, setAction] = useState("");
  const [requestStatusDetail, setRequestStatusDetail] = useState(false);
  const [requestStatusQuery, setRequestStatusQuery] = useState(false);

  const {
    getTransactionDetailsLoading,
    getTransactionDetailsPayload,
    getTransactionDetailsError,
    postTransactionRequestLoading,
    postTransactionRequestPayload,
    postTransactionRequestError,
  } = useSelector(({ payoutReducer }) => {
    return {
      postTransactionRequestError: payoutReducer?.postTransactionRequestError,
      postTransactionRequestPayload:
        payoutReducer?.postTransactionRequestPayload,
      postTransactionRequestLoading:
        payoutReducer?.postTransactionRequestLoading,
      getTransactionDetailsError: payoutReducer?.getTransactionDetailsError,
      getTransactionDetailsPayload: payoutReducer?.getTransactionDetailsPayload,
      getTransactionDetailsLoading: payoutReducer?.getTransactionDetailsLoading,
    };
  });

  const handleReferenceChange = (event) => {
    setReference(event.target.value);
  };

  const onSearch = () => {
    setRequestStatusDetail(true);
    dispatch(transactiondetails({"transactionReferences": [reference]}, true));
  };

  
  const camelCaseToWord = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  console.log({ffkgkgkjgjgg: result})
  useEffect(() => {
    if (requestStatusDetail) {
      if (
        !getTransactionDetailsLoading &&
        getTransactionDetailsPayload?.data?.requestSuccessful
      ) {
        setResult(getTransactionDetailsPayload?.data?.responseData);
      } else if (
        !getTransactionDetailsLoading &&
        (getTransactionDetailsError ||
          !getTransactionDetailsPayload?.data?.requestSuccessful)
      ) {
        dispatch(
          errorMessage(
            getTransactionDetailsError?.data?.message ||
              getTransactionDetailsPayload?.data?.message ||
              "Error occurred while making query"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTransactionDetailsLoading]);

  useEffect(() => {
    if (requestStatusQuery) {
      if (
        !postTransactionRequestLoading &&
        postTransactionRequestPayload?.data?.requestSuccessful
      ) {
        dispatch(successMessage(`${action} was successfull`));
      } else if (
        !postTransactionRequestLoading &&
        (postTransactionRequestError ||
          !postTransactionRequestPayload?.data?.requestSuccessful)
      ) {
        dispatch(
          errorMessage(
            postTransactionRequestError?.data?.message ||
              postTransactionRequestPayload?.data?.message ||
              "Error occurred while making action"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTransactionRequestLoading]);

  return (
    <>
      <div className="row lookup-container">
        <div className="col-4 m-0 p-0">
          <div className="account-lookup">Account Lookup</div>
          <div className="form-content">
            <label>Transaction Reference</label>
            <div>
              <input
                className="form-control"
                onChange={handleReferenceChange}
                value={reference}
              />
            </div>
            <div>
              <button
                className="search-btn"
                onClick={onSearch}
                disabled={reference === ""}>
                {getTransactionDetailsLoading ? (
                  <Spinner size="sm" />
                ) : (
                  "Search"
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="col-8 content">
          {getTransactionDetailsPayload?.data?.responseData?.map(val => (
             <>
             <div className="search-result">Search Results</div>
               <div className="result">
                 <div className="details">Transaction Details</div>
                 <div className="item">
                  Amount: <span>{val?.amount}</span>
                 </div>
                 <div className="item">
                 Total Amount: <span>{val?.totalAmount}</span>
                 </div>
                 <div className="item">
                   Currency: <span>{val?.currency}</span>
                 </div>
                 
                 <div className="item">
                 Country: <span>{val?.country}</span>
                 </div>
                 
                 <div className="item">
                   channel: <span>{val?.channel}</span>
                 </div>
                 <div className="item">
                   Charge Included: <span>{val?.chargeIncluded?.toString()}</span>
                 </div>
                 <div className="item">
                   Charge Payer: <span>{val?.chargePayer}</span>
                 </div>
                 <div className="item">
                   Charges: <span>{val?.charges}</span>
                 </div>
                 <div className="item">
                   Charge Payer: <span>{val?.chargePayer}</span>
                 </div>
                 <div className="item">
                   Client Id: <span>{val?.clientId}</span>
                 </div>
                 <div className="item">
                   Client Reference: <span>{val?.clientReference}</span>
                 </div>
                 
                 <div className="item">
                 Transaction Reference: <span>{val?.transactionReference}</span>
                 </div>
                 
                
               </div>
             <div className="result">
               <div className="details">Transaction Processing Details</div>
               <div className="item">
               Settlement Status: <span>{val?.settlementStatus}</span>
               </div>
               <div className="item">
               Transaction Status: <span>{val?.transactionStatus}</span>
               </div>
             </div>
           </>
))}
          {getTransactionDetailsPayload?.data?.responseData === null && (
            <div className="no-record">
              <NoRecord />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Lookup;
