import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import '../../../assets/scss/style.scss';
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomInput from 'components/Input/CustomInput';
import { forgotpasswordErrors } from 'validation';
import { CircularProgress } from '@material-ui/core';
import Logo from "../../../assets/images/baseone-full-logo.png";


class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            err: forgotpasswordErrors,
            data: {
                Email: ""
            },
            errorText: "",
            isError: false,
            isSuccess: false,
            loading: false,
            disableCreate: false,
            showRecoverButton: true,
            showInputField: true,
        }
    }

    login = () => {
        this.props.history.push("/home/dashboard")
    }

    handleCreateFormInputChange = (input, label) => ({ target: { value } }) => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [input]: value,
            },
        }), () => {
            this.props.validator({ name: input, value: value, label: label }, 'ForgotPassword', this);
        });
    };

    recover = () => {
        const { Constants } = this.props
        this.setState({ isSuccess: false, isError: false, errorText: "", loading: true, disableCreate: true })
        this.props.validatorAll([
            { name: 'Email', value: this.state.data.Email },
        ], "ForgotPassword", this);
        if (this.state.err.all.size > 0) {
            this.setState({ creating: false, disableCreate: false, loading: false, errorText: "" })
            return;
        }

        const payload = {
            Email: this.state.data.Email
        }

        const ForgotPasswordService = this.props.Service(null, null)
        ForgotPasswordService.createItem(payload, Constants.ACCOUNT, Constants.FORGOT_PASSWORD)
            .then((res) => {
                const { data } = res;
                if (data.requestSuccessful === true) {
                    this.setState({ isSuccess: true, isError: false, loading: false, errorText: "", disableCreate: false, showRecoverButton: false, showInputField: false, })

                } else {
                    this.setState({ isSuccess: false, isError: true, errorText: data.message, disableCreate: false, loading: false })

                }

            })
            .catch((err) => {

                if (err) {
                    if (err.response === undefined) {
                        this.setState({ disableCreate: false, loading: false, isSuccess: false, isError: true, errorText: "Check your network, or try again" })

                    } else {
                        this.setState({ disableCreate: false, loading: false, isSuccess: false, isError: true, errorText: "There was an error, please try again" })
                    }
                }
            })

    }
    render() {

        const { err, data } = this.state
        return (
            <Aux>
                <Breadcrumb />
                <div className="auth-wrapper">
                    <div className="auth-content">

                        <div className="card">
                            <div className="card-body text-center">
                                <Link to={"/"}>  <img src={Logo} alt="Logo" style={{ width: "50%", height: "40px" }} />
                                </Link>
                                {this.state.showInputField ? <h4 className="mb-5 mt-2"><b>Forgot Password</b></h4> : null}
                                {/* <h6 className="text-muted mt-3" style={{ marginBottom: "2rem" }}>Enter your email to reset your password</h6> */}
                                {this.state.showInputField ? <div className="input-group mb-3">
                                    <CustomInput
                                        errorText={err.Email}
                                        showError={err.Email.length > 0}
                                        value={data.Email}
                                        onChange={this.handleCreateFormInputChange("Email", "Email")}
                                        id="forgot-password"
                                        labelText="Email address"
                                        placeholder="Email address"
                                    />
                                </div> : null}
                                <br />
                                {this.state.isError && <h6 className="text-danger" style={{ marginBottom: "2rem" }}>{this.state.errorText}</h6>}
                                {this.state.isSuccess && <h6 className="text-success" style={{ marginBottom: "2rem" }}>A Reset link has been sent to your email</h6>}

                                {this.state.showRecoverButton ? <button id="recover-password-btn" className="btn btn-primary shadow-2 mb-4 mainColor" disabled={this.state.disableCreate} style={{ width: "100%" }} onClick={this.recover}>
                                    {this.state.loading && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} {" "}

                                    Recover Password</button> : null}
                                <p className="mb-2 text-muted" style={{ textAlign: "center" }}> <NavLink to="/login" style={{ color: "#5E4BCE" }}>Login</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default ForgotPassword;
