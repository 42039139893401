import actionTypes from "./actionTypes";
import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  Get, Post, Put
 } from "apiServices/apiHandler.service";
import {
  collectBaseUrl,
} from "apiServices/config.service";


export const getChargeBack = createAsyncAction(
  actionTypes.GET_COLLECT_CHARGEBACK,
  async (pageNumber = 1, pageSize = 10, keyword="") => {
    return await Get(
      `v1/admin/chargebacks/search?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      collectBaseUrl
    );
  }
);

export const postChargeBack = createAsyncAction(
  actionTypes.POST_COLLECT_CHARGEBACK,
  async (data) => {
    return await Post(
      `v1/admin/chargebacks`, data,
      collectBaseUrl
    );
  }
);
export const getChannel = createAsyncAction(
  actionTypes.GET_CHANNEL,
  async (pageNumber = 1, pageSize = 10, keyword="") => {
    return await Get(
      `v1/admin/channels?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      collectBaseUrl
    );
  }
);

export const postChannel = createAsyncAction(
  actionTypes.POST_CHANNEL,
  async (data) => {
    return await Post(
      `v1/admin/channels`, data,
      collectBaseUrl
    );
  }
);

export const putChannel = createAsyncAction(
  actionTypes.PUT_CHANNEL,
  async (id, data) => {
    return await Put(
      `v1/admin/channels/${id}`, data,
      collectBaseUrl
    );
  }
);

export const getMerchant = createAsyncAction(
  actionTypes.GET_COLLECT_MERCHANT,
  async (status, pageNumber = 1, pageSize = 10, keyword="") => {
    return await Get(
      `v1/admin/merchants?IsActive=${status}&&pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      collectBaseUrl
    );
  }
);

export const postMerchant = createAsyncAction(
  actionTypes.POST_COLLECT_MERCHANT,
  async (pageNumber = 1, pageSize = 10, keyword="") => {
    return await Get(
      `v1/merchants?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}&isAscending=false`,
      collectBaseUrl
    );
  }
);

export const toggleMerchant = createAsyncAction(
  actionTypes.TOGGLE_COLLECT_MERCHANT,
  async (id) => {
    return await Put(
      `v1/admin/merchants/toggleactive/${id}`, {},
      collectBaseUrl
    );
  }
);

export const toggleChannel = createAsyncAction(
  actionTypes.TOGGLE_COLLECT_CHANNEL,
  async (id, flag) => {
    return await Put(
      `v1/admin/channels/${flag?"deactivate":"activate"}/${id}`, {},
      collectBaseUrl
    );
  }
);

export const postCollectCountry = createAsyncAction(actionTypes.POST_COLLECT_COUNTRY, async (data) => {
  return await Post(`v1/Admin/country`, data, collectBaseUrl);
});
export const getCollectCountry = createAsyncAction(actionTypes.GET_COLLECT_COUNTRY, async (pageSize=10, pageNumber=1) => {
  return await Get(`v1/admin/country/config?pageSize=${pageSize}&pageNumber=${pageNumber}`, collectBaseUrl);
});

export const manageMerchant = createAsyncAction(
  actionTypes.MANAGE_COLLECT_MERCHANT,
  async (title, data, defaultCountry, id) => {
    if(defaultCountry) {
      return id? await Put(
        `v1/admin/country/config/${id}`, data,
        collectBaseUrl
      ): await Post(
        `v1/admin/country/config`, data,
        collectBaseUrl
      );
    }else{
      return await Post(
        `v1/admin/merchant/${title==="Country"?"country":"channel"}/config`, data,
        collectBaseUrl
      );
    }
  }
);

export const getSupportedCountry = createAsyncAction(
  actionTypes.GET_SUPPORTED_COUNTRY,
  async () => {
    return await Get(
      "v1/country/supportedCountry",
      collectBaseUrl
    );
  }
);

export const getSupportedCurrency = createAsyncAction(
  actionTypes.GET_SUPPORTED_CURRENCY,
  async () => {
    return await Get(
      "v1/currency",
      collectBaseUrl
    );
  }
);
