import React from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Header from "./Header";
import LoadingOverlay from "react-loading-overlay";
import { TableBody, TableCell } from "@material-ui/core";
import Error from "../../Pages/Error/Error";
import NoRecord from "../../Pages/Error/NoRecord";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    padding: "10px",
  },
  tableCell: {
    borderBottom: 0,
    paddingTop: "60px",
  },
  tableCellError: {
    borderBottom: 0,
    paddingTop: "80px",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "13px",
      },
    },
  },
});
export default function ListTable(props) {
  const classes = useStyles();

  return (
    <LoadingOverlay
      active={props.isSearching}
      styles={{
        overlay: (base) => ({
          ...base,
          // background: "#e0e0e0",
          minHeight: "100px",
          opacity: 0.7,
        }),
      }}
      spinner
      text="Loading Records...">
      <ThemeProvider theme={theme}>
        <TableContainer component={"div"} style={{ ...props.style }}>
          <Table
            className={classes.table}
            size="medium"
            aria-label="customized table">
            <Header header={props.header} />

            {!props.showError &&
              (!props.pageOfItems || props.pageOfItems?.length === 0) &&
              !props.isSearching && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={props.header?.length}
                      className={classes.tableCellError}>
                      <NoRecord />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            {props.content}
            {props.showError && !props.isSearching && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={props.header?.length}
                    className={classes.tableCell}>
                    <Error />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </ThemeProvider>
    </LoadingOverlay>
  );
}
