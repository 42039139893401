import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import Pagination from 'components/paginations/Paginations';
import ToggleModal from 'components/Modal/ToggleModal';
import { getRequest, approveSmartCheckRequest, rejectSmartCheckRequest } from 'logic/actions/smartCheck';
import { errorMessage, successMessage } from 'logic/actions/notification';
import { useDispatch, useSelector } from 'react-redux';


const Request = () => {
    const [id, setId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleSubmitStatus, setToggleSubmitStatus] = useState({
        approveSubmit: false,
        rejectSubmit: false
    });
    const [rejectModal, setRejectModal] = useState(false);
    const dispatch = useDispatch();

    const { getRequestLoading, getRequestPayload, approveRequestLoading, approveRequestPayload, rejectRequestLoading, rejectRequestPayload } = useSelector(({ smartCheckReducer }) => {
        return {
            getRequestError: smartCheckReducer?.getRequestError,
            getRequestPayload: smartCheckReducer?.getRequestPayload,
            getRequestLoading: smartCheckReducer?.getRequestLoading,
            approveRequestError: smartCheckReducer?.approveRequestError,
            approveRequestPayload: smartCheckReducer?.approveRequestPayload,
            approveRequestLoading: smartCheckReducer?.approveRequestLoading,
            rejectRequestLoading: smartCheckReducer?.rejectRequestLoading,
            rejectRequestPayload: smartCheckReducer?.rejectRequestPayload,
            rejectRequestError: smartCheckReducer?.rejectRequestError,
        };
    });

    // console.log("getRequestPayload", getRequestPayload)

    const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getRequest(page, 10, searchValue))
    }

    useEffect(() => {
        dispatch(getRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getRequestPayload && !getRequestLoading) {
            setData(getRequestPayload?.data?.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRequestLoading])

    useEffect(() => {
        if (!approveRequestLoading && approveRequestPayload?.data?.requestSuccessful && toggleSubmitStatus.approveSubmit) {
            let i = 0;
            for (let dt of data) {
                if (dt?.MerchantId === id) {
                    data.splice(i, 1);
                    setData([...data]);
                    setToggleModal(false);
                    dispatch(successMessage(approveRequestPayload?.data?.Message))
                    return
                }
                i++
            }
        } else if (approveRequestPayload?.data?.requestSuccessful === false && toggleSubmitStatus.approveSubmit) {
            dispatch(errorMessage(approveRequestPayload?.data?.message))
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveRequestLoading])

    const handleApprove = () => {
        dispatch(approveSmartCheckRequest(id))
        setToggleSubmitStatus({
            ...toggleSubmitStatus,
            approveSubmit: true
        })
    }

    const handleReject = () => {
        dispatch(rejectSmartCheckRequest(id))
        setToggleSubmitStatus({
            ...toggleSubmitStatus,
            rejectSubmit: true
        })
    }

    useEffect(() => {
        if (!rejectRequestLoading && rejectRequestPayload?.data?.requestSuccessful && toggleSubmitStatus.rejectSubmit) {
            let i = 0;
            for (let dt of data) {
                if (dt?.MerchantId === id) {
                    data.splice(i, 1);
                    setData([...data]);
                    setRejectModal(false);
                    dispatch(successMessage(rejectRequestPayload?.data?.message))
                    return
                }
                i++
            }
        } else if (rejectRequestPayload?.data?.requestSuccessful === false && toggleSubmitStatus.rejectSubmit) {
            dispatch(errorMessage(approveRequestPayload?.data?.message))
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejectRequestLoading])

    const debounceText = useCallback(debounce(async (srhTxt) => {
        // dispatch(getRequest(1, 10, srhTxt))
    }, 500), [])


    const handleSearchChange = (event) => {
        debounceText(event.target.value)
        setSearchValue(event.target.value)
    };

    const clearSearch = () => {
        debounceText("")
        setSearchValue("")
    };

    const showFilterMenu = () => {
    };

    const handleAutoCompleteCreateChange = (event, values, input) => {
    };

    const handleChange = (statusId, checked) => {
        setId(statusId);
        setToggleModal(true);
    }

    const handleRejectChange = (statusId, checked) => {
        setId(statusId);
        setRejectModal(true);
    }
    const itemsPerPage = 10;
    const format = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const pagintedData = data?.slice(startIndex, endIndex);

        const formattedData = pagintedData.filter((item) => item.MerchantName.toLowerCase().includes(searchValue.toLowerCase()))?.map((item) => {
            const date = new Date(item?.OnboardingDate);
            const formattedDate = date.toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            return {
                name: item?.MerchantName,
                email: item?.MerchantEmail,
                id: item?.MerchantId,
                date: formattedDate,
                status: item?.OnboardStatus,
                Actions: (
                    <div>
                        <span className="approve" onClick={() => handleChange(item?.MerchantId, false)}>Approve</span>
                        <span className="reject" onClick={() => handleRejectChange(item?.MerchantId, true)}>Reject</span>
                    </div>
                )
            };
        });
        return formattedData;
    };

    return (
        <>
            <div className="customScroll d-flex justify-space-between overflow-y-hidden" >
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={
                        handleAutoCompleteCreateChange
                    }
                    showFilter={false}
                    showCreateBtn={false}
                    showSearch={true}
                    handleSearchChange={handleSearchChange}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    searchValue={searchValue}
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>
            <ListTable
                header={[
                    { name: "Business Name" },
                    { name: "Email" },
                    { name: "Onboard Status" },
                    { name: "Date Onboarded" },
                    { name: "Action" },
                ]}
                isSearching={getRequestLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow
                                key={index}
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name || "N/A"}
                                </TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell className='font-italic' align="left">{row.status}</TableCell>
                                <TableCell align="left">
                                    {row.date}
                                </TableCell>
                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {format()?.length > 0 ? (
                <div className="paginate-content">
                    <div  >
                        Showing Page {currentPage} of {Math.ceil(data?.length / itemsPerPage)}
                </div>
                <div>
                    <Pagination
                            totalPages={Math.ceil(data?.length / itemsPerPage)}
                        currentPage={currentPage}
                        changeCurrentPage={(p) => getPaginationNumber(p)}
                        previousBtn="Prev"
                        nextBtn="Next"
                    />
                </div>
                </div>) : null}
            {toggleModal && <ToggleModal
                title={"Approve?"
                }
                toggleForm={toggleModal}
                onToggle={() => handleApprove()}
                toggling={approveRequestLoading}
                disableToggle={approveRequestLoading}
                hideToggleModal={() => setToggleModal(false)}
                toggleText={"Approve"}
                textContent={
                    "Are you sure you want to approve this item?"
                }
            />}

            {rejectModal && <ToggleModal
                title={
                    "Reject?"
                }
                toggleForm={rejectModal}
                onToggle={() => handleReject()}
                toggling={rejectRequestLoading}
                disableToggle={rejectRequestLoading}
                hideToggleModal={() => setRejectModal(false)}
                toggleText={"Disable"}
                textContent={"Are you sure you want to reject this item?"}
            />}
        </>
    )
}

export default Request;