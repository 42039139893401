import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {};

const merchantsReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_ALL_MERCHANTS):
      return {
        ...state,
        gettingMerchantsLoading: true,
        gettingMerchantsPayload: null,
        gettingMerchantsError: null,
      };
    case SUCCESS(actionTypes.GET_ALL_MERCHANTS):
      return {
        ...state,
        gettingMerchantsPayload: actions.payload,
        gettingMerchantsError: null,
        gettingMerchantsLoading: false,
      };
    case FAILURE(actionTypes.GET_ALL_MERCHANTS):
      return {
        ...state,
        gettingMerchantsError: actions.payload,
        gettingMerchantsPayload: null,
        gettingMerchantsLoading: false,
      };
    case REQUEST(actionTypes.GET_MERCHANT_INFO):
      return {
        ...state,
        gettingMerchantInfoLoading: true,
        gettingMerchantInfoPayload: null,
        gettingMerchantInfoError: null,
      };
    case SUCCESS(actionTypes.GET_MERCHANT_INFO):
      return {
        ...state,
        gettingMerchantInfoPayload: actions.payload,
        gettingMerchantInfoError: null,
        gettingMerchantInfoLoading: false,
      };
    case FAILURE(actionTypes.GET_MERCHANT_INFO):
      return {
        ...state,
        gettingMerchantInfoError: actions.payload,
        gettingMerchantInfoPayload: null,
        gettingMerchantInfoLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_MERCHANT):
      return {
        ...state,
        togglingMerchantLoading: true,
        togglingMerchantPayload: null,
        togglingMerchantError: null,
      };
    case SUCCESS(actionTypes.TOGGLE_MERCHANT):
      return {
        ...state,
        togglingMerchantPayload: actions.payload,
        togglingMerchantError: null,
        togglingMerchantLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_MERCHANT):
      return {
        ...state,
        togglingMerchantError: actions.payload,
        togglingMerchantPayload: null,
        togglingMerchantLoading: false,
      };
    case REQUEST(actionTypes.DEPOSIT_MERCHANT):
      return {
        ...state,
        depositingMerchantLoading: true,
        depositingMerchantPayload: null,
        depositingMerchantError: null,
      };
    case SUCCESS(actionTypes.DEPOSIT_MERCHANT):
      return {
        ...state,
        depositingMerchantPayload: actions.payload,
        depositingMerchantError: null,
        depositingMerchantLoading: false,
      };
    case FAILURE(actionTypes.DEPOSIT_MERCHANT):
      return {
        ...state,
        depositingMerchantError: actions.payload,
        depositingMerchantPayload: null,
        depositingMerchantLoading: false,
      };
    case REQUEST(actionTypes.WITHDRAW_MERCHANT):
      return {
        ...state,
        withdrawingMerchantLoading: true,
        withdrawingMerchantPayload: null,
        withdrawingMerchantError: null,
      };
    case SUCCESS(actionTypes.WITHDRAW_MERCHANT):
      return {
        ...state,
        withdrawingMerchantPayload: actions.payload,
        withdrawingMerchantError: null,
        withdrawingMerchantLoading: false,
      };
    case FAILURE(actionTypes.WITHDRAW_MERCHANT):
      return {
        ...state,
        withdrawingMerchantError: actions.payload,
        withdrawingMerchantPayload: null,
        withdrawingMerchantLoading: false,
      };
    default:
      return state;
  }
};

export default merchantsReducer;
