import React, { useEffect, useState } from "react";
import Select from "react-select";

const Form = ({ setPayload, payload, submitStatus, getCurrencyPayload }) => {
  const [marginCurrency, setMarginCurrency] = useState([]);

  useEffect(() => {
    if (payload?.DestinationCurrencyPayload && payload?.SourceCurrencyPayload) {
      setPayload({
        ...payload,
        marginCurrencyCode: null,
        MarginCurrencyPayload: null,
      });
      setMarginCurrency([
        payload?.SourceCurrencyPayload,
        payload?.DestinationCurrencyPayload,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.DestinationCurrencyPayload, payload?.SourceCurrencyPayload]);

  console.log({ marginCurrency });
  return (
    <form className="create-form">
      <div>
        <label htmlFor="from">From</label>
        <Select
          value={payload?.SourceCurrencyPayload}
          onChange={(e) =>
            setPayload({
              ...payload,
              sourceCurrencyCode: e.value,
              SourceCurrencyPayload: e,
            })
          }
          options={getCurrencyPayload?.data?.responseData?.items?.map(
            (val) => ({ value: val?.currencyCode, label: val?.name })
          )}
        />
      </div>
      {submitStatus && !payload?.sourceCurrencyCode && (
        <div className="error-desc">From is required</div>
      )}

      <div>
        <label htmlFor="from">To</label>
        <Select
          value={payload?.DestinationCurrencyPayload}
          onChange={(e) =>
            setPayload({
              ...payload,
              destinationCurrencyCode: e.value,
              DestinationCurrencyPayload: e,
            })
          }
          options={getCurrencyPayload?.data?.responseData?.items?.map(
            (val) => ({ value: val?.currencyCode, label: val?.name })
          )}
        />
      </div>
      {submitStatus && !payload?.destinationCurrencyCode && (
        <div className="error-desc">To is required</div>
      )}
      {submitStatus &&
        payload?.destinationCurrencyCode &&
        payload?.sourceCurrencyCode === payload?.destinationCurrencyCode && (
          <div className="error-desc">You can't use the same currency</div>
        )}

      <div>
        <label htmlFor="rate">Rate</label>
        <input
          value={payload.rate}
          onChange={(e) => setPayload({ ...payload, rate: e.target.value })}
          type="number"
          className="form-control"
          id="rate"
        />
      </div>
      {!payload?.rate && submitStatus && (
        <div className="error-desc">Rate is required</div>
      )}
      {payload?.rate && submitStatus && payload?.rate < 0 && (
        <div className="error-desc">Valid Rate is required</div>
      )}

      <div>
        <label htmlFor="rate">Margin</label>
        <input
          value={payload.margin}
          onChange={(e) => setPayload({ ...payload, margin: e.target.value })}
          type="number"
          className="form-control"
          id="Margin"
        />
      </div>
      {!payload?.margin && submitStatus && (
        <div className="error-desc">Margin is required</div>
      )}

      {marginCurrency?.length >= 2 && (
        <>
          <div>
            <label htmlFor="from">Margin Currency</label>
            <Select
              value={payload?.MarginCurrencyCode}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  marginCurrencyCode: e.value,
                  MarginCurrencyPayload: e,
                })
              }
              options={marginCurrency?.map((val) => ({
                value: val?.value,
                label: val?.label,
              }))}
            />
          </div>
          {submitStatus && !payload?.marginCurrencyCode && (
            <div className="error-desc">Margin Currency is required</div>
          )}
        </>
      )}
    </form>
  );
};

export default Form;
