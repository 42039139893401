import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

const Reducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.GET_SMART_CHECK_REQUEST):
      return {
        ...state,
        getRequestError: null,
        getRequestLoading: true,
        getRequestPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_REQUEST):
      return {
        ...state,
        getRequestError: null,
        getRequestLoading: false,
        getRequestPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_REQUEST):
      return {
        ...state,
        getRequestError: actions.payload,
        getRequestLoading: false,
        getRequestPayload: null,
      };
    case REQUEST(actionTypes.APPROVE_SMART_CHECK_REQUEST):
      return {
        ...state,
        approveRequestError: null,
        approveRequestLoading: true,
        approveRequestPayload: null,
      };
    case SUCCESS(actionTypes.APPROVE_SMART_CHECK_REQUEST):
      return {
        ...state,
        approveRequestError: null,
        approveRequestLoading: false,
        approveRequestPayload: actions.payload,
      };
    case FAILURE(actionTypes.APPROVE_SMART_CHECK_REQUEST):
      return {
        ...state,
        approveRequestError: actions.payload,
        approveRequestLoading: false,
        approveRequestPayload: null,
      };
    case REQUEST(actionTypes.REJECT_SMART_CHECK_REQUEST):
      return {
        ...state,
        rejectRequestError: null,
        rejectRequestLoading: true,
        rejectRequestPayload: null,
      };
    case SUCCESS(actionTypes.REJECT_SMART_CHECK_REQUEST):
      return {
        ...state,
        rejectRequestError: null,
        rejectRequestLoading: false,
        rejectRequestPayload: actions.payload,
      };
    case FAILURE(actionTypes.REJECT_SMART_CHECK_REQUEST):
      return {
        ...state,
        rejectRequestError: actions.payload,
        rejectRequestLoading: false,
        rejectRequestPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_MERCHANTS):
      return {
        ...state,
        getMerchantsError: null,
        getMerchantsLoading: true,
        getMerchantsPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_MERCHANTS):
      return {
        ...state,
        getMerchantsError: null,
        getMerchantsLoading: false,
        getMerchantsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_MERCHANTS):
      return {
        ...state,
        getMerchantsError: actions.payload,
        getMerchantsLoading: false,
        getMerchantsPayload: null,
      };
    case REQUEST(actionTypes.TOGGLE_SMART_CHECK_MERCHANTS_STATUS):
      return {
        ...state,
        toggleMerchantsStatusError: null,
        toggleMerchantsStatusLoading: true,
        toggleMerchantsStatusPayload: null,
      };
    case SUCCESS(actionTypes.TOGGLE_SMART_CHECK_MERCHANTS_STATUS):
      return {
        ...state,
        toggleMerchantsStatusError: null,
        toggleMerchantsStatusLoading: false,
        toggleMerchantsStatusPayload: actions.payload,
      };
    case FAILURE(actionTypes.TOGGLE_SMART_CHECK_MERCHANTS_STATUS):
      return {
        ...state,
        toggleMerchantsStatusError: actions.payload,
        toggleMerchantsStatusLoading: false,
        toggleMerchantsStatusPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        getMerchantsSubscriptionsError: null,
        getMerchantsSubscriptionsLoading: true,
        getMerchantsSubscriptionsPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        getMerchantsSubscriptionsError: null,
        getMerchantsSubscriptionsLoading: false,
        getMerchantsSubscriptionsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        getMerchantsSubscriptionsError: actions.payload,
        getMerchantsSubscriptionsLoading: false,
        getMerchantsSubscriptionsPayload: null,
      };
    case REQUEST(actionTypes.UPDATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        updateMerchantsSubscriptionsError: null,
        updateMerchantsSubscriptionsLoading: true,
        updateMerchantsSubscriptionsPayload: null,
      };
    case SUCCESS(actionTypes.UPDATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        updateMerchantsSubscriptionsError: null,
        updateMerchantsSubscriptionsLoading: false,
        updateMerchantsSubscriptionsPayload: actions.payload,
      };
    case FAILURE(actionTypes.UPDATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        updateMerchantsSubscriptionsError: actions.payload,
        updateMerchantsSubscriptionsLoading: false,
        updateMerchantsSubscriptionsPayload: null,
      };
    case REQUEST(actionTypes.CREATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        createMerchantsSubscriptionsError: null,
        createMerchantsSubscriptionsLoading: true,
        createMerchantsSubscriptionsPayload: null,
      };
    case SUCCESS(actionTypes.CREATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        createMerchantsSubscriptionsError: null,
        createMerchantsSubscriptionsLoading: false,
        createMerchantsSubscriptionsPayload: actions.payload,
      };
    case FAILURE(actionTypes.CREATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS):
      return {
        ...state,
        createMerchantsSubscriptionsError: actions.payload,
        createMerchantsSubscriptionsLoading: false,
        createMerchantsSubscriptionsPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_PRICING_ID):
      return {
        ...state,
        getSubscriptionsPlanPricingError: null,
        getSubscriptionsPlanPricingLoading: true,
        getSubscriptionsPlanPricingPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_PRICING_ID):
      return {
        ...state,
        getSubscriptionsPlanPricingError: null,
        getSubscriptionsPlanPricingLoading: false,
        getSubscriptionsPlanPricingPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_PRICING_ID):
      return {
        ...state,
        getSubscriptionsPlanPricingError: actions.payload,
        getSubscriptionsPlanPricingLoading: false,
        getSubscriptionsPlanPricingPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        getSubscriptionsPlanError: null,
        getSubscriptionsPlanLoading: true,
        getSubscriptionsPlanPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        getSubscriptionsPlanError: null,
        getSubscriptionsPlanLoading: false,
        getSubscriptionsPlanPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        getSubscriptionsPlanError: actions.payload,
        getSubscriptionsPlanLoading: false,
        getSubscriptionsPlanPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_LIST):
      return {
        ...state,
        getSubscriptionsPlanListError: null,
        getSubscriptionsPlanListLoading: true,
        getSubscriptionsPlanListPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_LIST):
      return {
        ...state,
        getSubscriptionsPlanListError: null,
        getSubscriptionsPlanListLoading: false,
        getSubscriptionsPlanListPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_LIST):
      return {
        ...state,
        getSubscriptionsPlanListError: actions.payload,
        getSubscriptionsPlanListLoading: false,
        getSubscriptionsPlanListPayload: null,
      };
    case REQUEST(actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        updateSubscriptionsPlanError: null,
        updateSubscriptionsPlanLoading: true,
        updateSubscriptionsPlanPayload: null,
      };
    case SUCCESS(actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        updateSubscriptionsPlanError: null,
        updateSubscriptionsPlanLoading: false,
        updateSubscriptionsPlanPayload: actions.payload,
      };
    case FAILURE(actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        updateSubscriptionsPlanError: actions.payload,
        updateSubscriptionsPlanLoading: false,
        updateSubscriptionsPlanPayload: null,
      };
    case REQUEST(actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        createSubscriptionsPlanError: null,
        createSubscriptionsPlanLoading: true,
        createSubscriptionsPlanPayload: null,
      };
    case SUCCESS(actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        createSubscriptionsPlanError: null,
        createSubscriptionsPlanLoading: false,
        createSubscriptionsPlanPayload: actions.payload,
      };
    case FAILURE(actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PLAN):
      return {
        ...state,
        createSubscriptionsPlanError: actions.payload,
        createSubscriptionsPlanLoading: false,
        createSubscriptionsPlanPayload: null,
      };
    case REQUEST(actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST):
      return {
        ...state,
        updateSubscriptionsPricingListError: null,
        updateSubscriptionsPricingListLoading: true,
        updateSubscriptionsPricingListPayload: null,
      };
    case SUCCESS(actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST):
      return {
        ...state,
        updateSubscriptionsPricingListError: null,
        updateSubscriptionsPricingListLoading: false,
        updateSubscriptionsPricingListPayload: actions.payload,
      };
    case FAILURE(actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST):
      return {
        ...state,
        updateSubscriptionsPricingListError: actions.payload,
        updateSubscriptionsPricingListLoading: false,
        updateSubscriptionsPricingListPayload: null,
      };
    case REQUEST(actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST):
      return {
        ...state,
        createSubscriptionsPricingListError: null,
        createSubscriptionsPricingListLoading: true,
        createSubscriptionsPricingListPayload: null,
      };
    case SUCCESS(actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST):
      return {
        ...state,
        createSubscriptionsPricingListError: null,
        createSubscriptionsPricingListLoading: false,
        createSubscriptionsPricingListPayload: actions.payload,
      };
    case FAILURE(actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST):
      return {
        ...state,
        createSubscriptionsPricingListError: actions.payload,
        createSubscriptionsPricingListLoading: false,
        createSubscriptionsPricingListPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_SERVICES):
      return {
        ...state,
        getServicesError: null,
        getServicesLoading: true,
        getServicesPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_SERVICES):
      return {
        ...state,
        getServicesError: null,
        getServicesLoading: false,
        getServicesPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_SERVICES):
      return {
        ...state,
        getServicesError: actions.payload,
        getServicesLoading: false,
        getServicesPayload: null,
      };
    case REQUEST(actionTypes.ADD_NEW_SMART_CHECK_SERVICES):
      return {
        ...state,
        addNewServicesError: null,
        addNewServicesLoading: true,
        addNewServicesPayload: null,
      };
    case SUCCESS(actionTypes.ADD_NEW_SMART_CHECK_SERVICES):
      return {
        ...state,
        addNewServicesError: null,
        addNewServicesLoading: false,
        addNewServicesPayload: actions.payload,
      };
    case FAILURE(actionTypes.ADD_NEW_SMART_CHECK_SERVICES):
      return {
        ...state,
        addNewServicesError: actions.payload,
        addNewServicesLoading: false,
        addNewServicesPayload: null,
      };
    case REQUEST(actionTypes.UPDATE_SMART_CHECK_SERVICES):
      return {
        ...state,
        updateServicesError: null,
        updateServicesLoading: true,
        updateServicesPayload: null,
      };
    case SUCCESS(actionTypes.UPDATE_SMART_CHECK_SERVICES):
      return {
        ...state,
        updateServicesError: null,
        updateServicesLoading: false,
        updateServicesPayload: actions.payload,
      };
    case FAILURE(actionTypes.UPDATE_SMART_CHECK_SERVICES):
      return {
        ...state,
        updateServicesError: actions.payload,
        updateServicesLoading: false,
        updateServicesPayload: null,
      };
    case REQUEST(actionTypes.GET_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        getCountryDocsError: null,
        getCountryDocsLoading: true,
        getCountryDocsPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        getCountryDocsError: null,
        getCountryDocsLoading: false,
        getCountryDocsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        getCountryDocsError: actions.payload,
        getCountryDocsLoading: false,
        getCountryDocsPayload: null,
      };
    case REQUEST(actionTypes.ADD_NEW_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        addNewCountryDocsError: null,
        addNewCountryDocsLoading: true,
        addNewCountryDocsPayload: null,
      };
    case SUCCESS(actionTypes.ADD_NEW_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        addNewCountryDocsError: null,
        addNewCountryDocsLoading: false,
        addNewCountryDocsPayload: actions.payload,
      };
    case FAILURE(actionTypes.ADD_NEW_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        addNewCountryDocsError: actions.payload,
        addNewCountryDocsLoading: false,
        addNewCountryDocsPayload: null,
      };
    case REQUEST(actionTypes.UPDATE_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        updateCountryDocsError: null,
        updateCountryDocsLoading: true,
        updateCountryDocsPayload: null,
      };
    case SUCCESS(actionTypes.UPDATE_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        updateCountryDocsError: null,
        updateCountryDocsLoading: false,
        updateCountryDocsPayload: actions.payload,
      };
    case FAILURE(actionTypes.UPDATE_SMART_CHECK_COUNTRY_DOCS):
      return {
        ...state,
        updateCountryDocsError: actions.payload,
        updateCountryDocsLoading: false,
        updateCountryDocsPayload: null,
      };
    case REQUEST(actionTypes.TOGGLE_SMART_CHECK_COUNTRY_DOCS_STATUS):
      return {
        ...state,
        toggleCountryStatusError: null,
        toggleCountryStatusLoading: true,
        toggleCountryStatusPayload: null,
      };
    case SUCCESS(actionTypes.TOGGLE_SMART_CHECK_COUNTRY_DOCS_STATUS):
      return {
        ...state,
        toggleCountryStatusError: null,
        toggleCountryStatusLoading: false,
        toggleCountryStatusPayload: actions.payload,
      };
    case FAILURE(actionTypes.TOGGLE_SMART_CHECK_COUNTRY_DOCS_STATUS):
      return {
        ...state,
        toggleCountryStatusError: actions.payload,
        toggleCountryStatusLoading: false,
        toggleCountryStatusPayload: null,
      };

    case REQUEST(actionTypes.GET_SMART_CHECK_DOCUMENTS):
      return {
        ...state,
        getDocumentsError: null,
        getDocumentsLoading: true,
        getDocumentsPayload: null,
      };
    case SUCCESS(actionTypes.GET_SMART_CHECK_DOCUMENTS):
      return {
        ...state,
        getDocumentsError: null,
        getDocumentsLoading: false,
        getDocumentsPayload: actions.payload,
      };
    case FAILURE(actionTypes.GET_SMART_CHECK_DOCUMENTS):
      return {
        ...state,
        getDocumentsError: actions.payload,
        getDocumentsLoading: false,
        getDocumentsPayload: null,
      };
    case REQUEST(actionTypes.ADD_NEW_SMART_CHECK_DOCUMENT):
      return {
        ...state,
        addNewDocumentsError: null,
        addNewDocumentsLoading: true,
        addNewDocumentsPayload: null,
      };
    case SUCCESS(actionTypes.ADD_NEW_SMART_CHECK_DOCUMENT):
      return {
        ...state,
        addNewDocumentsError: null,
        addNewDocumentsLoading: false,
        addNewDocumentsPayload: actions.payload,
      };
    case FAILURE(actionTypes.ADD_NEW_SMART_CHECK_DOCUMENT):
      return {
        ...state,
        addNewDocumentsError: actions.payload,
        addNewDocumentsLoading: false,
        addNewDocumentsPayload: null,
      };
    case REQUEST(actionTypes.UPDATE_SMART_CHECK_DOCUMENT):
      return {
        ...state,
        updateDocumentsError: null,
        updateDocumentsLoading: true,
        updateDocumentsPayload: null,
      };
    case SUCCESS(actionTypes.UPDATE_SMART_CHECK_DOCUMENT):
      return {
        ...state,
        updateDocumentsError: null,
        updateDocumentsLoading: false,
        updateDocumentsPayload: actions.payload,
      };
    case FAILURE(actionTypes.UPDATE_SMART_CHECK_DOCUMENT):
      return {
        ...state,
        updateDocumentsError: actions.payload,
        updateDocumentsLoading: false,
        updateDocumentsPayload: null,
      };
    default:
      return state;
  }
};

export default Reducer;
