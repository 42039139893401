import React from "react";
import "./merchants.scss";
import ModalLoading from "components/ModalLoading/ModalLoading";
import { PAYOUT_TYPES } from "utils/common";

const ManageMerchantForm = ({
  payload,
  onChange,
  submitStatus,
  countries,
  currencies,
  step,
  fetchingConfig,
}) => {
  if (fetchingConfig) return <ModalLoading />;
  if (step === 1)
    return (
      <form className="create-form form-height">
        <div className="mb-3">
          <label htmlFor="countryCode">Country</label>
          <select
            className="form-control"
            value={payload.countryCode}
            onChange={(e) => onChange(e)}
            placeholder="Select Country"
            label="Country"
            name="countryCode"
            id="countryCode"
          >
            <option disabled value="">
              Select an option
            </option>
            {countries?.map((country, idx) => (
              <option key={country?.label + idx} value={country?.value}>
                {country?.label}
              </option>
            ))}
          </select>
          {submitStatus && !payload?.countryCode && (
            <div className="error-desc">Country is required</div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="payoutType">Payment Channel</label>
          <select
            className="form-control"
            value={payload.payoutType}
            onChange={(e) => onChange(e)}
            placeholder="Select Payment Channel"
            label="Payment Channel"
            name="payoutType"
            id="payoutType"
          >
            <option disabled value="">
              Select an option
            </option>
            {PAYOUT_TYPES?.map((channel, idx) => (
              <option key={channel?.label + idx} value={channel?.value}>
                {channel?.label}
              </option>
            ))}
          </select>
          {submitStatus && !payload?.countryCode && (
            <div className="error-desc">Payment Channel is required</div>
          )}
        </div>
      </form>
    );
  if (step === 2)
    return (
      <form className="create-form payout-step-2">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <label htmlFor="chargeCurrency">Standard Charge Currency</label>
            <select
              className="form-control"
              value={payload.chargeCurrency}
              onChange={(e) => onChange(e)}
              placeholder="Select Standard Charge Currency"
              label="Standard Charge Currency"
              name="chargeCurrency"
              id="chargeCurrency"
            >
              <option disabled value="">
                Select an option
              </option>
              {currencies?.map((currency, idx) => (
                <option
                  key={`${currency?.value}${idx}`}
                  value={currency?.value}
                >
                  {currency?.label}
                </option>
              ))}
            </select>
            {submitStatus && !payload?.chargeCurrency && (
              <div className="error-desc">
                Standard Charge Currency is required
              </div>
            )}
          </div>

          <div className="col-12 col-md-6">
            <label htmlFor="localCharge">Local Flat Fee</label>
            <input
              type="number"
              name="localCharge"
              value={payload.localCharge}
              onChange={onChange}
              className="form-control"
              id="localCharge"
              placeholder="#2,000"
            />
            {submitStatus && !payload?.localCharge && (
              <div className="error-desc">Local Flat Fee is required</div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <label htmlFor="chargeValue">Standard Flat Fee</label>
            <input
              type="number"
              name="chargeValue"
              value={payload.chargeValue}
              onChange={onChange}
              className="form-control"
              id="chargeValue"
              placeholder="#2,000"
            />

            {submitStatus && !payload?.chargeValue && (
              <div className="error-desc">Standard Flat Fee is required</div>
            )}
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="localPercent">Local Percentage Fee</label>
            <input
              type="number"
              name="localPercent"
              value={payload.localPercent}
              onChange={onChange}
              className="form-control"
              id="localPercent"
              placeholder="5%"
            />

            {submitStatus && !payload?.localPercent && (
              <div className="error-desc">Local Percentage Fee is required</div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <label htmlFor="chargePercent">Standard Percentage Fee</label>
            <input
              type="number"
              name="chargePercent"
              value={payload.chargePercent}
              onChange={onChange}
              className="form-control"
              id="chargePercent"
              placeholder="5%"
            />
            {submitStatus && !payload?.chargePercent && (
              <div className="error-desc">
                Standard Percentage Fee is required
              </div>
            )}
          </div>

          <div className="col-12 col-md-6">
            <label htmlFor="localCap">Local Cap</label>
            <input
              type="number"
              name="localCap"
              value={payload.localCap}
              onChange={onChange}
              className="form-control"
              id="localCap"
              placeholder="#20,000"
            />
            {submitStatus && !payload?.localCap && (
              <div className="error-desc">Local Cap is required</div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <label htmlFor="chargeCap">Standard Cap</label>
            <input
              type="number"
              name="chargeCap"
              value={payload.chargeCap}
              onChange={onChange}
              className="form-control"
              id="chargeCap"
              placeholder="#2,000"
            />
            {submitStatus && !payload?.chargeCap && (
              <div className="error-desc">Standard Cap is required</div>
            )}
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="defaultPricing">Default Configuration</label>
            <select
              className="form-control"
              value={payload.defaultPricing}
              onChange={(e) => onChange(e)}
              placeholder="None"
              name="defaultPricing"
              id="defaultPricing"
            >
              <option disabled value="">
                Select an option
              </option>
              {["NONE", "STANDARD", "LOCAL"]?.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            {/* {submitStatus && !payload?.defaultPricing && (
              <div className="error-desc">
                Default Configuration is required
              </div>
            )} */}
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-12 col-md-6">
            <label htmlFor="TransactionLimitAmount">Transaction Limit Amount</label>
            <input
              type="number"
              name="TransactionLimitAmount"
              value={payload.TransactionLimitAmount}
              onChange={onChange}
              className="form-control"
              id="TransactionLimitAmount"
              placeholder="#2,000"
            />
            {submitStatus && !payload?.TransactionLimitAmount && (
              <div className="error-desc">Transaction Limit Amount is required</div>
            )}
          </div>
          
        </div> */}
      </form>
    );

  return null;
};

export default ManageMerchantForm;
