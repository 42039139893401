import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Aux from "../../components/hoc/_Aux";
import Request from "./Request/request";
import Merchants from "./Merchants/merchants";
import Subscription from "./Subscription/subscription";
import Services from "./Services/services";
import SubscriptionPlan from "./SubscriptionPlan/subscriptionPlan";
import Country from "./Country";
import Document from "./Document";
import "./smartCheck.scss";

const SmartCheck = () => {
  const [tab, setTab] = useState("request");

  return (
    <Aux>
      <Row className="smart-check-container">
        <Col>
          <div className="content-header">
            <div className="service-name">SmartCheck</div>
            <div className="service-items">
              <div
                className={`item ${tab === "request" && "active"}`}
                onClick={() => setTab("request")}>
                Request
              </div>
              <div
                className={`item ${tab === "merchants" && "active"}`}
                onClick={() => setTab("merchants")}>
                Merchants
              </div>
              <div
                className={`item ${tab === "subscription" && "active"}`}
                onClick={() => setTab("subscription")}>
                Subscriptions
              </div>
              <div
                className={`item ${tab === "subscription-plan" && "active"}`}
                onClick={() => setTab("subscription-plan")}>
                Subscription Plan
              </div>
              <div
                className={`item ${tab === "services" && "active"}`}
                onClick={() => setTab("services")}>
                Services
              </div>
              <div
                className={`item ${tab === "country" && "active"}`}
                onClick={() => setTab("country")}>
                Country
              </div>
              <div
                className={`item ${tab === "document" && "active"}`}
                onClick={() => setTab("document")}>
                Document
              </div>
            </div>
          </div>
          <div className="tab-items">
            {tab === "subscription" && <Subscription />}
            {tab === "request" && <Request />}
            {tab === "merchants" && <Merchants />}
            {tab === "services" && <Services />}
            {tab === "subscription-plan" && <SubscriptionPlan />}
            {tab === "country" && <Country />}
            {tab === "document" && <Document />}
          </div>
        </Col>
      </Row>
    </Aux>
  );
};

export default SmartCheck;
