import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom'
import ListTable from "../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import Pagination from '../../components/paginations/Paginations';
import Aux from 'components/hoc/_Aux';
import { Col, Row } from 'react-bootstrap';
import "./Currency.scss"
import { createCurrency, getCurrency, toggleCurrency } from 'logic/actions/requests';
import { errorMessage, successMessage } from 'logic/actions/notification';
import Switch from 'react-switch';
import ToggleModal from 'components/Modal/ToggleModal';
import CreateModal from 'components/Modal/CreateModal';
import AddCurrencyForm from 'components/Modal/AddCurrencyForm';

const Currency = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    // const history = useHistory();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false)
    const [activeRow, setActiveRow] = useState(null)
    const [submitted, setSubmitted] = React.useState(false);
    const [formData, setFormData] = useState({});

    const { getCurrencyLoading, getCurrencyPayload, toggleCurrencyLoading, toggleCurrencyPayload, addCurrencyLoading, addCurrencyPayload } = useSelector(({ virtualAccountReducer }) => {
        return {
            getCurrencyPayload: virtualAccountReducer?.getCurrencyPayload,
            getCurrencyLoading: virtualAccountReducer?.getCurrencyLoading,
            toggleCurrencyLoading: virtualAccountReducer?.toggleCurrencyLoading,
            toggleCurrencyPayload: virtualAccountReducer?.toggleCurrencyPayload,
            addCurrencyLoading: virtualAccountReducer?.addCurrencyLoading,
            addCurrencyPayload: virtualAccountReducer?.addCurrencyPayload,
        };
    });

    const getPaginationNumber = (page) => {
        setCurrentPage(page)
        dispatch(getCurrency(10, page))
    }

    useEffect(() => {
        if (!addCurrencyLoading && addCurrencyPayload?.requestSuccessful) {
            dispatch(
                successMessage(addCurrencyPayload?.message || "Added successfully")
            );
            setSubmitted(false);
            setOpenAddModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCurrencyLoading]);

    useEffect(() => {
        if (
            submitted &&
            !addCurrencyLoading &&
            !addCurrencyPayload?.requestSuccessful
        ) {
            dispatch(
                errorMessage(
                    addCurrencyPayload?.message ||
                    "An error occurred when performing this action"
                )
            );
            setSubmitted(false);
            setOpenModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCurrencyLoading]);

    const submitForm = (e, formData) => {
        e.preventDefault();
        setSubmitted(true);
        dispatch(createCurrency(formData));
    };



    const handleSearchChange = (event, input) => {
    };

    useEffect(() => {
        dispatch(getCurrency(10, 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!getCurrencyLoading && getCurrencyPayload) {
            setData(getCurrencyPayload?.data?.responseData?.items)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrencyLoading]);

    useEffect(() => {
        if (!toggleCurrencyLoading && activeRow && toggleCurrencyPayload?.data?.requestSuccessful) {
            dispatch(successMessage(toggleCurrencyPayload?.data?.message || "Enabled successfully"))
            setOpenModal(false)
            dispatch(getCurrency(10, currentPage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleCurrencyLoading]);
    useEffect(() => {
        if (!toggleCurrencyLoading && activeRow && !toggleCurrencyPayload?.data?.requestSuccessful) {
            dispatch(errorMessage(toggleCurrencyPayload?.data?.message || "An error occured when performing this operation"))
            dispatch(getCurrency(10, currentPage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleCurrencyLoading]);

    return (
        <Aux>
            <Row className="currency-container">
                <Col>
                    <div className="content-header d-flex justify-content-between mb-3">
                        <div className="service-name">Currency</div>

                    </div>

                    {openAddModal && (
                        <CreateModal
                            title="Add New Currency"
                            createForm={openAddModal}
                            hideCreateForm={() => setOpenAddModal(false)}
                            onCreate={(e) => submitForm(e, formData)}
                            creating={addCurrencyLoading}
                            disableCreate={addCurrencyLoading}
                            cancelText="Cancel"
                            createText="Create"
                            content={<AddCurrencyForm formData={formData} setFormData={setFormData} submitForm={submitForm} />}
                        />
                    )}
                    <div className="tab-items">

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                overflowY: "hidden",
                            }}
                            className="customScroll"
                        >
                            <FilterMenu
                                caption="Clients List"
                                totalCount={10}
                                searchLabel="Search.."
                                filterOptions={clientsFilter}
                                filterdefaultValueOptions={{
                                    label: "All",
                                    value: "all",
                                }}
                                handleAutoCompleteCreateChange={
                                    () => { }
                                }
                                showFilter={false}
                                showSearch={true}
                                showCreateBtn={true}
                                btnName="+&nbsp;&nbsp;New Currency"
                                openCreate={() => setOpenAddModal(true)}
                                handleSearchChange={handleSearchChange}
                                showFilterMenu={false}
                                filterLabel="Filter by"
                                searchName="keyword"
                                searchValue={""}
                                clearSearch={() => { }}
                                entriesName="Entries"
                                filterValue={"Search"}
                                entriesOptions={{}}
                                entriesdefaultValueOptions={{
                                    label: `Entries`,
                                    value: 20,
                                }}
                                entriesValue={{}}
                                entriesLabel="Entries"
                            />
                        </div>

                        <ListTable
                            header={[
                                { name: "Name" },
                                { name: "Currency Code" },
                                { name: "Numeric Code" },
                                { name: "Actions" },
                            ]}
                            isSearching={getCurrencyLoading || toggleCurrencyLoading}
                            showError={false}
                            style={{ opacity: 1 }}
                            pageOfItems={data}
                            content={
                                <TableBody>
                                    {data?.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.currencyCode}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.numericCode}
                                            </TableCell>
                                            <TableCell align="left">
                                                <div>
                                                    <Switch onChange={() => { setOpenModal(true); setActiveRow(row) }} onColor="#175FFF" checked={row?.isEnabled} uncheckedIcon={false} checkedIcon={false} />
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        />
                        {openModal && <ToggleModal
                            title={
                                activeRow.isEnabled ? "Disable item?" : "Enable Item?"
                            }
                            toggleForm={openModal}
                            onToggle={() => dispatch(toggleCurrency(activeRow.id))}
                            toggling={toggleCurrencyLoading}
                            disableToggle={toggleCurrencyLoading}
                            hideToggleModal={() => setOpenModal(false)}
                            toggleText={activeRow.isEnabled ? "Disable" : "Enable"}
                            textContent={
                                activeRow.isEnabled
                                    ? "Are you sure you want to disable this item?"
                                    : "Are you sure you want to enable this item?"
                            }
                        />}
                        <br />
                        {data?.length > 0 ? <div className="paginate-content">
                            <div  >
                                Showing Page {getCurrencyPayload?.data?.responseData?.pageNumber} of {getCurrencyPayload?.data?.responseData?.pages}
                            </div>
                            <div>
                                <Pagination
                                    totalPages={getCurrencyPayload?.data?.responseData?.pages}
                                    currentPage={currentPage}
                                    changeCurrentPage={(p) => getPaginationNumber(p)}
                                    previousBtn="Prev"
                                    nextBtn="Next"
                                />
                            </div>
                        </div> : null}

                    </div>
                </Col>
            </Row>
        </Aux>

    )
}

export default Currency