import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

 
const collectReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.POST_COLLECT_CHARGEBACK):
      return { ...state, postChargeBackLoading: true, postChargeBackPayload:null, postChargeBackError:null };
    case SUCCESS(actionTypes.POST_COLLECT_CHARGEBACK):
      return {
        ...state,
        postChargeBackPayload: actions.payload,
        postChargeBackError: null,
        postChargeBackLoading: false,
      };
    case FAILURE(actionTypes.POST_COLLECT_CHARGEBACK):
      return {
        ...state,
        postChargeBackError: actions.payload,
        postChargeBackPayload: null,
        postChargeBackLoading: false,
      };
    case REQUEST(actionTypes.GET_COLLECT_CHARGEBACK):
      return { ...state, getChargeBackLoading: true, getChargeBackPayload:null, getChargeBackError:null };
    case SUCCESS(actionTypes.GET_COLLECT_CHARGEBACK):
      return {
        ...state,
        getChargeBackPayload: actions.payload,
        getChargeBackError: null,
        getChargeBackLoading: false,
      };
    case FAILURE(actionTypes.GET_COLLECT_CHARGEBACK):
      return {
        ...state,
        getChargeBackError: actions.payload,
        getChargeBackPayload: null,
        getChargeBackLoading: false,
      };
    case REQUEST(actionTypes.GET_CHANNEL):
      return { ...state, getChannelLoading: true, getChannelPayload:null, getChannelError:null };
    case SUCCESS(actionTypes.GET_CHANNEL):
      return {
        ...state,
        getChannelPayload: actions.payload,
        getChannelError: null,
        getChannelLoading: false,
      };
    case FAILURE(actionTypes.GET_CHANNEL):
      return {
        ...state,
        getChannelError: actions.payload,
        getChannelPayload: null,
        getChannelLoading: false,
      };
    case REQUEST(actionTypes.POST_CHANNEL):
      return { ...state, postChannelLoading: true, postChannelPayload:null, postChannelError:null };
    case SUCCESS(actionTypes.POST_CHANNEL):
      return {
        ...state,
        postChannelPayload: actions.payload,
        postChannelError: null,
        postChannelLoading: false,
      };
    case FAILURE(actionTypes.POST_CHANNEL):
      return {
        ...state,
        postChannelError: actions.payload,
        postChannelPayload: null,
        postChannelLoading: false,
      };
    case REQUEST(actionTypes.POST_COLLECT_MERCHANT):
      return { ...state, postMerchantLoading: true, postMerchantPayload:null, postMerchantError:null };
    case SUCCESS(actionTypes.POST_COLLECT_MERCHANT):
      return {
        ...state,
        postMerchantPayload: actions.payload,
        postMerchantError: null,
        postMerchantLoading: false,
      };
    case FAILURE(actionTypes.POST_COLLECT_MERCHANT):
      return {
        ...state,
        postMerchantError: actions.payload,
        postMerchantPayload: null,
        postMerchantLoading: false,
      };
    case REQUEST(actionTypes.GET_COLLECT_MERCHANT):
      return { ...state, getMerchantLoading: true, getMerchantPayload:null, getMerchantError:null };
    case SUCCESS(actionTypes.GET_COLLECT_MERCHANT):
      return {
        ...state,
        getMerchantPayload: actions.payload,
        getMerchantError: null,
        getMerchantLoading: false,
      };
    case FAILURE(actionTypes.GET_COLLECT_MERCHANT):
      return {
        ...state,
        getMerchantError: actions.payload,
        getMerchantPayload: null,
        getMerchantLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_COLLECT_MERCHANT):
      return { ...state, toggleMerchantLoading: true, toggleMerchantPayload:null, toggleMerchantError:null };
    case SUCCESS(actionTypes.TOGGLE_COLLECT_MERCHANT):
      return {
        ...state,
        toggleMerchantPayload: actions.payload,
        toggleMerchantError: null,
        toggleMerchantLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_COLLECT_MERCHANT):
      return {
        ...state,
        toggleMerchantError: actions.payload,
        toggleMerchantPayload: null,
        toggleMerchantLoading: false,
      };
    case REQUEST(actionTypes.TOGGLE_COLLECT_CHANNEL):
      return { ...state, toggleChannelLoading: true, toggleChannelPayload:null, toggleChannelError:null };
    case SUCCESS(actionTypes.TOGGLE_COLLECT_CHANNEL):
      return {
        ...state,
        toggleChannelPayload: actions.payload,
        toggleChannelError: null,
        toggleChannelLoading: false,
      };
    case FAILURE(actionTypes.TOGGLE_COLLECT_CHANNEL):
      return {
        ...state,
        toggleChannelError: actions.payload,
        toggleChannelPayload: null,
        toggleChannelLoading: false,
      };
    case REQUEST(actionTypes.PUT_CHANNEL):
      return { ...state, putChannelLoading: true, putChannelPayload:null, putChannelError:null };
    case SUCCESS(actionTypes.PUT_CHANNEL):
      return {
        ...state,
        putChannelPayload: actions.payload,
        putChannelError: null,
        putChannelLoading: false,
      };
    case FAILURE(actionTypes.PUT_CHANNEL):
      return {
        ...state,
        putChannelError: actions.payload,
        putChannelPayload: null,
        putChannelLoading: false,
      };
    case REQUEST(actionTypes.GET_COLLECT_COUNTRY):
      return { ...state, getCollectCountryLoading: true, getCollectCountryPayload:null, getCollectCountryError:null };
    case SUCCESS(actionTypes.GET_COLLECT_COUNTRY):
      return {
        ...state,
        getCollectCountryPayload: actions.payload,
        getCollectCountryError: null,
        getCollectCountryLoading: false,
      };
    case FAILURE(actionTypes.GET_COLLECT_COUNTRY):
      return {
        ...state,
        getCollectCountryError: actions.payload,
        getCollectCountryPayload: null,
        getCollectCountryLoading: false,
      };
    case REQUEST(actionTypes.MANAGE_COLLECT_MERCHANT):
      return { ...state, manageMerchantLoading: true, manageMerchantPayload:null, manageMerchantError:null };
    case SUCCESS(actionTypes.MANAGE_COLLECT_MERCHANT):
      return {
        ...state,
        manageMerchantPayload: actions.payload,
        manageMerchantError: null,
        manageMerchantLoading: false,
      };
    case FAILURE(actionTypes.MANAGE_COLLECT_MERCHANT):
      return {
        ...state,
        manageMerchantError: actions.payload,
        manageMerchantPayload: null,
        manageMerchantLoading: false,
      };
    case REQUEST(actionTypes.POST_COLLECT_COUNTRY):
      return { ...state, postCollectCountryLoading: true, postCollectCountryPayload:null, postCollectCountryError:null };
    case SUCCESS(actionTypes.POST_COLLECT_COUNTRY):
      return {
        ...state,
        postCollectCountryPayload: actions.payload,
        postCollectCountryError: null,
        postCollectCountryLoading: false,
      };
    case FAILURE(actionTypes.POST_COLLECT_COUNTRY):
      return {
        ...state,
        postCollectCountryError: actions.payload,
        postCollectCountryPayload: null,
        postCollectCountryLoading: false,
      };
      case REQUEST(actionTypes.GET_SUPPORTED_COUNTRY):
        return { ...state, getSupportedCountryLoading: true, getSupportedCountryPayload:null, getSupportedCountryError:null };
      case SUCCESS(actionTypes.GET_SUPPORTED_COUNTRY):
        return {
          ...state,
          getSupportedCountryPayload: actions.payload,
          getSupportedCountryError: null,
          getSupportedCountryLoading: false,
        };
      case FAILURE(actionTypes.GET_SUPPORTED_COUNTRY):
        return {
          ...state,
          getSupportedCountryError: actions.payload,
          getSupportedCountryPayload: null,
          getSupportedCountryLoading: false,
        };
      case REQUEST(actionTypes.GET_SUPPORTED_CURRENCY):
        return { ...state, getSupportedCurrencyLoading: true, getSupportedCurrencyPayload:null, getSupportedCurrencyError:null };
      case SUCCESS(actionTypes.GET_SUPPORTED_CURRENCY):
        return {
          ...state,
          getSupportedCurrencyPayload: actions.payload,
          getSupportedCurrencyError: null,
          getSupportedCurrencyLoading: false,
        };
      case FAILURE(actionTypes.GET_SUPPORTED_CURRENCY):
        return {
          ...state,
          getSupportedCurrencyError: actions.payload,
          getSupportedCurrencyPayload: null,
          getSupportedCurrencyLoading: false,
        };
        default:
      return { ...state };
  }
};

export default collectReducer;
