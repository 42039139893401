import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import Switch from "react-switch";
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter } from "Constants";
import Pagination from 'components/paginations/Paginations';
import ToggleModal from 'components/Modal/ToggleModal';
import { Button } from "reactstrap";
import CreateModal from 'components/Modal/CreateModal';
import ManageMerchantForm from './manageMerchantForm';
import { getSmartCheckMerchants, createSmartCheckMerchantsSubscriptions, toggleSmartCheckMerchants, getSmartCheckMerchantsSubscriptions } from 'logic/actions/smartCheck';
import { useDispatch, useSelector } from "react-redux";
import { errorMessage, successMessage } from 'logic/actions/notification';

const initialFormValues = {
    amount: 0,
    duration: "",
    startDate: "",
    endDate: "",
};

const Merchants = () => {
    const [id, setId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [editorModal, setEditorModal] = useState({
        open: false,
        selectedId: [],
        payload: { ...initialFormValues },
        step: 1,
    }
    );
    const [modalForm, setModalForm] = useState(false);
    const [status, setStatus] = useState(null);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [toggleStatus, setToggleStatus] = useState(false);
    const dispatch = useDispatch();

    const { getMerchantsLoading, getMerchantsPayload, getMerchantsSubData, getMerchantsSubLoading, createSubscriptionsLoading, toggleMerchantsPayload, toggleMerchantsLoading, createSubscriptionsPayload } = useSelector(({ smartCheckReducer }) => {
        return {
            getMerchantsError: smartCheckReducer?.getMerchantsError,
            getMerchantsPayload: smartCheckReducer?.getMerchantsPayload,
            getMerchantsLoading: smartCheckReducer?.getMerchantsLoading,
            getMerchantsSubError: smartCheckReducer?.getMerchantsSubscriptionsError,
            getMerchantsSubData: smartCheckReducer?.getMerchantsSubscriptionsPayload,
            getMerchantsSubLoading: smartCheckReducer?.getMerchantsSubscriptionsLoading,
            updateSubError: smartCheckReducer?.updateMerchantsSubscriptionsError,
            updateSubscriptionsPayload: smartCheckReducer?.updateMerchantsSubscriptionsPayload,
            updateSubscriptionsLoading: smartCheckReducer?.updateMerchantsSubscriptionsLoading,
            createSubscriptionsError: smartCheckReducer?.createMerchantsSubscriptionsError,
            createSubscriptionsPayload: smartCheckReducer?.createMerchantsSubscriptionsPayload,
            createSubscriptionsLoading: smartCheckReducer?.createMerchantsSubscriptionsLoading,
            toggleMerchantsError: smartCheckReducer?.toggleMerchantsStatusError,
            toggleMerchantsPayload: smartCheckReducer?.toggleMerchantsStatusPayload,
            toggleMerchantsLoading: smartCheckReducer?.toggleMerchantsStatusLoading,
        }
    });

    const toggleMerchatStaus = () => {
        dispatch(toggleSmartCheckMerchants(id));
        setToggleStatus(true);
    };
    console.log(getMerchantsSubData)
    useEffect(() => {
        if (!toggleMerchantsLoading && toggleMerchantsPayload && toggleStatus) {
            setToggleStatus(false);
            if (toggleMerchantsPayload?.data?.requestSuccessful) {
                let i = 0;
                for (let dt of data) {
                    if (dt?.MerchantId === id) {
                        data[i]["status"] = !dt?.Status;
                        setData([...data]);
                        setModalForm
                            (false);
                    }
                    i++;
                }
                dispatch(successMessage(toggleMerchantsPayload?.data?.message));
                dispatch(getSmartCheckMerchants(currentPage, 10, searchValue
                ));
            } else {
                dispatch(errorMessage(toggleMerchantsPayload?.data?.message));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleMerchantsLoading]);

    const getPaginationNumber = (page) => {
        setCurrentPage(page);
    }

    useEffect(() => {
        dispatch(getSmartCheckMerchants(currentPage, 10, searchValue
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!getMerchantsLoading && getMerchantsPayload) {
            setData(getMerchantsPayload?.data?.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getMerchantsLoading]);

    useEffect(() => {
        const isSuccessful = createSubscriptionsPayload?.data.requestSuccessful;
        if(submitStatus && !createSubscriptionsLoading && !isSuccessful){
            const message = createSubscriptionsPayload?.data?.message || "Something went wrong";
            dispatch(errorMessage(message));
            setSubmitStatus(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createSubscriptionsPayload, createSubscriptionsLoading]);

    const debounceText = useCallback(
        debounce(async (srhTxt) => {
            // dispatch(getSmartCheckMerchants(1, 10, srhTxt));
        }, 500),
        []
    );

    const handleSearchChange = (event) => {
        debounceText(event.target.value)
        setSearchValue(event.target.value)
    };

    const clearSearch = () => {
        debounceText("")
        setSearchValue("")
    };

    const showFilterMenu = () => { };

    const handleAutoCompleteCreateChange = (event, values, input) => { };

    const handleChange = (statusId, checked) => {
        setId(statusId);
        setStatus(checked);
        setModalForm(true);
    };

    const itemsPerPage = 10;

    const format = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const paginatedData = data.slice(startIndex, endIndex);

        return paginatedData.filter((item) => item.MerchantName.toLowerCase().includes(searchValue.toLowerCase())).map((item) => {
            return {
                name: item?.MerchantName,
                id: item?.MerchantId,
                subscription: item?.subscription,
                status: item?.Status,
                email: item?.MerchantEmail,
                Actions: (
                    <div className="d-flex align-items-center">
                        <Button
                            size="sm"
                            color="link"
                            className="text-primary font-weight-bold mr-4 p-0"
                            onClick={() => handleManageClick(item)}
                        >
                            Manage
                        </Button>
                        <Switch
                            onChange={() => handleChange(item?.MerchantId, item?.Status)}
                            onColor="#175FFF"
                            checked={item?.Status === "Active" ? true : false}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>

                )
            };
        });
    };

    const handleManageClick = (row) => {
        setEditorModal((prev) => ({ ...prev, selectedId: row, open: true }));
        dispatch(getSmartCheckMerchantsSubscriptions(row?.MerchantId));
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setEditorModal((prev) => ({
            ...prev, payload: { ...prev.payload, [name]: value },
        }));
    };

    const submit = () => {
        let errorStatus = false;
        if (editorModal.step === 1) {
            setEditorModal((prev) => ({ ...prev, step: 2 }));
        } else {
            for (let data in editorModal.payload) {
                if (!editorModal.payload[data] || editorModal.payload[data] === "") {
                    errorStatus = true;
                    setSubmitStatus(true);
                }
            }

            if (!errorStatus) {
                dispatch(
                    createSmartCheckMerchantsSubscriptions({
                        id: editorModal.selectedId.MerchantId,
                        duration: editorModal.payload?.duration,
                        amount: editorModal.payload?.amount,
                    })
                );
                setEditorModal((prev) => ({ ...prev, submitted: true }));
            }
        }
    };

    return (
        <>
            <div className="customScroll d-flex justify-space-between overflow-y-hidden" >
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={
                        handleAutoCompleteCreateChange
                    }
                    showFilter={true}
                    showCreateBtn={false}
                    showSearch={true}
                    handleSearchChange={handleSearchChange}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    searchValue={searchValue}
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>
            <br />
            <ListTable
                header={[
                    { name: "Merchant Name" },
                    { name: "Merchant Email" },
                    { name: "Status" },
                    { name: "Action" },
                ]}
                isSearching={getMerchantsLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow
                                key={index}
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell component="th" scope="row">
                                    <span className="capitalise">{row.name || "N/A"}</span>
                                </TableCell>
                                <TableCell align="left">
                                    {row.email || "N/A"}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="status-flag">
                                        <span className={`dot-status ${row.status === "Active" ? 'dot-active' : 'dot-inactive'}`}>
                                            .
                                        </span>
                                        {row.status ? row.status === "Active" ? 'Enabled' : 'Disabled' : "N/A"}</div>
                                </TableCell>
                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {format()?.length > 0 ? <div className="paginate-content">
                <div  >
                    Showing Page {currentPage} of {Math.ceil(data?.length / itemsPerPage)}
                </div>
                <div>
                    <Pagination
                        totalPages={Math.ceil(data?.length / itemsPerPage)}
                        currentPage={currentPage}
                        changeCurrentPage={(p) => getPaginationNumber(p)}
                        previousBtn="Prev"
                        nextBtn="Next"
                    />
                </div>
            </div> : null}

            {modalForm && (
                <ToggleModal
                    title={status === "Active" ? "Disable Merchant?" : "Enable Merchant?"}
                    toggleForm={modalForm
                    }
                    onToggle={() => toggleMerchatStaus()}
                    toggling={toggleMerchantsLoading}
                    disableToggle={toggleMerchantsLoading}
                    hideToggleModal={() => setModalForm(false)}
                    toggleText={status === "Active" ? "Disable" : "Enable"}
                    textContent={
                        status === "Active"
                            ? "Are you sure you want to disable this item?"
                            : "Are you sure you want to enable this item?"
                    }
                />
            )}

            {editorModal.open && (
                <CreateModal
                    handleCancelFromParent={editorModal.step > 1}
                    title={`${editorModal.step === 1 ? `Manage Merchant` : getMerchantsSubData?.data.data ? `Manage custom plan pricing` : "Create custom plan"}`}
                    onCancelClick={() => setEditorModal((prev) => ({ ...prev, step: 1 }))}
                    createForm={editorModal.open}
                    creating={createSubscriptionsLoading}
                    disableCreate={createSubscriptionsLoading}
                    hideCreateForm={() =>
                        setEditorModal({ open: false, step: 1 })
                    }
                    onCreate={() => submit()}
                    cancelText={editorModal.step > 1 ? "Go Back" : "Close"}
                    hidebtns={getMerchantsSubLoading}
                    createText={
                        editorModal.step > 1
                            ? getMerchantsSubData?.data?.data
                                ? "Update"
                                : "Create"
                            : getMerchantsSubData?.data?.data
                                ? "Manage Subscription"
                                : "Create Subscription"
                    }

                    content={
                        <ManageMerchantForm
                            step={editorModal.step}
                            submitStatus={submitStatus}
                            subscriptionPlan={getMerchantsSubData?.data.data}
                            subscriptionLoading={getMerchantsSubLoading}
                            onChange={handleFormChange}
                            payload={editorModal.payload}
                            subscription={editorModal.selectedId.subscription}
                        />
                    }
                />
            )}
        </>
    )
}

export default Merchants;