import { appHelpers } from "appHelpers";

export const collectBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afcollectionaggregatortest.azurewebsites.net/api"
    : `${window.env?.collectionApiUrl}/api`;

export const virtualAccountBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://virtualaccount-apitest.azurewebsites.net/api"
    : window.env?.virtualAccountBaseUrl;
export const smartCheckBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://smartcheck-api-test.azurewebsites.net/smartcheck"
    : window.env?.smartCheckBaseUrl;
export const paymentTypeId =
  process.env.NODE_ENV === "development" ? "2" : window.env?.paymentTypeId;
export const complianceBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagertest.azurewebsites.net/api"
    : window.env?.complianceBaseUrl;
export const kycBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://vggkycservicetest.azurewebsites.net/api"
    : window.env?.kycBaseUrl;
export const serviceManagerClientUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api/v1"
    : window.env?.SERVICEMANAGERCLIENTBASEURL;
export const payoutBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://vgnpcdisagrservicetest.azurewebsites.net/api"
    : window.env?.payoutBaseUrl;
export const cardsBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://baseone-cardservice-apitest.azurewebsites.net/api"
    : window.env?.cardsBaseUrl;
export const config = () => {
  let tkEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.UI_"));
  const bearerToken = `bearer ${appHelpers
    .cryptographyService()
    .decrypt(tkEncrypt)}`;
  return { headers: { Authorization: bearerToken } };
};
