import React, {useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {debounce} from "lodash";
import ToggleModal from 'components/Modal/ToggleModal';

import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter, generalErrorMessage } from "Constants";
import '../../payout/payout.scss';
import { getCountry, getCurrency } from 'logic/actions/requests';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from '../../../components/paginations/Paginations';
import Form from './form';
import { errorMessage } from 'logic/actions/notification';
import { getGateWay, toggleCountryPayout } from 'logic/actions/payout';
import { getCollectCountry, getSupportedCountry, getSupportedCurrency, manageMerchant } from 'logic/actions/collectAction';

const payloadData = {
  "countryCode": null,
  "pricingPercentage": null,
  "pricingCap": null,
  "revenuePercentage": null,
  "currencyCode": null,
  "minimumSettlementAmount":null
}

const Country = () => {
    const [id, setId] = useState(null); 
    const [searchValue, setSearchValue] = useState("");
    const [payload, setPayload] = useState(payloadData);
    const [viewDetail, setViewDetail] = useState(null);
    const [showViewModal, setShowViewModal] = useState(false);
    const [toggleForm, setToggleForm] = useState(false);
    const [status] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const { manageMerchantLoading, getSupportedCurrencyPayload, getSupportedCountryPayload, toggleCountryLoading, toggleCountryPayload,getCollectCountryLoading, getCollectCountryPayload, getIdentityTypeLoading, getIdentityTypePayload, getIdentityTypeError, postCollectCountryLoading, postCollectCountryPayload,  } = useSelector(({collectReducer}) => {
        return {
          manageMerchantLoading: collectReducer?.manageMerchantLoading,
           getCollectCountryError: collectReducer?.getCollectCountryError,
            getCollectCountryPayload: collectReducer?.getCollectCountryPayload,
            getCollectCountryLoading: collectReducer?.getCollectCountryLoading,
            getSupportedCurrencyPayload: collectReducer?.getSupportedCurrencyPayload,
            getSupportedCountryPayload: collectReducer?.getSupportedCountryPayload,
          };
      });
    
    const getPaginationNumber = (page) => {
      setCurrentPage(page)
      dispatch(getCollectCountry(10, page, searchValue));
    }

           
  useEffect(()=>{
      dispatch(getCollectCountry(10));
      // dispatch(getIdentityType());
      dispatch(getCountry());
      dispatch(getCurrency());
      dispatch(getGateWay());
        dispatch(getSupportedCountry());
        dispatch(getSupportedCurrency());
    
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(!getCollectCountryLoading && getCollectCountryPayload?.data?.data){
      setData(getCollectCountryPayload?.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCollectCountryLoading]);

  useEffect(()=>{
    if(!getIdentityTypeLoading && (getIdentityTypePayload?.data?.hasError || getIdentityTypeError)) {
      dispatch(errorMessage(getIdentityTypeError?.data?.message|| generalErrorMessage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdentityTypeLoading]);

  useEffect(()=>{
    if(!postCollectCountryLoading && postCollectCountryPayload?.data?.requestSuccessful){
      setData([postCollectCountryPayload?.data?.responseData, ...data])
      setPayload(payloadData);
      setCreateForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCollectCountryLoading]);

  useEffect(()=>{
    if(!toggleCountryLoading && toggleCountryPayload?.data?.requestSuccessful){
      let i = 0;
      for(let dt of data) {
        if(dt?.id === id) {
           data[i]['isActive'] = !dt?.isActive; 
            setData([...data]);
            setToggleForm(false)
            return
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCountryLoading])
  
  const handleAutoCompleteCreateChange = (event, values, input) => {
  };

  const onView = (val) => {
    setViewDetail(val);
    setShowViewModal(true)
  }

  const onEdit = (myData) => {
    setId(myData?.id)
    setPayload({...myData, 
      countryPayload: getSupportedCountryPayload?.data?.filter(val => val?.countryName === myData?.countryCode)?.map(val=>({value: val?.countryCode, label: val?.countryName})),
      currencyPayload: getSupportedCurrencyPayload?.data?.filter(val => val?.currencyCode === myData?.currencyCode)?.map(val=>({value: val?.currencyCode, label: val?.currencyName})),
    });
    setEditStatus(true);
    setCreateForm(true)
  }

  const submit = () => {
    let errorStatus = false;
      setSubmitStatus(true);
      let resPayload = {};
      for(let data in payload) {
          if((!payload[data] || payload[data] === "") && data !== "queueName" && data !== "pricingFlat" && data !== "revenueFlat") {
              errorStatus = true;
              return
          }
          if(payload[data]){
            resPayload[data] = payload[data]
          }
     }
     if(payload?.revenuePercentage && payload?.revenuePercentage > 100 ) {
         dispatch(errorMessage("Revenue percentage should not be greater than 100"))
         return
     }else if(payload?.pricingPercentage && payload?.pricingPercentage > 100 ) {
         dispatch(errorMessage("Price percentage should not be greater than 100"))
         return
     }   
      if(!errorStatus) {
          dispatch(manageMerchant("country",{...resPayload}, true, id ))
      }
  }


const debounceText = useCallback(debounce(async(srhTxt) => {
  dispatch(getCollectCountry(10, 1, srhTxt)) 
}, 500), [])


const handleSearchChange = (event, input) => {
  debounceText(event.target.value)
  setSearchValue(event.target.value)
};

const clearSearch = () => {
  debounceText("")
  setSearchValue("")
};

const showFilterMenu = () => {
}; 

const openCreate = () => {
  setCreateForm(true); 
  setEditStatus(false); 
  setEditStatus(false);
  setId(null)
  setPayload(payloadData)
}
  
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            overflowY: "hidden",
          }}
          className="customScroll">
          <FilterMenu
            caption="Clients List"
            totalCount={10}
            searchLabel="Search.."
            filterOptions={clientsFilter}
            filterdefaultValueOptions={{
              label: "All",
              value: "all",
            }}
            handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
            showFilter={false}
            showSearch={false}
            showCreateBtn={true}
            btnName="New Country&nbsp;&nbsp;+"
            openCreate={() => openCreate()}
            handleSearchChange={handleSearchChange}
            showFilterMenu={showFilterMenu}
            filterLabel="Filter by"
            searchName="keyword"
            searchValue={searchValue}
            clearSearch={clearSearch}
            entriesName="Entries"
            filterValue={"Search"}
            entriesOptions={{}}
            entriesdefaultValueOptions={{
              label: `Entries`,
              value: 20,
            }}
            entriesValue={{}}
            entriesLabel="Entries"
          />
        </div>

        <ListTable
          header={[
            { name: "Country" },
            { name: "Currency" },
            { name: "Minimum Settlement Amount" },
            { name: "Pricing Flat" },
            { name: "Pricing Percentage" },
            { name: "Revenue Flat" },
            { name: "Revenue Percentage" },
            { name: "Action" },
            { name: "" },
          ]}
          isSearching={getCollectCountryLoading}
          showError={false}
          style={{ opacity: 1 }}
          pageOfItems={getCollectCountryPayload?.data?.data}
          content={
            <TableBody>
              {data?.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    {row.countryCode}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.currencyCode}
                  </TableCell>
                  <TableCell align="left">
                    {row.minimumSettlementAmount}
                  </TableCell>
                  <TableCell align="left">{row.pricingFlat}</TableCell>
                  <TableCell align="left">{row.pricingPercentage}</TableCell>
                  <TableCell align="left">{row.revenueFlat}</TableCell>
                  <TableCell align="left">{row.revenuePercentage}</TableCell>
                  <TableCell align="left">
                    <div className="payout-action">
                      <span className="edit-icon" onClick={() => onView(row)}>
                        View
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className="payout-action">
                      <span className="edit-icon" onClick={() => onEdit(row)}>
                        Edit
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
        <br />
        {data?.length > 0 ? (
          <div className="paginate-content">
            <div>
              Showing Page {getCollectCountryPayload?.data?.pageNumber} of{" "}
              {getCollectCountryPayload?.data?.totalPages}
            </div>
            <div>
              <Pagination
                totalPages={getCollectCountryPayload?.data?.totalPages}
                currentPage={currentPage}
                changeCurrentPage={(p) => getPaginationNumber(p)}
                previousBtn="Prev"
                nextBtn="Next"
              />
            </div>
          </div>
        ) : null}

        {createForm && (
          <CreateModal
            title={editStatus ? "Update Country" : "New Country"}
            createForm={createForm}
            hideCreateForm={() => setCreateForm(false)}
            onCreate={() => submit()}
            creating={manageMerchantLoading}
            disableCreate={manageMerchantLoading}
            cancelText="Cancel"
            createText={editStatus ? "Update" : "Finish"}
            content={
              <Form
                setPayload={setPayload}
                payload={payload}
                editStatus={editStatus}
                submitStatus={submitStatus}
                setSubmitStatus={setSubmitStatus}
                setCreateForm={setCreateForm}
              />
            }
          />
        )}

        {toggleForm && (
          <ToggleModal
            title={status ? "Disable item?" : "Enable Item?"}
            toggleForm={toggleForm}
            onToggle={() => dispatch(toggleCountryPayout(id, status))}
            toggling={toggleCountryLoading}
            disableToggle={toggleCountryLoading}
            hideToggleModal={() => setToggleForm(false)}
            toggleText={status ? "Disable" : "Enable"}
            textContent={
              status
                ? "Are you sure you want to reject this item?"
                : "Are you sure you want to approve this item?"
            }
          />
        )}
        {showViewModal && (
          <ToggleModal
            title="Country Details"
            toggleForm={showViewModal}
            hideIcon={true}
            cancelText="Close"
            onToggle={() => setShowViewModal(false)}
            toggling={toggleCountryLoading}
            disableToggle={toggleCountryLoading}
            hideToggleModal={() => setShowViewModal(false)}
            textContent={
              <div className="detail-form">
                <div className="display-item">
                  <div className="title">Country</div>
                  <div className="desc">{viewDetail?.countryCode}</div>
                </div>
                <div className="display-item">
                  <div className="title">Currency</div>
                  <div className="desc">{viewDetail?.currencyCode}</div>
                </div>
                <div className="display-item">
                  <div className="title">Minimum Settlement Amount</div>
                  <div className="desc">
                    {viewDetail?.minimumSettlementAmount}
                  </div>
                </div>
                <div className="display-item">
                  <div className="title">Pricing Flat</div>
                  <div className="desc">{viewDetail?.pricingFlat}</div>
                </div>
                <div className="display-item">
                  <div className="title">Pricing Percentage</div>
                  <div className="desc">{viewDetail?.pricingPercentage}</div>
                </div>
                <div className="display-item">
                  <div className="title">Revenue Flat</div>
                  <div className="desc">{viewDetail?.revenueFlat}</div>
                </div>
                <div className="display-item">
                  <div className="title">Revenue Percentage</div>
                  <div className="desc">{viewDetail?.revenuePercentage}</div>
                </div>
              </div>
            }
          />
        )}
      </div>
    );
}

export default Country;