import { Get, Post, Put } from "apiServices/apiHandler.service";
import { smartCheckBaseUrl } from "apiServices/config.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import actionTypes from "./actionTypes";

export const getRequest = createAsyncAction(
  actionTypes.GET_SMART_CHECK_REQUEST,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(`v1/admin-api/requests/get-requests`, smartCheckBaseUrl);
  }
);

export const approveSmartCheckRequest = createAsyncAction(
  actionTypes.APPROVE_SMART_CHECK_REQUEST,
  async (id) => {
    return await Post(
      `v1/admin-api/requests/approve?MerchantId=${id}`,
      {},
      smartCheckBaseUrl
    );
  }
);

export const rejectSmartCheckRequest = createAsyncAction(
  actionTypes.REJECT_SMART_CHECK_REQUEST,
  async (id) => {
    return await Post(
      `v1/admin-api/requests/reject?MerchantId=${id}`,
      {},
      smartCheckBaseUrl
    );
  }
);

export const getSmartCheckMerchants = createAsyncAction(
  actionTypes.GET_SMART_CHECK_MERCHANTS,
  async () => {
    return await Get(`v1/admin-api/merchants/get-merchants`, smartCheckBaseUrl);
  }
);

export const toggleSmartCheckMerchants = createAsyncAction(
  actionTypes.TOGGLE_SMART_CHECK_MERCHANTS_STATUS,
  async (merchantId) => {
    return await Post(
      `v1/admin-api/merchants/toggle?MerchantId=${merchantId}`,
      null,
      smartCheckBaseUrl
    );
  }
);

export const getSmartCheckMerchantsSubscriptions = createAsyncAction(
  actionTypes.GET_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS,
  async (id) => {
    return await Get(
      `/v1/admin-api/merchants/subscription/get/${id}`,
      smartCheckBaseUrl
    );
  }
);

export const updateSmartCheckMerchantsSubscriptions = createAsyncAction(
  actionTypes.UPDATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS,
  async (id, amount) => {
    const payload = {
      id: id,
      amount: amount,
    };
    return await Post(
      `v1/admin-api/merchants/subscription/update`,
      payload,
      smartCheckBaseUrl
    );
  }
);

export const createSmartCheckMerchantsSubscriptions = createAsyncAction(
  actionTypes.CREATE_SMART_CHECK_MERCHANTS_SUBSCRIPTIONS,
  async ({ id, duration, amount }) => {
    const payload = {
      MerchantID: id,
      customAmount: amount,
      customDuration: duration,
    };
    return await Post(
      `v1/admin-api/merchants/subscription/manage-custom-plan`,
      payload,
      smartCheckBaseUrl
    );
  }
);

export const getSmartCheckSubscriptionsPlan = createAsyncAction(
  actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(`v1/admin-api/subscriptions/plans`, smartCheckBaseUrl);
  }
);

export const getSmartCheckSubscriptionsPlanList = createAsyncAction(
  actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_LIST,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(
      `v1/admin-api/subscriptions/plan-pricing`,
      smartCheckBaseUrl
    );
  }
);

export const getSmartCheckSubscriptionsPlanPricingById = createAsyncAction(
  actionTypes.GET_SMART_CHECK_SUBSCRIPTIONS_PLAN_PRICING_ID,
  async (id) => {
    return await Get(
      `v1/admin-api/subscriptions/plan-pricing/${id}`,
      smartCheckBaseUrl
    );
  }
);

export const updateSmartCheckSubscriptionsPricingList = createAsyncAction(
  actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST,
  async (payload) => {
    const data = {
      PlanID: payload.PlanId,
      Price: payload.amount,
      ItemID: payload.ItemID,
    };
    return await Post(
      `v1/admin-api/subscriptions/update-pricing`,
      data,
      smartCheckBaseUrl
    );
  }
);

export const createSmartCheckSubscriptionsPricingList = createAsyncAction(
  actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PRICING_LIST,
  async (id) => {
    const data = {
      PlanId: 6,
    };
    return await Post(
      `v1/admin-api/subscription-plans/create-default`,
      data,
      smartCheckBaseUrl
    );
  }
);

export const createSmartCheckSubscriptionsPlan = createAsyncAction(
  actionTypes.CREATE_SMART_CHECK_SUBSCRIPTIONS_PLAN,
  async (payload) => {
    return await Post(
      `v1/admin-api/subscription-plans/create`,
      payload,
      smartCheckBaseUrl
    );
  }
);

export const updateSmartCheckSubscriptionsPlan = createAsyncAction(
  actionTypes.UPDATE_SMART_CHECK_SUBSCRIPTIONS_PLAN,
  async (payload) => {
    return await Post(
      `v1/admin-api/subscription-plans/update`,
      payload,
      smartCheckBaseUrl
    );
  }
);

export const getSmartCheckServices = createAsyncAction(
  actionTypes.GET_SMART_CHECK_SERVICES,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(`v1/admin-api/billable-item/list`, smartCheckBaseUrl);
  }
);

export const newSmartCheckServices = createAsyncAction(
  actionTypes.ADD_NEW_SMART_CHECK_SERVICES,
  async (payload) => {
    return await Post(
      `v1/admin-api/billable-item/create`,
      payload,
      smartCheckBaseUrl
    );
  }
);

export const updateSmartCheckServices = createAsyncAction(
  actionTypes.UPDATE_SMART_CHECK_SERVICES,
  async (payload) => {
    return await Post(
      `v1/admin-api/billable-item/update`,
      payload,
      smartCheckBaseUrl
    );
  }
);

export const getSmartCheckCountryDocs = createAsyncAction(
  actionTypes.GET_SMART_CHECK_COUNTRY_DOCS,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(`v1/admin-api/country/list`, smartCheckBaseUrl);
  }
);

export const newSmartCheckCountryDocs = createAsyncAction(
  actionTypes.ADD_NEW_SMART_CHECK_COUNTRY_DOCS,
  async (payload) => {
    const data = {
      CountryName: payload.name,
      CountryCode: payload.code,
      CurrencyCode: payload.currencyCode,
      Documents: payload.documents,
      Status: "Active",
    };
    return await Post(`v1/admin-api/country/create`, data, smartCheckBaseUrl);
  }
);

export const updateSmartCheckCountryDocs = createAsyncAction(
  actionTypes.UPDATE_SMART_CHECK_COUNTRY_DOCS,
  async (payload) => {
    const data = {
      CountryName: payload.name,
      CountryCode: payload.code,
      CurrencyCode: payload.CurrencyCode,
      Status: payload.Status,
      Documents: payload.Documents,
      CountryId: payload.CountryId,
    };
    return await Put(`v1/admin-api/country/update`, data, smartCheckBaseUrl);
  }
);

export const toggleSmartCheckCountry = createAsyncAction(
  actionTypes.TOGGLE_SMART_CHECK_COUNTRY_DOCS_STATUS,
  async (payload) => {
    const data = {
      CountryId: payload.CountryId,
      Status: payload.Status === "Active" ? "Inactive" : "Active",
    };
    return await Put(`v1/admin-api/country/toggle`, data, smartCheckBaseUrl);
  }
);

export const getSmartCheckDocument = createAsyncAction(
  actionTypes.GET_SMART_CHECK_DOCUMENTS,
  async (pageNumber = 1, pageSize = 10, keyword = "") => {
    return await Get(`v1/admin-api/document/list`, smartCheckBaseUrl);
  }
);

export const newSmartCheckDocument = createAsyncAction(
  actionTypes.ADD_NEW_SMART_CHECK_DOCUMENT,
  async (payload) => {
    const data = {
      DocumentName: payload.DocumentName,
      DocumentCode: payload.DocumentCode,
      DocumentDescription: payload.DocumentDescription,
    };
    return await Post(`v1/admin-api/document/create`, data, smartCheckBaseUrl);
  }
);

export const updateSmartCheckDocument = createAsyncAction(
  actionTypes.UPDATE_SMART_CHECK_DOCUMENT,
  async (payload) => {
    const data = {
      DocumentName: payload.Name,
      DocumentCode: payload.Code,
      DocumentDescription: payload.Description,
      DocumentId: payload.DocumentId,
    };
    return await Put(`v1/admin-api/document/update`, data, smartCheckBaseUrl);
  }
);
