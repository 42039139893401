import React, { useEffect, useState, useCallback } from "react";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from "../../../components/Input/FilterMenu";
import { clientsFilter, SETTLEMENT_PREFERENCE_OPTIONS } from "Constants";
import "../Reconcilation.scss";
import {
  configureVirtualAccountMerchant,
  fetchVirtualAccountMerchantConfigs,
  getCountry,
  getReconcilationMerchant,
  getWalletCurrency,
  toggleReconcilationMerchant,
} from "logic/actions/requests";
import ToggleModal from "components/Modal/ToggleModal";
import CreateModal from "components/Modal/CreateModal";
import Pagination from "../../../components/paginations/Paginations";
import { errorMessage, successMessage } from "logic/actions/notification";
import ManageMerchantForm from "./manageMerchantForm";
import { Button } from "reactstrap";
import ViewModal from "components/Modal/ViewModal";

const initialFormValues = {
  pricingType: "percentage",
  currency: "",
  pricingPercentage: "",
  pricingCap: "",
  revenuePercentage: "",
  chargeConfigurationType: "",
  settlementPreference: "",
};

const Merchants = () => {
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [, setHasMapped] = useState(false);
  const [currentPage, setCurrentPage] = useState(2);
  const [toggleForm, setToggleForm] = useState(false);
  const [createForm, setCreateForm] = useState(false);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [editor, setEditor] = useState({
    open: false,
    payload: { ...initialFormValues },
    merchantId: "",
    recordId: "",
    step: 1,
  });
  const [viewDetails, setViewDetails] = useState({
    open: false,
    payload: { ...initialFormValues },
    countries: [],
    step: 1,
  });
  const [submitStatus, setSubmitStatus] = useState(false);
  const dispatch = useDispatch();

  const {
    getReconcilationLoading,
    getReconcilationPayload,
    toggleReconcilationMerchantLoading,
    toggleReconcilationMerchantPayload,
    postMerchantLoading,
    getCountryPayload,
    getWalletCurrencyPayload,
    mutatingMerchantConfig,
    mutateMerchantConfigPayload,
    mutateMerchantConfigError,
    getCountryLoading,
    merchantConfigs,
    fetchingMerchantConfigs,
  } = useSelector(({ virtualAccountReducer }) => {
    return {
      getReconcilationError: virtualAccountReducer?.getReconcilationError,
      getReconcilationPayload: virtualAccountReducer?.getReconcilationPayload,
      getReconcilationLoading: virtualAccountReducer?.getReconcilationLoading,
     
     
      toggleReconcilationMerchantError: virtualAccountReducer?.toggleReconcilationMerchantError,
      toggleReconcilationMerchantPayload: virtualAccountReducer?.toggleReconcilationMerchantPayload,
      toggleReconcilationMerchantLoading: virtualAccountReducer?.toggleReconcilationMerchantLoading,
      getCountryError: virtualAccountReducer?.getCountryError,
      getCountryPayload: virtualAccountReducer?.getCountryPayload,
      getCountryLoading: virtualAccountReducer?.getCountryLoading,
      getWalletCurrencyError: virtualAccountReducer?.getWalletCurrencyError,
      getWalletCurrencyPayload: virtualAccountReducer?.getWalletCurrencyPayload,
      getWalletCurrencyLoading: virtualAccountReducer?.getWalletCurrencyLoading,
      mutatingMerchantConfig: virtualAccountReducer?.mutatingMerchantConfig,
      mutateMerchantConfigPayload:
        virtualAccountReducer?.mutateMerchantConfigPayload,
      mutateMerchantConfigError:
        virtualAccountReducer?.mutateMerchantConfigError,
      merchantConfigs: virtualAccountReducer?.merchantConfigs,
      fetchingMerchantConfigs: virtualAccountReducer?.fetchingMerchantConfigs,
    };
  });

  const getPaginationNumber = (page) => {
    setCurrentPage(page);
    dispatch(getReconcilationMerchant(2, page, 10, searchValue));
  };

  useEffect(() => {
    dispatch(getReconcilationMerchant(2));
    dispatch(getCountry());
    dispatch(getWalletCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getReconcilationLoading && getReconcilationPayload) {
      setData(getReconcilationPayload?.data?.responseData?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReconcilationLoading]);

  useEffect(() => {
    if (!mutatingMerchantConfig) {
      if (
        mutateMerchantConfigPayload?.data?.responseData &&
        mutateMerchantConfigPayload?.data?.requestSuccessful &&
        editor.submitted
      ) {
        setEditor({
          open: false,
          payload: {},
          currencies: [],
          step: 1,
          submitted: false,
        });

        dispatch(
          successMessage(
            mutateMerchantConfigPayload?.data?.responseMessage ||
              "Record saved successfully"
          )
        );
        dispatch(getReconcilationMerchant(2));
      }
      if (
        !mutateMerchantConfigPayload?.data?.responseData &&
        !mutateMerchantConfigPayload?.data?.requestSuccessful &&
        editor.submitted
      ) {
        dispatch(
          errorMessage(
            mutateMerchantConfigPayload?.data?.message ||
              "Unable to submit configuration"
          )
        );
        setEditor((prev) => ({ ...prev, submitted: false }));
      }

      if (
        Array.isArray(mutateMerchantConfigError?.data?.errors) &&
        mutateMerchantConfigError?.data?.errors?.length &&
        editor.submitted
      ) {
        const errors = mutateMerchantConfigError?.data?.errors;
        for (let error of errors) {
          dispatch(errorMessage(error));
        }
        setEditor((prev) => ({ ...prev, submitted: false }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutatingMerchantConfig]);

  useEffect(() => {
    if (!toggleReconcilationMerchantLoading && toggleReconcilationMerchantPayload) {
      let i = 0;
      for (let dt of data) {
        if (dt?.id === id) {
          data[i]["isActive"] = !dt?.isActive;
          setData([...data]);
          setToggleForm(false);
          return;
        }
        i++;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleReconcilationMerchantLoading]);

  useEffect(() => {
    if (
      !fetchingMerchantConfigs &&
      merchantConfigs?.data?.responseData &&
      !merchantConfigs?.data?.hasError
    ) {
      const data = merchantConfigs?.data?.responseData;

      const payload = {
        ...editor.payload,
        pricingType:
          data?.pricingFlat || data.revenueFlat ? "flat" : "percentage",
        currency: data?.currency || editor.payload.currency,
        pricingFlat: data?.pricingFlat || "",
        pricingPercentage: data?.pricingPercentage || "",
        pricingCap: data?.pricingCap || "",
        revenueFlat: data?.revenueFlat || "",
        revenuePercentage: data?.revenuePercentage || "",
        chargeConfigurationType: data?.chargeConfigurationType || "",
        // settlementPreference: SETTLEMENT_PREFERENCE_OPTIONS.find(v => v.label.toLowerCase() === data?.settlementPreference)?.value || ""
      };

      setEditor((prev) => ({ ...prev, payload, recordId: data.id }));
      setHasMapped(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingMerchantConfigs]);

  const handleChange = (statusId, checked) => {
    setId(statusId);
    setStatus(checked);
    setToggleForm(true);
  };

  const handleViewClick = (row) => {
    setViewDetails((prev) => ({
      ...prev,
      payload: {
        ...row,
      },
      open: true,
      merchantName: row.merchantName,
    }));
  };

  const debounceText = useCallback(
    debounce(async (srhTxt) => {
      dispatch(getReconcilationMerchant(2, 1, 10, srhTxt));
    }, 500),
    []
  );

  const handleSearchChange = (event, input) => {
    debounceText(event.target.value);
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    debounceText("");
    setSearchValue("");
  };

  const showFilterMenu = () => {};

  const handleAutoCompleteCreateChange = (event, values, input) => {};

  const handleManageClick = (row) => {
    setEditor((prev) => ({ ...prev, merchantId: row?.a1ClientId, open: true }));
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const numberFields = [
      "pricingFlat",
      "pricingPercentage",
      "pricingCap",
      "revenueFlat",
      "revenuePercentage",
    ];
    if (name === "currency") {
      dispatch(
        fetchVirtualAccountMerchantConfigs({
          merchantId: editor.merchantId,
          currencyCode: value,
        })
      );
    }

    if (numberFields.includes(name)) {
      setEditor((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: +value },
      }));
    } else {
      setEditor((prev) => ({
        ...prev,
        payload: { ...prev.payload, [name]: value },
      }));
    }
    setEditor((prev) => ({
      ...prev,
      payload: { ...prev.payload, [name]: value },
    }));
  };

  const submit = () => {
    let errorStatus = false;
    if (editor.step === 1) {
      setHasMapped(true);
      setEditor((prev) => ({ ...prev, step: 2 }));
    } else {
      const percentagePayload = {
        currency: editor.payload.currency,
        pricingPercentage: editor.payload.pricingPercentage,
        pricingCap: editor.payload.pricingCap,
        revenuePercentage: editor.payload.revenuePercentage,
        chargeConfigurationType: editor.payload.chargeConfigurationType,
      };
      const flatPayload = {
        currency: editor.payload.currency,
        pricingFlat: editor.payload.pricingFlat,
        revenueFlat: editor.payload.revenueFlat,
        chargeConfigurationType: editor.payload.chargeConfigurationType,
      };

      const payload =
        editor.payload.pricingType === "percentage"
          ? percentagePayload
          : flatPayload;

      for (let data in payload) {
        if (!payload[data] || payload[data] === "") {
          errorStatus = true;
          setSubmitStatus(true);
        }
      }

      if (!errorStatus) {
        dispatch(
          configureVirtualAccountMerchant({
            payload,
            id: editor.recordId,
            merchantId: editor.merchantId,
          })
        );
        setEditor((prev) => ({ ...prev, submitted: true }));
      }
    }
  };

  const format = () => {
    return data?.map((item, index) => {
      return {
        name: item?.merchantName,
        Id: item?.id,
        settlementType: item?.erpSystem,
        status: item?.approvalStatusDesc,
        Actions: (
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              color="link"
              className="text-primary pl-0 font-weight-bold"
              onClick={() => handleViewClick(item)}
            >
              View
            </Button>
            {/* <Button
              size="sm"
              color="link"
              className="text-primary ml-2 font-weight-bold"
              onClick={() => handleManageClick(item)}>
              Manage
            </Button> */}
            <Switch
              onChange={() => handleChange(item?.id, item?.isActive)}
              onColor="#175FFF"
              checked={item?.isActive}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
        ),
      };
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowY: "hidden",
        }}
        className="customScroll">
        <FilterMenu
          caption="Clients List"
          totalCount={10}
          searchLabel="Search.."
          filterOptions={clientsFilter}
          filterdefaultValueOptions={{
            label: "All",
            value: "all",
          }}
          handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
          showFilter={false}
          showSearch={true}
          showCreateBtn={false}
          btnName=""
          openCreate={() => setCreateForm(true)}
          handleSearchChange={handleSearchChange}
          showFilterMenu={showFilterMenu}
          filterLabel="Filter by"
          searchName="keyword"
          searchValue={searchValue}
          clearSearch={clearSearch}
          entriesName="Entries"
          filterValue={"Search"}
          entriesOptions={{}}
          entriesdefaultValueOptions={{
            label: `Entries`,
            value: 20,
          }}
          entriesValue={{}}
          entriesLabel="Entries"
        />
      </div>

      <ListTable
        header={[
          { name: "Name" },
          { name: "Erp System" },
          { name: "Status" },
          { name: "Action" },
        ]}
        isSearching={getReconcilationLoading}
        showError={false}
        style={{ opacity: 1 }}
        pageOfItems={data}
        content={
          <TableBody>
            {format()?.map((row, index) => (
              <TableRow key={index} style={{ cursor: "pointer" }}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.settlementType}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.Actions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        }
      />
      <br />
      {data?.length > 0 ? (
        <div className="paginate-content">
          <div>
            Showing Page {getReconcilationPayload?.data?.responseData?.pageNumber} of{" "}
            {getReconcilationPayload?.data?.responseData?.pages}
          </div>
          <div>
            <Pagination
              totalPages={getReconcilationPayload?.data?.responseData?.pages}
              currentPage={currentPage}
              changeCurrentPage={(p) => getPaginationNumber(p)}
              previousBtn="Prev"
              nextBtn="Next"
            />
          </div>
        </div>
      ) : null}

      {createForm && (
        <CreateModal
          title="Add Bank"
          createForm={createForm}
          hideCreateForm={() => setCreateForm(false)}
          onCreate={() => {}}
          creating={postMerchantLoading}
          disableCreate={postMerchantLoading}
          cancelText="Cancel"
          createText="Create Bank"
          content={
            <form className="create-form">
              <div>
                <label id="bank-name">Bank Name</label>
                <input
                  onChange={() => {}}
                  className="form-control"
                  for="bank-name"
                />
              </div>
              <div>
                <label id="country">Country</label>
                <select
                  onChange={() => {}}
                  className="form-control"
                  for="country">
                  <option></option>
                  {getCountryPayload?.data?.responseData?.items?.map((val) => (
                    <option key={val?.id}>{val?.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label id="currency">Currency</label>
                <select
                  onChange={() => {}}
                  className="form-control"
                  for="currency">
                  <option></option>
                  {getWalletCurrencyPayload?.data?.responseData?.items?.map(
                    (val) => (
                      <option key={val?.id}>{val?.name}</option>
                    )
                  )}
                </select>
              </div>
            </form>
          }
        />
      )}

      {toggleForm && (
        <ToggleModal
          title={status ? "Disable item?" : "Enable Item?"}
          toggleForm={toggleForm}
          onToggle={() => dispatch(toggleReconcilationMerchant(id))}
          toggling={toggleReconcilationMerchantLoading}
          disableToggle={toggleReconcilationMerchantLoading}
          hideToggleModal={() => setToggleForm(false)}
          toggleText={status ? "Disable" : "Enable"}
          textContent={
            status
              ? "Are you sure you want to disable this item?"
              : "Are you sure you want to enable this item?"
          }
        />
      )}

      {viewDetails.open && <ViewModal
        open={viewDetails.open}
        content={viewDetails.payload}
        hideViewForm={() =>
          setViewDetails({...viewDetails, open: false})
        }
        title="Merchant Details"
      />}
      {editor.open && (
        <CreateModal
          handleCancelFromParent={editor.step > 1}
          onCancelClick={() => setEditor((prev) => ({ ...prev, step: 1 }))}
          title={`Manage Merchant (${editor.step}/2)`}
          createForm={editor.open}
          hideCreateForm={() =>
            setEditor({ open: false, payload: {}, countries: [], step: 1 })
          }
          onCreate={() => submit()}
          creating={mutatingMerchantConfig}
          disableCreate={
            mutatingMerchantConfig ||
            (editor.step === 1 && !editor.payload.currency)
          }
          cancelText={editor.step > 1 ? "Back" : "Cancel"}
          createText={editor.step > 1 ? "Finish" : "Continue"}
          content={
            <ManageMerchantForm
              step={editor.step}
              payload={editor.payload}
              submitStatus={submitStatus}
              onChange={handleFormChange}
              loading={getCountryLoading}
              loadingConfigs={fetchingMerchantConfigs}
              countries={
                getCountryPayload?.data?.responseData?.items?.map((v) => ({
                  label: v.name,
                  value: v.isO3,
                })) || []
              }
              currencies={getWalletCurrencyPayload?.data?.responseData?.items}
              settlementOptions={SETTLEMENT_PREFERENCE_OPTIONS}
            />
          }
        />
      )}
    </>
  );
};

export default Merchants;
