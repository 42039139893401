import { Grid } from "@material-ui/core";
import Select from "react-select";
import React from "react";
import "../payout.scss";
import SelectControl from "components/selectControl/SelectControl";
import { PAYOUT_TYPES } from "utils/common";

const Form = ({
  setPayload,
  payload,
  submitStatus,
  countries,
  currencies,
  gateways,
}) => {
  return (
    <form className="payout-country-form w-100">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="mb-4">
            <label htmlFor="countryCode" className="mb-1">
              Country
            </label>
            <Select
              placeholder="Nigeria"
              controlClassName={`form-control  font-small px-0 ${
                submitStatus && !payload?.countryCode ? "is-invalid" : ""
              } p-1`}
              controlErrorMsg={
                submitStatus && !payload?.countryCode && "Country is required"
              }
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              id="countryCode"
              name="countryCode"
              defaultValue={countries?.find(
                (v) => v.value === payload?.countryCode
              )}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  countryCode: e.value,
                  countryName: e.label,
                });
              }}
              options={countries}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="destinationCurrency" className="mb-1">
              Local Currency
            </label>
            <Select
              placeholder="NGN"
              controlClassName={`form-control  font-small px-0 ${
                submitStatus && !payload?.destinationCurrency
                  ? "is-invalid"
                  : ""
              } p-1`}
              controlErrorMsg={
                submitStatus &&
                !payload?.destinationCurrency &&
                "Local Currency is required"
              }
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              id="destinationCurrency"
              name="destinationCurrency"
              defaultValue={currencies?.find(
                (v) => v.value === payload?.destinationCurrency
              )}
              onChange={(e) =>
                setPayload({ ...payload, destinationCurrency: e.value })
              }
              options={currencies}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="gatewayCode" className="mb-1">
              Gateway
            </label>
            <Select
              placeholder="Gateway"
              controlClassName={`form-control  font-small px-0 ${
                submitStatus && !payload?.gatewayCode ? "is-invalid" : ""
              } p-1`}
              controlErrorMsg={
                submitStatus && !payload?.gatewayCode && "Gateway is required"
              }
              components={{ 
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              id="gatewayCode"
              name="gatewayCode"
              defaultValue={gateways?.find(
                (v) => v.value === payload?.gatewayCode
              )}
              onChange={(e) => setPayload({ ...payload, gatewayCode: e.value })}
              options={gateways}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="payoutType" className="mb-1">
              Payment Channel
            </label>
            <Select
              placeholder="MB Money"
              controlClassName={`form-control  font-small px-0 ${
                submitStatus && !payload?.payoutType ? "is-invalid" : ""
              } p-1`}
              controlErrorMsg={
                submitStatus &&
                !payload?.payoutType &&
                "Payment Channel is required"
              }
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              id="payoutType"
              name="payoutType"
              defaultValue={PAYOUT_TYPES?.find(
                (v) =>
                  v.label === payload?.payoutType ||
                  v.value === payload?.payoutType
              )}
              onChange={(e) => setPayload({ ...payload, payoutType: e.value })}
              options={PAYOUT_TYPES}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="localCharge" className="mb-1">
              Local Flat Fee{" "}
            </label>
            <input
              type="number"
              value={payload?.localCharge}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  localCharge: e.target.value ? Number(e.target.value) : null,
                })
              }
              className={`form-control ${
                submitStatus && !payload?.localCharge ? "is-invalid" : ""
              }`}
              id="localCharge"
              placeholder="#20,000"
            />
            {submitStatus && !payload?.localCharge && (
              <div className="error-desc">Charge Amount is required</div>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="localPercent">Local Charge Percent</label>
            <input
              type="number"
              name="localPercent"
              value={payload.localPercent}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  localPercent: e.target.value ? Number(e.target.value) : null,
                })
              }
              className={`form-control ${
                submitStatus && !payload?.localPercent ? "is-invalid" : ""
              }`}
              id="localPercent"
              placeholder="5%"
            />
            {submitStatus && !payload?.localPercent && (
              <div className="error-desc">Local Charge Percent is required</div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="mb-4">
            <label htmlFor="localCap" className="mb-1">
              Local Cap
            </label>
            <input
              type="number"
              value={payload?.localCap}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  localCap: e.target.value ? Number(e.target.value) : null,
                })
              }
              className={`form-control ${
                submitStatus && !payload?.localCap ? "is-invalid" : ""
              }`}
              id="localCap"
              placeholder="$200"
            />
            {submitStatus && !payload?.localCap && (
              <div className="error-desc">Local Cap is required</div>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="chargeValue" className="mb-1">
              Standard Flat Fee
            </label>
            <input
              type="number"
              value={payload?.chargeValue}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  chargeValue: e.target.value ? Number(e.target.value) : null,
                })
              }
              className={`form-control ${
                submitStatus && !payload?.chargeValue ? "is-invalid" : ""
              }`}
              id="chargeValue"
              placeholder="#2,000"
            />
            {submitStatus && !payload?.chargeValue && (
              <div className="error-desc">Standard Flat Fee is required</div>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="chargePercent">Standard Charge Percent</label>
            <input
              type="number"
              name="chargePercent"
              value={payload.chargePercent}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  chargePercent: e.target.value ? Number(e.target.value) : null,
                })
              }
              className={`form-control ${
                submitStatus && !payload?.chargePercent ? "is-invalid" : ""
              }`}
              id="chargePercent"
              placeholder="15%"
            />
            {submitStatus && !payload?.chargePercent && (
              <div className="error-desc">
                Standard Charge Percent is required
              </div>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="chargeCurrency" className="mb-1">
              Standard Currency
            </label>
            <Select
              placeholder="NGN"
              controlClassName={`form-control  font-small px-0 ${
                submitStatus && !payload?.chargeCurrency ? "is-invalid" : ""
              } p-1`}
              controlErrorMsg={
                submitStatus &&
                !payload?.chargeCurrency &&
                "Standard Currency is required"
              }
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              id="chargeCurrency"
              name="chargeCurrency"
              defaultValue={currencies?.find(
                (v) => v.value === payload?.chargeCurrency
              )}
              onChange={(e) =>
                setPayload({ ...payload, chargeCurrency: e.value })
              }
              options={currencies}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="chargeCap" className="mb-1">
              Standard Cap
            </label>
            <input
              type="number"
              value={payload?.chargeCap}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  chargeCap: e.target.value ? Number(e.target.value) : null,
                })
              }
              className={`form-control ${
                submitStatus && !payload?.chargeCap ? "is-invalid" : ""
              }`}
              id="chargeCap"
              placeholder="#200"
            />
            {submitStatus && !payload?.chargeCap && (
              <div className="error-desc">Standard Cap is required</div>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="TransactionLimitAmount">Transaction Limit Amount</label>
            <input
              type="number"
              name="TransactionLimitAmount"
              value={payload.transactionLimitAmount}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  transactionLimitAmount: e.target.value ? Number(e.target.value) : null,
                })
              }
              className="form-control"
              id="TransactionLimitAmount"
              placeholder="#2,000"
            />
            {submitStatus && !payload?.TransactionLimitAmount && (
              <div className="error-desc">Transaction Limit Amount is required</div>
            )}
          </div>
          
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
