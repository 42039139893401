import React from "react";
import { useEffect, useState } from "react";
import "./Paginations.scss";

const Pagination = ({
  totalPages,
  currentPage,
  changeCurrentPage,
  nextBtn,
  previousBtn,
}) => {
  const [firstThreeArray, setFirstThreeArray] = useState([1]);
  const [showEllipis, setShowEllipis] = useState(true);

  useEffect(() => {
    if (totalPages <= 5) {
      let fArray = [];
      for (var i = 1; i <= totalPages; i++) {
        fArray.push(i);
      }
      setFirstThreeArray(fArray);
    } else {
      if (currentPage < 3) {
        setFirstThreeArray([1, 2, 3]);
      } else {
        let fArray = [];
        let index = 1;
        for (let j = currentPage; j >= 0; j--) {
          fArray.push(j);
          if (index === 3) {
            break;
          }
          index++;
        }

        fArray.reverse();
        setFirstThreeArray(fArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  useEffect(() => {
    let fArray = [];
    if (totalPages <= 5) {
      for (var i = 1; i <= totalPages; i++) {
        fArray.push(i);
      }
      setFirstThreeArray(fArray);
    } else {
      if (currentPage < 3) {
        setFirstThreeArray([1, 2, 3]);
      } else {
        fArray.push(currentPage - 1);
        fArray.push(currentPage);
        if (currentPage + 1 < totalPages) {
          fArray.push(currentPage + 1);
        }
        if (
          currentPage === totalPages - 2 ||
          currentPage === totalPages - 1 ||
          currentPage === totalPages
        ) {
          setShowEllipis(false);
        } else {
          setShowEllipis(true);
        }
        setFirstThreeArray(fArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const prev = () => {
    if (currentPage > 1) {
      changeCurrentPage(currentPage - 1);
    }
  };
  const next = () => {
    if (currentPage < totalPages) {
      changeCurrentPage(currentPage + 1);
    }
  };

  const showEllipsis = () => {
    if (showEllipis) {
      return <div className="page-number-div">...</div>;
    }
  };
  // const isactive = (page) => {
  //   if (currentPage === page) {
  //     return true;
  //   }
  //   return false;
  // };

  const showLastPagi = () => {
    if (currentPage !== totalPages) {
      return (
        <div
          className="page-number-div"
          active={currentPage === totalPages}
          onClick={() => changeCurrentPage(totalPages)}>
          {totalPages}
        </div>
      );
    }
  };
  const showPrev = () => {
    if (currentPage !== 1) {
      return (
        <span className="arrow-icon" onClick={prev}>
          {previousBtn ? previousBtn : "<"}
        </span>
      );
    }
  };
  const showNext = () => {
    if (currentPage < totalPages) {
      return (
        <span className="arrow-icon" onClick={next}>
          {nextBtn ? nextBtn : ">"}
        </span>
      );
    }
  };

  return (
    <div className="pagination-container px-3">
      {showPrev()}
      {totalPages <= 5 ? (
        firstThreeArray.map((no, index) => {
          return (
            <React.Fragment key={index}>
              <div
                className={`page-number-div ${currentPage === no && "active"}`}
                onClick={() => changeCurrentPage(no)}>
                {no}
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment>
          {firstThreeArray.map((no, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`page-number-div ${
                    currentPage === no && "active"
                  }`}
                  onClick={() => changeCurrentPage(no)}>
                  {no}
                </div>
              </React.Fragment>
            );
          })}
          {showEllipsis()}

          {showLastPagi()}
        </React.Fragment>
      )}
      {showNext()}
    </div>
  );
};
export default Pagination;
