import actionTypes from "../actions/actionTypes";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {};

 
const transfersReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(actionTypes.POST_TRANSFERS):
      return { ...state, postTransfersLoading: true, postTransfersPayload:null, postTransfersError:null };
    case SUCCESS(actionTypes.POST_TRANSFERS):
      return {
        ...state,
        postTransfersPayload: actions.payload,
        postTransfersError: null,
        postTransfersLoading: false,
      };
    case FAILURE(actionTypes.POST_TRANSFERS):
      return {
        ...state,
        postTransfersError: actions.payload,
        postTransfersPayload: null,
        postTransfersLoading: false,
      };
      case REQUEST(actionTypes.GET_TRANSFERS):
        return { ...state, gettingTransfersLoading: true, gettingTransfersPayload:null, gettingTransfersError:null };
      case SUCCESS(actionTypes.GET_TRANSFERS):
        return {
          ...state,
          gettingTransfersPayload: actions.payload,
          gettingTransfersError: null,
          gettingTransfersLoading: false,
        };
      case FAILURE(actionTypes.GET_TRANSFERS):
        return {
          ...state,
          gettingTransfersError: actions.payload,
          gettingTransfersPayload: null,
          gettingTransfersLoading: false,
        };
      case REQUEST(actionTypes.GET_BANKS):
        return { ...state, gettingBanksLoading: true, gettingBanksPayload:null, gettingBanksError:null };
      case SUCCESS(actionTypes.GET_BANKS):
        return {
          ...state,
          gettingBanksPayload: actions.payload,
          gettingBanksError: null,
          gettingBanksLoading: false,
        };
      case FAILURE(actionTypes.GET_BANKS):
        return {
          ...state,
          gettingBanksError: actions.payload,
          gettingBanksPayload: null,
          gettingBanksLoading: false,
        };
      case REQUEST(actionTypes.ACCOUNT_LOOKUP):
        return { ...state, accountLookupLoading: true, accountLookupPayload:null, accountLookupError:null };
      case SUCCESS(actionTypes.ACCOUNT_LOOKUP):
        return {
          ...state,
          accountLookupPayload: actions.payload,
          accountLookupError: null,
          accountLookupLoading: false,
        };
      case FAILURE(actionTypes.ACCOUNT_LOOKUP):
        return {
          ...state,
          accountLookupError: actions.payload,
          accountLookupPayload: null,
          accountLookupLoading: false,
        };
      case REQUEST(actionTypes.APPROVE_TRANSFER):
        return { ...state, approveTransferLoading: true, approveTransferPayload:null, approveTransferError:null };
      case SUCCESS(actionTypes.APPROVE_TRANSFER):
        return {
          ...state,
          approveTransferPayload: actions.payload,
          approveTransferError: null,
          approveTransferLoading: false,
        };
      case FAILURE(actionTypes.APPROVE_TRANSFER):
        return {
          ...state,
          approveTransferError: actions.payload,
          approveTransferPayload: null,
          approveTransferLoading: false,
        };
    default:
      return { ...state };
  }
};

export default transfersReducer;
