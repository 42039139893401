import React, { useState, useEffect } from 'react';
import ListTable from "../../../components/Table/ListTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterMenu from 'components/Input/FilterMenu';
import Switch from "react-switch";
import { clientsFilter } from 'Constants';
import CreateModal from 'components/Modal/CreateModal';
import Pagination from 'components/paginations/Paginations';
import { Button } from 'reactstrap';
import { getSmartCheckCountryDocs, newSmartCheckCountryDocs, updateSmartCheckCountryDocs, toggleSmartCheckCountry, getSmartCheckDocument } from 'logic/actions/smartCheck';
import { useDispatch, useSelector } from 'react-redux';
import { successMessage, errorMessage } from 'logic/actions/notification';
import ToggleModal from 'components/Modal/ToggleModal';
import Select from 'react-select';

const initialFormValues = {
    name: "",
    code: "",
    currencyCode: "",
    documents: [],
};

const initialEditFormValues = {
    name: "",
    code: "",
    CurrencyCode: "",
    CountryId: "",
    Documents: [],
    Status: true,
};

const styles = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        maxWidth: "400px",
        width: "100%",
        height: "40px",
    }),

    menu: (base) => ({
        ...base,
        zIndex: 99999999,
        overflow: "auto",
    }),

};

const Country = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [createForm, setCreateForm] = useState(false);
    const [modalForm, setModalForm] = useState(false);
    const [statusData, setStatusData] = useState(null);
    const [toggleStatus, setToggleStatus] = useState(false);
    const [createModal, setCreateModal] = useState({
        open: false,
        payload: { ...initialFormValues },
    });
    const [editorModal, setEditorModal] = useState({
        open: false,
        payload: {
            ...initialEditFormValues
        },
    });
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    const {
        getCountryPayload,
        getCountryLoading,
        addNewCountryDocsLoading,
        addNewCountryDocsPayload,
        updateCountryDocsPayload,
        updateCountryDocsLoading,
        toggleCountryStatusLoading,
        toggleCountryStatusPayload,
        getDocumentsPayload,
        getDocumentsLoading,
    } = useSelector(({ smartCheckReducer }) => {
        return {
            getCountryError: smartCheckReducer?.getCountryDocsError,
            getCountryPayload: smartCheckReducer?.getCountryDocsPayload,
            getCountryLoading: smartCheckReducer?.getCountryDocsLoading,
            addNewCountryDocsLoading: smartCheckReducer?.addNewCountryDocsLoading,
            addNewCountryDocsPayload: smartCheckReducer?.addNewCountryDocsPayload,
            addNewCountryDocsError: smartCheckReducer?.addNewCountryDocsError,
            updateCountryDocsPayload: smartCheckReducer?.updateCountryDocsPayload,
            updateCountryDocsLoading: smartCheckReducer?.updateCountryDocsLoading,
            updateCountryDocsError: smartCheckReducer?.updateCountryDocsError,
            toggleCountryStatusLoading: smartCheckReducer?.toggleCountryStatusLoading,
            toggleCountryStatusPayload: smartCheckReducer?.toggleCountryStatusPayload,
            toggleCountryStatusError: smartCheckReducer?.toggleCountryStatusError,
            getDocumentsError: smartCheckReducer?.getDocumentsError,
            getDocumentsPayload: smartCheckReducer?.getDocumentsPayload,
            getDocumentsLoading: smartCheckReducer?.getDocumentsLoading,
        }
    });
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setCreateModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
        setEditorModal((prev) => ({ ...prev, payload: { ...prev.payload, [name]: value } }));
    };
    const getPaginationNumber = (page) => {
        setCurrentPage(page);
        // dispatch((page, 10, searchValue))
    }

    useEffect(() => {
        dispatch(getSmartCheckCountryDocs(currentPage, 10, searchValue
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!getCountryLoading && getCountryPayload) {
            setData(getCountryPayload?.data?.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCountryLoading]);

    const handleAutoCompleteCreateChange = (event, values, input) => { };

    // const debounceText = useCallback(debounce(async (srhTxt) => {
    // }, 500), [])

    const handleSearchChange = (event, input) => {
        // debounceText(event.target.value);
        setSearchValue(event.target.value);
    };

    const clearSearch = () => {
        // debounceText("");
        setSearchValue("");
    };

    const showFilterMenu = () => { };

    const handleManageClick = (row) => {
        setEditorModal((prev) => ({
            ...prev,
            open: true,
            payload: {
                name: row?.CountryName,
                code: row?.CountryCode,
                CountryId: row?.CountryId,
                CurrencyCode: row?.CurrencyCode,
                Documents: row?.Documents,
                Status: row?.Status,
            },
        }));

    };
    console.log(editorModal.payload)
    const handleChange = (item) => {
        setStatusData(item);
        setModalForm(true);
    };

    const toggleCountryStatus = () => {
        setToggleStatus(true);
        dispatch(toggleSmartCheckCountry(statusData));
    };

    useEffect(() => {
        if (!toggleCountryStatusLoading && toggleCountryStatusPayload && toggleStatus) {
            setToggleStatus(false);
            if (toggleCountryStatusPayload?.data?.requestSuccessful) {
                setModalForm(false);
                dispatch(successMessage(toggleCountryStatusPayload?.data?.message || "Status Updated Successfully"));
                dispatch(getSmartCheckCountryDocs(currentPage, 10, searchValue
                ));
            } else {
                dispatch(errorMessage(toggleCountryStatusPayload?.data?.message || "Something went wrong!"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleCountryStatusLoading]);


    const updateCountry = () => {
        let errorStatus = false;
        for (let data in editorModal.payload) {
            if (!editorModal.payload[data] || editorModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }

        if (!errorStatus) {
            dispatch(updateSmartCheckCountryDocs(editorModal.payload));
            setSubmitStatus(true); // Add this line to reset submitStatus
        }
    };

    useEffect(() => {
        if (!updateCountryDocsLoading && updateCountryDocsPayload?.data?.requestSuccessful && submitStatus) {
            setEditorModal((prev) => ({
                ...prev,
                open: false,
            }));
            setSubmitStatus(false);
            dispatch(getSmartCheckCountryDocs());
            dispatch(successMessage("Country updated successfully"))
        } else if (!updateCountryDocsLoading && !updateCountryDocsPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(updateCountryDocsPayload?.data?.message || "Error occurred while updating item"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateCountryDocsLoading]);

    const createNewCountry = () => {
        let errorStatus = false;
        for (let data in createModal.payload) {
            if (!createModal.payload[data] || createModal.payload[data] === "") {
                return (errorStatus = true, setSubmitStatus(true))
            }
        }
        if (!errorStatus) {
            dispatch(newSmartCheckCountryDocs(createModal.payload));
            setSubmitStatus(true); // Add this line
        }
    }

    useEffect(() => {
        if (!addNewCountryDocsLoading && addNewCountryDocsPayload?.data?.requestSuccessful && submitStatus) {
            dispatch(getSmartCheckCountryDocs());
            setCreateModal((prev) => ({
                ...prev,
                payload: { ...initialFormValues },
            }));
            setCreateForm(false);
            setSubmitStatus(false);
            dispatch(successMessage("Country created successfully"))
        } else if (!addNewCountryDocsLoading && !addNewCountryDocsPayload?.data?.requestSuccessful && submitStatus) {
            setSubmitStatus(false);
            dispatch(errorMessage(addNewCountryDocsPayload?.data?.message || "Error occurred while updating"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNewCountryDocsLoading]);
    const itemsPerPage = 10;

    const format = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const formattedData = data
            ?.filter(
                (item) =>
                    item?.CountryName?.toLowerCase().includes(searchValue?.toLowerCase() ?? '')
            )
            ?.slice(startIndex, endIndex)
            ?.map((item) => {
                let documentTypesArray = [];
                if (item?.Documents) {
                    try {
                        documentTypesArray = JSON.parse(item.Documents);
                        if (!Array.isArray(documentTypesArray) || !documentTypesArray.every(entry => typeof entry === 'string')) {
                            documentTypesArray = [];
                        }
                    } catch (error) {
                        documentTypesArray = [];
                    }
                }
            return {
                name: item?.CountryName ?? '', // Use empty string as fallback for null or undefined
                code: item?.CountryCode ?? '',
                documentTypes:
                    documentTypesArray?.join(', ')
                , // Join the documentTypes array with a comma and space
                ItemId: item?.CountryId ?? '',
                CurrencyCode: item?.CurrencyCode ?? '',
                status: item?.Status ?? '',
                Actions: (
                    <div className='d-flex align-items-center'>
                        <Switch
                            onChange={() => handleChange(item)}
                            onColor="#175FFF"
                            checked={item?.Status === 'Active' ? true : false}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                        <Button
                            size="sm"
                            color="link"
                            className="text-primary ml-2 font-weight-bold"
                            onClick={() => handleManageClick(item)}>
                            Edit
                        </Button>
                    </div>
                ),
            };
            });
        return formattedData;
    };

    return (
        <>
            <div className="customScroll customScroll d-flex aling-items-center justify-space-between overflow-y-hidden">
                <FilterMenu
                    caption="Clients List"
                    totalCount={10}
                    searchLabel="Search.."
                    filterOptions={clientsFilter}
                    filterdefaultValueOptions={{
                        label: "All",
                        value: "all",
                    }}
                    handleAutoCompleteCreateChange={handleAutoCompleteCreateChange}
                    showFilter={false}
                    showSearch={true}
                    showCreateBtn={true}
                    btnName="New Country&nbsp;&nbsp;+"
                    openCreate={() => {
                        dispatch(getSmartCheckDocument());
                        setCreateForm(true)
                    }}
                    handleSearchChange={handleSearchChange}
                    searchValue={searchValue}
                    showFilterMenu={showFilterMenu}
                    filterLabel="Filter by"
                    searchName="keyword"
                    clearSearch={clearSearch}
                    entriesName="Entries"
                    filterValue={"Search"}
                    entriesOptions={{}}
                    entriesdefaultValueOptions={{
                        label: `Entries`,
                        value: 20,
                    }}
                    entriesValue={{}}
                    entriesLabel="Entries"
                />
            </div>

            <ListTable
                header={[
                    { name: "Country Name" },
                    { name: "Code" },
                    { name: "Document Types" },
                    { name: "Status" },
                    { name: "" },
                ]}
                isSearching={getCountryLoading}
                showError={false}
                style={{ opacity: 1 }}
                pageOfItems={format()}
                content={
                    <TableBody>
                        {format()?.map((row, index) => (
                            <TableRow key={index} style={{ cursor: "pointer" }}>
                                <TableCell component="th" scope="row">
                                    <span className="capitalise">{row.name}</span>
                                </TableCell>
                                <TableCell align="left">{row.code}</TableCell>
                                <TableCell align="left">
                                    {row.documentTypes ? row.documentTypes : "N/A"}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="status-flag">
                                        <span
                                            className={`dot-status ${row.status === "Active" ? "dot-active" : "dot-inactive"
                                                }`}>
                                            .
                                        </span>
                                        {row.status === "Active" ? "Enabled" : "Disabled"}
                                    </div>
                                </TableCell>
                                <TableCell align="left">{row.Actions}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                }
            />
            <br />
            {format()?.length > 0 ? (
                <div className="paginate-content">
                    <div  >
                        Showing Page {currentPage}
                        of {
                            Math.ceil(
                                data?.length / itemsPerPage
                            )
                        }
                    </div>
                    <div>
                        <Pagination
                            totalPages={
                                Math.ceil(
                                    data?.length / itemsPerPage
                                )
                            }
                            currentPage={currentPage}
                            changeCurrentPage={(p) => getPaginationNumber(p)}
                            previousBtn="Prev"
                            nextBtn="Next"
                        />
                    </div>
                </div>
            ) : null}

            {/* Create modal component */}
            {createForm && (
                <CreateModal
                    title="New Country"
                    createForm={createForm}
                    hideCreateForm={() => setCreateForm(false)}
                    onCreate={createNewCountry}
                    creating={addNewCountryDocsLoading}
                    disableCreate={addNewCountryDocsLoading}
                    cancelText="Cancel"
                    createText="Create"
                    content={
                        <form className="create-form">
                            <div>
                                <label id="name">Name</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Country Name'
                                    className="form-control"
                                    name="name"
                                />
                                {submitStatus && !createModal?.payload?.name && (
                                    <div className="error-desc">Name is required</div>
                                )}
                            </div>
                            <div>
                                <label htmlFor='documents' >Document type</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    styles={styles}
                                    name="documents"
                                    options={getDocumentsPayload?.data?.data?.map(val => ({ value: val?.DocumentId, label: val?.Name }))}
                                    onChange={e => {
                                        setEditorModal((prev) => ({ ...prev, payload: { ...prev.payload, documents: Array.isArray(e) ? e.map(x => x.Name) : [] } }));
                                    }}
                                    classNamePrefix="document types"
                                />
                                {submitStatus && editorModal?.payload.documents.length <= 0 && (
                                    <div className="error-desc">Document types code is required</div>
                                )}
                            </div>
                            <div>
                                <label id="code">Code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Country Code'
                                    className="form-control"
                                    name="code"
                                />
                                {submitStatus && !createModal?.payload.code && (
                                    <div className="error-desc">Code is required</div>
                                )}
                            </div>
                            <div>
                                <label htmlFor='narration' >Currency code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Currency code here'
                                    className='form-control'
                                    name="currencyCode"
                                />
                                {submitStatus && !createModal?.payload.currencyCode && (
                                    <div className="error-desc">Currency code is required</div>
                                )}
                            </div>

                        </form>
                    }
                />
            )}

            {editorModal.open && (
                <CreateModal
                    onCancelClick={() => setEditorModal((prev) => ({ ...prev, open: false }))}
                    title={`Edit Country`}
                    createForm={editorModal.open}
                    hideCreateForm={() =>
                        setEditorModal({ open: false, payload: {}, })
                    }
                    onCreate={updateCountry}
                    creating={updateCountryDocsLoading}
                    disableCreate={
                        updateCountryDocsLoading
                    }
                    cancelText={"Cancel"}
                    createText={"Update"}
                    content={
                        <form className="create-form">
                            <div>
                                <label id="name">Name</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Services Name'
                                    className="form-control"
                                    name="name"
                                    defaultValue={editorModal.payload.name}
                                />
                                {submitStatus && !editorModal?.payload?.name && (
                                    <div className="error-desc">Name is required</div>
                                )}
                            </div>
                            <div>
                                <label htmlFor='documents' >Document type</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    styles={styles}
                                    name="documents"
                                    defaultValue={
                                        editorModal.payload.Documents
                                            ? JSON.parse(editorModal.payload.Documents)
                                                .filter(val => typeof val === 'string') // Filter out non-string values
                                                .map((val, index) => ({ value: index, label: val }))
                                            : []
                                    }
                                    options={getDocumentsPayload?.data?.data?.map(val => ({ value: val?.DocumentId, label: val?.Name }))}
                                    onChange={e => {
                                        setEditorModal((prev) => ({ ...prev, payload: { ...prev.payload, Documents: Array.isArray(e) ? e.map(x => x.Name) : [] } }));
                                    }}
                                    classNamePrefix="document types"
                                />
                                {submitStatus && editorModal?.payload.Documents.length <= 0 && (
                                    <div className="error-desc">Document types code is required</div>
                                )}
                            </div>
                            <div>
                                <label id="code">Code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Services Code'
                                    className="form-control"
                                    name="code"
                                    defaultValue={editorModal.payload.code}
                                />
                                {submitStatus && !editorModal?.payload.code && (
                                    <div className="error-desc">Code is required</div>
                                )}
                            </div>
                            <div>
                                <label htmlFor='narration' >Currency code</label>
                                <input
                                    onChange={(e) => { handleFormChange(e) }}
                                    placeholder='Enter Currency code here'
                                    className='form-control'
                                    name="CurrencyCode"
                                    defaultValue={editorModal.payload.CurrencyCode}
                                />
                                {submitStatus && !editorModal?.payload.CurrencyCode && (
                                    <div className="error-desc">Currency code is required</div>
                                )}
                            </div>
                        </form>
                    }
                />
            )
            }


            {modalForm && (
                <ToggleModal
                    title={statusData.Status === "Active" ? "Disable Merchant?" : "Enable Merchant?"}
                    toggleForm={modalForm
                    }
                    onToggle={() => toggleCountryStatus()}
                    toggling={toggleCountryStatusLoading}
                    disableToggle={toggleCountryStatusLoading}
                    hideToggleModal={() => setModalForm(false)}
                    toggleText={statusData.Status === "Active" ? "Disable" : "Enable"}
                    textContent={
                        statusData.Status === "Active"
                            ? "Are you sure you want to disable this item?"
                            : "Are you sure you want to enable this item?"
                    }
                />
            )}
        </>
    )
}

export default Country;