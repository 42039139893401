import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { CircularProgress } from "@material-ui/core";

export default function ToggleModal(props) {
  const [open] = React.useState(props.toggleForm);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    props.hideToggleModal();

  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ textAlign: "center" }}
      >
        {!props.hideIcon && <ErrorOutlineIcon
          style={{
            fontSize: "80px",
            color: "#ccc",
            margin: "auto",
            marginTop: "25px",
          }}
        />}

        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.textContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: "auto", marginBottom: "25px" }}>
          <button
            onClick={handleClose}
            className="btn btn-sm text-white btn-cancel"
          >
            {props.cancelText||"Cancel"}

      </button>
          {props.toggleText && <button
            disabled={props.disableToggle}
            onClick={props.onToggle}
            className="btn btn-sm  text-white btn-delete"
            style={{ backgroundColor: props.toggleText === "Disable" ? '#F34F44' : '#28A745', borderColor: props.toggleText === "Disable" ? '#F34F44' : '#28A745' }}
          // style={props.toggleItem.isEnabled ? 'backgroundColor: "red"' : 'backgroundColor: "#28a745"'}
          >
            {props.toggling && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} {props.toggleText}

          </button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
