import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Aux from "../../components/hoc/_Aux";
import './Collect.scss';
import Merchants from "./merchants/merchants";
import Request from "./request/request";
import ChargeBack from "./chargeBack/chargeBack";
import Channel from "./channel/channel";
import Country from "./country/country";
import Lookup from "./lookup/lookup";

const Collect = () => {
  const [tab, setTab] = useState("merchants")
  
    return (
      <Aux>
        <Row className="collect-account-container">
          <Col>
              <div className="content-header">
                <div className="service-name">Collect</div>
                <div className="service-items">
                  <div className={`item ${tab === 'merchants' && 'active'}`} onClick={()=>setTab('merchants')}>Merchants</div>
                  <div className={`item ${tab === 'request' && 'active'}`} onClick={()=>setTab('request')}>Request</div>
                  <div className={`item ${tab === 'chargeback' && 'active'}`} onClick={()=>setTab('chargeback')}>Chargeback</div>
                  <div className={`item ${tab === 'channel' && 'active'}`} onClick={()=>setTab('channel')}>Channel</div>
                  <div className={`item ${tab === 'country' && 'active'}`} onClick={()=>setTab('country')}>Country</div>
                  <div className={`item ${tab === "lookup" && "active"}`} onClick={() => setTab("lookup")}>Lookup</div>
                </div>

                </div>
              <div className="tab-items">
                  {tab === "merchants" && <Merchants/>}
                  {tab === "request" && <Request/>}
                  {tab === "chargeback" && <ChargeBack/>}
                  {tab === "channel" && <Channel/>}
                  {tab === "country" && <Country/>}
                  {tab === "lookup" && <Lookup/>}

              </div>
          </Col>
        </Row>
    </Aux>
    );
  }

export default Collect;
